import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function usePuzzleHistoryApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    Error,
    {
      skills?: string;
      time_range?: number;
      access_user_key?: string;
    }
  >(
    async filters => {
      const queryParams = new URLSearchParams();
      if (filters.skills) queryParams.append('skills', filters.skills);
      if (filters.time_range)
        queryParams.append('time_range', filters.time_range.toString());
      if (filters.access_user_key)
        queryParams.append('access_user_key', filters.access_user_key); // Add only if provided

      const queryString = queryParams.toString();
      const response = await ChessRestClient.get(
        `assessment/v1/learning/get_assessment_history/?${
          queryString ? `${queryString}` : ''
        }`,
      );
      console.log(response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        console.log('Assessment history data = ', data);
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
