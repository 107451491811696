import React from 'react';
import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import {Colors} from '@/styles';
import CCColors from '@/Utils/CCColors';
import {StyleSheet, View} from 'react-native';
import {styles} from '../../styles';
import {TouchableOpacity} from 'react-native-gesture-handler';

const NestedDropDown = ({
  dropdownData,
  setNestedDropdown,
  setDropdown,
  setOpen,
}) => {
  return (
    <Box flexDirection="column">
      {dropdownData.map((dropdownItem, index) => (
        <Box
          key={dropdownItem.key}
          style={{
            width: 150,
            backgroundColor: CCColors.White,
            borderRadius: 8,
            shadowColor: Colors.primaryDark,
            shadowRadius: 8,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowOpacity: 0.2,
            borderBottomWidth:
              index === dropdownData?.length - 1 ? 0 : StyleSheet.hairlineWidth,
            borderColor: CCColors.Grey.Border,
          }}>
          <TouchableOpacity>
            <CCButton
              onPress={() => {
                console.log(
                  'Nested dropdown item clicked:',
                  dropdownItem.title,
                );
                dropdownItem.onPress();
                setNestedDropdown(false);
                setDropdown(false);
                setOpen(false);
              }}
              style={styles.userMenuItem}
              contentContainerStyle={styles.userMenuItemContent}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Box
                  flexDirection="column"
                  style={{
                    paddingVertical: 6,
                  }}>
                  <Box
                    flexDirection="row"
                    gap={8}
                    style={{alignItems: 'center', justifyContent: 'center'}}>
                    {dropdownItem.icon}
                    <CCText style={styles.userMenuItemText}>
                      {dropdownItem.title}
                    </CCText>
                  </Box>
                </Box>
              </View>
            </CCButton>
          </TouchableOpacity>
        </Box>
      ))}
    </Box>
  );
};

export default NestedDropDown;
