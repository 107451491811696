/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useRef, useState} from 'react';
import Box from '@/Atoms/Box';
import useGetUserGamesApi from '@/Hooks/GetUserGamesApi/useGetUserGamesApi';
import CCPackageTable from '@/Atoms/CCPackageTable';
import {useNavigation, useRoute} from '@react-navigation/native';
import CCText from '@/Atoms/CCText';
import {ScreenName} from '@/navigators/StackConstants';
import CCButton from '@/Atoms/CCButton';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import eco from '@/Assets/Eco/eco.json';
import CCColors from '@/Utils/CCColors';
import {FontSizes, WINDOW_WIDTH, vs} from '@/Utils/Dimensions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudDownload} from '@fortawesome/free-solid-svg-icons';
import pullIncrementalGamesApi from '@/Hooks/PullIncrementalGamesApi/pullIncrementalGamesApi';
import ProgressBar from '@/Atoms/ProgressBar';
import pullGameStatusApi from '@/Hooks/PullIncrementalGamesApi/pullGameStatusApi';
import usePullGamesApi from '@/Hooks/PullIncrementalGamesApi/pullIncrementalGamesApi';
import useMarkFavouriteGameApi from '@/Hooks/MarkFavouriteGameApi/markFavouriteGameApi';
import AntDesign from 'react-native-vector-icons/AntDesign';
import BlackRook from '@/Assets/Images/ChessPieces/Black-Rook.svg';
import WhiteRook from '@/Assets/Images/ChessPieces/White-Rook.svg';
import {useFocusEffect} from '@react-navigation/native';
import {View, Image, ActivityIndicator} from 'react-native';
import CCLoader from '@/Atoms/CCLoader';
import {logEvent} from '@/Utils/GAEvents';
import useGAPageView from '@/navigators/useGAPageView';
import customStyles from './styles';
import Pagination from './Pagination';
import Filters from './components/Filters';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Spacer from '@/Atoms/Spacer';
import Divider from '@/Atoms/Divider/Divider';
import GamesTable from '@/Components/GamesTable';
import {capitalizeFirstLetter, debounce, formatDate} from '@/Utils/CommonUtils';
import SubscriptionModal from '../Subscription/components/Modals';
import ConditionalRender from '@/Atoms/ConditionalRender';
import WebFilters from './components/WebFilters';
import search from '../../Assets/Images/png/search.png';
import CCTextInput from '@/Atoms/CCTextInput';
import useAlertModalStore from '@/Store/AlertModalStore';
import AnimatedLoaderText from '@/Atoms/CCLoaderText';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {convertTimeControlToMins} from '../ChessAnalysis/utils';

export const FavStar = ({
  isFav,
  gameUuid,
  callBack,
}: {
  isFav: boolean;
  gameUuid: string;
  callBack: () => void;
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(isFav);
  }, [isFav]);

  return (
    <CCButton
      type="none"
      onPress={() => {
        setIsSelected(prev => !prev);
        callBack?.();
      }}>
      {isSelected ? (
        <AntDesign name="star" size={20} color={CCColors?.Primary?.Default} />
      ) : (
        <AntDesign name="staro" size={20} color={CCColors?.Primary?.Default} />
      )}
    </CCButton>
  );
};
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const Games: React.FC = ({dashboardData}) => {
  const {isMobileOrTab} = useMediaQuery();
  // let isMobileOrTab = true;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState(0);
  const [imported_games, setImportedGames] = useState(0);
  const [total_games, setTotalGames] = useState(0);
  const [progress, setProgress] = useState(0);
  const [import_in_progress, setImportInProgress] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({});
  const [iconResult, setIconResult] = useState(0);
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] =
    useState(false);
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();
  const [filterText, setFilterText] = useState<string>('');
  const openAlertModal = useAlertModalStore(state => state.openModal);
  const initialButtonState = {
    '1': 'Initial Button Text in Row 1',
    '2': 'Initial Button Text in Row 2',
    '3': 'Initial Button Text in Row 3',
  };

  const [favouriteState, setFavouriteState] = useState(initialButtonState);

  const handleOnGetUserSuccess = data => {
    setLimitType(data?.limit_type);
    setUsageLimit(data?.usage_limit);
    setPlanName(data?.plan_name);
    if (data?.data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
    }
  };

  const handleOnGetUserError = error => {
    setLimitType(data?.limit_type);
    setUsageLimit(data?.usage_limit);
    setPlanName(data?.plan_name);
    console.log('handleOnGetUserError', error);
    if (data?.data?.is_permitted === false) {
      setSubscriptionModalOpen(true);
    }
  };
  const {
    mutate: fetchGames,
    data,
    isLoading,
  } = useGetUserGamesApi(handleOnGetUserSuccess, handleOnGetUserError);
  const updateFavouriteStatus = (game_uuid, value) => {
    //printCompleteState();
    let favouriteTxt = '⭐';
    let noFavouriteText = '☆';

    // console.log(
    //   'start state of ' + game_uuid + ' is : ' + favouriteState[game_uuid],
    // );
    let val = value == 1 ? favouriteTxt : noFavouriteText;
    // console.log('val is : ' + val);
    setFavouriteState(prevState => ({...prevState, [game_uuid]: val}));
    // console.log(
    //   'end state of ' + game_uuid + ' is : ' + favouriteState[game_uuid],
    // );

    //printCompleteState();
  };

  const addNewFavouriteStatus = (game_uuid, value) => {
    //printCompleteState();

    // console.log('called addNewFavouriteStatus for : ', game_uuid);
    let favouriteTxt = '⭐';
    let noFavouriteText = '☆';

    // console.log(
    //   'start state of ' + game_uuid + ' is : ' + favouriteState[game_uuid],
    // );
    let val = value == 1 ? favouriteTxt : noFavouriteText;
    if (favouriteState.hasOwnProperty(game_uuid)) {
      // console.log('updating existing property : ', game_uuid);
      // updateFavouriteStatus(game_uuid, value);
    } else {
      const updatedButtonText = {...favouriteState};
      // console.log('adding new property : ', game_uuid);
      updatedButtonText[game_uuid] = val;
      //setFavouriteState(updatedButtonText);
      // setFavouriteState(prevState => ({...prevState, [game_uuid]: val}));
      // console.log('property is set now : ', favouriteState[game_uuid]);
      //printCompleteState();
    }
    return val;
  };

  const printCompleteState = () => {
    let completeStateString = '';
    Object.keys(favouriteState).forEach(game_uuid => {
      const text = favouriteState[game_uuid];
      completeStateString += ' ';
      completeStateString += game_uuid;
      completeStateString += ' : ';
      completeStateString += `Text - ${text}\n`;
    });
    // console.log('current state map is : ', completeStateString);
  };

  const paginate = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      // console.log('page:', pageNumber);
      fetchGames({
        page: pageNumber,
        assessmentId: route.params?.assessmentId,
        filter_date: filter_date,
        ...filters,
        opening_mainline: route.params?.opening_mainline,
        opening: route.params?.opening,
        endgame_type: route.params?.endgame_type,
      });
    }
  };

  // useEffect(() => {
  //   fetchGames({page: 1, filter_date: filter_date});
  // }, [filter_date]);

  useEffect(() => {
    // console.log('Updated favourite state:', favouriteState);
  }, [favouriteState]);

  const handleBookMarkUpdateSuccess = (responseData: any) => {
    // console.log('MarkFavourite Success : ', responseData);
    // console.log('1 Setting Faourite to : ', responseData.data.result);
    updateFavouriteStatus(
      responseData.data.game_uuid,
      responseData.data.result,
    );

    setIconResult(responseData.data.result);
  };

  const handleBookMarkUpdateFailure = (responseData: any) => {
    // console.log('MarkFavourite Failure: ', responseData);
    // console.log('2 Setting Faourite to : ', responseData.data.result);
    updateFavouriteStatus(
      responseData.data.game_uuid,
      responseData.data.result,
    );
  };

  const handleFilter = newFilters => {
    const queryParams = new URLSearchParams();
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();

    setFilters(newFilters);
    setCurrentPage(1);

    const updatedFilters = {
      // ...filters,
      ...newFilters,
      page: 1, // Always reset to page 1 when applying new filters
    };

    console.log('updatedFilters: ', updatedFilters);
    console.log('querystring:', queryString);

    // Update route params
    navigation.setParams({
      // ...route.params,
      filter_date: updatedFilters.filter_date,
      assessmentId: updatedFilters.assessmentId,
      opening_mainline: updatedFilters.opening_mainline,
      opening: updatedFilters.opening,
      query: queryString,
      endgame_type: updatedFilters.endgame_type,
    });

    fetchGames(updatedFilters);
  };

  const timer: any = useRef(null);

  const handleSuccess = (apiData: any) => {
    // console.log('Handling success .. ', count);
    // console.log(apiData);
    setCount(count + 1);
    if (count >= 25) {
      // console.log('Stopping further pulls for count exceeding 25');
      setImportInProgress(false);
      clearInterval(timer.current);
      fetchGames({page: 1});
    } else if (apiData.data.status == 'COMPLETED') {
      setImportInProgress(false);
      clearInterval(timer.current);
      fetchGames({page: 1});
      // console.log('API Progress is completed');
    } else {
      const {imported_games, total_games} = apiData.data.data;
      // console.log('Continuing to update progress ..');
      setImportedGames(imported_games);
      setTotalGames(total_games);
      setProgress(imported_games / total_games);
    }
  };

  const {
    mutate: pullGamesStatus,
    data: pullGamesStatusData,
    error: pullGamesStatusError,
  } = pullGameStatusApi(handleSuccess, () => {});

  const {
    mutate: pullGames,
    data: pullGamesData,
    error: pullGamesError,
  } = usePullGamesApi(
    () => {
      setImportInProgress(true);
      timer.current = setInterval(pullGamesStatus, 1000);
    },
    () => {},
  );

  const {
    mutate: markFavourite,
    data: markFavouriteData,
    error: markFavouriteError,
  } = useMarkFavouriteGameApi(
    handleBookMarkUpdateSuccess,
    handleBookMarkUpdateFailure,
  );

  const [tableData, setTableData] = useState<
    Array<
      Array<{
        name: string;
        type: 'text' | 'link' | 'dropdown';
        url?: string;
        options?: string[];
      }>
    >
  >([]);
  const [tabHeaders, setTabHeaders] = useState<string[]>([
    'Opponent Details',
    'Opponent Play',
    'My Play',
    'Result',
    'Solve positions',
    '',
    '',
  ]);

  const openingMap = {};
  eco.map(e => {
    openingMap[e.eco] = e.name;
  });

  const navigation = useNavigation();
  const [filter_date, setFilterDate] = useState<string>('');
  const [assessmentId, setAssessmentId] = useState<String>('');
  const [opening_mainline, setOpening] = useState<String>('');
  const [color, setColor] = useState<String>('');
  const convertToReadableString = (key: string) => {
    if (key && key.indexOf('_') >= 0) {
      let tokens = key.split('_');
      tokens[0] = tokens[0].charAt(0).toUpperCase() + tokens[0].slice(1);
      return tokens.join(' ');
    } else {
      return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };

  const route = useRoute();

  function decodeAndSplit(input: String) {
    return input
      .split('%2C')
      .map(item => decodeURIComponent(item.replace(/\+/g, ' ')));
  }

  useFocusEffect(
    React.useCallback(() => {
      // TODO: validate date
      setAssessmentId(route.params?.assessmentId);
      setOpening(route.params?.opening_mainline);
      setColor(route.params?.color);
      // setFilterDate(() => {
      //   const date = route.params?.filter_date;
      //   const assessmentId = route.params?.assessmentId;
      //   const opening = route.params?.opening;
      //   fetchGames({page: 1, filter_date: date, assessmentId, opening});
      //   return date;
      // });
      setFilterDate(() => {
        const date = route.params?.filter_date;
        const assessmentId = route.params?.assessmentId;
        const opening = route.params?.opening;

        const queryString = route?.params?.query;
        const obj = {};

        if (queryString) {
          queryString?.split('&')?.forEach(item => {
            const parts = item?.split('=');
            console.log(parts[1]);
            obj[parts[0]] = decodeAndSplit([parts[1]].toString());
          });
        }

        console.log('query string object is:', obj);

        // console.log(queryString, 'opening', opening, obj);
        let encodedParts;
        let decodedParts;
        if (Object.keys(obj).length > 0 && obj?.opening_mainline) {
          encodedParts = obj?.opening_mainline[0]?.split('%2C');

          // Step 2: Decode each part
          decodedParts = encodedParts?.map(part =>
            decodeURIComponent(part.replace(/\+/g, ' ')),
          );
        }
        console.log('inside usefocuseffect calling fetch games....');

        fetchGames({
          page: 1,
          filter_date: date,
          assessmentId,
          opening_mainline: route.params?.opening_mainline,
          opening: opening,
          endgame_type: route.params?.endgame_type,
          ...obj,
        });
        return date;
      });
    }, [
      route.params?.filter_date,
      route.params?.assessmentId,
      // route.params?.opening_mainline,
    ]),
  );
  function modalFunctions() {
    setImportInProgress(true);
    pullGames();
  }
  const blacklistCols = ['userid', 'user_key', 'id'];
  const whitelistCols = [
    'uuid',
    'end_time',
    'time_class',
    'result',
    'opponent_username',
    'opening',
  ];

  const removeDateFilter = () => {
    // setFilterDate("")
    navigation.navigate(ScreenName?.Games, {
      filter_date: undefined,
      assessmentId: undefined,
      opening: undefined,
    });
  };
  useGAPageView(ScreenName.Games);
  // useEffect(() => {
  //   logEvent('pageview');
  // }, []);
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'game review' : 'game reviews'
  } is exhausted. Upgrade now to continue with the game analysis.`;

  useEffect(() => {
    if (data && Array.isArray(data?.data?.data)) {
      setTotalPages(data.data.total_pages);

      const formattedData = data.data.data.map(item => {
        // Convert each property in the item to the desired format
        console.log('game item is .... : ', item);
        const game_id = item.id;
        const game_url = item.url;
        const game_uuid = item.uuid;
        const is_favourite = item?.is_favourite;
        // console.log(game_uuid + ' favourite is ' + item['is_favourite']);
        const time_control = convertTimeControlToMins(item?.time_control);
        const time_class = capitalizeFirstLetter(item?.time_class);
        let favouriteTxt = '⭐';
        let noFavouriteText = '☆';

        let val = is_favourite == 1 ? favouriteTxt : noFavouriteText;

        let buttonVal = addNewFavouriteStatus(game_uuid, is_favourite);
        //printCompleteState();

        let game_result = item.result;
        const game_opponent = item.opponent_username;
        const player = item.username;
        const player_rating = item.rating;
        const opponent_rating = item.opponent_rating;
        const game_opening = item.opening_mainline;
        const game_time_class = item.time_class;
        const game_date = item.end_time;
        const game_platform = item.platform;

        const formatDateToLocal = (dateString: string) => {
          const date = dayjs.utc(dateString).tz(dayjs.tz.guess());
          const day = date.date();
          const ordinal = getOrdinal(day);
          return date.format(`D[${ordinal}] MMM 'YY @ h:mm A`);
        };

        const getOrdinal = (day: number): string => {
          if (day > 3 && day < 21) return 'th';
          switch (day % 10) {
            case 1:
              return 'st';
            case 2:
              return 'nd';
            case 3:
              return 'rd';
            default:
              return 'th';
          }
        };
        let itemValues: any = [];

        itemValues.push({
          name: isMobileOrTab ? (
            <Box style={customStyles.cellWrapperBox}>
              <CCText style={customStyles.cellText}>
                {game_opponent}({item.opponent_rating})
              </CCText>
            </Box>
          ) : (
            <Box style={customStyles.cellWrapperBox}>
              <CCText style={customStyles.cellText}>{game_opponent}</CCText>
              <Box type="row-flex-start">
                <CCText
                  color={CCColors.Grey.Grey}
                  fontSize={FontSizes[11]}
                  style={customStyles.cellText}>
                  {game_platform} |{' '}
                </CCText>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontSize={FontSizes[11]}
                  style={customStyles.cellText}>
                  {game_time_class} |{' '}
                </CCText>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontSize={FontSizes[11]}
                  style={customStyles.cellText}>
                  {game_platform !== 'OTB'
                    ? formatDateToLocal(game_date)
                    : formatDateToLocal(game_date).split('@')[0]}
                </CCText>
              </Box>
              {/* <CCText style={customStyles.cellText}>{game_opponent}</CCText>
              <CCText style={customStyles.cellText}>
                ({item['opponent_rating']})
              </CCText> */}
            </Box>
          ),
          type: 'text',
        });

        // if(isMobileOrTab)
        itemValues.push({
          name: isMobileOrTab ? (
            <Box type="row-flex-start">
              {/* <Spacer spacing={4} horizontal /> */}
              <CCText>{game_platform}</CCText>
              <Divider style={{marginHorizontal: 5}} isVertical />
              <CCText>{game_time_class}</CCText>
              <Divider style={{marginHorizontal: 5}} isVertical />
              <CCText>{game_date}</CCText>
            </Box>
          ) : (
            <Box style={customStyles.cellWrapperBox}>
              <Box type="row-flex-start">
                <Image
                  style={{
                    width: 20,
                    height: 27,
                    // backgroundColor: CCColors.Primary.lightYellow,
                  }}
                  resizeMethod="resize"
                  source={
                    item.color?.toLowerCase() !== 'black'
                      ? BlackRook
                      : WhiteRook
                  }
                />
                <Spacer spacing={5} horizontal />
                <Box>
                  <CCText color={CCColors?.Grey.Grey}>
                    Avg time:{' '}
                    {item?.opponent_average_time
                      ? item?.opponent_average_time + 's'
                      : 'NA'}
                  </CCText>
                  {/* <CCText>{item?.['opponent_average_time']}s</CCText> */}
                  <CCText style={customStyles.cellText}>
                    Castle:{' '}
                    {`${
                      item?.opponent_castling_index
                        ? `#${item?.opponent_castling_index}`
                        : 'NA'
                    }`}
                  </CCText>
                  {item['opponent_accuracy'] > 0 && (
                    <CCText color={CCColors.Grey.Grey} fontSize={14}>
                      Opp accuracy: {item['opponent_accuracy'].toFixed(1)}%
                    </CCText>
                  )}
                </Box>
              </Box>

              {/* <CCText style={customStyles.cellText}>{game_platform}</CCText>
              <CCText style={customStyles.cellText}>
                Color: {item['color']}
              </CCText>
              <CCText style={customStyles.cellText}>
                Time Control: {game_time_class}
              </CCText>
              <CCText style={customStyles.cellText}>
                Played on: {new Date(game_date).toDateString()}
              </CCText> */}
            </Box>
          ),
          type: 'highlighted_text',
        });

        itemValues.push({
          name: isMobileOrTab ? (
            <Box style={customStyles.mobileLine}>
              <CCText color={CCColors?.Grey.Grey}>Opponent</CCText>
              <Box type="row-flex-start">
                <Image
                  style={{
                    width: 20,
                    height: 27,
                    // backgroundColor: CCColors.Primary.lightYellow,
                  }}
                  resizeMethod="resize"
                  source={
                    item.color?.toLowerCase() !== 'black'
                      ? BlackRook
                      : WhiteRook
                  }
                />
                <Spacer spacing={5} horizontal />
                <CCText color={CCColors?.Grey.Grey}>Avg time: </CCText>
                <CCText>
                  {item?.opponent_average_time
                    ? item?.opponent_average_time + 's'
                    : 'NA'}
                </CCText>
                <Divider isVertical style={{marginHorizontal: 5}} />
                <CCText color={CCColors?.Grey.Grey}>Castle: </CCText>
                <CCText>move #{item?.opponent_castling_index}</CCText>
                {item['opponent_accuracy'] > 0 && (
                  <CCText color={CCColors.Grey.Grey} fontSize={14}>
                    Opp accuracy: {item['opponent_accuracy'].toFixed(1)}%
                  </CCText>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              style={[
                customStyles.cellWrapperBox,
                // {backgroundColor: CCColors.Primary.lightYellow},
              ]}>
              <Box type="row-flex-start">
                <Image
                  style={{
                    width: 20,
                    height: 27,
                    // backgroundColor: CCColors.Primary.lightYellow,
                  }}
                  resizeMethod="resize"
                  source={
                    item.color?.toLowerCase() === 'black'
                      ? BlackRook
                      : WhiteRook
                  }
                />
                <Spacer spacing={5} horizontal />
                <Box>
                  <CCText color={CCColors?.Grey.Grey}>
                    Avg time:{' '}
                    {item?.average_time ? item?.average_time + 's' : 'NA'}
                  </CCText>
                  <CCText style={customStyles.cellText}>
                    Castle:{' '}
                    {`${
                      item?.castling_index ? `#${item?.castling_index}` : 'NA'
                    }`}
                  </CCText>
                  {item['accuracy'] > 0 && (
                    <CCText color={CCColors?.Grey.Grey} fontSize={14}>
                      Your accuracy: {item['accuracy'].toFixed(1)}%
                    </CCText>
                  )}
                </Box>
              </Box>
              {/* <CCText style={customStyles.cellText}>
                Number of moves: {item['total_number_of_moves']}
              </CCText>
              {item['castling_index'] > 0 && (
                <CCText style={customStyles.cellText}>
                  You castled: Move #{item['castling_index']}
                </CCText>
              )}
              {item['castling_index'] <= 0 && (
                <CCText style={customStyles.cellText}>You didn't castle</CCText>
              )}
              {item['opponent_castling_index'] > 0 && (
                <CCText style={customStyles.cellText}>
                  Opponent castled: Move #{item['opponent_castling_index']}
                </CCText>
              )}
              {item['opponent_castling_index'] <= 0 && (
                <CCText style={customStyles.cellText}>
                  Opponent didn't castle
                </CCText>
              )} */}
            </Box>
          ),
          type: 'text',
        });

        itemValues.push({
          name: isMobileOrTab ? (
            <Box style={[customStyles.mobileLine, customStyles.yourMoves]}>
              <CCText color={CCColors?.Grey.Grey}>Your</CCText>
              <Box type="row-flex-start">
                <Image
                  style={{
                    width: 20,
                    height: 27,
                    // backgroundColor: CCColors.Primary.lightYellow,
                  }}
                  resizeMethod="resize"
                  source={
                    item.color?.toLowerCase() === 'black'
                      ? BlackRook
                      : WhiteRook
                  }
                />
                <Spacer spacing={5} horizontal />
                <CCText color={CCColors?.Grey.Grey}>Avg time: </CCText>
                <CCText>
                  {item?.average_time ? item?.average_time + 's' : 'NA'}
                </CCText>
                <Divider isVertical style={{marginHorizontal: 5}} />
                <CCText color={CCColors?.Grey.Grey}>Castle: </CCText>
                {<CCText>move #{item?.castling_index || 'N/A'}</CCText>}
                {item['accuracy'] > 0 && (
                  <CCText color={CCColors?.Grey.Grey} fontSize={14}>
                    Your accuracy: {item['accuracy'].toFixed(1)}%
                  </CCText>
                )}
              </Box>
            </Box>
          ) : (
            <Box style={customStyles.cellWrapperBox}>
              <Box
                style={[
                  customStyles?.mobileResult,
                  {
                    backgroundColor:
                      game_result?.toLowerCase() === 'loss'
                        ? CCColors.Red
                        : game_result?.toLowerCase() === 'win'
                        ? CCColors.Green
                        : CCColors.Primary.Default,
                  },
                ]}>
                <CCText
                  fontSize={'10'}
                  style={{width: 'fit-content'}}
                  textAlign="center"
                  fontWeight="semibold">
                  {game_result?.toLowerCase() === 'loss'
                    ? 'L'
                    : game_result?.toLowerCase() === 'win'
                    ? 'W'
                    : 'D'}
                </CCText>
              </Box>
              {/* <CCText style={customStyles.cellText}>{game_result}</CCText>
              {item['accuracy'] > 0 && (
                <CCText style={customStyles.cellText}>
                  Your accuracy: {item['accuracy'].toFixed(1)}%
                </CCText>
              )}
              {item['opponent_accuracy'] > 0 && (
                <CCText style={customStyles.cellText}>
                  Opponent accuracy: {item['opponent_accuracy'].toFixed(1)}%
                </CCText>
              )} */}
            </Box>
          ),
          type: 'text',
        });

        let opening = openingMap[game_opening];
        if (!opening) {
          opening = 'Non-Standard Opening';
        }

        let color = CCColors.ColorCodes.Done;
        let fontColor = CCColors.TextColor.Primary;
        let reviewButtonText = 'Review';
        if (item.total_positions == item.solved_positions) {
          color = CCColors.success;
          fontColor = '#ffffff';
          reviewButtonText = 'Study Again';
        } else {
          color = CCColors.Primary.Default;
          fontColor = CCColors.TextColor.Dark;
        }
        if (item.total_positions == 0) {
          color = CCColors.Primary.Default;
          fontColor = CCColors.TextColor.Dark;
          reviewButtonText = 'Review';
        }
        if (item.is_analyzing) {
          itemValues.push({
            name: (
              <AnimatedLoaderText style={customStyles.cellText}>
                Analyzing...
              </AnimatedLoaderText>
            ),
            type: 'text',
          });
        } else if (item.analyzed === 0) {
          itemValues.push({
            name: (
              <CCText style={customStyles.cellText}>
                Request computer analysis
              </CCText>
            ),
            type: 'text',
          });
        } else if (
          item.total_number_of_moves > 20 &&
          item.total_positions === 0 &&
          item.result === 'Win'
        ) {
          itemValues.push({
            name: (
              <Box type="row-flex-start">
                <MaterialIcons
                  color={CCColors.Green}
                  name="verified-user"
                  size={30}
                />
                <Spacer spacing={5} />
                <CCText
                  fontSize={FontSizes[12]}
                  color={CCColors.TextColor.Grey}>
                  You played a perfect game!
                </CCText>
              </Box>
            ),
            type: 'text',
          });
        } else if (
          item.total_number_of_moves > 20 &&
          item.total_positions === 0 &&
          item.result === 'Loss'
        ) {
          itemValues.push({
            name: (
              <Box type="row-flex-start">
                <Spacer spacing={5} />
                <CCText
                  fontSize={FontSizes[13]}
                  color={CCColors.TextColor.Grey}>
                  No puzzles to solve
                </CCText>
              </Box>
            ),
            type: 'text',
          });
        } else if (
          item.total_number_of_moves < 20 &&
          item.total_positions === 0
        ) {
          itemValues.push({
            name: (
              <Box type="row-flex-start">
                <Spacer spacing={5} />
                <CCText
                  fontSize={FontSizes[13]}
                  color={CCColors.TextColor.Grey}>
                  Not enough moves to analyze
                </CCText>
              </Box>
            ),
            type: 'text',
          });
        } else {
          itemValues.push({
            name: isMobileOrTab ? (
              <Box type="row-flex-start">
                <CCText>
                  {item.solved_positions}/{item.total_positions}
                </CCText>
                <Spacer spacing={5} horizontal />
                <ProgressBar
                  type="secondary"
                  progress={
                    Number(item.solved_positions) / Number(item.total_positions)
                  }
                  style={{height: 6, width: '80%'}}
                />
              </Box>
            ) : (
              <Box style={{width: '100%'}}>
                <CCText>
                  {item.solved_positions}/{item.total_positions}
                </CCText>
                <Spacer spacing={5} />
                <ProgressBar
                  type="secondary"
                  progress={
                    Number(item.solved_positions) / Number(item.total_positions)
                  }
                  style={{height: 6, width: '80%'}}
                />
              </Box>
              // <View style={customStyles.progressBar}>
              //   <View
              //     style={[
              //       customStyles.progressBarFilling,
              //       {
              //         width: `${
              //           (item['solved_positions'] * 100) /
              //           item['total_positions']
              //         }%`,
              //       },
              //     ]}
              //   />
              //   <CCText style={customStyles.progressBarText}>
              //     {item['solved_positions'] +
              //       ' of ' +
              //       item['total_positions'] +
              //       ' positions solved'}
              //   </CCText>
              // </View>
            ),
            type: 'text',
          });
        }

        itemValues.push({
          name: (
            <Box
              flexDirection="row"
              type="row-flex-start"
              style={{
                margin: 'auto',
                width: '100%',
                flexWrap: 'wrap',
                // justifyContent: 'row-center',
              }}
              type={isMobileOrTab ? 'row-flex-end' : 'row-flex-start'}>
              <CCButton
                onPress={() => {
                  navigation.navigate(ScreenName?.ChessAnalysis, {
                    game_id: game_uuid,
                    page: currentPage,
                    isUser: dashboardData?.user?.is_staff,
                  });
                }}
                style={{
                  backgroundColor: color,
                  borderRadius: 20,
                  width: 95,
                }}
                titleStyle={{
                  color: fontColor,
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}>
                {reviewButtonText}
              </CCButton>
              <Spacer spacing={5} horizontal />
              <FavStar
                isFav={is_favourite == 1}
                gameUuid={game_uuid}
                callBack={() => {
                  markFavourite({game_uuid: game_uuid});
                }}
              />
            </Box>
          ),
          type: 'text',
        });

        if (isMobileOrTab) {
          itemValues.push({
            name: (
              <Box style={customStyles?.mobileResult}>
                <CCText
                  fontSize={'10'}
                  style={{width: 'fit-content'}}
                  textAlign="center"
                  fontWeight="semibold">
                  {game_result?.toLowerCase() === 'loss'
                    ? 'L'
                    : game_result?.toLowerCase() === 'win'
                    ? 'W'
                    : 'D'}
                </CCText>
              </Box>
            ),
            type: 'text',
          });
        }
        itemValues.push([
          game_uuid,
          time_control,
          time_class,
          item.analyzed === 0 || item.is_analyzing,
          game_opponent,
          player,
          opponent_rating,
          player_rating,
        ]);
        return itemValues;
      });

      const tabHeader = header => (
        <CCText
          fontSize={FontSizes[10]}
          style={{
            color: CCColors.TextColor.Grey,
            marginLeft: 24,
            height: '100%',
            marginTop: 12,
          }}>
          {header}
        </CCText>
      );
      const tabHeaderWithBackground = header => (
        <CCText
          fontSize={FontSizes[10]}
          style={{
            color: CCColors.TextColor.Grey,
            paddingLeft: 24,
            paddingTop: 12,
            height: '100%',
            backgroundColor: CCColors.GamesList.Background,
          }}>
          {header}
        </CCText>
      );

      const tabHeaders = [
        'Opponent Details',
        'Opponent Play',
        'My Play',
        'Result',
        'Solve positions',
        '',
        '',
      ];
      setTabHeaders(tabHeaders);

      setTableData(formattedData || []);
    }
  }, [data, currentPage]);

  const callHandleFilter = debounce(newFilters => {
    handleFilter(newFilters);
  }, 1000);

  const filterSearchHandler = text => {
    setFilterText(text);
    callHandleFilter({...filters, player_name: text});
  };

  if (isLoading) {
    // return <Text>Loading...</Text>;
    return <CCLoader loading bgWhite />;
  }

  // return <GamesTable />;
  return (
    <>
      <Box
        flex={1}
        style={[
          {paddingVertical: 24},
          {
            backgroundColor: isMobileOrTab
              ? CCColors.Primary.lightBrown
              : CCColors.Primary.lightBrown,
          },
        ]}>
        {/* {filter_date && (
        <FilterItem text={filter_date} onRemove={removeDateFilter} />
      )}
      {assessmentId && (
        <FilterItem text={assessmentId} onRemove={removeDateFilter} />
      )} */}
        {/* {import_in_progress && <CCLoader loading />} */}
        {isMobileOrTab && (
          <Box marginHorizontal={10}>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              marginBottom={5}>
              <CCText fontSize={22}>Games</CCText>
              <CCTextInput
                icon={search}
                fontSize={14}
                value={filterText}
                style={{
                  width: 250,
                  height: 35,
                  backgroundColor: CCColors.White,
                  overflow: 'hidden',
                }}
                autoFocus={true}
                placeholder="Search for Player"
                onChangeText={filterSearchHandler}
                placeholderTextColor={CCColors.TextColor.Grey}
              />
            </Box>
            <CCText fontSize={FontSizes[14]}>
              Imports the latest 25 games you have played since the last sync{' '}
            </CCText>
            <Box type={'row-flex-end'}>
              <CCButton
                disabled={import_in_progress}
                // style={{width: vs(240), minWidth: vs(240)}}
                onPress={() => {
                  if (
                    dashboardData?.user?.cdc_name == null &&
                    dashboardData?.user?.lic_name == null
                  ) {
                    openAlertModal({
                      header: 'Info',
                      bodyTxt:
                        'You need to provide a lichess or chess.com ID in the user profile page to continue with the import.',
                      buttonText: 'OK',
                    });
                  } else {
                    openAlertModal({
                      header: 'Info',
                      bodyTxt: `1. Games played on lichess.org and chess.com can only be imported after 15 mins once the game ends.\n\n2. Imports will run in the background, this may take a while.`,
                      buttonText: 'Continue',
                      handleConfirmation: () => {
                        modalFunctions();
                      },
                    });
                  }
                }}>
                <ConditionalRender
                  condition={import_in_progress}
                  childrenA={
                    <ActivityIndicator size="small" color={CCColors.Green} />
                  }
                  childrenB={
                    <CCText style={{whiteSpace: 'nowrap'}}>Import</CCText>
                  }
                />
              </CCButton>
              <Spacer spacing={10} horizontal />
              <Filters handleFilter={handleFilter} />
              <Spacer spacing={10} horizontal />
              <CCButton
                onPress={() => {
                  navigation.setParams({});
                  handleFilter([]);
                  setFilters({});
                  // fetchGames({page: 1});
                }}>
                <CCText>Reset Filters</CCText>
              </CCButton>
            </Box>
          </Box>
        )}
        {!isMobileOrTab && (
          <Box
            style={{
              width: '95%',
              marginHorizontal: vs(26),
              marginBottom: vs(12),
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              padding: 15,
            }}>
            <Box flexDirection="row" gap={10} alignItems="center">
              <CCText fontSize={FontSizes[18]}>Games</CCText>
              <CCTextInput
                icon={search}
                fontSize={14}
                value={filterText}
                style={{
                  width: 250,
                  height: 35,
                  backgroundColor: CCColors.White,
                  overflow: 'hidden',
                }}
                autoFocus={true}
                placeholder="Search for Player"
                onChangeText={filterSearchHandler}
                placeholderTextColor={CCColors.TextColor.Grey}
              />
            </Box>
            <Box style={{paddingRight: vs(60)}} flexDirection="row" gap={5}>
              <CCButton
                disabled={import_in_progress}
                onPress={() => {
                  if (
                    dashboardData?.user?.cdc_name == null &&
                    dashboardData?.user?.lic_name == null
                  ) {
                    openAlertModal({
                      header: 'Info',
                      bodyTxt:
                        'You need to provide a lichess or chess.com ID in the user profile page to continue with the import.',
                      buttonText: 'OK',
                    });
                  } else {
                    openAlertModal({
                      header: 'Info',
                      bodyTxt: `1. Games played on lichess.org and chess.com can only be imported after 15 mins once the game ends.\n\n2. Imports will run in the background, this may take a while.`,
                      buttonText: 'Continue',
                      handleConfirmation: () => {
                        modalFunctions();
                      },
                    });
                  }
                }}>
                <ConditionalRender
                  condition={import_in_progress}
                  childrenA={
                    <ActivityIndicator size="small" color={CCColors.Green} />
                  }
                  childrenB={
                    <CCText style={{whiteSpace: 'nowrap'}}>Import</CCText>
                  }
                />
              </CCButton>
              <CCButton
                onPress={() => {
                  navigation.setParams({});
                  handleFilter([]);
                  setFilters({});
                  // fetchGames({page: 1});
                }}>
                <CCText>Reset Filters</CCText>
              </CCButton>
              {!filter_date && <Filters handleFilter={handleFilter} />}
            </Box>
          </Box>
        )}
        {/* <ConditionalRender
          condition={!isMobileOrTab}
          childrenA={
            <Box>
              <WebFilters handleFilter={handleFilter} />
            </Box>
          }
        /> */}
        {isMobileOrTab ? (
          <>
            {tableData?.map(i => (
              <Box style={customStyles?.mobileRowBox}>
                <Box type="row-center-between">
                  <Box>
                    <CCText style={{marginLeft: -3}}>{i?.[0]?.name}</CCText>
                    {i?.[1]?.name}
                  </Box>
                  <Box>
                    <Box>
                      <CCText color={CCColors?.Grey?.Grey}>Result</CCText>
                      {i?.[6]?.name}
                    </Box>
                  </Box>
                </Box>
                {i?.[2]?.name}
                {i?.[3]?.name}

                <Box
                  type="row-center-between"
                  style={[customStyles.mobileLine]}>
                  <Box flex={0.49}>
                    <CCText color={CCColors?.Grey.Grey}>Solve positions</CCText>
                    {i?.[4]?.name}
                  </Box>
                  <Box type="row-flex-end" flex={0.49}>
                    {i?.[5]?.name}
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        ) : (
          // <GamesTable />
          <Box style={{zIndex: -2}}>
            <CCPackageTable
              flexArr={[2.6, 1.7, 1.7, 0.7, 2, 1.3, 0.01]}
              headers={tabHeaders}
              tableData={tableData}
              isMargin={false}
              jsxTable={true}
              handleRowPress={rowIndex => {
                const gameId =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][0];
                const timeControl =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][1];
                const timeClass =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][2];
                const viewing =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][3];
                const opponent =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][4];
                const player =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][5];
                const opponentRating =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][6];
                const playerRating =
                  tableData[rowIndex]?.[tableData[rowIndex].length - 1][7];
                if (viewing) {
                  navigation.navigate(ScreenName?.ChessAnalysis, {
                    game_id: gameId,
                    page: currentPage,
                    viewgame: true,
                    time_control: timeControl,
                    time_class: timeClass,
                    opponent: opponent,
                    player: player,
                    opponent_rating: opponentRating,
                    player_rating: playerRating,
                  });
                } else {
                  navigation.navigate(ScreenName?.ChessAnalysis, {
                    game_id: gameId,
                    page: currentPage,
                    isUser: dashboardData?.user?.is_staff,
                  });
                }
              }}
            />
          </Box>
        )}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
        {/* <Chessboard position={'start'} /> */}
        {/* <Chessboard boardOrientation="black" /> */}
        {/* <ChessboardComponent position={'start'} /> */}
      </Box>
      <SubscriptionModal
        visible={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        user={dashboardData?.user}
        planMessage={planMessage}
        currentPlan={planName}
      />
      ;
    </>
  );
};

export default Games;
