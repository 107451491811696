import { useMutation } from '@tanstack/react-query';
import TournamentRestClient from '@/Api/TournamentRestClient';

export default function useGetMyJoinedTournaments(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const response = await TournamentRestClient.get(
        `/tournament/get-joined-tournaments?userId=${userId}`
      );
      return response.data;
    },
    onSuccess: data => {
      if (handleOnSuccess) handleOnSuccess(data);
    },
    onError: error => {
      if (handleOnError) handleOnError(error);
    },
  });

  return mutation;
}
