/* eslint-disable react-native/no-inline-styles */
import React, {useState} from 'react';
import {Platform, TouchableOpacity} from 'react-native';
import Box from '../Box';
import CCColors from '@/Utils/CCColors';
import DateTimePicker, {DateType} from 'react-native-ui-datepicker';
import CCText from '../CCText';
import {StyleSheet} from 'react-native';
import SlideModal from '../SlideModal';

const CCDatePicker = ({onChange, value, isMobileOrTab, minDate}) => {
  // Get the user's local date and format it as 'YYYY-MM-DD' for the web picker
  // const today = new Date().toLocaleDateString('en-CA'); // 'YYYY-MM-DD' format for HTML input

  const [selectedDate, setSelectedDate] = useState<DateType>();
  const [show, setShow] = useState(false);
  const formatDateForInput = date => {
    if (!(date instanceof Date) || isNaN(date.getTime())) return '';

    // Adjust for timezone offset
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    );

    // Extract the day, month, and year
    const day = String(offsetDate.getUTCDate()).padStart(2, '0'); // Use getUTCDate()
    const month = String(offsetDate.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
    const year = offsetDate.getUTCFullYear();

    return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
  };
  const [rawValue, setRawValue] = useState(
    value ? formatDateForInput(value) : '',
  );
  const parseDate = dateString => {
    if (!dateString) return null;

    const parts = dateString.split('-');
    if (parts.length !== 3) return null;

    // Check if the format is YYYY-MM-DD (first part is a 4-digit year)
    if (parts[0].length === 4) {
      const [year, month, day] = parts.map(Number);
      return new Date(year, month - 1, day); // Month is zero-indexed
    }
    // Format is DD-MM-YYYY
    else {
      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day); // Month is zero-indexed
    }
  };

  const parsedValue = typeof value === 'string' ? parseDate(value) : value;
  const formattedDate =
    parsedValue instanceof Date && !isNaN(parsedValue.getTime())
      ? formatDateForInput(parsedValue)
      : 'Select Date';
  // Today's date in 'YYYY-MM-DD' format
  const today = formatDateForInput(new Date());

  const formatDateForWebInput = (date: Date) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) return '';
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    );
    return offsetDate.toISOString().split('T')[0];
  };

  // if (Platform.OS === 'web') {
  return isMobileOrTab ? (
    <Box>
      <TouchableOpacity
        onPress={() => setShow(true)}
        style={{
          padding: 10,
          backgroundColor: 'transparent',
          borderRadius: 5,
          borderWidth: StyleSheet.hairlineWidth,
          borderColor: CCColors.TextBoxes.Borders,
        }}>
        <CCText style={{color: CCColors.TextColor}}>{formattedDate}</CCText>
      </TouchableOpacity>
      <SlideModal visible={show} onClose={() => setShow(false)}>
        <DateTimePicker
          mode="single"
          date={selectedDate}
          onChange={({date}) => {
            setSelectedDate(date);
            onChange(date);
            setShow(false);
          }}
          minDate={minDate}
        />
      </SlideModal>
    </Box>
  ) : (
    <Box
      style={{
        borderRadius: 10,
        borderColor: CCColors.Grey.Border,
        borderWidth: 1,
      }}>
      <input
        type="date"
        value={rawValue} // Use raw string state
        onChange={e => {
          const inputValue = e.target.value;
          setRawValue(inputValue); // Update raw value

          // Parse only if input is complete and valid
          const selectedDate = parseDate(inputValue);
          if (selectedDate) {
            onChange(selectedDate); // Update parent state
          }
        }}
        style={{
          padding: 15,
          fontSize: 16,
          border: 'none',
          color: rawValue ? 'inherit' : '#A8A7A6',
        }}
        placeholder="mm/dd/yyyy"
        max={today} // Max date is today in user's timezone
        min={minDate ? formatDateForWebInput(minDate) : undefined}
      />
    </Box>
  );
};

export default CCDatePicker;
