import CCColors from '@/Utils/CCColors';
import React, {useCallback, useMemo, useState} from 'react';
import Dropzone from 'react-dropzone';
import {useBookImportApi} from '@/Hooks/BooksApi/useBooksApi';
import {StyleSheet, View, TextInput, ScrollView} from 'react-native';
import {FontSizes, vs} from '@/Utils/Dimensions';
import upload from '../../../Assets/Images/png/upload.png';
import fileIcon from '../../../Assets/Images/png/file.png';
import deleteIcon from '../../../Assets/Images/png/delete.png';
import fontWeightToFamily from '@/Assets/Fonts/fontWeightToFamily';
import CCButton from '../../../Atoms/CCButton';
import CCText from '../../../Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import Ionicons from '@expo/vector-icons/Ionicons';
import ChipList from '@/Atoms/ChipList/ChipList';
import CCDatePicker from '@/Atoms/CCDatePicker';
import {formatDateToYYYYMMDD} from '@/Utils/CommonUtils';

// interface UploadBookProps {
//   buttonText: string;
//   handleClick: () => void;
// }
export const BOOK_TYPE = [
  {value: 1, key: 'Free'},
  {value: 2, key: 'Paid'},
  {value: 0, key: 'Private'},
];

export const PRODUCT_TYPE = [
  {value: 1, key: 'Assignment'},
  {value: 2, key: 'Evaluate'},
  {value: 3, key: 'Open'},
];

const UploadBook: React.FC<{
  importSuccess: any;
  buttonName: string;
  uploadType?: 'assignment' | 'book';
  isMobileOrTab?: boolean;
}> = ({importSuccess, buttonName, uploadType = 'book', isMobileOrTab}) => {
  const {mutate: importBook} = useBookImportApi(
    data => importSuccess(uploadType === 'assignment' ? 1 : 0),
    error => {
      if (error && error.response && error.response.data) {
        setErrorMessage(
          error.response.data.message ||
            'Failed to upload the book. Assignment name already exists!',
        );
      }
    },
  );
  const [date, setDate] = useState<string>('');
  const [releaseDate, setReleaseDate] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [chapter, setChapterName] = useState<string>('');
  const [mrp, setMrp] = useState('');
  const [price, setPrice] = useState('');
  const [tournamentId, setTournamentId] = useState(0);
  const [tournamentCategory, setTournamentCategory] = useState('');
  const [productType, setProductType] = useState(1);
  const [bookType, setBookType] = useState('');
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isBooks = useMemo(() => uploadType === 'book', [uploadType]);
  const uploadTypeName = useMemo(
    () => (uploadType === 'book' ? 'Book' : 'Assignment'),
    [uploadType],
  );
  // const selectFile = async () => {

  //   try {
  //     const res: DocumentPickerResponse[] = await DocumentPicker.pick({
  //       type: [{type: 'application/x-chess-pgn'} as any], // Specify the MIME type for PGN files
  //     });

  //     // Handle the selected PGN file
  //     console.log('Selected PGN file:', res);
  //     // Handle the selected file here
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //       console.log('File selection cancelled');
  //     } else {
  //       console.log('Error selecting file:', err);
  //     }
  //   }
  // };

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
    setErrorMessage('');
  };

  const handleClick = () => {
    if (file && name.length) {
      const formattedDate = formatDateToYYYYMMDD(date);
      const formattedReleaseDate = releaseDate
        ? formatDateToYYYYMMDD(releaseDate)
        : undefined;

      if (isBooks) {
        importBook({
          file,
          tournament_date: formattedDate,
          name,
          chapter,
          mrp,
          price,
          bookType: bookType?.value,
          tournamentId,
          tournamentCategory,
        });
      } else {
        importBook({
          file,
          tournament_date: formattedDate,
          ...(formattedReleaseDate && {release_date: formattedReleaseDate}),
          name,
          author: chapter,
          product_type: productType?.value ?? 1,
          mrp,
          price,
          bookType: bookType?.value,
          ...(userPhone.length && {user_phone: userPhone}),
          tournamentId,
          tournamentCategory,
        });
      }
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView>
        {!file ? (
          <Dropzone
            accept={{'application/x-chess-pgn': ['.pgn']}}
            maxFiles={1}
            onDrop={handleDrop}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} style={styles.dropContainer}>
                <input {...getInputProps()} />
                <img src={upload} alt="Upload Icon" />
                <Spacer spacing={vs(12)} />
                <CCText textAlign="center">
                  Drag and drop file {'\n'}or click here to select file
                </CCText>
              </div>
            )}
          </Dropzone>
        ) : (
          <>
            <View style={[styles.dropContainer]}>
              <img src={fileIcon} style={{width: 100}} alt="File Icon" />
              <Spacer spacing={vs(12)} />
              <CCText textAlign="center" style={{width: '80%'}}>
                {file.name}
              </CCText>
              <img
                src={deleteIcon}
                style={styles.deleteIcon}
                alt="Delete Icon"
                onClick={() => setFile(undefined)}
              />
            </View>
            <Spacer spacing={vs(5)} />
            <CCText color={CCColors.Green}>
              PGN {uploadTypeName} uploaded successfully
            </CCText>
            <Spacer spacing={vs(20)} />

            <CCText>Tournament Date</CCText>
            <Spacer spacing={vs(5)} />
            <CCDatePicker
              onChange={text => setDate(formatDateToYYYYMMDD(text))}
              value={date}
              isMobileOrTab={isMobileOrTab}
            />
            <Spacer spacing={vs(20)} />
            <CCText>Release Date (Optional)</CCText>
            <Spacer spacing={vs(5)} />
            <CCDatePicker
              onChange={text => setReleaseDate(formatDateToYYYYMMDD(text))}
              value={releaseDate}
              isMobileOrTab={isMobileOrTab}
              minDate={new Date(new Date().setHours(0, 0, 0, 0))}
            />
            <Spacer spacing={vs(20)} />
            <CCText>{uploadTypeName} Name</CCText>
            <Spacer spacing={vs(5)} />
            <TextInput
              style={styles.input}
              placeholder={`Enter a ${uploadTypeName} name`}
              onChangeText={text => setName(text)}
              placeholderTextColor={CCColors.TextColor.Grey}
            />
            <Spacer spacing={vs(20)} />
            <CCText>{isBooks ? 'Chapter' : 'Author'} Name (optional)</CCText>
            <Spacer spacing={vs(5)} />
            <TextInput
              style={styles.input}
              placeholder={`Enter ${isBooks ? 'a Chapter' : 'an Author'} name`}
              onChangeText={text => setChapterName(text)}
              placeholderTextColor={CCColors.TextColor.Grey}
            />
            <Spacer spacing={vs(20)} />
            {!isBooks && (
              <>
                <CCText>Product type</CCText>
                <Spacer spacing={vs(5)} />
                <ChipList
                  chipBackgroundColor={CCColors.Primary.lightBrown}
                  onChange={value => setProductType(value)}
                  options={PRODUCT_TYPE}
                  value={productType}
                />
                <Spacer spacing={vs(20)} />
                <CCText>Book type</CCText>
                <Spacer spacing={vs(5)} />
                <ChipList
                  chipBackgroundColor={CCColors.Primary.lightBrown}
                  type={1}
                  onChange={text => setBookType(text)}
                  options={BOOK_TYPE}
                  value={bookType}
                />
                {bookType.key === 'Paid' || bookType.key === 'Private' ? (
                  <>
                    <Spacer spacing={vs(20)} />
                    <CCText>MRP</CCText>
                    <Spacer spacing={vs(5)} />
                    <TextInput
                      style={styles.input}
                      placeholder="Enter mrp"
                      onChangeText={text => setMrp(text)}
                      placeholderTextColor={CCColors.TextColor.Grey}
                      keyboardType="numeric"
                    />
                    <Spacer spacing={vs(20)} />
                    <CCText>Price</CCText>
                    <Spacer spacing={vs(5)} />
                    <TextInput
                      style={styles.input}
                      placeholder="Enter price"
                      onChangeText={text => setPrice(text)}
                      placeholderTextColor={CCColors.TextColor.Grey}
                      value={price}
                      keyboardType="numeric"
                    />
                  </>
                ) : null}
                <Spacer spacing={vs(20)} />
                <CCText>User Phone Number (optional)</CCText>
                <Spacer spacing={vs(5)} />
                <TextInput
                  style={styles.input}
                  placeholder={`Enter a valid Phone Number`}
                  onChangeText={text => setUserPhone(text)}
                  value={userPhone}
                  placeholderTextColor={CCColors.TextColor.Grey}
                />
                <Spacer spacing={vs(20)} />
                <CCText>Tournament ID (optional)</CCText>
                <Spacer spacing={vs(5)} />
                <TextInput
                  style={styles.input}
                  placeholder={`Enter a valid Tournament ID`}
                  onChangeText={text => setTournamentId(parseInt(text))}
                  placeholderTextColor={CCColors.TextColor.Grey}
                  keyboardType="numeric"
                />
                <Spacer spacing={vs(20)} />
                <CCText>Tournament Category (optional)</CCText>
                <Spacer spacing={vs(5)} />
                <TextInput
                  style={styles.input}
                  placeholder={`Enter Tournament Category`}
                  onChangeText={text => setTournamentCategory(text)}
                  placeholderTextColor={CCColors.TextColor.Grey}
                />
                <Spacer spacing={vs(20)} />
                {errorMessage && (
                  <CCText color={CCColors.Red}>{errorMessage}</CCText>
                )}
                <Spacer spacing={vs(10)} />
                <View style={styles.button}>
                  <CCButton
                    icon={upload}
                    stretched
                    disabled={!name.length || !date.length}
                    titleStyle={{color: CCColors.TextColor.Dark, padding: 15}}
                    onPress={handleClick}>
                    {buttonName ? buttonName : 'Upload Book'}
                  </CCButton>
                </View>
              </>
            )}
          </>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: FontSizes[24],
    fontFamily: fontWeightToFamily('medium'),
  },
  input: {
    height: 48,
    borderRadius: 8,
    padding: 12,
    border: `1px solid ${CCColors.Border}`,
  },
  button: {
    width: 'calc(100% - 120px)',
    alignSelf: 'center',
  },
  icon: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: CCColors.Primary.lightBrown,
  },
  iconImg: {
    width: 16,
    height: 16,
  },
  dropContainer: {
    borderRadius: 8,
    border: '1px dashed rgba(34, 34, 34, 0.20)',
    backgroundColor: CCColors.Primary.lightBrown,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  deleteIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    width: 30,
  },
});

export default UploadBook;
