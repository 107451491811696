import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import React, {useState} from 'react';
import {renderStatusIcon} from './ccLeagueUtils';
import {View, Image, Pressable} from 'react-native';
import {Colors} from '@/styles';
import ChallengeModal from './ChallengeModal';
import points from '../../../../Assets/Images/Svgs/points.svg';
import {useNavigation} from '@react-navigation/native';
import {setLocalStorage} from '@/Utils/CommonUtils';
import {ScreenName} from '@/navigators/StackConstants';
import Divider from '@/Atoms/Divider/Divider';
import CCTable from '@/Atoms/CCTable';
import Tooltip from '@/Atoms/Tooltip';

const StandingsRow = ({
  standing,
  index,
  selectedRow,
  hoveredRow,
  setSelectedRow,
  setHoveredRow,
  standings,
  goldMedal,
  silverMedal,
  bronzeMedal,
  challengeIcon,
  isMobileOrTab,
  leagueId,
  leagueRules,
}) => {
  const navigation = useNavigation();
  const isRowSelected = selectedRow === index;
  const isRowHovered = hoveredRow === index;
  const [openChallenge, setOpenChallenge] = useState(false);
  const handleCloseChallenge = () => {
    setOpenChallenge(false);
  };
  const handleOpenChallenge = () => {
    setOpenChallenge(true);
  };

  const challengePlayer = () => {
    setLocalStorage('league_opp_id', standing.user_id);
    setLocalStorage('league_id', leagueId);
    navigation.navigate(ScreenName.PlayChess);
  };
  return (
    <>
      <Box
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 20,
          borderRight: isRowSelected ? '1px solid #26232233' : null,
          borderLeft: isRowSelected ? '1px solid #26232233' : null,
          borderBottom: '1px solid #26232233',
          backgroundColor: isRowSelected && Colors.primaryLight,
          backgroundImage: isRowHovered
            ? 'linear-gradient(90deg, rgba(250, 246, 235, 0) 0.37%, #FAF6EB 8.1%, rgba(250, 246, 235, 0) 37.16%)'
            : 'none',
          cursor: isRowHovered ? 'pointer' : 'default',
        }}
        onPress={() => {
          setSelectedRow(isRowSelected ? null : index);
          if (standings.player_name !== 'You') {
            handleOpenChallenge();
          }
        }}
        onMouseEnter={() => setHoveredRow(index)}
        onMouseLeave={() => setHoveredRow(null)}>
        <Box
          type="row-center"
          gap={10}
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}>
          <Box
            style={{
              height: '100%',
            }}>
            <Box
              type="row-flex-start"
              style={{
                margin: 'auto',
                width: '100%',
                flexWrap: 'wrap',
              }}>
              {renderStatusIcon(standing.status)}
            </Box>
          </Box>
          <Box
            style={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {index === 0 ? (
              <Image source={goldMedal} style={{width: 24, height: 24}} />
            ) : index === 1 ? (
              <Image source={silverMedal} style={{width: 24, height: 24}} />
            ) : index === 2 ? (
              <Image source={bronzeMedal} style={{width: 24, height: 24}} />
            ) : (
              <Box
                style={{
                  width: 25,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CCText
                  color={'#4D3F37'}
                  fontWeight={500}
                  fontSize={FontSizes[15]}>
                  {index + 1}.
                </CCText>
              </Box>
            )}
          </Box>
          <Box type="row-flex-start" gap={10}>
            <Box
              type="center"
              style={{
                width: 25,
                height: 25,
              }}>
              <Image
                source={standing.profile}
                style={{
                  height: 25,
                  width: 25,
                }}
              />
            </Box>
            <CCText
              width={isMobileOrTab ? 225 : 400}
              color={CCColors.Black}
              fontWeight={500}
              fontSize={FontSizes[16]}>
              {standing.player_name}
            </CCText>
          </Box>
          {!isMobileOrTab && (
            <Box type="row-flex-start" gap={10}>
              {standing.fide_rating > 0 && (
                <>
                  <Box
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingHorizontal: 5,
                    }}>
                    <CCText
                      color={CCColors.Grey.Grey}
                      fontWeight={300}
                      fontSize={FontSizes[8]}>
                      FIDE RATING
                    </CCText>
                    <CCText
                      color={CCColors.Grey.Grey}
                      fontWeight={500}
                      fontSize={FontSizes[10]}>
                      {standing.fide_rating}
                    </CCText>
                  </Box>
                  <Divider isVertical style={{height: 30}} />
                </>
              )}
              <Box
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 5,
                }}>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontWeight={300}
                  fontSize={FontSizes[8]}>
                  CC RATING
                </CCText>
                <CCText
                  color={CCColors.Grey.Grey}
                  fontWeight={500}
                  fontSize={FontSizes[10]}>
                  {standing.rating}
                </CCText>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          gap={20}
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          {!isMobileOrTab && standing.player_name !== 'You' && (
            <View style={{position: 'relative'}}>
              {standing.online ? (
                <CCButton
                  onPress={challengePlayer}
                  contentContainerStyle={{
                    paddingHorizontal: 30,
                  }}>
                  <Box type="center" flexDirection="row" gap={10}>
                    <Image
                      source={challengeIcon}
                      style={{
                        height: 16,
                        width: 16,
                        paddingRight: 8,
                      }}
                    />
                    <CCText fontWeight={500} fontSize={FontSizes[12]}>
                      Challenge
                    </CCText>
                  </Box>
                </CCButton>
              ) : (
                <Tooltip
                  text={
                    !standing.online ? (
                      <CCText
                        textAlign="center"
                        color={CCColors.White}
                        fontSize={FontSizes[8]}>
                        Player is offline
                      </CCText>
                    ) : (
                      ''
                    )
                  }
                  children={toggleEvent => (
                    <Pressable
                      onPointerEnter={toggleEvent}
                      onPointerLeave={toggleEvent}>
                      <CCButton
                        disabled={!standing.online}
                        contentContainerStyle={{
                          paddingHorizontal: 30,
                        }}>
                        <Box type="center" flexDirection="row" gap={10}>
                          <Image
                            source={challengeIcon}
                            style={{
                              height: 16,
                              width: 16,
                              paddingRight: 8,
                            }}
                          />
                          <CCText fontWeight={500} fontSize={FontSizes[12]}>
                            Challenge
                          </CCText>
                        </Box>
                      </CCButton>
                    </Pressable>
                  )}
                />
              )}
            </View>
          )}
          <Box type="row-center" gap={20}>
            {!isMobileOrTab && (
              <Box
                style={{width: isMobileOrTab ? 'auto' : 90}}
                type="row-flex-start"
                gap={10}>
                <Image source={points} style={{height: 20, width: 20}} />
                <CCText
                  color={CCColors.Rewards.TextGreen}
                  fontWeight={400}
                  fontSize={FontSizes[12]}>
                  {standing.net_score}
                </CCText>
              </Box>
            )}
            <CCText
              width={isMobileOrTab ? 'auto' : 80}
              color={CCColors.Black}
              fontSize={FontSizes[13]}>
              {standing.end_score} pts
            </CCText>
            {/* </Pressable> */}
          </Box>
        </Box>
      </Box>
      {selectedRow === index && !isMobileOrTab && (
        <CCTable
          headers={leagueRules.map(r => ({key: r.type, label: r.name}))}
          data={[
            leagueRules.reduce((a, b) => {
              a[b.type] = `${standing.stats[b.type] || 0}/${b.max_points}`;
              return a;
            }, {}),
          ]}
          headerStyle={{
            backgroundColor: 'transparent',
            color: CCColors.TextColor.Grey,
          }}
        />
      )}
      {selectedRow === index &&
        standing.player_name !== 'You' &&
        isMobileOrTab && (
          <ChallengeModal
            standing={standing}
            hoveredRow={hoveredRow}
            index={index}
            openChallenge={openChallenge}
            handleCloseChallenge={handleCloseChallenge}
            isMobileOrTab={isMobileOrTab}
            challengePlayer={challengePlayer}
            leagueRules={leagueRules}
          />
        )}
    </>
  );
};

export default StandingsRow;
