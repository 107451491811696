import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCDatePicker from '@/Atoms/CCDatePicker';
import CCLoader from '@/Atoms/CCLoader';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import ChipList from '@/Atoms/ChipList/ChipList';
import {ChipType} from '@/Atoms/ChipList/types';
import OrSeparator from '@/Atoms/OrSeparator';
import Spacer from '@/Atoms/Spacer';
import useSubscribeUser from '@/Hooks/SubscribeUserApi/useSubscribeUser';
import useVerifyFideID from '@/Hooks/VerifyFideIDApi/useVerifyFideID';
import CCColors from '@/Utils/CCColors';
import {formatDateToDDMMYYYY, formatDateToYYYYMMDD} from '@/Utils/CommonUtils';
import {GENDER_TYPE} from '@/Utils/Constants';
import React, {useEffect, useState} from 'react';

// import Box from "../../../../Atoms/Box";
// import CCButton from "../../../../Atoms/CCButton";
// import CCLoader from "../../../../Atoms/CCLoader";
// import CCText from "../../../../Atoms/CCText";
// import CCTextInput from "../../../../Atoms/CCTextInput";
// import ChipList from "../../../../Atoms/ChipList/ChipList";
// import { ChipType } from "../../../../Atoms/ChipList/types";
// import { GENDER_TYPE } from "../../../../Utils/Constants";
// import OrSeparator from "../../../../Atoms/OrSeparator";
// import Spacer from "../../../../Atoms/Spacer";
// import useRegisterUser from "../../../../hooks/useSubscribeUser";
// import useVerifyFideID from "../../../../hooks/useVerifyFideID";
// import useToastStore from "@/Atoms/Toast/ToastStore";

const NewUserRegistration = ({
  name,
  dateOfBirth,
  gender,
  handleNameChange,
  handleDOBChange,
  handleGenderChange,
  isValid,
  setFideID,
  fideID,
  setStage,
  handleStage,
  postSubmitCallBack,
  isMobileOrTab,
}) => {
  const {
    mutate: verifyFideID,
    data: verifyFideIDData,
    isLoading: verifyFideIdLoading,
    error: verifyFideIdError,
  } = useVerifyFideID(
    () => {},
    data => {
      setFideError({flag: true, msg: 'Invalid fide id'});
    },
  );

  // this state is to show the data to the users so that he/she can just confirm the
  // data entered. false means proceed button should act as a confirmation button
  // if true then the proceed button should act as a subscribe button
  const [isConfirmation, setIsConfirmation] = useState(false);
  // const showToast = useToastStore((state) => state.showToast);
  const [fideError, setFideError] = useState({flag: false, msg: ''});
  const {
    mutate: registerUser,
    data: registerUserData,
    isLoading: registerUserLoading,
    error: registerUserError,
  } = useSubscribeUser();

  useEffect(() => {
    if (registerUserData?.success) {
      setStage?.(1);
      // showToast("User added successfully", "success");
      postSubmitCallBack?.();
    }
  }, [registerUserData]);

  const resetState = (tempStage: 1 | 2) => {
    switch (tempStage) {
      case 2:
        if (fideID) {
          setFideID('');
        }
        break;
      case 1:
        if (name) {
          handleNameChange('');
        }
        if (dateOfBirth) {
          handleDOBChange('');
        }
        if (gender) {
          handleGenderChange('');
        }
        setIsConfirmation(false);
        break;
    }
  };

  const handleCompleteUserDetails = (
    type: 'name' | 'date' | 'gender' | 'fideid',
    text: string | ChipType | ChipType[],
  ) => {
    setFideError({flag: false, msg: ''});
    switch (type) {
      case 'name':
        resetState(2);
        handleNameChange(text);
        break;
      case 'date':
        resetState(2);
        handleDOBChange(text);

        break;
      case 'gender':
        resetState(2);
        handleGenderChange(text);
        break;
      case 'fideid':
        resetState(1);
        setFideID(text);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (verifyFideIDData?.name === '') {
      // showToast("Invalid fide id", "error");
      setFideError({flag: true, msg: 'Invalid fide id'});
    }
  }, [verifyFideIDData?.name]);

  useEffect(() => {
    if (verifyFideIdError) {
      setFideError({flag: true, msg: 'Invalid fide id'});
    }
  }, [verifyFideIdError]);

  const handleSubmit = () => {
    if (isValid) {
      if (fideID && verifyFideIDData) {
        registerUser({
          // source:'web',
          fideID: fideID,
          //   country:"IND",
          name: verifyFideIDData?.name,
          gender: gender,
          //   user_type:1,
          pincode: verifyFideIDData?.pincode,
          // gender: verifyFideIDData?.gender,
          dateOfBirth: verifyFideIDData?.dateOfBirth,
        });
      } else if (fideID) {
        verifyFideID({fide_id: fideID});
      } else {
        if (isConfirmation) {
          registerUser({
            // source:'web',
            //   country:"IND",
            name: name,
            //   user_type:1,
            //   pincode:"",
            gender: gender,
            dateOfBirth: dateOfBirth,
          });
          setIsConfirmation(false);
        } else {
          setIsConfirmation(true);
        }
      }
    }
  };

  const completeUserDetails = (
    <>
      <Spacer spacing={20} />
      <CCTextInput
        label="Name (min 3 letters)"
        value={name}
        onChangeText={text => handleCompleteUserDetails('name', text)}
      />
      <Spacer spacing={20} />
      <CCDatePicker
        onChange={text => {
          handleCompleteUserDetails('date', formatDateToDDMMYYYY(text));
        }}
        value={dateOfBirth}
        isMobileOrTab={isMobileOrTab}
      />
      {/* <CCTextInput
        label="date of birth(dd-mm-yyyy)"
        onChangeText={text => handleCompleteUserDetails('date', text)}
        value={dateOfBirth}
        inputType="date"
      /> */}
      <Spacer spacing={20} />
      <ChipList
        chipBackgroundColor={CCColors.Primary.lightBrown}
        type={1}
        onChange={text => handleCompleteUserDetails('gender', text)}
        options={GENDER_TYPE}
        value={gender}
      />
    </>
  );

  const onlyFideId = (
    <>
      <Spacer spacing={20} />
      <CCTextInput
        label="Fide ID"
        onChangeText={text => handleCompleteUserDetails('fideid', text)}
        value={fideID}
        keyboardType="numeric"
        error={fideError}
        // inputType="date"
      />
    </>
  );

  const fideIDDetails = (
    <>
      <Spacer spacing={10} />
      <Box flexDirection="row">
        <CCText fontWeight="bold">Name: </CCText>
        <CCText>{verifyFideIDData?.name}</CCText>
      </Box>
      <Spacer spacing={10} />
      <Box flexDirection="row">
        <CCText fontWeight="bold">Pincode: </CCText>
        <CCText>{verifyFideIDData?.pincode}</CCText>
      </Box>
      <Spacer spacing={10} />
      <Box flexDirection="row">
        <CCText fontWeight="bold">Federation: </CCText>
        <CCText>{verifyFideIDData?.federation}</CCText>
      </Box>
    </>
  );

  const userEnteredDetails = (
    <>
      <Spacer spacing={10} />
      <Box flexDirection="row">
        <CCText fontWeight="bold">Name: </CCText>
        <CCText>{name}</CCText>
      </Box>
      <Spacer spacing={10} />
      <Box flexDirection="row">
        <CCText fontWeight="bold">DOB: </CCText>
        <CCText>{dateOfBirth}</CCText>
      </Box>
      <Spacer spacing={10} />
      <Box flexDirection="row">
        <CCText fontWeight="bold">Gender: </CCText>
        <CCText>{gender?.key}</CCText>
      </Box>
    </>
  );
  return (
    <>
      <Box margin={25}>
        {completeUserDetails}
        <Spacer spacing={10} />
        <OrSeparator />
        <Spacer spacing={10} />
        {onlyFideId}
        <Spacer spacing={20} />
        {verifyFideIDData && fideID && fideIDDetails}
        {isConfirmation && userEnteredDetails}
        <Spacer spacing={20} />
        <CCButton
          // type="box"
          // titleStyle={{color: CCColors.White}}
          // disabled={!isValid}
          style={{width: '35%'}}
          onPress={handleSubmit}>
          Proceed
        </CCButton>
      </Box>
      <CCLoader loading={verifyFideIdLoading || registerUserLoading} />
    </>
  );
};

export default NewUserRegistration;
