import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  SafeAreaView,
  Image,
} from 'react-native';
import Tournaments from './components/TournamentData'; // Import your existing table component
import CCText from '@/Atoms/CCText';
import Feather from '@expo/vector-icons/Feather';
import CCButton from '@/Atoms/CCButton';
import Spacer from '@/Atoms/Spacer';
import CCColors from '@/Utils/CCColors';
import {faAlignCenter} from '@fortawesome/free-solid-svg-icons';
import Box from '@/Atoms/Box';
import {useNavigation} from '@react-navigation/native';
import TournamentFiltersModal from './components/TournamentFiltersModal';
import useIsMobile from '@/Hooks/useIsMobile';
// export default TournamentsScreen;
const TournamentsScreen = props => {
  const isMobileOrTab = useIsMobile();
  const [activeTab, setActiveTab] = useState('upcoming');
  const [searchQuery, setSearchQuery] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const navigation = useNavigation();
  const handleFilterOpen = () => {
    console.log('filter open');
    setOpenFilter(true);
  };
  const {getTournamentDetails, userId} = props;
  const handleSearchPress = () => {
    console.log('Search button pressed');
  };

  const handleAllTournamentsPress = () => {
    console.log('All Tournaments button pressed');
    navigation.navigate('CCTournaments', {
      query: 'platform=Circlechess',
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      {/* Search Bar and Tabs in One Row */}
      <View style={styles.searchAndTabs}>
        {/* Tabs */}
        <View style={styles.tabs}>
          <TouchableOpacity
            style={[styles.tab, activeTab === 'upcoming' && styles.activeTab]}
            onPress={() => setActiveTab('upcoming')}>
            <CCText style={styles.tabText}>Upcoming</CCText>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.tab, activeTab === 'registered' && styles.activeTab]}
            onPress={() => setActiveTab('registered')}>
            <CCText style={styles.tabText}>Joined</CCText>
          </TouchableOpacity>
        </View>

        {/* Search Bar */}
        <View style={styles.actions}>
          {/* Search Button */}
          {/* <Box onPress={handleSearchPress} style={styles.iconButton}>
            <Feather name="search" size={16} color="#5B5B5B" />
          </Box> */}

          {/* Filter Button */}
          {/* <Box onPress={handleFilterOpen} style={styles.iconButton}>
            <Feather name="filter" size={16} color="#5B5B5B" />
          </Box> */}

          {/* All Tournaments Button */}
          {/* <CCButton
            onPress={handleAllTournamentsPress}
            disabled={true}
            style={styles.ctaButton}>
            <CCText style={styles.ctaText}>All Tournaments</CCText>
          </CCButton> */}
        </View>

        {/* <CCButton onPress={handleFilterOpen}>
          <Feather name="filter" size={14} color={CCColors.TextColor.Primary} />{' '}
          <Spacer spacing={5} horizontal />{' '}
        </CCButton> */}

        {/* {<TextInput
          style={styles.searchBar}
          placeholder="Search tournaments..."
          value={searchQuery}
          onChangeText={setSearchQuery}
        />} */}
      </View>

      <View
        style={{
          backgroundColor: CCColors.White,
          height: isMobileOrTab ? '100%' : 600,
        }}>
        <Tournaments
          getTournamentDetails={getTournamentDetails}
          activeTab={activeTab}
          userId={userId}
          newLayout={true}
        />
      </View>

      {/* Tournaments Table */}

      {
        <TournamentFiltersModal
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
        />
      }
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: CCColors.White,
    paddingHorizontal: 16,
    // height: '100%',
    borderRadius: 8,
  },
  header: {
    paddingVertical: 16,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#333',
  },
  searchAndTabs: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: 16,
    marginBottom: 16,
  },
  tabs: {
    flexDirection: 'row',
    flex: 2, // Take up more space in the row
  },
  tab: {
    flex: 1,
    paddingVertical: 10,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: '#FFC107',
  },
  tabText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
  },
  searchBar: {
    flex: 3, // Search bar takes more space
    padding: 10,
    backgroundColor: '#f1f1f1',
    borderRadius: 8,
    fontSize: 16,
    marginLeft: 16, // Space between tabs and search bar
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {
    backgroundColor: '#F6F6F6',
    borderRadius: 25,
    width: 25,
    height: 25,
    alignItems: 'center',
  },
  ctaButton: {
    borderRadius: 20,
    marginLeft: 10,
  },
  ctaText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#fff',
  },
});

export default TournamentsScreen;
