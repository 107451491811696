import {FontSizes, hs, isPhone, vs, isPortrait} from '../../Utils/Dimensions';

import CCColors, {updateColorWithOpacity} from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';

const progressBarHeight = 16;

export const styles = StyleSheet.create({
  container: {
    paddingTop: '1%',
    padding: '1%',
    flex: 1,
    backgroundColor: '#FAF6EB',
    // overflow: 'hidden',
  },
  containerMobile: {
    paddingTop: '1%',
    padding: '1%',
    flex: 1,
    backgroundColor: '#FAF6EB',
    overflow: 'scroll',
  },
  infoContainer: {
    flex: 0.2,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  chessboardContainer: {
    flexDirection: 'column',
    // flex: (isPhone || isPortrait) ? 1 : 0.6,
    overflow: 'visible',
    backgroundColor: '#FAF6EB',
    flex: 2,
    width: isPhone ? '100%' : '90%',
    marginLeft: isPhone || isPortrait ? 0 : '2%',
    marginRight: isPhone || isPortrait ? 0 : '2%',
    height: '100%',
  },
  puzzleSolutionText: {
    padding: 16,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: '500',
  },
  cardHeading: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardHeadingIcon: {
    borderRadius: 100,
    // paddingVertical: 8,
    // paddingHorizontal: 10,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    // display: 'flex',
  },
  reviewGameAction: {
    // width: '100%',
    borderRadius: 100,
    alignSelf: 'center',
  },

  reviewGameActionText: {
    fontSize: FontSizes[16],
    lineHeight: 24,
    fontWeight: '500',
    paddingRight: 16,
    // color: ''
  },
  reviewGameActionCoinsContainer: {
    backgroundColor: CCColors.Primary.Light,
    borderRadius: 100,
    padding: 4,
    paddingHorizontal: 16,
    // flex: 1,
    flexDirection: 'row',
    // height: 32
  },

  cardHeadingTextContainer: {
    flexDirection: 'column',
  },

  cardHeadingText: {
    fontWeight: '500',
    marginLeft: 12,
    // display: 'flex',
  },
  cardHeadingSubText: {
    fontSize: 14,
    lineHeight: 16,
    marginLeft: 12,
    color: CCColors.TextColor.Grey,
  },

  reviewGameText: {
    lineHeight: 20,
    // width: '70%',
    marginBottom: 16,
  },
  reviewGameStat: {
    marginVertical: 16,
  },
  reviewGameStatHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  reviewGameStatIcon: {
    marginRight: 8,
  },

  reviewGameStatHeadingText: {
    fontSize: 14,
    color: CCColors.TextColor.Grey,
    lineHeight: 24,
  },

  reviewGameStatText: {
    fontSize: 14,
    lineHeight: 24,
  },

  reviewGameScoreStat: {
    flexDirection: 'row',
  },
  top12: {
    marginTop: vs(16),
  },
  reviewGameScoreStatHeadingText: {
    width: '80%',
    fontSize: 14,
    color: CCColors.TextColor.Grey,
    lineHeight: 24,
  },

  reviewGameScoreStatText: {
    fontSize: 14,
    lineHeight: 24,
  },

  /** Progress bar styling classes */
  progressBarWrapper: {
    borderRightWidth: 0,
    borderColor: updateColorWithOpacity(CCColors.TextColor.Dark, 0.2),
    width: '100%',
    margin: 'auto',
    alignItems: 'center',
  },

  progressBar: {
    margin: 12,
    width: '85%',
    backgroundColor: CCColors.Primary.Light,
    height: progressBarHeight,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 3,
    borderColor: CCColors.Primary.Light,
    borderWidth: 4,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },

  progressBarIcon: {
    height: progressBarHeight / 2,
    width: progressBarHeight / 2,
    marginRight: 4,
  },

  progressBarText: {
    lineHeight: 18,
    fontSize: 12,
    fontWeight: '500',
  },

  progressBarFilling: {
    backgroundColor: CCColors.Primary.Action,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  },
  notificationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: updateColorWithOpacity(CCColors.Red, 0.4),
    borderColor: CCColors.Red,
    borderWidth: StyleSheet.hairlineWidth,
    position: 'absolute',
    zIndex: 5,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    margin: 20,
    width: 'fit-content',
  },
  indicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    marginRight: 8,
  },
  notificationText: {
    flex: 1,
    color: CCColors.White,
    fontWeight: 'bold',
  },
  iconButton: {
    backgroundColor: 'white',
    borderRadius: 25,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
    borderColor: CCColors.Border,
    borderWidth: 1
  }
});

export const infoStyles = StyleSheet.create({
  title: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
  },
  playColor: {
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 18,
    color: CCColors.TextColor.Grey,
  },
  status: {
    backgroundColor: CCColors.White,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 100,
    textAlign: 'center',
    marginVertical: 24,
  },
  statsContainer: {
    borderTopColor: CCColors.Border,
    borderTopWidth: 1,
    paddingVertical: 16,
  },
  stat: {
    display: 'flex',
    flexDirection: 'row',
  },
  statIcon: {
    marginRight: 8,
    // paddingTop: 1,
    color: CCColors.TextColor.Grey,
  },
  statText: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 8,
  },
  gameDetails: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 24,
    marginBottom: 8,
  },
});

export const rightPaneStyles = StyleSheet.create({
  rightPaneContainer: {
    backgroundColor: CCColors.Primary.White,
    flex: isPhone || isPortrait ? 1 : 0.48,
    paddingHorizontal: isPhone ? 8 : 24,
    paddingBottom: 12,
  },
});

export const filterStyles = StyleSheet.create({
  container: {
    backgroundColor: CCColors.White,
    flex: 0.35,
    paddingHorizontal: 32,
    paddingVertical: 24,
    overflow: 'scroll',
  },
  containerHeading: {
    fontSize: 24,
    fontWeight: '500',
  },
  groupHeading: {
    fontWeight: '500',
    marginBottom: 8,
    fontSize: 12,
    lineHeight: 18,
  },

  item: {
    backgroundColor: '#fff',
    borderRadius: 16,
    marginVertical: 3,
  },
  itemSelected: {
    backgroundColor: CCColors.Primary.Dark,
  },

  itemContent: {
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  itemText: {
    fontWeight: '500',
    lineHeight: 18,
    fontSize: 14,
    paddingHorizontal: 8,
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  group: {
    flexBasis: '50%',
    paddingVertical: 16,
  },

  groupContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  pieceImage: {
    width: 48,
    height: 48,
  },
});
