import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Box from '@/Atoms/Box';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigation, useRoute} from '@react-navigation/native';
import CCText from '@/Atoms/CCText';
import {ScreenName} from '@/navigators/StackConstants';
import CCColors from '@/Utils/CCColors';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  FontSizes,
  hs,
  SCREEN_WIDTH,
  vs,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
} from '@/Utils/Dimensions';
import Entypo from '@expo/vector-icons/Entypo';
import Spacer from '@/Atoms/Spacer';
import useGetAssessmentDetailsApi from '@/Hooks/AssessmentApi/useGetAssessmentDetailsApi';

import {
  FlatList,
  Image,
  StyleSheet,
  View,
  ScrollView,
  Pressable,
  TouchableOpacity,
} from 'react-native';
import emptyResult from '../../../../Assets/Images/png/empty_result.png';
import opening from '../../../../Assets/Images/png/opening.png';
import middlegame from '../../../../Assets/Images/png/middlegame.png';
import endgame from '../../../../Assets/Images/png/endgame.png';
import gamesummary from '../../../../Assets/Images/png/gamesummary.png';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {AccordionSection} from '@/Atoms/CCAccordion';
import CCButton from '@/Atoms/CCButton';
import CCTable from '@/Atoms/CCTable';
import ProgressBar from '@/Atoms/ProgressBar';
import Divider from '@/Atoms/Divider/Divider';
import Empty from '@/Atoms/Empty';
import Tooltip from '@/Atoms/Tooltip';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {useUserPlanStore} from '@/Store/userPlanStore';
import OverallAssessmentBox from '../OverallAssessmentBox';
import GraphComponent, {SkillDurationSelector} from './graphComponent';
import useGraphDimensionsStore from './graphDimensionStore';
import useAssessmentFiltersApi from '@/Hooks/AssessmentApi/assessmentFilter';
import useAssessmentHistoryApi from '@/Hooks/AssessmentApi/assessmentHistory';
import CCSelect from '@/Atoms/CCSelect';
import Graph from '@/Components/Graph';
import AssessmentTabModal from '../AssessmentTabModal';
import CCLoader from '@/Atoms/CCLoader';

dayjs.extend(LocalizedFormat);
interface SkillsState {
  skill: string[];
}
const CCBoxTooltip = ({getSelectedTabData, gameKey: key, game, type}) => {
  if (key && game && type) {
    return (
      // <Tooltip
      //   text={
      //     <CCText color={CCColors.White} fontSize={14} fontWeight="regular">
      //       {/* {(getSelectedTabData(key)[game][type] * 100).toFixed(2)}% */}
      //     </CCText>
      //   }
      //   children={toggleEvent => {
      //     return (
      //       <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
      //         <CCText fontSize={16} color={CCColors.White}>
      //           {(getSelectedTabData(key)[game][type] * 100).toFixed(2)}%
      //         </CCText>
      //       </View>
      //     );
      //   }}
      // />
      <View>
        <CCText fontSize={16} color={CCColors.White}>
          {(getSelectedTabData(key)[game][type] * 100).toFixed(2)}%
        </CCText>
      </View>
    );
  }
};

// const RenderProgress = ({progress, data = {}, noOfBars = 1}) => {
//   const [tooltipVisible, setTooltipVisible] = useState(false);
//   const bars = [];
//   for (let i = 0; i < noOfBars; i++) {
//     bars.push(1);
//   }
//   let progressData = [progress, data?.better, data?.equal, data?.worse];

//   if (Object.keys(data).length > 0) {
//     progressData = progressData.slice(1);
//   }

//   return (
//     <View
//       style={{
//         width: '100%',
//         flexDirection: 'row',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}>
//       {bars.map((bar, index) => {
//         return (
//           <>
//             <Tooltip
//               text={
//                 <CCText fontSize={14} color={CCColors.White}>
//                   <ConditionalRender
//                     condition={noOfBars > 1}
//                     childrenA={
//                       <>
//                         {index === 0
//                           ? 'You performed better in'
//                           : index === 1
//                           ? 'You performed equally in'
//                           : index === 2
//                           ? 'You performed worse in'
//                           : null}
//                         {` ${progressData[index] * 100}% games out of ${
//                           data['total']
//                         } `}
//                         {data['total'] > 1 ? 'games' : 'game'}
//                       </>
//                     }
//                     childrenB={<>{progressData[index] * 100}%</>}
//                   />
//                 </CCText>
//               }
//               children={toggleEvent => {
//                 return (
//                   <View
//                     onPointerEnter={toggleEvent}
//                     onPointerLeave={toggleEvent}>
//                     <ProgressBar
//                       onPointerMove={() => setTooltipVisible(true)}
//                       onPress={() => setTooltipVisible(true)}
//                       onPointerLeave={() => setTooltipVisible(false)}
//                       style={{
//                         // width: `${100 / noOfBars}%`,
//                         width: 100 / noOfBars,
//                         height: 6,
//                         backgroundColor: 'rgba(38, 35, 34, 0.1)',
//                       }}
//                       progress={progressData[index] || 1}
//                       fillStyle={{
//                         backgroundColor:
//                           progressData[index] > 0.6
//                             ? CCColors.Green
//                             : progressData[index] > 0.3
//                             ? CCColors.ChessBoard.Dark
//                             : CCColors.Red,
//                       }}
//                       text={undefined}
//                       icon={undefined}
//                     />
//                   </View>
//                 );
//               }}
//             />
//             {/* <ProgressBar
//               onPointerMove={() => setTooltipVisible(true)}
//               onPress={() => setTooltipVisible(true)}
//               onPointerLeave={() => setTooltipVisible(false)}
//               style={{
//                 width: `${100 / noOfBars}%`,
//                 height: 6,
//                 backgroundColor: 'rgba(38, 35, 34, 0.1)',
//               }}
//               progress={progressData[index] || 1}
//               fillStyle={{
//                 backgroundColor:
//                   progressData[index] > 0.6
//                     ? CCColors.Green
//                     : progressData[index] > 0.3
//                     ? CCColors.ChessBoard.Dark
//                     : CCColors.Red,
//               }}
//               text={undefined}
//               icon={undefined}
//             /> */}
//             {/* {tooltipVisible && (
//               <CCText
//                 style={{
//                   position: 'absolute',
//                   top: '100%',
//                   marginLeft: 'auto',
//                   marginRight: 'auto',
//                 }}>
//                 {progressData.map(progressbar => {
//                   if (!isNaN(Number(progressbar))) {
//                     return Number(progressbar).toFixed(2) + '  ';
//                   }
//                 })}
//               </CCText>
//             )} */}
//           </>
//         );
//       })}
//     </View>
//   );
// };

const RenderResultProgress = ({result, data = {}, noOfBars = 1}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const bars = Array(noOfBars).fill(1);
  let resultData = [
    result,
    data?.winpercent,
    data?.drawpercent,
    data?.losspercent,
  ];

  if (Object.keys(data).length > 0) {
    resultData = resultData.slice(1);
  }

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // alignItems: 'center',
      }}>
      {bars.map((bar, index) => (
        <View key={index} style={{flex: 1, marginVertical: 2}}>
          <Tooltip
            text={
              <CCText fontSize={14} color={CCColors.White}>
                <ConditionalRender
                  condition={noOfBars > 1}
                  childrenA={
                    <>
                      {/* {index === 0
                        ? 'You performed better in'
                        : index === 1
                        ? 'You performed equally in'
                        : index === 2
                        ? 'You performed worse in'
                        : null}
                      {` ${progressData[index] * 100}% games out of ${data['total']} `}
                      {data['total'] > 1 ? 'games' : 'game'} */}
                      {`Games Won : ${(resultData[0] * 100).toFixed(2)}%\n`}
                      {`Games drew : ${(resultData[1] * 100).toFixed(2)}%\n`}
                      {`Games lost : ${(resultData[2] * 100).toFixed(2)}%\n`}
                    </>
                  }
                  childrenB={<>{resultData[index] * 100}%</>}
                />
              </CCText>
            }
            children={toggleEvent => (
              <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
                <ProgressBar
                  onPointerMove={() => setTooltipVisible(true)}
                  onPress={() => setTooltipVisible(true)}
                  onPointerLeave={() => setTooltipVisible(false)}
                  style={{
                    width: '100%',
                    height: 6,
                    backgroundColor: 'rgba(38, 35, 34, 0.1)',
                  }}
                  progress={resultData[index]}
                  fillStyle={{
                    backgroundColor:
                      index == 0
                        ? CCColors.Green
                        : index == 1
                        ? CCColors.ChessBoard.Dark
                        : CCColors.Red,
                  }}
                  text={undefined}
                  icon={undefined}
                />
              </View>
            )}
          />
        </View>
      ))}
    </View>
  );
};

export const RenderProgress = ({progress, data = {}, noOfBars = 1}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const bars = Array(noOfBars).fill(1);
  let progressData = [progress, data?.better, data?.equal, data?.worse];

  if (Object.keys(data).length > 0) {
    progressData = progressData.slice(1);
  }

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // alignItems: 'center',
      }}>
      {bars.map((bar, index) => (
        <View key={index} style={{flex: 1, marginVertical: 2}}>
          <Tooltip
            text={
              <CCText fontSize={14} color={CCColors.White}>
                <ConditionalRender
                  condition={noOfBars > 1}
                  childrenA={
                    <>
                      {/* {index === 0
                        ? 'You performed better in'
                        : index === 1
                        ? 'You performed equally in'
                        : index === 2
                        ? 'You performed worse in'
                        : null}
                      {` ${progressData[index] * 100}% games out of ${data['total']} `}
                      {data['total'] > 1 ? 'games' : 'game'} */}
                      {`Better : ${(progressData[0] * 100).toFixed(2)}%\n`}
                      {`Equal : ${(progressData[1] * 100).toFixed(2)}%\n`}
                      {`Worse : ${(progressData[2] * 100).toFixed(2)}%\n`}
                    </>
                  }
                  childrenB={<>{progressData[index] * 100}%</>}
                />
              </CCText>
            }
            children={toggleEvent => (
              <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
                <ProgressBar
                  onPointerMove={() => setTooltipVisible(true)}
                  onPress={() => setTooltipVisible(true)}
                  onPointerLeave={() => setTooltipVisible(false)}
                  style={{
                    width: '100%',
                    height: 6,
                    backgroundColor: 'rgba(38, 35, 34, 0.1)',
                  }}
                  progress={progressData[index]}
                  fillStyle={{
                    backgroundColor:
                      index == 0
                        ? CCColors.Green
                        : index == 1
                        ? CCColors.ChessBoard.Dark
                        : CCColors.Red,
                  }}
                  text={undefined}
                  icon={undefined}
                />
              </View>
            )}
          />
        </View>
      ))}
    </View>
  );
};

const assessmentTypes = [
  {
    id: 0,
    icon: endgame,
    title: 'Overall Track',
    heading: 'Overall Track',
    desc: 'Track your skills and see real-time progress data.',
    iconBg: '#E17846',
  },
  {
    id: 1,
    icon: endgame,
    title: 'Overall Assessment',
    heading: 'Overall Assessment Summary',
    desc: 'Overall assessment summary analyzed from your games.',
    iconBg: '#E17846',
  },
  {
    id: 2,
    icon: opening,
    title: 'Opening Accuracy',
    heading: 'Opening Accuracy',
    desc: 'We identified the opening from your recent games.',
    iconBg: '#DB7ACC',
  },
  {
    id: 3,
    icon: middlegame,
    title: 'Middle Game Accuracy',
    heading: 'Middle Game Accuracy',
    desc: 'Mid-game aspects analyzed from your games.',
    iconBg: '#67B3E1',
  },
  {
    id: 4,
    svg: <Entypo name="calculator" size={20} />,
    title: 'Calculation Accuracy',
    heading: 'Calculation Accuracy',
    desc: 'Calculation aspects analyzed from your games.',
    iconBg: '#DB7ACC',
  },
  {
    id: 5,
    icon: endgame,
    title: 'End game Accuracy',
    heading: 'End Game Accuracy',
    desc: 'End-game aspects analyzed from your games.',
    iconBg: '#E17846',
  },
  {
    id: 6,
    icon: endgame,
    title: 'End Game Patterns',
    heading: 'End Game Patterns',
    desc: 'End-game performance analyzed from your games.',
    iconBg: '#3DAB9E',
  },
  {
    id: 7,
    icon: gamesummary,
    title: 'Games Details',
    heading: 'Games Details',
    desc: 'The following games were assessed as a part of this assessment.',
    iconBg: '#E17846',
  },
];

const AssessmentDetails: React.FC = props => {
  const navigation = useNavigation();
  const assesstmentTypeRef = useRef();
  const {planDetails} = useUserPlanStore();
  const [activeType, setActiveType] = useState<number>(1);
  const [openingReport, setOpeningReport] = useState({});
  const [color, setColor] = useState('white');
  const route = useRoute();
  const user_key = route.params?.user_key || '';
  const [middlegameReport, setMiddlegameReport] = useState({});
  const [egReport, setEgReport] = useState({});
  const [endgameReport, setEndgameReport] = useState({});
  const [calculationReport, setCalculationReport] = useState({});

  const [gamesData, setGamesData] = useState([]);
  const [numberOfGames, setNumberOfGames] = useState<number>(0);
  const [coachAssessmentReport, setCoachAssessmentReport] = useState({});
  const {isMobileOrTab} = useMediaQuery();

  const viewabilityConfig = {
    viewAreaCoveragePercentThreshold: 50,
    minimumViewTime: 0,
  };
  const onViewableItemsChanged = useCallback(({viewableItems}) => {
    if (viewableItems.length > 0) {
      const visibleItem = viewableItems[0];
      setActiveType(visibleItem.item.id);
    }
  }, []);

  const viewabilityConfigCallbackPairs = useRef([
    {onViewableItemsChanged, viewabilityConfig},
  ]);
  const [sectionHeights, setSectionHeights] = useState({});

  // Calculate layout for each item based on stored heights
  const getItemLayout = (data, index) => {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      offset += sectionHeights[i] || 0;
    }
    return {
      length: sectionHeights[index] || 0,
      offset,
      index,
    };
  };
  // Handle measuring section heights
  const onSectionLayout = (id, event) => {
    const {height} = event.nativeEvent.layout;
    setSectionHeights(prev => ({
      ...prev,
      [id]: height,
    }));
  };
  const planId = useMemo(
    () => planDetails?.user_subscription_details?.fields?.plan_id,
    [planDetails],
  );
  dayjs.extend(customParseFormat);

  const [skills, setSkills] = useState<SkillsState>({
    skill: [],
  });
  const [selectedSkillKeys, setSelectedSkillKeys] = useState<string[]>([]);
  const [selectedDurationKeys, setSelectedDurationKeys] = useState<string[]>(
    [],
  );
  const [durationArray, setDurationArray] = useState<string[]>([]);
  const [durationKeyArray, setDurationKeyArray] = useState<string[]>([]);
  const [duration, setDuration] = useState('');
  const handleDurationChange = text => {
    setDuration(text);
    const selectedKeys = durationArray
      .map((duration, index) =>
        text.includes(duration) ? durationKeyArray[index] : null,
      )
      .filter((key): key is string => key !== null);
    setSelectedDurationKeys(selectedKeys);
  };
  const [skillArray, setSkillArray] = useState<string[]>([]);
  const [skillKeyArray, setSkillKeyArray] = useState<string[]>([]);

  useEffect(() => {
    if (user_key) {
      getAssessmentFilters({access_user_key: user_key});
    } else {
      getAssessmentFilters({});
    }

    setSelectedSkillKeys([]);
    setSelectedDurationKeys([]);
  }, [user_key]);

  const handleSkillChange = selectedItems => {
    setSkills(prev => ({
      ...prev,
      skill: selectedItems,
    }));
    const selectedKeys = skillArray
      .map((skill, index) =>
        selectedItems.includes(skill) ? skillKeyArray[index] : null,
      )
      .filter((key): key is string => key !== null);

    setSelectedSkillKeys(selectedKeys);
  };

  const handleOnSuccess = (data: any) => {
    const myObject = Object.values(data?.skills);
    const myObjectKeys = Object.keys(data?.skills);
    const timeRangeValues = Object.values(data?.time_range);
    const timeRangeKeys = Object.keys(data?.time_range);

    setSkillArray(myObject as string[]);
    setDurationArray(timeRangeValues as string[]);
    setDurationKeyArray(timeRangeKeys as string[]);
    setSkillKeyArray(myObjectKeys as string[]);

    if (myObject?.length > 0) {
      setSkills({
        skill: [myObject[0] as string],
      });
    }

    if (timeRangeValues?.length > 0) {
      setDuration(timeRangeValues[2] as string);
    }
  };

  const handleOnFailure = (data: any) => {
    console.log('error=', data);
  };
  const {
    mutate: getAssessmentFilters,
    data: filtersData,
    isLoading: filtersLoading,
    error: error,
  } = useAssessmentFiltersApi(handleOnSuccess, handleOnFailure);

  const [verticalCoords, setVerticalCoords] = useState<number[]>([]);
  const [verticalCoords2, setVerticalCoords2] = useState<number[]>([]);
  const [horizontalLabel, setHorizontalLabel] = useState([]);

  const parsedDates = horizontalLabel
    ?.map(date => dayjs(date, 'DD-MM-YY'))
    .filter(date => date?.isValid())
    .sort((a, b) => a?.valueOf() - b?.valueOf());

  const startDate = dayjs(parsedDates[0]);
  const endDate = dayjs(parsedDates[parsedDates.length - 1]);

  const numDays = endDate.diff(startDate, 'days');

  const maxTicks = selectedDurationKeys.includes('LAST_DAY') ? 1 : 5;
  const daysPerTick = Math.max(Math.floor(numDays / maxTicks), 1);

  const dates: string[] = [];
  let tickDate = startDate.clone();

  while (
    tickDate.isBefore(endDate) ||
    (tickDate.isSame(endDate, 'day') && dates.length <= maxTicks)
  ) {
    dates.push(tickDate.format('D MMM'));
    tickDate = tickDate.add(daysPerTick, 'days');
  }

  const [graphData, setGraphData] = useState(true);
  const [dataText, setDataText] = useState('');
  const [level1, setLevel1] = useState<number[]>([]);
  const [level2, setLevel2] = useState<number[]>([]);
  const [dates1, setDates1] = useState<string[]>([]);
  const [dates2, setDates2] = useState<string[]>([]);
  const handleSuccessHistory = data => {
    if (
      !data ||
      !data?.coach_assessments ||
      Object.keys(data?.coach_assessments).length === 0
    ) {
      setGraphData(false);
      setDataText('No data available');
      console.log('No data or coach assessments available.');
      return;
    }
    const formatDateToDayMonth = dateString => {
      const [day, month, year] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);
      return new Intl.DateTimeFormat('en', {
        month: 'short',
        day: 'numeric',
      }).format(date);
    };
    const coachKeys = Object.keys(data?.coach_assessments);
    const coachKeysA = data?.coach_assessments?.[coachKeys[0]]?.stats;
    const coachKeysB = data?.coach_assessments?.[coachKeys[1]]?.stats;
    const uniqueCoachKeysA = coachKeysA?.filter(
      (item, index, self) =>
        item?.date !== undefined &&
        index === self.findIndex(i => i?.date === item.date),
    );
    const uniqueCoachKeysB = coachKeysB?.filter(
      (item, index, self) =>
        item?.date !== undefined &&
        index === self.findIndex(i => i?.date === item.date),
    );
    setVerticalCoords(
      uniqueCoachKeysA?.map(i => i?.assessment_rating).reverse(),
    );

    setVerticalCoords2(
      uniqueCoachKeysB?.map(i => i?.assessment_rating).reverse(),
    );

    const combinedStats = uniqueCoachKeysA?.concat(uniqueCoachKeysB);

    const dates = combinedStats?.flatMap(item => {
      if (item && item.date) {
        return item.date;
      } else {
        console.warn(
          'Item is missing a date or has an unexpected structure:',
          item,
        );
        return [];
      }
    });

    setLevel1(uniqueCoachKeysA?.map(i => i?.assessment_level).reverse());
    setLevel2(uniqueCoachKeysB?.map(i => i?.assessment_level).reverse());

    setDates1(
      Array.from(
        new Set(
          uniqueCoachKeysA
            ?.map(i => i?.date)
            .map(formatDateToDayMonth)
            .reverse(),
        ),
      ),
    );

    setDates2(
      Array.from(
        new Set(
          uniqueCoachKeysB
            ?.map(i => i?.date)
            .map(formatDateToDayMonth)
            .reverse(),
        ),
      ),
    );

    console.log('Dates Array:', dates);

    setHorizontalLabel(dates);
    setGraphData(true);
    setDataText('');
  };

  const handleFailureHistory = (data: any) => {
    console.log('error=', data);
  };

  const {
    mutate: getAssessmentRecord,
    data: recordData,
    isLoading: historyLoading,
  } = useAssessmentHistoryApi(handleSuccessHistory, handleFailureHistory);

  useEffect(() => {
    if (skillKeyArray?.length && durationKeyArray?.length) {
      if (!selectedSkillKeys?.length && !selectedDurationKeys?.length) {
        setSelectedSkillKeys([skillKeyArray[0]]);
        setSelectedDurationKeys([durationKeyArray[2]]);
        if (user_key) {
          getAssessmentRecord({
            skills: [skillKeyArray?.[0]],
            time_range: durationKeyArray[2],
            access_user_key: user_key,
          });
        } else {
          getAssessmentRecord({
            skills: [skillKeyArray?.[0]],
            time_range: durationKeyArray[2],
          });
        }
      } else if (selectedSkillKeys?.length && selectedDurationKeys?.length) {
        if (user_key) {
          getAssessmentRecord({
            skills: selectedSkillKeys,
            time_range: selectedDurationKeys,
            access_user_key: user_key,
          });
        } else {
          getAssessmentRecord({
            skills: selectedSkillKeys,
            time_range: selectedDurationKeys,
          });
        }
      }
    }
  }, [
    user_key,
    skillKeyArray,
    durationKeyArray,
    selectedSkillKeys,
    selectedDurationKeys,
    getAssessmentRecord,
  ]);

  const {
    handleBack,
    assessmentId,
    openingGamePercentage,
    middleGamePercentage,
    endGamePercentage,
    calculationDepthPercentage,
    onboardingStatus,
    firstAssessmentPending,
    cdcUsername,
    licUsername,
  } = props;

  const [assessment, setAssessment] = useState();

  const setDimensions = useGraphDimensionsStore(state => state.setDimensions);
  const handleLayout = event => {
    const {width, height} = event.nativeEvent.layout;
    setDimensions(width, height); // Update dimensions in the store
  };
  const calculateEndgameReport = (data: object) => {
    let endGamePercentage = {};
    const endgameReport = Object.keys(data).reduce((acc, key) => {
      endGamePercentage[key] = 0;
      acc[key] = [];
      const value = data[key];

      Object.keys(value).forEach(type => {
        const obj = {};
        const TOTAL_GAMES: number = Object.values(value[type]).reduce(
          (a, b) => a + b,
          0,
        );

        if (
          value[type]?.hasOwnProperty('Better') &&
          value[type]?.hasOwnProperty('Slightly_Better')
        ) {
          const PLAY_BETTER = value[type]?.Better;
          const PLAY_SLIGHTLY_BETTER = value[type]?.Slightly_Better;
          const PLAY_NEUTRAL = value[type]?.Neutral || 0;
          const PLAY_SLIGHTLY_WORSE = value[type]?.Slightly_Worse || 0;
          const PLAY_WORSE = value[type]?.Worse || 0;

          const win = data[`${key}_wins`][type]?.win || 0;
          const loss = data[`${key}_wins`][type]?.loss || 0;
          const draw = data[`${key}_wins`][type]?.draw || 0;

          let better_percent =
            (PLAY_BETTER + PLAY_SLIGHTLY_BETTER) / TOTAL_GAMES;
          let worse_percent = (PLAY_WORSE + PLAY_SLIGHTLY_WORSE) / TOTAL_GAMES;
          let equal_percent = PLAY_NEUTRAL / TOTAL_GAMES;
          let win_percent = win / TOTAL_GAMES;
          let draw_percent = draw / TOTAL_GAMES;
          let loss_percent = loss / TOTAL_GAMES;

          obj['progress'] = Number(better_percent.toFixed(4));
          obj['better'] = Number(better_percent.toFixed(4));
          obj['worse'] = Number(worse_percent.toFixed(4));
          obj['equal'] = Number(equal_percent.toFixed(4));
          obj['win'] = win;
          obj['loss'] = loss;
          obj['draw'] = draw;
          obj['result'] = Number(win_percent.toFixed(4));
          obj['winpercent'] = Number(win_percent.toFixed(4));
          obj['losspercent'] = Number(loss_percent.toFixed(4));
          obj['drawpercent'] = Number(draw_percent.toFixed(4));
          endGamePercentage[key] = endGamePercentage[key] + better_percent;
          endGamePercentage[key] += win_percent;
        }
        obj['name'] = `${expand(type)} endgame`;
        obj['type'] = type;
        obj['total'] = TOTAL_GAMES;
        acc[key].push(obj);
      });
      if (endGamePercentage[key])
        endGamePercentage[key] = Number(
          (endGamePercentage[key] / acc[key].length).toFixed(4),
        );
      acc[key].sort((a, b) => b.total - a.total);
      return acc;
    }, {});
    endgameReport['percentages'] = user_key ? data?.score : endGamePercentage;
    endgameReport['avg_percentage'] =
      Object.values(endGamePercentage).reduce((a, b) => a + b, 0) / 2;
    console.log(endgameReport, 'endgameReport');
    setEndgameReport(endgameReport);
  };

  const calculateOpeningReport = (data: object) => {
    console.log('opening data: ', data);
    let openingPercentage = {};
    const openingReport = Object.keys(data).reduce((acc, key) => {
      openingPercentage[key] = data[key].accuracy / 100;
      acc[key] = [];
      for (let i in data[key].sstats) {
        const value = data[key].sstats[i];
        console.log('value', value);
        const TOTAL_GAMES: number = Object.values(value.Play).reduce(
          (a, b) => a + b,
          0,
        );
        const obj = {};
        obj['total'] = TOTAL_GAMES;
        obj['opening_eco'] = i;
        obj['Accuracy'] = value.Accuracy
          ? `${Math.round(value.Accuracy)}%`
          : '0%';
        Object.keys(value).forEach(type => {
          if (
            value[type]?.hasOwnProperty('Better') &&
            value[type]?.hasOwnProperty('Slightly_Better')
          ) {
            const PLAY_BETTER = value[type]?.Better;
            const PLAY_SLIGHTLY_BETTER = value[type]?.Slightly_Better;
            const PLAY_NEUTRAL = value[type]?.Neutral || 0;
            const PLAY_SLIGHTLY_WORSE = value[type]?.Slightly_Worse || 0;
            const PLAY_WORSE = value[type]?.Worse || 0;

            let better_percent =
              (PLAY_BETTER + PLAY_SLIGHTLY_BETTER) / TOTAL_GAMES;
            let worse_percent =
              (PLAY_WORSE + PLAY_SLIGHTLY_WORSE) / TOTAL_GAMES;
            let equal_percent = PLAY_NEUTRAL / TOTAL_GAMES;
            obj[type] = {
              better: Number(better_percent.toFixed(4)),
              equal: Number(equal_percent.toFixed(4)),
              worse: Number(worse_percent.toFixed(4)),
            };
          } else if (type === 'Name') {
            const nameParts = i.split(';');
            const name = nameParts[0];
            obj[type] = (
              <View>
                <CCText fontSize={14}>{value[type]}</CCText>
                <CCText fontSize={14}>({name})</CCText>
              </View>
            );
          }
        });

        acc[key].push(obj);
        // whiteOpReportStats.push({key, value}); // [{ key: 'a', value: 1 }, { key: 'b', value: 2 }]
      }
      acc[key].sort((a, b) => b.total - a.total);
      // openingPercentage[key] = Number(
      //   (openingPercentage[key] / acc[key].length).toFixed(4),
      // );
      return acc;
    }, {});
    console.log('openingPercentage: ', openingPercentage);
    openingReport['percentages'] = openingPercentage;
    openingReport['avg_percentage'] =
      Object.values(openingPercentage).reduce((a, b) => a + b, 0) / 2;
    openingReport['assessment_percentage'] = user_key
      ? data?.score
      : openingGamePercentage;
    console.log('opening Report: ', openingReport);
    setOpeningReport(openingReport);
  };

  const calculatePerformanceReport = (
    data: object,
    type,
    userKeyPercentage?,
  ) => {
    const report = Object.keys(data).reduce((acc, curr) => {
      const value = data[curr];
      const total = Object.values(value).reduce((a, b) => a + b, 0);
      if (curr === 'CALCULATION_DEPTH') {
        curr = 'Calculation Depth';
      }
      acc[curr] = {total: total, assessment_info: value?.assessment_info};
      Object.keys(value).forEach(key => {
        if (type === 'middlegame') {
          if (key === 'MISSED') {
            let goodCount = value['GOOD'];
            const percentage = Number(
              (value[key] / (goodCount + value[key])).toFixed(4),
            );
            const foundPercentage = Number(
              (value['GOOD'] / (goodCount + value[key])).toFixed(4),
            );

            acc[curr][key] = percentage;
            acc[curr]['FOUND'] = foundPercentage;
          } else {
            const percentage = Number(
              (
                value[key] /
                (value['GOOD'] +
                  value['INACCURACY'] +
                  value['MISTAKE'] +
                  value['BLUNDER'])
              ).toFixed(4),
            );

            acc[curr][key] = isNaN(percentage) ? 0 : percentage;
          }
        } else if (type === 'coachassessment') {
          let percentage;
          if (key === 'assessment_rating') {
            percentage = Number(((value[key] * 10) / 100).toFixed(4));
          } else {
            percentage = Number(((value[key] * 100) / 100).toFixed(4));
          }
          acc[curr][key] = percentage;
        } else {
          const percentage = Number((value[key] / total).toFixed(4));

          acc[curr][key] = percentage;
        }
      });
      acc[curr] = {...acc[curr], assessment_info: value?.assessment_info};
      return acc;
    }, {});
    if (type === 'middlegame') {
      report['assessment_percentage'] = user_key
        ? userKeyPercentage
        : middleGamePercentage;

      setMiddlegameReport(report);
    } else if (type === 'calculation') {
      report['assessment_percentage'] = user_key
        ? userKeyPercentage
        : calculationDepthPercentage;
      setCalculationReport(report);
    } else if (type === 'endgame') {
      report['assessment_percentage'] = user_key
        ? userKeyPercentage
        : endGamePercentage;
      setEgReport(report);
    } else if (type === 'coachassessment') {
      // debugger;
      setCoachAssessmentReport(report);
    }
  };

  const getSelectedTabData = id => {
    switch (id) {
      case 1:
        return coachAssessmentReport;
      case 2:
        return openingReport;
      case 3:
        return middlegameReport;
      case 4:
        return calculationReport;
      case 5:
        return egReport;
      case 6:
        return endgameReport;
      case 7:
        return gamesData;
      case 0:
        return gamesData;
      default:
        return [];
    }
  };

  const onLoadAssessmentDetails = assessmentDetails => {
    setNumberOfGames(assessmentDetails.data?.games.length);
    setAssessment(assessmentDetails.data);
    setGamesData(
      assessmentDetails.data?.games?.map(g => ({...g, ...g.eval_score})),
    );
    if (assessmentDetails.data?.opening)
      calculateOpeningReport(assessmentDetails.data?.opening);
    if (assessmentDetails?.data?.middlegame?.stats)
      calculatePerformanceReport(
        assessmentDetails?.data?.middlegame?.stats,
        'middlegame',
        assessmentDetails?.data?.middlegame?.score,
      );

    if (assessmentDetails?.data?.calculation?.stats)
      calculatePerformanceReport(
        assessmentDetails?.data?.calculation?.stats,
        'calculation',
        assessmentDetails?.data?.calculation?.score,
      );

    if (assessmentDetails?.data?.endgame?.stats)
      calculatePerformanceReport(
        assessmentDetails?.data?.endgame?.stats,
        'endgame',
        assessmentDetails?.data?.endgame?.score,
      );
    if (assessmentDetails?.data?.coach_assessments?.stats)
      calculatePerformanceReport(
        // {
        //   ATTACKING_FEELING: {
        //     assessment_rating: 0.0,
        //     assessment_confidence: 0.36,
        //   },
        //   SIMPLE_CALCULATION: {
        //     assessment_rating: 10.0,
        //     assessment_confidence: 1.0,
        //   },
        //   DECISIVENESS: {
        //     assessment_rating: 0.0,
        //     assessment_confidence: 0.04,
        //   },
        //   OPENING_KNOWLEDGE: {
        //     assessment_rating: 7.91,
        //     assessment_confidence: 0.24,
        //   },
        //   STRENGTH_TO_RECOVER: {
        //     assessment_rating: 100.0,
        //     assessment_confidence: 0.24,
        //   },
        //   CONVERSION: {
        //     assessment_rating: 0.0,
        //     assessment_confidence: 0.24,
        //   },
        // }
        assessmentDetails?.data?.coach_assessments?.stats,
        'coachassessment',
      );

    if (assessmentDetails?.data?.endgame_performance?.aggregate)
      calculateEndgameReport(
        assessmentDetails?.data?.endgame_performance?.aggregate,
      );
  };

  // const {
  //   mutate: getAssessmentDetails,
  //   data: assessmentData,
  //   error: assessmentError,
  // } = useGetAssessmentDetailsApi(onLoadAssessmentDetails, error => {
  //   console.log('Error Data = ', error);
  // });

  const {
    data: assessmentData,
    error: assessmentError,
    refetch: getAssessmentDetails,
    isLoading: assessmentLoading,
  } = useGetAssessmentDetailsApi(assessmentId, user_key, {
    onSuccess: onLoadAssessmentDetails,
    onError: error => {
      console.log('Error Data = ', error);
    },
    enabled: true, // prevent auto-fetch; call getAssessmentDetails() when needed
  });

  useEffect(() => {
    if (user_key) {
      getAssessmentDetails(user_key);
    } else {
      getAssessmentDetails(assessmentId);
    }
  }, [user_key]);

  const convertToReadableString = (key: string) => {
    switch (key) {
      case 'ONE_MOVE':
        return '1 move';
      case 'TWO_MOVE':
        return '2 move';
      case 'THREE_MOVE':
        return '3 move';
      case 'FOUR_OR_MORE_MOVE':
        return '4+ move';
      default:
        if (key && key.indexOf('_') >= 0) {
          let tokens = key.split('_');
          for (var i = 0; i < tokens.length; i++) {
            tokens[i] =
              tokens[i].charAt(0).toUpperCase() +
              tokens[i].slice(1).toLowerCase();
          }
          return tokens.join(' ');
        } else {
          return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
        }
    }
  };

  const expand = endgameConfiguration => {
    const pieces = endgameConfiguration.split('v');
    let side1 = pieces[0];
    let side2 = pieces[1];

    side1 = side1
      .replace('R', 'Rook')
      .replace('Q', 'Queen')
      .replace('N', 'Knight')
      .replace('B', 'Bishop');
    side2 = side2
      .replace('R', 'Rook')
      .replace('Q', 'Queen')
      .replace('N', 'Knight')
      .replace('B', 'Bishop');

    return side1 + ' vs ' + side2;
  };

  const navigateToPuzzle = (key, type, value) => {
    console.log('navigating....', value);
    if (value) {
      switch (key) {
        case 'GOOD':
          // return navigation.navigate(ScreenName.Puzzles, {
          //   filter: 'good_move',
          //   type: type,
          // });
          return;
        case 'INACCURACY':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'inaccuracy_move',
            type: type,
          });
        case 'MISSED':
          return navigation.navigate(ScreenName.Puzzles, {
            evaluationResult: 'MISSED',
            type: type,
          });
        case 'FOUND':
          // return navigation.navigate(ScreenName.Puzzles, {
          //   filter: 'found_move',
          //   type: type,
          // });
          return;
        case 'MISTAKE':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'mistake_move',
            type: type,
          });
        case 'BLUNDER':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'blunder_move',
            type: type,
          });
        case 'ONE_MOVE':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'one_move',
            type:
              type == 'BLUNDER_DEPTH'
                ? 'BLUNDER_DEPTH'
                : 'MOVE_CALCULATION_DEPTH',
          });
        case 'TWO_MOVE':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'two_move',
            type:
              type == 'BLUNDER_DEPTH'
                ? 'BLUNDER_DEPTH'
                : 'MOVE_CALCULATION_DEPTH',
          });
        case 'THREE_MOVE':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'three_move',
            type:
              type == 'BLUNDER_DEPTH'
                ? 'BLUNDER_DEPTH'
                : 'MOVE_CALCULATION_DEPTH',
          });
        case 'FOUR_OR_MORE_MOVE':
          return navigation.navigate(ScreenName.Puzzles, {
            filter: 'four_or_more_move',
            type:
              type == 'BLUNDER_DEPTH'
                ? 'BLUNDER_DEPTH'
                : 'MOVE_CALCULATION_DEPTH',
          });
        default:
          break;
      }
    }
  };

  const colorsMapping = [
    {
      id: 1,
      name: 'Exceptional',
      color: CCColors.Green,
    },
    {
      id: 2,
      name: 'Excellent',
      color: CCColors.Blue,
    },
    {
      id: 3,
      name: 'Poor',
      color: CCColors.ChessBoard.Dark,
    },
    {
      id: 4,
      name: 'Urgent Attention',
      color: CCColors.Red,
    },
  ];

  const getColorBasedonType = (key, value) => {
    if ((key === 'FOUND' || key === 'MISSED') && isNaN(value)) {
      return CCColors.TextColor.Grey;
    }

    switch (key) {
      case 'FOUND':
      case 'assessment_confidence':
        return value > 0.75
          ? CCColors.Green
          : value > 0.5
          ? CCColors.Blue
          : value > 0.25
          ? CCColors.ChessBoard.Dark
          : CCColors.Red;
      case 'assessment_rating':
        return value > 0.75
          ? CCColors.Green
          : value > 0.5
          ? CCColors.Blue
          : value > 0.25
          ? CCColors.ChessBoard.Dark
          : CCColors.Red;
      case 'GOOD':
        return value > 0.6
          ? CCColors.Green
          : value > 0.4
          ? CCColors.Blue
          : value > 0.2
          ? CCColors.ChessBoard.Dark
          : CCColors.Red;
      case 'INACCURACY':
      case 'MISSED':
      case 'MISTAKE':
      case 'BLUNDER':
      case 'ONE_MOVE':
      case 'THREE_MOVE':
      case 'TWO_MOVE':
        return value < 0.1
          ? CCColors.Green
          : value < 0.15
          ? CCColors.Blue
          : value < 0.2
          ? CCColors.ChessBoard.Dark
          : CCColors.Red;
      case 'FOUR_OR_MORE_MOVE':
        return CCColors.Green;
      default:
        break;
    }
  };

  const renderContent = (key, item) => {
    switch (key) {
      case 1:
        return (
          <Box style={{margin: 20}}>
            <Box flexDirection="row" justifyContent="space-between">
              <Box gap={20} type="row-flex-start">
                {colorsMapping.map(color => {
                  return (
                    <Box flexDirection="row" alignItems="center" gap={10}>
                      <Box
                        style={{
                          width: 10,
                          height: 10,
                          borderRadius: '50%',
                          backgroundColor: color.color,
                        }}></Box>
                      <CCText color={CCColors.TextColor.Dark}>
                        {color.name}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box gap={5}>
              {Object.keys(coachAssessmentReport)?.length === 0 && (
                <CCText textAlign="center" style={{flex: 1}}>
                  No Data Available
                </CCText>
              )}
              {/* {Object.keys(getSelectedTabData(key)).map(game => {
                if (game === 'assessment_percentage') {
                  return;
                }
              })} */}
              {Object.keys(coachAssessmentReport)?.length > 0 && (
                <OverallAssessmentBox
                  overallAssessmentData={coachAssessmentReport}
                />
              )}
            </Box>
          </Box>
          // <Box style={{marginVertical: 20}} type="row-center-space-around">
          //   {/* <Box flexDirection="row" alignItems="flex-end" gap={30}>
          //     <Box gap={5}>
          //       {Object.keys(coachAssessmentReport).length === 0 && (
          //         <CCText textAlign="center" style={{flex: 1}}>
          //           No Data Available
          //         </CCText>
          //       )}
          //       {Object.keys(getSelectedTabData(key)).map(game => {
          //         if (game === 'assessment_percentage') {
          //           return;
          //         }
          //         return (
          //           <Box style={{height: 60}}>
          //             <CCText color={CCColors.TextColor.Dark}>
          //               {convertToReadableString(game)}
          //             </CCText>
          //           </Box>
          //         );
          //       })}
          //     </Box>
          //     <Box gap={5}>
          //       {Object.keys(getSelectedTabData(key)).map((game, i) => {
          //         if (game === 'assessment_percentage') {
          //           return;
          //         }
          //         if (i === 0) {
          //           return (
          //             <Box>
          //               <Box type="row-flex-start" gap={10}>
          //                 {Object.keys(getSelectedTabData(key)[game])
          //                   .filter(type => type !== 'total')
          //                   .map(type => (
          //                     <Box type="center" style={{height: 40}}>
          //                       <CCText
          //                         style={{width: 80, textAlign: 'center'}}
          //                         color={CCColors.TextColor.Grey}>
          //                         {convertToReadableString(type.split('_')[1])}
          //                       </CCText>
          //                     </Box>
          //                   ))}
          //               </Box>

          //               <Box type="row-flex-start" gap={5}>
          //                 {Object.keys(getSelectedTabData(key)[game])
          //                   .filter(type => type !== 'total')
          //                   .map(type => (
          //                     <Box
          //                       onPress={() => {
          //                         navigateToPuzzle(
          //                           type,
          //                           game,
          //                           getSelectedTabData(key)[game][type],
          //                         );
          //                       }}
          //                       style={{
          //                         backgroundColor: getColorBasedonType(
          //                           type,
          //                           getSelectedTabData(key)[game][type],
          //                         ),
          //                         borderRadius: 4,
          //                         width: 85,
          //                         height: 60,
          //                         justifyContent: 'center',
          //                         alignItems: 'center',
          //                       }}>
          //                       <CCBoxTooltip
          //                         getSelectedTabData={getSelectedTabData}
          //                         key={key}
          //                         gameKey={key}
          //                         game={game}
          //                         type={type}
          //                       />
          //                     </Box>
          //                   ))}
          //               </Box>
          //             </Box>
          //           );
          //         }
          //         return (
          //           <Box type="row-flex-start" gap={5}>
          //             {Object.keys(coachAssessmentReport[game])
          //               .filter(type => type !== 'total')
          //               .map(type => (
          //                 <Box
          //                   onPress={() =>
          //                     navigateToPuzzle(
          //                       type,
          //                       game,
          //                       getSelectedTabData(key)[game][type],
          //                     )
          //                   }
          //                   style={{
          //                     backgroundColor: getColorBasedonType(
          //                       type,
          //                       coachAssessmentReport[game][type],
          //                     ),
          //                     borderRadius: 4,
          //                     width: 85,
          //                     height: 60,
          //                     justifyContent: 'center',
          //                     alignItems: 'center',
          //                   }}>
          //                   <CCBoxTooltip
          //                     getSelectedTabData={getSelectedTabData}
          //                     key={key}
          //                     gameKey={key}
          //                     game={game}
          //                     type={type}
          //                   />
          //                 </Box>
          //               ))}
          //           </Box>
          //         );
          //       })}
          //     </Box>
          //   </Box> */}
          //   <Box gap={30}>
          //     {colorsMapping.map(color => {
          //       return (
          //         <Box flexDirection="row" alignItems="center" gap={10}>
          //           <Box
          //             style={{
          //               width: 10,
          //               height: 10,
          //               borderRadius: '50%',
          //               backgroundColor: color.color,
          //             }}></Box>
          //           <CCText color={CCColors.TextColor.Dark}>
          //             {color.name}
          //           </CCText>
          //         </Box>
          //       );
          //     })}
          //   </Box>
          // </Box>
        );
      case 2:
        return (
          <CCTable
            assessmentId={assessmentId}
            color={color}
            clickableRow={true}
            headers={[
              {
                key: 'Name',
                label: 'Opening',
                flex: 1,
              },
              {
                key: 'Accuracy',
                label: 'Accuracy',
                flex: 1,
              },
              {
                key: 'total',
                label: 'Games Played',
              },
              {
                key: 'Play',
                label: 'Position after Opening',
                render: (val, rowDetails) => {
                  return (
                    <RenderProgress
                      progress={val['better']}
                      data={val}
                      noOfBars={3}
                    />
                  );
                },
              },
              {
                key: 'DevAdvantage',
                label: 'Piece Development',
                render: (val, rowDetails) => {
                  return (
                    <RenderProgress progress={val} data={val} noOfBars={3} />
                  );
                },
              },
              {
                key: 'CenterControl',
                label: 'Center Control',
                render: (val, rowDetails) => {
                  return (
                    <RenderProgress progress={val} data={val} noOfBars={3} />
                  );
                },
              },
            ]}
            data={getSelectedTabData(key)?.[color]}
            headerStyle={{
              backgroundColor: 'transparent',
              color: CCColors.TextColor.Grey,
            }}
          />
        );
      case 3:
        return (
          <Box style={{marginVertical: 20}} type="row-center-space-around">
            <Box flexDirection="row" alignItems="flex-end" gap={30}>
              <Box gap={10}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 60}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
              <Box gap={5}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={10}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .slice(-2)
                            .filter(type => type !== 'total')
                            .sort((a, b) => {
                              const order = [
                                'INACCURACY',
                                'MISTAKE',
                                'MISSED',
                                'GOOD',
                                'BLUNDER',
                                'FOUND',
                              ];
                              return order.indexOf(b) - order.indexOf(a);
                            })
                            .map(type => (
                              <CCText
                                style={{width: 80, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={5}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .slice(-2)
                            .filter(type => type !== 'total')
                            .sort((a, b) => {
                              const order = [
                                'INACCURACY',
                                'MISTAKE',
                                'MISSED',
                                'GOOD',
                                'BLUNDER',
                                'FOUND',
                              ];
                              return order.indexOf(b) - order.indexOf(a);
                            })
                            .map(type => (
                              <Box
                                style={{
                                  backgroundColor: getColorBasedonType(
                                    type,
                                    getSelectedTabData(key)[game][type],
                                  ),
                                  borderRadius: 4,
                                  width: 85,
                                  height: 60,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <CCText fontSize={16} color={CCColors.White}>
                                  {isNaN(getSelectedTabData(key)[game][type])
                                    ? '-'
                                    : `${(
                                        getSelectedTabData(key)[game][type] *
                                        100
                                      ).toFixed(2)}%`}
                                </CCText>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={5}>
                      {Object.keys(middlegameReport[game])
                        .slice(-2)
                        .filter(type => type !== 'total')
                        .sort((a, b) => {
                          const order = [
                            'INACCURACY',
                            'MISTAKE',
                            'MISSED',
                            'GOOD',
                            'BLUNDER',
                            'FOUND',
                          ];
                          return order.indexOf(b) - order.indexOf(a);
                        })
                        .map(type => (
                          <Box
                            style={{
                              backgroundColor: getColorBasedonType(
                                type,
                                middlegameReport[game][type],
                              ),
                              borderRadius: 4,
                              width: 85,
                              height: 60,
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <CCText fontSize={16} color={CCColors.White}>
                              {isNaN(getSelectedTabData(key)[game][type])
                                ? '-'
                                : `${(
                                    getSelectedTabData(key)[game][type] * 100
                                  ).toFixed(2)}%`}
                            </CCText>
                          </Box>
                        ))}
                    </Box>
                  );
                })}
              </Box>
              <Box gap={5} marginLeft={5}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={10}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .slice(0, 6)
                            .filter(type => type !== 'total')
                            .sort((a, b) => {
                              const order = [
                                'GOOD',
                                'BLUNDER',
                                'MISTAKE',
                                'MISSED',
                                'INACCURACY',
                                'FOUND',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .slice(2)
                            .map((type, index) => (
                              <CCText
                                key={index}
                                style={{
                                  width: index === 2 ? 100 : 80,
                                  textAlign: 'center',
                                }}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={5}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .slice(0, 6)
                            .filter(type => type !== 'total')
                            .sort((a, b) => {
                              const order = [
                                'GOOD',
                                'BLUNDER',
                                'MISTAKE',
                                'MISSED',
                                'INACCURACY',
                                'FOUND',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .slice(2)
                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].BLUNDER +
                                getSelectedTabData(key)[game].MISTAKE +
                                getSelectedTabData(key)[game].INACCURACY;
                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  onPress={() =>
                                    navigateToPuzzle(
                                      type,
                                      game,
                                      getSelectedTabData(key)[game][type],
                                    )
                                  }
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 85,
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={16} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    );
                  }

                  return (
                    <Box type="row-flex-start" gap={5}>
                      {Object.keys(middlegameReport[game])
                        .slice(0, 6)
                        .filter(type => type !== 'total')
                        .sort((a, b) => {
                          const order = [
                            'GOOD',
                            'BLUNDER',
                            'MISTAKE',
                            'MISSED',
                            'INACCURACY',
                            'FOUND',
                          ];
                          return order.indexOf(a) - order.indexOf(b);
                        })
                        .slice(2)
                        .map(type => {
                          const totalErrors =
                            getSelectedTabData(key)[game].BLUNDER +
                            getSelectedTabData(key)[game].MISTAKE +
                            getSelectedTabData(key)[game].INACCURACY;
                          const percentage =
                            totalErrors > 0
                              ? (getSelectedTabData(key)[game][type] /
                                  totalErrors) *
                                100
                              : 0;
                          return (
                            <Box
                              onPress={() =>
                                navigateToPuzzle(
                                  type,
                                  game,
                                  getSelectedTabData(key)[game][type],
                                )
                              }
                              style={{
                                backgroundColor: getColorBasedonType(
                                  type,
                                  middlegameReport[game][type],
                                ),
                                borderRadius: 4,
                                width: 85,
                                height: 60,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <CCText fontSize={16} color={CCColors.White}>
                                {isNaN(getSelectedTabData(key)[game][type])
                                  ? '-'
                                  : `${percentage.toFixed(2)}%`}
                              </CCText>
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box gap={30} marginLeft={10}>
              {colorsMapping.map(color => {
                return (
                  <Box flexDirection="row" alignItems="center" gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box style={{marginVertical: 20}} type="row-center-space-around">
            <Box flexDirection="row" alignItems="flex-end" gap={30}>
              <Box gap={10}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 60}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
              <Box gap={5}>
                {Object.keys(getSelectedTabData(key))[0] === 'Blunders' && (
                  <Box flexDirection="row" gap={10}>
                    <CCText>
                      Best Calculation Depth:
                      {isFinite(maxCalculationDepth) ? maxCalculationDepth : 0}
                    </CCText>
                    <CCText>
                      Median Calculation Depth:
                      {isNaN(medianCalculationDepth)
                        ? 0
                        : medianCalculationDepth}
                    </CCText>
                  </Box>
                )}
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={10}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(type => type !== 'total')
                            .slice(1)
                            .map(type => (
                              <CCText
                                style={{width: 80, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={5}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(type => type !== 'total')
                            .slice(1)
                            .map(type => (
                              <Box
                                onPress={() => {
                                  navigateToPuzzle(
                                    type,
                                    game,
                                    getSelectedTabData(key)[game][type],
                                  );
                                }}
                                style={{
                                  backgroundColor: getColorBasedonType(
                                    type,
                                    getSelectedTabData(key)[game][type],
                                  ),
                                  borderRadius: 4,
                                  width: 85,
                                  height: 60,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <CCBoxTooltip
                                  getSelectedTabData={getSelectedTabData}
                                  key={key}
                                  gameKey={key}
                                  game={game}
                                  type={type}
                                />
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    );
                  }

                  return (
                    <Box type="row-flex-start" gap={5}>
                      {getSelectedTabData(key)[game] &&
                        Object.keys(getSelectedTabData(key)[game])
                          .filter(type => type !== 'total')
                          .slice(1)
                          .map(type => (
                            <Box
                              style={{
                                backgroundColor: CCColors.Green,
                                // getColorBasedonType(
                                //   type,
                                //   getSelectedTabData(key)[game][type],
                                // ),
                                borderRadius: 4,
                                width: 85,
                                height: 60,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <CCBoxTooltip
                                getSelectedTabData={getSelectedTabData}
                                key={key}
                                gameKey={key}
                                game={game}
                                type={type}
                              />
                            </Box>
                          ))}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box gap={30}>
              {colorsMapping.map(color => {
                return (
                  <Box flexDirection="row" alignItems="center" gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      case 5:
        return (
          <Box style={{marginVertical: 20}} type="row-center-space-around">
            <Box flexDirection="row" alignItems="flex-end" gap={30}>
              <Box gap={10}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 60}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
              <Box gap={5} flexDirection="row">
                {Object.keys(getSelectedTabData(key))[0] === 'Blunders' && (
                  <Box flexDirection="row" gap={10}>
                    <CCText>
                      Best Calculation Depth:
                      {isFinite(maxCalculationDepth) ? maxCalculationDepth : 0}
                    </CCText>
                    <CCText>
                      Median Calculation Depth:
                      {isNaN(medianCalculationDepth)
                        ? 0
                        : medianCalculationDepth}
                    </CCText>
                  </Box>
                )}
                <Box gap={5}>
                  {Object.keys(getSelectedTabData(key)).map((game, i) => {
                    if (game === 'assessment_percentage') {
                      return;
                    }
                    if (i === 0) {
                      return (
                        <Box>
                          <Box type="row-flex-start">
                            {Object.keys(getSelectedTabData(key)[game])
                              .filter(
                                type => type === 'GOOD' || type === 'MISSED',
                              )

                              .map(type => (
                                <CCText
                                  style={{width: 80, textAlign: 'center'}}
                                  color={CCColors.TextColor.Grey}>
                                  {convertToReadableString(type)}
                                </CCText>
                              ))}
                          </Box>
                          <Spacer spacing={vs(20)} />
                          <Box type="row-flex-start" gap={5}>
                            {Object.keys(getSelectedTabData(key)[game])
                              .filter(
                                type => type === 'GOOD' || type === 'MISSED',
                              )

                              .map(type => {
                                const totalErrors =
                                  getSelectedTabData(key)[game].GOOD +
                                  getSelectedTabData(key)[game].MISSED;

                                const percentage =
                                  totalErrors > 0
                                    ? (getSelectedTabData(key)[game][type] /
                                        totalErrors) *
                                      100
                                    : 0;

                                return (
                                  <Box
                                    onPress={
                                      type === 'MISSED'
                                        ? () =>
                                            navigateToPuzzle(
                                              type,
                                              game,
                                              getSelectedTabData(key)[game][
                                                type
                                              ],
                                            )
                                        : undefined
                                    }
                                    style={{
                                      backgroundColor: getColorBasedonType(
                                        type,
                                        getSelectedTabData(key)[game][type],
                                      ),
                                      borderRadius: 4,
                                      width: 85,
                                      height: 60,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}>
                                    <CCText
                                      fontSize={16}
                                      color={CCColors.White}>
                                      {isNaN(
                                        getSelectedTabData(key)[game][type],
                                      )
                                        ? '-'
                                        : `${percentage.toFixed(2)}%`}
                                    </CCText>
                                  </Box>
                                );
                              })}
                          </Box>
                        </Box>
                      );
                    }

                    return (
                      <Box type="row-flex-start" gap={5}>
                        {getSelectedTabData(key)[game] &&
                          Object.keys(getSelectedTabData(key)[game])
                            .filter(
                              type => type === 'GOOD' || type === 'MISSED',
                            )

                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].GOOD +
                                getSelectedTabData(key)[game].MISSED;

                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  onPress={
                                    type === 'MISSED'
                                      ? () =>
                                          navigateToPuzzle(
                                            type,
                                            game,
                                            getSelectedTabData(key)[game][type],
                                          )
                                      : undefined
                                  }
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 85,
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={16} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                </Box>
                              );
                            })}
                      </Box>
                    );
                  })}
                </Box>
                <Box gap={5} marginLeft={10}>
                  {Object.keys(getSelectedTabData(key)).map((game, i) => {
                    if (game === 'assessment_percentage') {
                      return;
                    }
                    if (i === 0) {
                      return (
                        <Box>
                          <Box type="row-flex-start" gap={10}>
                            {Object.keys(getSelectedTabData(key)[game])
                              .filter(
                                type =>
                                  type === 'BLUNDER' ||
                                  type === 'INACCURACY' ||
                                  type === 'MISTAKE',
                              )
                              .sort((a, b) => {
                                const order = [
                                  'BLUNDER',
                                  'MISTAKE',
                                  'INACCURACY',
                                ];
                                return order.indexOf(a) - order.indexOf(b);
                              })

                              .map((type, index) => (
                                <CCText
                                  key={index}
                                  style={{
                                    width: index === 2 ? 100 : 80,
                                    textAlign: 'center',
                                  }}
                                  color={CCColors.TextColor.Grey}>
                                  {convertToReadableString(type)}
                                </CCText>
                              ))}
                          </Box>
                          <Spacer spacing={vs(20)} />
                          <Box type="row-flex-start" gap={5}>
                            {getSelectedTabData(key)[game] &&
                              Object.keys(getSelectedTabData(key)[game])
                                .filter(
                                  type =>
                                    type === 'BLUNDER' ||
                                    type === 'INACCURACY' ||
                                    type === 'MISTAKE',
                                )
                                .sort((a, b) => {
                                  const order = [
                                    'BLUNDER',
                                    'MISTAKE',
                                    'INACCURACY',
                                  ];
                                  return order.indexOf(a) - order.indexOf(b);
                                })
                                .map(type => {
                                  const totalErrors =
                                    getSelectedTabData(key)[game].BLUNDER +
                                    getSelectedTabData(key)[game].INACCURACY +
                                    getSelectedTabData(key)[game].MISTAKE;
                                  const percentage =
                                    totalErrors > 0
                                      ? (getSelectedTabData(key)[game][type] /
                                          totalErrors) *
                                        100
                                      : 0;

                                  return (
                                    <Box
                                      onPress={() => {
                                        navigateToPuzzle(
                                          type,
                                          game,
                                          getSelectedTabData(key)[game][type],
                                        );
                                      }}
                                      style={{
                                        backgroundColor: getColorBasedonType(
                                          type,
                                          getSelectedTabData(key)[game][type],
                                        ),
                                        borderRadius: 4,
                                        width: 85,
                                        height: 60,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}>
                                      <CCText
                                        fontSize={16}
                                        color={CCColors.White}>
                                        {isNaN(
                                          getSelectedTabData(key)[game][type],
                                        )
                                          ? '-'
                                          : `${percentage.toFixed(2)}%`}
                                      </CCText>
                                    </Box>
                                  );
                                })}
                          </Box>
                        </Box>
                      );
                    }

                    return (
                      <Box type="row-flex-start" gap={5}>
                        {getSelectedTabData(key)[game] &&
                          Object.keys(getSelectedTabData(key)[game])
                            .filter(
                              type =>
                                type === 'BLUNDER' ||
                                type === 'INACCURACY' ||
                                type === 'MISTAKE',
                            )
                            .sort((a, b) => {
                              const order = [
                                'BLUNDER',
                                'MISTAKE',
                                'INACCURACY',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].BLUNDER +
                                getSelectedTabData(key)[game].INACCURACY +
                                getSelectedTabData(key)[game].MISTAKE;
                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  onPress={() => {
                                    navigateToPuzzle(
                                      type,
                                      game,
                                      getSelectedTabData(key)[game][type],
                                    );
                                  }}
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 85,
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={16} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                  {/* <CCBoxTooltip
                                getSelectedTabData={getSelectedTabData}
                                key={key}
                                gameKey={key}
                                game={game}
                                type={type}
                              /> */}
                                </Box>
                              );
                            })}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box gap={30}>
              {colorsMapping.map(color => {
                return (
                  <Box flexDirection="row" alignItems="center" gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      case 6:
        return (
          <CCTable
            assessmentId={assessmentId}
            clickableRow={true}
            color={color}
            headers={[
              {
                key: 'name',
                label: 'Pieces',
                flex: 1.8,
              },

              {
                key: 'total',
                label: 'Games Played',
                // render: (value, rowDetails) => {
                //   console.log('assessment', value, rowDetails);
                //   return <CCText>{value}</CCText>;
                // },
              },
              {
                key: 'result',
                label: 'Result',
                flex: 1.5,
                render: (val, rowDetails) => (
                  <RenderResultProgress
                    result={val}
                    data={rowDetails}
                    noOfBars={3}
                  />
                ),
              },
              {
                key: 'progress',
                label: 'Position at Endgame Entry',
                flex: 1.5,
                render: (val, rowDetails) => (
                  <RenderProgress
                    progress={val}
                    data={rowDetails}
                    noOfBars={3}
                  />
                ),
              },
            ]}
            data={getSelectedTabData(key)?.[color]}
            headerStyle={{
              backgroundColor: 'transparent',
              color: CCColors.TextColor.Grey,
            }}
          />
        );
      case 7:
        return (
          <CCTable
            headers={[
              {
                key: 'date',
                label: 'Game Played',
                render: date => <CCText>{dayjs(date).format('ll')}</CCText>,
              },
              {
                key: 'opponent',
                label: 'Opponent',
              },
              {
                key: 'moves',
                label: 'Moves',
              },
              {
                key: 'accuracy',
                label: 'Accuracy',
                render: t => <CCText>{t ? `${t}%` : 'NA'} </CCText>,
              },
              {
                key: 'opening',
                label: 'Opening score',
                render: t => <CCText>{t ? `${t}%` : 'NA'} </CCText>,
              },
              {
                key: 'middlegame',
                label: 'Middle game score',
                render: t => <CCText>{t ? `${t}%` : 'NA'} </CCText>,
              },
              {
                key: 'endgame',
                label: 'End game score',
                render: t => <CCText>{t ? `${t}%` : 'NA'} </CCText>,
              },
              {
                key: 'uuid',
                label: '',
                render: uuid => (
                  <CCText
                    onPress={() =>
                      navigation.navigate(ScreenName.ChessAnalysis, {
                        game_id: uuid,
                      })
                    }
                    color={CCColors.Primary.Aqua}>
                    Review
                  </CCText>
                ),
              },
            ]}
            data={gamesData}
            headerStyle={{
              backgroundColor: 'transparent',
              color: CCColors.TextColor.Grey,
            }}
            displayLimit={100}
          />
        );
      case 0:
        return (
          <>
            {/* {isMobileOrTab ? ( */}
            <Box
              style={{
                width: '100%',
                alignSelf: 'center',
              }}>
              <Box
                flexDirection="row"
                // justifyContent="space-between"
                gap={10}
                justifyContent={'flex-end'}
                style={styles.skillBox}>
                {skills.skill.map((skill, index) => (
                  <Box
                    key={index}
                    flexDirection="row"
                    alignItems="center"
                    gap={8}>
                    <Box
                      style={[
                        styles.skillCircle,
                        {
                          backgroundColor:
                            index === 0 ? CCColors.Purple : CCColors.Red,
                        },
                      ]}
                    />
                    <CCText
                      fontSize={FontSizes[14]}
                      color={CCColors.TextColor.Dark}>
                      {skill}
                    </CCText>
                  </Box>
                ))}
              </Box>
              <Graph
                level1={level1}
                level2={level2}
                date1={dates1}
                date2={dates2}
                tooltipText={'Rating'}
                noDataText={dataText}
                data={verticalCoords}
                data2={verticalCoords2}
                color2={CCColors.Red}
                graphData={graphData}
                noOfTicks={selectedDurationKeys.includes('LAST_DAY') ? 1 : 6}
                color={CCColors.Purple}
                xLabels={dates}
              />
            </Box>
          </>
        );
      default:
        break;
    }
  };
  const desiredOrder = ['Found', 'Missed', 'Blunder'];

  const renderMobileContent = key => {
    switch (key) {
      case 1:
        return (
          <Box style={{marginVertical: 20}}>
            <Box
              style={{marginHorizontal: 20, flexWrap: 'wrap'}}
              gap={20}
              type="row-flex-start">
              {colorsMapping.map(color => {
                return (
                  <Box flexDirection="row" alignItems="center" gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>

            <Box>
              {Object.keys(coachAssessmentReport).length === 0 && (
                <CCText textAlign="center" style={{flex: 1}}>
                  No Data Available
                </CCText>
              )}
              <OverallAssessmentBox
                overallAssessmentData={coachAssessmentReport}
              />
              {/* <Box gap={5}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 50}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box> */}
              {/* <Box gap={5}>
                
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(type => type !== 'total')
                            .map(type => (
                              <CCText
                                fontSize={9}
                                style={{width: 50, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type.split('_')[1])}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(type => type !== 'total')
                            .map(type => (
                              <Box
                                onPress={() =>
                                  navigateToPuzzle(
                                    type,
                                    game,
                                    getSelectedTabData(key)[game][type],
                                  )
                                }
                                style={{
                                  backgroundColor: getColorBasedonType(
                                    type,
                                    getSelectedTabData(key)[game][type],
                                  ),
                                  borderRadius: 4,
                                  width: 50,
                                  height: 50,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <CCText fontSize={10} color={CCColors.White}>
                                  {(
                                    getSelectedTabData(key)[game][type] * 100
                                  ).toFixed(2)}
                                  %
                                </CCText>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={3}>
                      {Object.keys(coachAssessmentReport[game])
                        .filter(type => type !== 'total')
                        .map(type => (
                          <Box
                            onPress={() =>
                              navigateToPuzzle(
                                type,
                                game,
                                coachAssessmentReport[game][type],
                              )
                            }
                            style={{
                              backgroundColor: getColorBasedonType(
                                type,
                                coachAssessmentReport[game][type],
                              ),
                              borderRadius: 4,
                              width: 50,
                              height: 50,
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <CCText fontSize={10} color={CCColors.White}>
                              {(
                                coachAssessmentReport[game][type] * 100
                              ).toFixed(2)}
                              %
                            </CCText>
                          </Box>
                        ))}
                    </Box>
                  );
                })}
              </Box> */}
            </Box>
            {/* <Spacer spacing={vs(30)} />
            <Box
              style={{width: '70%', alignSelf: 'flex-end'}}
              flexDirection="row"
              flexWrap="wrap"
              gap={10}>
              {colorsMapping.map((color, i) => {
                return (
                  <Box
                    style={{width: i % 2 !== 0 ? '46%' : '30%'}}
                    flexDirection="row"
                    alignItems="center"
                    gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box> */}
          </Box>
        );
      case 2:
        return getSelectedTabData(key)?.[color]?.map(data => (
          <Box style={{marginHorizontal: 20}}>
            <Box type="row-center-between">
              <CCText fontSize={14}>{data.Name}</CCText>
              <CCText fontSize={14}>{data.total}</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box flexDirection="row" gap={20} flex={1}>
              <Box flex={1}>
                <CCText color={CCColors.TextColor.Grey} showEllipses>
                  Position after Opening
                </CCText>
                <Spacer spacing={vs(10)} />
                <RenderProgress
                  progress={data?.Play?.better}
                  data={data?.Play}
                  noOfBars={3}
                />
              </Box>
              <Box flex={1}>
                <CCText
                  showEllipses
                  ellipsizeMode="clip"
                  numberOfLines={1}
                  color={CCColors.TextColor.Grey}>
                  Piece Development
                </CCText>
                <Spacer spacing={vs(10)} />
                <RenderProgress
                  progress={data?.DevAdvantage?.better}
                  data={data?.DevAdvantage}
                  noOfBars={3}
                />
                {/* {renderProgress(data.DevAdvantage)} */}
              </Box>
              <Box flex={1}>
                <CCText color={CCColors.TextColor.Grey}>Center Control</CCText>
                <Spacer spacing={vs(10)} />
                <RenderProgress
                  progress={data?.CenterControl?.better}
                  data={data?.CenterControl}
                  noOfBars={3}
                />
                {/* {renderProgress(data.CenterControl)} */}
              </Box>
            </Box>
            <Spacer spacing={vs(30)} />
            <Divider />
            <Spacer spacing={vs(20)} />
          </Box>
        ));
      case 3:
        return (
          <Box style={{marginHorizontal: 20}}>
            <Box flexDirection="row" alignItems="flex-end" gap={5}>
              <Box gap={5} style={{width: 60}}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 50}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
              <Box gap={3}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])

                            .filter(
                              type => type === 'FOUND' || type === 'MISSED',
                            )
                            .sort((a, b) => {
                              const order = ['FOUND', 'MISSED'];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .map(type => (
                              <CCText
                                fontSize={9}
                                style={{width: 50, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])

                            .filter(
                              type => type === 'FOUND' || type === 'MISSED',
                            )
                            .sort((a, b) => {
                              const order = ['FOUND', 'MISSED'];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].FOUND +
                                getSelectedTabData(key)[game].MISSED;

                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 50,
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={10} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={3}>
                      {Object.keys(middlegameReport[game])

                        .filter(type => type === 'FOUND' || type === 'MISSED')
                        .sort((a, b) => {
                          const order = ['FOUND', 'MISSED'];
                          return order.indexOf(a) - order.indexOf(b);
                        })
                        .map(type => {
                          const totalErrors =
                            getSelectedTabData(key)[game].FOUND +
                            getSelectedTabData(key)[game].MISSED;

                          const percentage =
                            totalErrors > 0
                              ? (getSelectedTabData(key)[game][type] /
                                  totalErrors) *
                                100
                              : 0;

                          return (
                            <Box
                              style={{
                                backgroundColor: getColorBasedonType(
                                  type,
                                  middlegameReport[game][type],
                                ),
                                borderRadius: 4,
                                width: 50,
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <CCText fontSize={10} color={CCColors.White}>
                                {isNaN(getSelectedTabData(key)[game][type])
                                  ? '-'
                                  : `${percentage.toFixed(2)}%`}
                              </CCText>
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              </Box>

              <Box gap={3} marginLeft={5}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .slice(0, 6)
                            .filter(type => type !== 'total')
                            .sort((a, b) => {
                              const order = [
                                'GOOD',
                                'BLUNDER',
                                'MISTAKE',
                                'MISSED',
                                'INACCURACY',
                                'FOUND',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .slice(2)
                            .map(type => (
                              <CCText
                                fontSize={9}
                                style={{width: 50, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .slice(0, 6)
                            .filter(type => type !== 'total')
                            .sort((a, b) => {
                              const order = [
                                'GOOD',
                                'BLUNDER',
                                'MISTAKE',
                                'MISSED',
                                'INACCURACY',
                                'FOUND',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .slice(2)
                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].BLUNDER +
                                getSelectedTabData(key)[game].MISTAKE +
                                getSelectedTabData(key)[game].INACCURACY;
                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  onPress={() =>
                                    navigateToPuzzle(
                                      type,
                                      game,
                                      getSelectedTabData(key)[game][type],
                                    )
                                  }
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 50,
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={10} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={3}>
                      {Object.keys(middlegameReport[game])
                        .slice(0, 6)
                        .filter(type => type !== 'total')
                        .sort((a, b) => {
                          const order = [
                            'GOOD',
                            'BLUNDER',
                            'MISTAKE',
                            'MISSED',
                            'INACCURACY',
                            'FOUND',
                          ];
                          return order.indexOf(a) - order.indexOf(b);
                        })
                        .slice(2)
                        .map(type => {
                          const totalErrors =
                            getSelectedTabData(key)[game].BLUNDER +
                            getSelectedTabData(key)[game].MISTAKE +
                            getSelectedTabData(key)[game].INACCURACY;
                          const percentage =
                            totalErrors > 0
                              ? (getSelectedTabData(key)[game][type] /
                                  totalErrors) *
                                100
                              : 0;

                          return (
                            <Box
                              onPress={() =>
                                navigateToPuzzle(
                                  type,
                                  game,
                                  middlegameReport[game][type],
                                )
                              }
                              style={{
                                backgroundColor: getColorBasedonType(
                                  type,
                                  middlegameReport[game][type],
                                ),
                                borderRadius: 4,
                                width: 50,
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <CCText fontSize={10} color={CCColors.White}>
                                {isNaN(getSelectedTabData(key)[game][type])
                                  ? '-'
                                  : `${percentage.toFixed(2)}%`}
                              </CCText>
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Spacer spacing={vs(30)} />
            <Box
              style={{width: '70%', alignSelf: 'flex-end'}}
              flexDirection="row"
              flexWrap="wrap"
              gap={10}>
              {colorsMapping.map((color, i) => {
                return (
                  <Box
                    style={{width: i % 2 !== 0 ? '46%' : '30%'}}
                    flexDirection="row"
                    alignItems="center"
                    gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box style={{marginHorizontal: 20}}>
            {Object.keys(getSelectedTabData(key))[0] === 'Blunders' && (
              <Box flexDirection="row" gap={10}>
                <CCText>
                  Best Calculation Depth:
                  {isFinite(maxCalculationDepth) ? maxCalculationDepth : 0}
                </CCText>
                <CCText>
                  Median Calculation Depth:
                  {isNaN(medianCalculationDepth) ? 0 : medianCalculationDepth}
                </CCText>
              </Box>
            )}
            <Box flexDirection="row" alignItems="flex-end" gap={5}>
              <Box gap={5} style={{width: 60}}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 50}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
              <Box gap={3}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(type => type !== 'total')
                            .slice(1)
                            .map(type => (
                              <CCText
                                fontSize={9}
                                style={{width: 50, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(type => type !== 'total')
                            .slice(1)
                            .map(type => (
                              <Box
                                onPress={() =>
                                  navigateToPuzzle(
                                    type,
                                    game,
                                    getSelectedTabData(key)[game][type],
                                  )
                                }
                                style={{
                                  backgroundColor: getColorBasedonType(
                                    type,
                                    getSelectedTabData(key)[game][type],
                                  ),
                                  borderRadius: 4,
                                  width: 50,
                                  height: 50,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <CCText fontSize={10} color={CCColors.White}>
                                  {(
                                    getSelectedTabData(key)[game][type] * 100
                                  ).toFixed(2)}
                                  %
                                </CCText>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={3}>
                      {getSelectedTabData(key)[game] &&
                        Object.keys(getSelectedTabData(key)[game])
                          .filter(type => type !== 'total')
                          .slice(1)
                          .map(type => (
                            <Box
                              style={{
                                backgroundColor: getColorBasedonType(
                                  type,
                                  getSelectedTabData(key)[game][type],
                                ),
                                borderRadius: 4,
                                width: 50,
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <CCText fontSize={10} color={CCColors.White}>
                                {(
                                  getSelectedTabData(key)[game][type] * 100
                                ).toFixed(2)}
                                %
                              </CCText>
                            </Box>
                          ))}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Spacer spacing={vs(30)} />
            <Box
              style={{width: '70%', alignSelf: 'flex-end'}}
              flexDirection="row"
              flexWrap="wrap"
              gap={10}>
              {colorsMapping.map((color, i) => {
                return (
                  <Box
                    style={{width: i % 2 !== 0 ? '46%' : '30%'}}
                    flexDirection="row"
                    alignItems="center"
                    gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      case 5:
        return (
          <Box style={{marginHorizontal: 20}}>
            {Object.keys(getSelectedTabData(key))[0] === 'Blunders' && (
              <Box flexDirection="row" gap={10}>
                <CCText>
                  Best Calculation Depth:
                  {isFinite(maxCalculationDepth) ? maxCalculationDepth : 0}
                </CCText>
                <CCText>
                  Median Calculation Depth:
                  {isNaN(medianCalculationDepth) ? 0 : medianCalculationDepth}
                </CCText>
              </Box>
            )}
            <Box flexDirection="row" alignItems="flex-end" gap={5}>
              <Box gap={5} style={{width: 60}}>
                {Object.keys(getSelectedTabData(key)).map(game => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  return (
                    <Box type="center" style={{height: 50}}>
                      <CCText color={CCColors.TextColor.Dark}>
                        {convertToReadableString(game)}
                      </CCText>
                    </Box>
                  );
                })}
              </Box>
              <Box gap={3}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(
                              type => type === 'GOOD' || type === 'MISSED',
                            )

                            .map(type => (
                              <CCText
                                fontSize={9}
                                style={{width: 50, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(
                              type => type === 'GOOD' || type === 'MISSED',
                            )

                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].GOOD +
                                getSelectedTabData(key)[game].MISSED;

                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  onPress={() =>
                                    navigateToPuzzle(
                                      type,
                                      game,
                                      getSelectedTabData(key)[game][type],
                                    )
                                  }
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 50,
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={10} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={3}>
                      {getSelectedTabData(key)[game] &&
                        Object.keys(getSelectedTabData(key)[game])
                          .filter(type => type === 'GOOD' || type === 'MISSED')

                          .map(type => {
                            const totalErrors =
                              getSelectedTabData(key)[game].GOOD +
                              getSelectedTabData(key)[game].MISSED;

                            const percentage =
                              totalErrors > 0
                                ? (getSelectedTabData(key)[game][type] /
                                    totalErrors) *
                                  100
                                : 0;

                            return (
                              <Box
                                onPress={() =>
                                  navigateToPuzzle(
                                    type,
                                    game,
                                    getSelectedTabData(key)[game][type],
                                  )
                                }
                                style={{
                                  backgroundColor: getColorBasedonType(
                                    type,
                                    getSelectedTabData(key)[game][type],
                                  ),
                                  borderRadius: 4,
                                  width: 50,
                                  height: 50,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <CCText fontSize={10} color={CCColors.White}>
                                  {isNaN(getSelectedTabData(key)[game][type])
                                    ? '-'
                                    : `${percentage.toFixed(2)}%`}
                                </CCText>
                              </Box>
                            );
                          })}
                    </Box>
                  );
                })}
              </Box>
              <Box gap={3} marginLeft={5}>
                {Object.keys(getSelectedTabData(key)).map((game, i) => {
                  if (game === 'assessment_percentage') {
                    return;
                  }
                  if (i === 0) {
                    return (
                      <Box>
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(
                              type =>
                                type === 'BLUNDER' ||
                                type === 'INACCURACY' ||
                                type === 'MISTAKE',
                            )
                            .sort((a, b) => {
                              const order = [
                                'BLUNDER',
                                'MISTAKE',
                                'INACCURACY',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })

                            .map(type => (
                              <CCText
                                fontSize={9}
                                style={{width: 50, textAlign: 'center'}}
                                color={CCColors.TextColor.Grey}>
                                {convertToReadableString(type)}
                              </CCText>
                            ))}
                        </Box>
                        <Spacer spacing={vs(20)} />
                        <Box type="row-flex-start" gap={3}>
                          {Object.keys(getSelectedTabData(key)[game])
                            .filter(
                              type =>
                                type === 'BLUNDER' ||
                                type === 'INACCURACY' ||
                                type === 'MISTAKE',
                            )
                            .sort((a, b) => {
                              const order = [
                                'BLUNDER',
                                'MISTAKE',
                                'INACCURACY',
                              ];
                              return order.indexOf(a) - order.indexOf(b);
                            })

                            .map(type => {
                              const totalErrors =
                                getSelectedTabData(key)[game].BLUNDER +
                                getSelectedTabData(key)[game].INACCURACY +
                                getSelectedTabData(key)[game].MISTAKE;
                              const percentage =
                                totalErrors > 0
                                  ? (getSelectedTabData(key)[game][type] /
                                      totalErrors) *
                                    100
                                  : 0;

                              return (
                                <Box
                                  onPress={() =>
                                    navigateToPuzzle(
                                      type,
                                      game,
                                      getSelectedTabData(key)[game][type],
                                    )
                                  }
                                  style={{
                                    backgroundColor: getColorBasedonType(
                                      type,
                                      getSelectedTabData(key)[game][type],
                                    ),
                                    borderRadius: 4,
                                    width: 50,
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <CCText fontSize={10} color={CCColors.White}>
                                    {isNaN(getSelectedTabData(key)[game][type])
                                      ? '-'
                                      : `${percentage.toFixed(2)}%`}
                                  </CCText>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    );
                  }
                  return (
                    <Box type="row-flex-start" gap={3}>
                      {getSelectedTabData(key)[game] &&
                        Object.keys(getSelectedTabData(key)[game])
                          .filter(
                            type =>
                              type === 'BLUNDER' ||
                              type === 'INACCURACY' ||
                              type === 'MISTAKE',
                          )
                          .sort((a, b) => {
                            const order = ['BLUNDER', 'MISTAKE', 'INACCURACY'];
                            return order.indexOf(a) - order.indexOf(b);
                          })

                          .map(type => {
                            const totalErrors =
                              getSelectedTabData(key)[game].BLUNDER +
                              getSelectedTabData(key)[game].INACCURACY +
                              getSelectedTabData(key)[game].MISTAKE;
                            const percentage =
                              totalErrors > 0
                                ? (getSelectedTabData(key)[game][type] /
                                    totalErrors) *
                                  100
                                : 0;

                            return (
                              <Box
                                onPress={() =>
                                  navigateToPuzzle(
                                    type,
                                    game,
                                    getSelectedTabData(key)[game][type],
                                  )
                                }
                                style={{
                                  backgroundColor: getColorBasedonType(
                                    type,
                                    getSelectedTabData(key)[game][type],
                                  ),
                                  borderRadius: 4,
                                  width: 50,
                                  height: 50,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <CCText fontSize={10} color={CCColors.White}>
                                  {isNaN(getSelectedTabData(key)[game][type])
                                    ? '-'
                                    : `${percentage.toFixed(2)}%`}
                                </CCText>
                              </Box>
                            );
                          })}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Spacer spacing={vs(30)} />
            <Box
              style={{width: '70%', alignSelf: 'flex-end'}}
              flexDirection="row"
              flexWrap="wrap"
              gap={10}>
              {colorsMapping.map((color, i) => {
                return (
                  <Box
                    style={{width: i % 2 !== 0 ? '46%' : '30%'}}
                    flexDirection="row"
                    alignItems="center"
                    gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: color.color,
                      }}></Box>
                    <CCText color={CCColors.TextColor.Dark}>
                      {color.name}
                    </CCText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      case 6:
        return getSelectedTabData(key)?.[color]?.map(data => (
          <Box style={{marginHorizontal: 20}}>
            <Box type="row-center-between">
              <CCText fontSize={14}>{data.name}</CCText>
              <CCText fontSize={14}>{data.total}</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box flexDirection="row" gap={20} flex={1}>
              <Box flex={1}>
                <CCText color={CCColors.TextColor.Grey}>Result</CCText>
                <Spacer spacing={vs(10)} />
                <RenderResultProgress
                  result={data.progress}
                  data={data}
                  noOfBars={3}
                />
              </Box>
              <Box flex={1}>
                <CCText color={CCColors.TextColor.Grey}>
                  Position at Endgame Entry
                </CCText>
                <Spacer spacing={vs(10)} />
                <RenderProgress
                  progress={data.progress}
                  data={data}
                  noOfBars={3}
                />
              </Box>
            </Box>
            <Spacer spacing={vs(30)} />
            <Divider />
            <Spacer spacing={vs(20)} />
          </Box>
        ));
      case 7:
        return gamesData.map(data => (
          <Box style={{marginHorizontal: 20}}>
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                Game Played
              </CCText>
              <CCText fontSize={14}>{dayjs(data.date).format('ll')}</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                Opponent
              </CCText>
              <CCText fontSize={14}>{data.opponent}</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                Moves
              </CCText>
              <CCText fontSize={14}>{data.moves}</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                Accuracy
              </CCText>
              <CCText fontSize={14}>{data.accuracy}%</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                Opening score
              </CCText>
              <CCText fontSize={14}>{data.opening}%</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                Middle game score
              </CCText>
              <CCText fontSize={14}>{data.middlegame}%</CCText>
            </Box>
            <Spacer spacing={vs(10)} />
            <Box type="row-center-between">
              <CCText fontSize={12} color={CCColors.TextColor.Grey}>
                End game score
              </CCText>
              <CCText fontSize={14}>{data.endgame}%</CCText>
            </Box>
            <Spacer spacing={vs(20)} />
            <Box type="row-flex-end">
              <CCText
                fontSize={16}
                onPress={() =>
                  navigation.navigate(ScreenName.ChessAnalysis, {
                    game_id: data.uuid,
                  })
                }
                color={CCColors.Primary.Aqua}>
                Review
              </CCText>
            </Box>
            <Spacer spacing={vs(30)} />
            <Divider />
            <Spacer spacing={vs(20)} />
          </Box>
        ));
      case 0:
        return (
          <>
            {/* {isMobileOrTab ? ( */}
            <Box style={{width: '100%', alignSelf: 'center'}}>
              <Box gap={12} style={styles.mobileSkills}>
                <CCSelect
                  ccSelectHeight={45}
                  defaultSelection={true}
                  multiSelectDropdown={true}
                  showModal={false}
                  placeholder={`Skills : ${
                    skills.skill.length > 0
                      ? ` ${skills.skill[0]}${
                          skills.skill.length > 1
                            ? `, +${skills.skill.length - 1}`
                            : ''
                        }`
                      : ''
                  }`}
                  items={skillArray?.map(skill => ({
                    label: skill,
                    value: skill,
                  }))}
                  limit={2}
                  multiSelect={true}
                  selectedValue={skills.skill}
                  onValueChange={handleSkillChange}
                  ccSelectWidth="100%"
                />
              </Box>
              <Box gap={12} style={styles.select}>
                <CCSelect
                  ccSelectHeight={45}
                  singleSelectDropdown={true}
                  showModal={false}
                  placeholder={`Duration : ${duration ? duration : ''}`}
                  items={durationArray?.map(time => ({
                    label: time,
                    value: time,
                  }))}
                  multiSelect={false}
                  selectedValue={duration}
                  onValueChange={handleDurationChange}
                  ccSelectWidth="100%"
                />
              </Box>

              <Box
                flexDirection="row"
                // justifyContent="space-between"
                gap={10}
                justifyContent={'flex-end'}
                style={styles.skillcircle}>
                {skills.skill.map((skill, index) => (
                  <Box
                    key={index}
                    flexDirection="row"
                    alignItems="center"
                    gap={8}>
                    <Box
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        backgroundColor:
                          index === 0 ? CCColors.Purple : CCColors.Red,
                      }}
                    />
                    <CCText
                      fontSize={FontSizes[12]}
                      color={CCColors.TextColor.Dark}>
                      {skill}
                    </CCText>
                  </Box>
                ))}
              </Box>
              <Graph
                level1={level1}
                level2={level2}
                date1={dates1}
                date2={dates2}
                tooltipText={'Rating'}
                noDataText={dataText}
                data={verticalCoords}
                data2={verticalCoords2}
                color2={CCColors.Red}
                graphData={graphData}
                noOfTicks={selectedDurationKeys.includes('LAST_DAY') ? 1 : 6}
                color={CCColors.Purple}
                xLabels={dates}
              />
            </Box>
          </>
        );

      default:
        break;
    }
  };

  const calculationDepths = Array.isArray(gamesData)
    ? gamesData.map(game => Number(game.calculation_depth))
    : [];
  const maxCalculationDepth = Math.max(...calculationDepths);
  let medianCalculationDepth;
  const sortedCalculationsArray = calculationDepths.sort((a, b) => a - b);
  const length = sortedCalculationsArray.length;
  if (length % 2 === 0) {
    medianCalculationDepth =
      (sortedCalculationsArray[length / 2 - 1] +
        sortedCalculationsArray[length / 2]) /
      2;
  } else {
    medianCalculationDepth = sortedCalculationsArray[Math.floor(length / 2)];
  }

  // const filteredAssessmentTypes = isMobileOrTab
  //   ? assessmentTypes
  //   : assessmentTypes.filter(at => at.id !== 8);

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <CCLoader loading={assessmentLoading} />
      <Box
        style={[styles.container, isMobileOrTab && styles.containerMobile]}
        flexDirection="column"
        flex={1}>
        <Box flexDirection="column">
          <Box
            flexDirection={!isMobileOrTab ? 'row' : 'column'}
            alignItems="center"
            justifyContent="space-between">
            <CCText color={CCColors.TextColor.Dark} fontSize={FontSizes[24]}>
              Detailed Assessment
            </CCText>
            <Box
              flexDirection={isMobileOrTab ? 'column' : 'row'}
              gap={isMobileOrTab ? 10 : 20}
              marginBottom={isMobileOrTab ? 10 : undefined}
              justifyContent="space-between"
              alignItems="center">
              <CCText textAlign="right" fontWeight="bold">
                Number of Games assessed: {numberOfGames}
              </CCText>

              {planId < 4 && (
                <CCButton
                  onPress={() => navigation.navigate(ScreenName.Subscription)}>
                  <CCText>Upgrade to access full assessments</CCText>
                </CCButton>
              )}
            </Box>
          </Box>
          <Spacer spacing={vs(5)} />
          {/* <CCText fontSize={FontSizes[10]} style={{marginLeft: vs(16)}}>
            # {assessmentId}
          </CCText> */}
          <Box flexDirection="row" alignItems="center">
            <Feather
              name="calendar"
              size={16}
              color={CCColors.TextColor.Grey}
            />
            <Spacer spacing={hs(5)} horizontal />
            <CCText color={CCColors.TextColor.Grey}>
              Assessment date:
              <Spacer spacing={hs(5)} horizontal />
              <CCText color={CCColors.TextColor.Primary}>
                {!assessment
                  ? 'Loading ..'
                  : dayjs(assessment?.date).format('YYYY-MM-DD')}
              </CCText>
            </CCText>
          </Box>
        </Box>
        {/* <Box flex={0.5} style={{width: '100%'}}>
          <CCButton
            type="outline"
            onPress={() => {
              navigation.navigate(ScreenName.Dashboard);
            }}
            disabled={false}
            style={{
              alignSelf: 'flex-end',
              marginRight: vs(16),
              width: vs(240),
              borderRadius: vs(20),
            }}>
            Back
          </CCButton>
        </Box> */}
        <Spacer spacing={vs(36)} />

        <Box flexDirection="row" style={{alignItems: 'flex-start'}} gap={20}>
          {!isMobileOrTab && (
            <Box flex={0.2} style={styles.typeContainer} flexDirection="column">
              {assessmentTypes.map((at, index) => (
                <Box
                  type="row-flex-start"
                  gap={10}
                  style={{
                    ...(activeType === at.id
                      ? styles.activeTypeList
                      : styles.typeList),
                    borderBottom:
                      assessmentTypes.length !== index + 1 &&
                      activeType !== at.id
                        ? `1px solid ${CCColors.BorderLight}`
                        : 'none',
                  }}
                  onPress={() => {
                    setActiveType(at.id);
                    // if (at.id === filteredAssessmentTypes.length - 1) {
                    //   assesstmentTypeRef.current?.scrollToEnd({
                    //     animated: true,
                    //   });
                    // } else {
                    if (sectionHeights[at.id]) {
                      assesstmentTypeRef.current?.scrollToIndex({
                        index: at.id,
                        animated: true,
                        viewPosition: 0,
                        viewOffset: 0,
                      });
                    }
                    // }
                  }}
                  key={at.id}>
                  {at.svg ? (
                    at.svg
                  ) : (
                    <Image style={{width: 20, height: 20}} source={at.icon} />
                  )}
                  <CCText>{at.title}</CCText>
                </Box>
              ))}
            </Box>
          )}
          <Box flex={!isMobileOrTab ? 0.8 : undefined}>
            <FlatList
              showsHorizontalScrollIndicator={
                !isMobileOrTab ? false : undefined
              }
              onLayout={handleLayout}
              initialScrollIndex={0}
              ref={assesstmentTypeRef}
              nestedScrollEnabled={true}
              data={assessmentTypes}
              getItemLayout={getItemLayout}
              contentContainerStyle={{
                flexGrow: 1,
                height: !isMobileOrTab ? 0.75 * WINDOW_HEIGHT : '100%',
                width: '100%',
                gap: isMobileOrTab && 8,
              }}
              renderItem={({item}) => (
                <View onLayout={event => onSectionLayout(item.id, event)}>
                  <AccordionSection
                    width={isMobileOrTab ? SCREEN_WIDTH - 20 : undefined}
                    margin={isMobileOrTab ? 0 : undefined}
                    keepOpen
                    showIcon={isMobileOrTab}
                    header={
                      <Box
                        gap={item.id === 0 && WINDOW_WIDTH < 900 ? 0 : 20}
                        style={{
                          paddingHorizontal: isMobileOrTab ? 0 : 10,
                          paddingTop: isMobileOrTab ? 0 : 20,

                          flexShrink: isMobileOrTab ? 1 : 0,
                          flexDirection: isMobileOrTab ? 'column' : 'row',
                          flexGrow: 1,
                          justifyContent:
                            item.id === 0 && WINDOW_WIDTH < 900
                              ? undefined
                              : 'space-between',
                          alignItems: 'center',
                        }}>
                        <Box type="row-flex-start" gap={10}>
                          <Box
                            type="center"
                            style={[
                              styles.icon,
                              {backgroundColor: item.iconBg},
                            ]}>
                            {item.svg ? (
                              item.svg
                            ) : (
                              <Image
                                style={{width: 20, height: 20}}
                                source={item.icon}
                              />
                            )}
                          </Box>
                          <View style={{flex: 1}}>
                            <CCText
                              fontSize={FontSizes[14]}
                              color={CCColors.TextColor.Dark}>
                              {item.heading === 'Calculation Accuracy' &&
                                `${item.heading} (average calculation depth - ${
                                  getSelectedTabData(item.id)
                                    ?.assessment_percentage
                                    ? `${
                                        getSelectedTabData(item.id)
                                          ?.assessment_percentage
                                      })`
                                    : ')'
                                }`}
                              {item.heading !== 'Calculation Accuracy' &&
                                `${item.heading} ${
                                  getSelectedTabData(item.id)
                                    ?.assessment_percentage
                                    ? `(${
                                        isNaN(
                                          Number(
                                            getSelectedTabData(item.id)
                                              ?.assessment_percentage,
                                          ),
                                        )
                                          ? 0
                                          : getSelectedTabData(item.id)
                                              ?.assessment_percentage
                                      }%)`
                                    : ''
                                }`}
                            </CCText>
                            <CCText
                              style={{flexWrap: 'wrap'}}
                              fontSize={FontSizes[10]}
                              color={CCColors.TextColor.Grey}>
                              {item.desc}
                            </CCText>
                          </View>
                        </Box>

                        {[2, 6].includes(item.id) && (
                          <Box type="row-flex-start">
                            <Tooltip
                              text={
                                <CCText fontSize={14} color={CCColors.White}>
                                  {item.id === 6
                                    ? 'Performed better as White'
                                    : 'Accuracy as White'}
                                </CCText>
                              }
                              children={toggleEvent => (
                                <View
                                  onPointerEnter={toggleEvent}
                                  onPointerLeave={toggleEvent}>
                                  <CCButton
                                    isRounded={false}
                                    onPress={() => setColor('white')}
                                    titleStyle={{
                                      color:
                                        color === 'white'
                                          ? CCColors.White
                                          : CCColors.TextColor.Dark,
                                    }}
                                    style={{
                                      borderTopLeftRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      backgroundColor:
                                        color === 'white'
                                          ? CCColors.Primary.Dark
                                          : CCColors.White,
                                      border:
                                        color === 'white'
                                          ? `1px solid ${CCColors.TextColor.Dark}`
                                          : '1px solid #26232233',
                                    }}>
                                    {`White ${
                                      getSelectedTabData(item.id)?.percentages
                                        ?.white
                                        ? `(${
                                            (
                                              getSelectedTabData(item.id)
                                                ?.percentages?.white * 100
                                            ).toFixed(2) || 100
                                          }%)`
                                        : ''
                                    }`}
                                  </CCButton>
                                </View>
                              )}
                            />
                            <Tooltip
                              text={
                                <CCText fontSize={14} color={CCColors.White}>
                                  {item.id === 6
                                    ? 'Performed better as Black'
                                    : 'Accuracy as Black'}
                                </CCText>
                              }
                              children={toggleEvent => (
                                <View
                                  onPointerEnter={toggleEvent}
                                  onPointerLeave={toggleEvent}>
                                  <CCButton
                                    isRounded={false}
                                    onPress={() => setColor('black')}
                                    titleStyle={{
                                      color:
                                        color === 'black'
                                          ? CCColors.White
                                          : CCColors.TextColor.Dark,
                                    }}
                                    style={{
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      backgroundColor:
                                        color === 'black'
                                          ? CCColors.Primary.Dark
                                          : CCColors.White,
                                      border:
                                        color === 'black'
                                          ? '1px solid #262322'
                                          : '1px solid #26232233',
                                    }}>
                                    {`Black ${
                                      getSelectedTabData(item.id)?.percentages
                                        ?.black
                                        ? `(${(
                                            getSelectedTabData(item.id)
                                              ?.percentages?.black * 100 || 100
                                          ).toFixed(2)}%)`
                                        : ''
                                    }`}
                                  </CCButton>
                                </View>
                              )}
                            />
                          </Box>
                        )}
                        {item.id === 0 && !isMobileOrTab && (
                          <Box
                            flexDirection="row"
                            gap={5}
                            justifyContent="flex-end"
                            style={{
                              width: '50%',
                            }}>
                            <Box
                              gap={12}
                              style={{
                                width: '45%',

                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <CCSelect
                                ccSelectHeight={45}
                                defaultSelection={true}
                                multiSelectDropdown={true}
                                showModal={false}
                                placeholder={` Skills : ${
                                  skills.skill.length > 0
                                    ? ` ${skills.skill[0]}${
                                        skills.skill.length > 1
                                          ? `, +${skills.skill.length - 1}`
                                          : ''
                                      }`
                                    : ''
                                }`}
                                items={skillArray?.map(skill => ({
                                  label: skill,
                                  value: skill,
                                }))}
                                limit={2}
                                multiSelect={true}
                                selectedValue={skills.skill}
                                onValueChange={handleSkillChange}
                                ccSelectWidth="100%"
                              />
                            </Box>
                            <Box
                              gap={12}
                              style={{
                                width: '45%',

                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <CCSelect
                                ccSelectHeight={45}
                                singleSelectDropdown={true}
                                showModal={false}
                                placeholder={`Duration : ${
                                  duration ? duration : ''
                                }`}
                                items={durationArray?.map(time => ({
                                  label: time,
                                  value: time,
                                }))}
                                multiSelect={false}
                                selectedValue={duration}
                                onValueChange={handleDurationChange}
                                ccSelectWidth="100%"
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    }
                    customStyles={{
                      position:
                        item.id === 0 && !isMobileOrTab
                          ? 'absolute'
                          : undefined,
                      zIndex: item.id === 0 && !isMobileOrTab ? 100 : 0,
                      top: item.id === 0 && !isMobileOrTab ? -25 : 0,
                      width: item.id === 0 && !isMobileOrTab && '100%',
                      marginBottom: item.id === 0 && !isMobileOrTab && 10,
                    }}
                    customStylesContainer={{
                      marginTop: item.id === 0 && !isMobileOrTab ? 72 : 0,
                    }}
                    title={'Focus'}
                    isScrollable={false}
                    renderContent={() =>
                      !assessment ? (
                        <Empty
                          imgWidth={isMobileOrTab ? 100 : 150}
                          imgSrc={emptyResult}
                          title={'No Data found'}
                          description=""
                          noBorder
                          height="20vh"
                        />
                      ) : isMobileOrTab ? (
                        renderMobileContent(item.id)
                      ) : (
                        renderContent(item.id, item)
                      )
                    }
                  />
                </View>
              )}
              keyExtractor={item => item.id}
              viewabilityConfigCallbackPairs={
                viewabilityConfigCallbackPairs.current
              }
            />
          </Box>
        </Box>
      </Box>
      {!user_key && (
        <AssessmentTabModal
          visible={
            onboardingStatus === 2 ||
            (onboardingStatus === 1 && firstAssessmentPending === 1)
          }
          cdcUsername={cdcUsername}
          licUsername={licUsername}
          onboardingStatus={onboardingStatus}
          firstAssessmentPending={firstAssessmentPending}
        />
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  containerMobile: {
    padding: 10,
    backgroundColor: CCColors.Primary.lightBrown,
  },
  typeContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: CCColors.BorderLight,
  },
  typeList: {
    padding: 20,
    backgroundColor: CCColors.White,
  },
  activeTypeList: {
    padding: 20,
    backgroundColor: CCColors.LightYellow,
    borderLeftWidth: 3,
    borderLeftColor: CCColors.Primary.Default,
  },
  icon: {
    borderRadius: 50,
    width: 40,
    height: 40,
  },
  skillBox: {
    marginTop: 10,
    marginBottom: 10,
    paddingHorizontal: '30px',
    position: 'relative',
    zIndex: -1,
  },
  skillCircle: {width: 12, height: 12, borderRadius: '50%'},
  mobileSkills: {
    width: '90%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1002,
    marginHorizontal: 'auto',
  },
  select: {
    width: '90%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1001,
    marginHorizontal: 'auto',
  },
  skillcircle: {
    marginTop: 15,
    marginBottom: 15,
    paddingHorizontal: '10px',
    position: 'relative',
    zIndex: -1,
  },
});

export default AssessmentDetails;
