import React from 'react';
import {useNavigation} from '@react-navigation/native';
import CCPackageTable from '@/Atoms/CCPackageTable';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';
import {Platform, Pressable, View} from 'react-native';
import useGetTournamentsList from '@/Hooks/TournamentsApi/useGetTournamentsList';
import {useEffect, useState, useRef} from 'react';
import useGetMyJoinedTournaments from '@/Hooks/TournamentsApi/useGetMyJoinedTournaments';
import CCButton from '@/Atoms/CCButton';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {Image} from 'react-native';
import NoTournamentFound from '@/Assets/Images/Tournaments/no_tournament_found.svg';
import useIsMobile from '@/Hooks/useIsMobile';
import TournamentRow from './TournamentRow';
import {RFPercentage} from 'react-native-responsive-fontsize';
import Pagination from '@/Screens/Games/Pagination';
interface TournamentsProps {
  getTournamentDetails: (tournamentId: number) => void; // Function passed as a prop
  activeTab: string;
  userId: string;
  newLayout: boolean;
}

const Tournaments: React.FC<TournamentsProps> = ({
  getTournamentDetails,
  activeTab,
  userId,
  newLayout,
}) => {
  const isMobileOrTab = useIsMobile();
  const navigation = useNavigation();
  // Mock data for the tournaments
  const headers = ['Time', 'Name', 'Starting in', 'Rounds', '', ''];
  const mobileHeaders = ['Time', 'Tournament Details', ''];
  const [tableData, setTableData] = useState([]);
  const [tabSwitch, setTabSwitch] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const paginate = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const tournamentData = [
    {
      time: '10:00 AM',
      name: 'Rapid Swiss Tournament',
      startingIn: '2 hrs',
      players: 40,
      fees: '₹1000',
      url: 'https://example.com/join/1',
      id: 1,
    },
    {
      time: '12:00 PM',
      name: 'Blitz Open Tournament',
      startingIn: '1 hr',
      players: 20,
      fees: 'Free',
      url: 'https://example.com/join/2',
      id: 2,
    },
    {
      time: '2:00 PM',
      name: 'Classic Chess Tournament',
      startingIn: '3 hrs',
      players: 50,
      fees: '₹500',
      url: 'https://example.com/join/3',
      id: 3,
    },
  ];

  const tournamentsDataref = useRef(tournamentData);

  const {
    mutate: fetchTournaments,
    data: tournamentsData,
    isLoading,
    isError,
    error,
  } = useGetTournamentsList();

  const {mutate: fetchMyTournaments, data: myTournamentsData} =
    useGetMyJoinedTournaments();

  const POLL_INTERVAL = 5000; // Poll every 5 seconds

  useEffect(() => {
    let interval;
    setTabSwitch(true);
    const fetchData = () => {
      if (activeTab === 'upcoming') {
        fetchTournaments({limit: 25, offset: 0});
      } else if (activeTab === 'registered') {
        fetchMyTournaments({userId});
      }
    };

    fetchData(); // Fetch data immediately

    // Start polling only for "upcoming" tab
    if (activeTab === 'upcoming') {
      interval = setInterval(fetchData, POLL_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval); // Cleanup on unmount or tab change
      }
    };
  }, [activeTab]);

  useEffect(() => {
    console.log('tounamentsData is :', tournamentsData);
    let tournamentsList =
      activeTab == 'upcoming' ? tournamentsData : myTournamentsData;

    if (activeTab == 'upcoming') {
      console.log('asdf', tournamentsDataref.current, tournamentsData?.data);
      if (
        tournamentsData?.data &&
        (tournamentsDataref.current != tournamentsData?.data || tabSwitch)
      ) {
        const itemsPerPage = 10;
        const totalItems = tournamentsData?.data.length;

        setTotalPages(Math.ceil(totalItems / itemsPerPage));
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const paginatedData = tournamentsData?.data.slice(startIndex, endIndex);
        setTableData(paginatedData);
        tournamentsDataref.current = tournamentsData?.data;
        setTabSwitch(false);
      }
    } else {
      const itemsPerPage = 10;
      myTournamentsData?.data.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);

        // Extract only the date part (YYYY-MM-DD) for comparison
        const dateOnlyA = dateA.toISOString().split('T')[0];
        const dateOnlyB = dateB.toISOString().split('T')[0];

        // Sort by date (descending order)
        if (dateOnlyA > dateOnlyB) return -1;
        if (dateOnlyA < dateOnlyB) return 1;

        // If dates are the same, sort by time (ascending order)
        return dateA.getTime() - dateB.getTime();
      });

      const totalItems = myTournamentsData?.data.length;

      setTotalPages(Math.ceil(totalItems / itemsPerPage));
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = myTournamentsData?.data.slice(startIndex, endIndex);
      setTableData(paginatedData);
    }
  }, [currentPage, tournamentsData, myTournamentsData]);
  const timeControlToSvgAndColor = {
    Bullet: {
      svg: (
        <Image
          source={require('@/Assets/Images/Svgs/Bullet.svg')}
          style={{
            width: 20,
            height: 20,
          }}
        />
      ),
      color: '#937ADB47',
    },
    Bolt: {
      svg: (
        <Image
          source={require('@/Assets/Images/Svgs/Bolt.svg')}
          style={{
            width: 20,
            height: 20,
          }}
        />
      ),
      color: '#67B3E147',
    },
    Rapid: {
      svg: (
        <Image
          source={require('@/Assets/Images/Svgs/Rapid.svg')}
          style={{
            width: 20,
            height: 20,
          }}
        />
      ),
      color: '#DB7ACC48',
    },
    Snail: {
      svg: (
        <Image
          source={require('@/Assets/Images/Svgs/Snail.svg')}
          style={{
            width: 20,
            height: 20,
          }}
        />
      ),
      color: '#3DAB9E47',
    },
  };
  // Handle row press (e.g., navigate to tournament details)
  const handleRowPress = (rowIndex: number) => {
    console.log(`Row ${rowIndex} pressed`);
    const tournamentId =
      activeTab == 'upcoming'
        ? tournamentsData.data[rowIndex + 10 * (currentPage - 1)].id
        : myTournamentsData.data[rowIndex + 10 * (currentPage - 1)].id;
    navigation.setParams({
      tournamentId,
      tournamentName:
        activeTab == 'upcoming'
          ? tournamentsData.data[rowIndex].name
          : myTournamentsData.data[rowIndex].name,
    });
    getTournamentDetails(tournamentId);

    // navigation.navigate('TournamentDetails', {id: rowIndex});
  };

  return (
    <Box style={{borderRadius: 8, backgroundColor: CCColors.White}}>
      {tableData && tableData.length > 0 ? (
        <Box
          flexDirection="column"
          flex={1}
          style={{width: '100%', marginHorizontal: !isMobileOrTab && 5}}>
          <Box
            flexDirection="row"
            style={{marginBottom: 10}}
            alignItems="center">
            <CCText
              color={CCColors.TextColor.LightGrey}
              fontWeight={600}
              fontSize={!isMobileOrTab ? FontSizes[20] : RFPercentage(1.8)}
              style={{marginRight: '20%'}}>
              Time
            </CCText>
            <CCText
              color={CCColors.TextColor.LightGrey}
              fontWeight={600}
              fontSize={!isMobileOrTab ? FontSizes[20] : RFPercentage(1.8)}>
              Tournament Details
            </CCText>
          </Box>
          <Box
            style={{
              borderTopColor: CCColors.BorderLight,
              borderTopWidth: 1,
            }}
            flexDirection="column">
            {tableData?.map((item, rowIndex) => {
              return (
                <>
                  <TournamentRow
                    item={item}
                    rowIndex={rowIndex}
                    activeTab={activeTab}
                    handleRowPress={handleRowPress}
                    timeControlToSvgAndColor={timeControlToSvgAndColor}
                    isMobileOrTab={isMobileOrTab}
                  />
                </>
              );
            })}
          </Box>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            paginate={paginate}
          />
        </Box>
      ) : (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
            gap: 20,
            marginTop: isMobileOrTab ? 0 : '10%',
          }}>
          <Image
            resizeMode="contain"
            style={{
              width: isMobileOrTab ? Platform.OS === 'web' && 100 : 180,
              height: isMobileOrTab ? Platform.OS === 'web' && 100 : 180,
            }}
            source={NoTournamentFound}
          />
          <CCText fontSize={24} style={{lineHeight: 24, fontWeight: 500}}>
            Nothing scheduled today
          </CCText>
        </View>
      )}
    </Box>
  );
};

export default Tournaments;
