import React, {useRef, useState} from 'react';
import CCText from '@/Atoms/CCText';
import {FontSizes} from '@/Utils/Dimensions';
import {
  Image,
  ImageBackground,
  View,
  ScrollView,
  Dimensions,
} from 'react-native';
import {styles} from './styles';
import Box from '@/Atoms/Box';
import Parental_control_rules from '../../../../Assets/Images/Svgs/Parental_control_rules.svg';
import fully_private_games from '../../../../Assets/Images/Svgs/fully_private_games.svg';
import gameplay_assistant from '../../../../Assets/Images/Svgs/gameplay_assistant.svg';
import Play_from_any_position from '../../../../Assets/Images/Svgs/Play_from_any_position.svg';
import reflects_your_rating from '../../../../Assets/Images/Svgs/gameplay_assistant.svg';
import {RFPercentage} from 'react-native-responsive-fontsize';
import largest_learning_community from '@/Assets/Images/Svgs/largest_learning_community.svg';
import expert_cheat_detection from '@/Assets/Images/Svgs/expert_cheat_detection.svg';
import custom_positions_tournaments from '@/Assets/Images/Svgs/custom_positions_tournaments.svg';
import singular_ecosystem from '@/Assets/Images/Svgs/singular_ecosystem.svg';
import tournament_friends from '@/Assets/Images/Svgs/tournament_friends.svg';
import {
  isPortrait,
  isPhone,
  SCREEN_WIDTH,
  WINDOW_WIDTH,
  WINDOW_HEIGHT,
} from '@/Utils/Dimensions';
import {LinearGradient} from 'expo-linear-gradient';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {TouchableOpacity} from 'react-native-gesture-handler';

function IdleStateGameBanner({
  visible,
  StartColor,
  StopColor,
  gamePlayMode,
  isMobileOrTab,
}) {
  const isSmallerWindow = WINDOW_WIDTH < 1212 || WINDOW_HEIGHT < 800;
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollViewRef = useRef(null);
  const [viewWidth, setViewWidth] = useState(0);
  const handleScroll = event => {
    const contentOffsetX = event.nativeEvent.contentOffset.x;
    const viewWidth = Dimensions.get('window').width;
    const newIndex = Math.round(contentOffsetX / viewWidth);
    setCurrentIndex(newIndex);
  };

  const scrollToIndex = index => {
    if (scrollViewRef.current) {
      const viewWidth = Dimensions.get('window').width;
      scrollViewRef.current.scrollTo({x: viewWidth * index, animated: true});
      setCurrentIndex(index);
    }
  };
  // const rules = [
  //   {
  //     logo: Parental_control_rules,
  //     title: 'Parental control rules',
  //     description: 'Control your child’s gameplay on the platform',
  //   },
  //   {
  //     logo: gameplay_assistant,
  //     title: 'Gameplay Assistant',
  //     description: 'Get real-time feedback after the wrong moves',
  //   },
  //   {
  //     logo: fully_private_games,
  //     title: 'Fully private games',
  //     description: 'Your gameplay data is yours and yours only',
  //   },
  //   {
  //     logo: Play_from_any_position,
  //     title: 'Play from any position',
  //     description: 'Practice specific positions to improve gameplay',
  //   },
  //   {
  //     logo: reflects_your_rating,
  //     title: 'Reflects your FIDE rating',
  //     description: 'Our CC rating is based on FIDE rating calculations',
  //   },
  // ];

  function getRulesForMode(gamePlayMode) {
    switch (gamePlayMode) {
      case 'tournament':
        return [
          {
            logo: largest_learning_community,
            title: 'Largest Learning/Competitive Community',
            description: 'Compete with Aspiring Players',
          },
          {
            logo: expert_cheat_detection,
            title: 'Expert Cheat Detection',
            description: 'Play worry-free without cheaters',
          },
          {
            logo: custom_positions_tournaments,
            title: 'Custom Positions Tournaments',
            description: 'Advanced customization for your practice',
          },
          {
            logo: singular_ecosystem,
            title: 'Seamless Tournament Management',
            description: 'Hassle-free tournament management for all',
          },
          {
            logo: tournament_friends,
            title: 'Tournament Amongst Friends',
            description: 'Compete and grow together with friends',
          },
        ];
      case 'Play':
      case 'Play and Learn':
      default:
        return [
          {
            logo: Parental_control_rules,
            title: 'Parental control rules',
            description: 'Control your child’s gameplay on the platform',
          },
          {
            logo: gameplay_assistant,
            title: 'Gameplay Assistant',
            description: 'Get real-time feedback after the wrong moves',
          },
          {
            logo: fully_private_games,
            title: 'Fully private games',
            description: 'Your gameplay data is yours and yours only',
          },
          {
            logo: Play_from_any_position,
            title: 'Play from any position',
            description: 'Practice specific positions to improve gameplay',
          },
          {
            logo: reflects_your_rating,
            title: 'Reflects your FIDE rating',
            description: 'Our CC rating is based on FIDE rating calculations',
          },
        ];
    }
  }

  let rules = getRulesForMode(gamePlayMode);

  return (
    visible && (
      <LinearGradient
        // eslint-disable-next-line react-native/no-inline-styles
        style={
          isMobileOrTab
            ? styles.mobilebg
            : [styles.desktopbg, {position: !isMobileOrTab && 'relative'}]
        }
        colors={[StartColor, StopColor]}
        LinearGradient={{
          start: {y: 0},
          end: {y: 1},
        }}>
        <ImageBackground
          resizeMode="cover"
          source={require('../../../../Assets/Images/Svgs/circle_chess_back_half.png')}
          style={styles.backgroundImg}
        />
        <View style={isMobileOrTab ? styles.mobileView : styles.mainView}>
          <View
            style={isMobileOrTab ? styles.mobileSubView : styles.deskSubView}>
            <Box
              flex={100}
              style={styles.mainHeading}
              flexDirection="column"
              gap={'5%'}>
              <CCText
                color={'#FFFFFF'}
                fontSize={RFPercentage(2)}
                fontWeight="500">
                Why Play
              </CCText>
              <CCText
                fontSize={RFPercentage(2)}
                color={'#FFFFFF'}
                fontWeight="500">
                On CircleChess?
              </CCText>
            </Box>

            <View
              onLayout={event => {
                const {width} = event.nativeEvent.layout;
                setViewWidth(width);
              }}
              style={isMobileOrTab ? styles.mobileImage : styles.deskImage}>
              <Image
                resizeMode="contain"
                source={require('../../../../Assets/Images/Svgs/knights_elephant.png')}
                style={styles.knight_elephants_logo}
              />
              {isMobileOrTab ? (
                <ScrollView
                  ref={scrollViewRef}
                  horizontal
                  pagingEnabled
                  showsHorizontalScrollIndicator={false}
                  onScroll={handleScroll}
                  scrollEventThrottle={16}
                  onMomentumScrollEnd={handleScroll}>
                  {rules.map(({logo, title, description}, index) => (
                    <View
                      key={index}
                      style={[
                        {
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        {width: viewWidth},
                      ]}>
                      <Box
                        flex={1}
                        flexDirection="row"
                        alignItems="center"
                        gap={10}>
                        <Image
                          source={logo}
                          resizeMethod="resize"
                          style={[
                            styles.tileLogo,
                            {backgroundColor: StartColor},
                          ]}
                        />
                        <Box
                          flex={100}
                          style={{overflow: 'visible'}}
                          flexDirection="column"
                          gap={2}>
                          <CCText
                            fontSize={FontSizes[12]}
                            style={styles.title}
                            fontWeight="semibold">
                            {title}
                          </CCText>
                          <CCText
                            fontSize={FontSizes[12]}
                            style={styles.tileDescription}>
                            {description}
                          </CCText>
                        </Box>
                      </Box>
                    </View>
                  ))}
                </ScrollView>
              ) : (
                rules.map(({logo, title, description}, index) => (
                  <Box
                    key={index}
                    flex={100}
                    flexDirection="row"
                    alignItems="center"
                    gap={20}>
                    <Image
                      source={logo}
                      resizeMethod="resize"
                      style={[styles.tileLogo, {backgroundColor: StartColor}]}
                    />
                    <Box
                      flex={100}
                      style={{overflow: 'visible'}}
                      flexDirection="column"
                      gap={2}>
                      <CCText
                        fontSize={RFPercentage(1)}
                        style={styles.title}
                        fontWeight="semibold">
                        {title}
                      </CCText>
                      <CCText
                        fontSize={RFPercentage(1)}
                        style={styles.tileDescription}>
                        {description}
                      </CCText>
                    </Box>
                  </Box>
                ))
              )}
            </View>
            {isMobileOrTab && (
              <View style={styles.carouselRules}>
                {rules.map((_, index) => (
                  <TouchableOpacity
                    key={index}
                    onPress={() => scrollToIndex(index)}>
                    <View
                      style={[
                        styles.circle,
                        index === currentIndex
                          ? styles.circleActive
                          : styles.circleInactive,
                      ]}
                    />
                  </TouchableOpacity>
                ))}
              </View>
            )}
          </View>
        </View>
      </LinearGradient>
    )
  );
}
export default IdleStateGameBanner;
