import React, {useRef, useState, useEffect} from 'react';
import ReactPlayer from 'react-player';
import Entypo from 'react-native-vector-icons/Entypo';
import CCColors from '@/Utils/CCColors';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Box from '../Box';
import {View, TouchableOpacity} from 'react-native';
import CCText from '../CCText';
import CCButton from '../CCButton';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {Picker} from '@react-native-picker/picker';

const CustomVideoPlayer = ({selectedVideo}) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const mainRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPip, setIsPip] = useState(false);

  // Toggle play/pause state
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  // Handle volume changes
  const handleVolumeChange = e => {
    setVolume(parseFloat(e.target.value));
  };

  // Handle progress bar changes (scrubbing)
  const handleProgressChange = e => {
    const newProgress = parseFloat(e.target.value);
    setProgress(newProgress);
    playerRef.current.seekTo(newProgress);
  };

  // Format time in mm:ss format
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  //Handle rewind
  const handleSeekBackward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };
  //Handle seeking forward
  const handleSeekForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  //Handle Mouse move for controls visibility
  let mouseTimeout;
  const handleMouseMove = () => {
    setShowControls(true);
    if (mouseTimeout) clearTimeout(mouseTimeout);
    mouseTimeout = setTimeout(() => setShowControls(false), 5000);
  };

  //Handle PictureInPicture
  const handlePictureInPicture = async () => {
    if (playerRef.current && !isPip) {
      try {
        await playerRef.current.getInternalPlayer().requestPictureInPicture();
        setIsPip(true);
      } catch (error) {
        console.error('Picture-in-Picture failed:', error);
      }
    } else if (document.pictureInPictureElement) {
      await document.exitPictureInPicture();
      setIsPip(false);
    }
  };

  // Handle Full Screen
  const handleFullScreen = () => {
    if (mainRef.current) {
      if (!document.fullscreenElement) {
        mainRef.current.requestFullscreen();
        setFullScreen(true);
      } else {
        document.exitFullscreen();
        setFullScreen(false);
      }
    } else {
      console.error('mainRef.current is undefined');
    }
  };

  // Add event listeners for fullscreen change
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Add event listeners for pip change
  useEffect(() => {
    const handlePipChange = () => {
      if (!document.pictureInPictureElement) {
        setIsPip(false);
      }
    };

    document.addEventListener('leavepictureinpicture', handlePipChange);

    return () => {
      document.removeEventListener('leavepictureinpicture', handlePipChange);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = event => {
      switch (event.key) {
        case ' ':
          handlePlayPause();
          event.preventDefault();
          break;
        case 'ArrowRight':
          handleSeekForward();
          break;
        case 'ArrowLeft':
          handleSeekBackward();
          break;
        case 'f':
          handleFullScreen();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [
    handlePlayPause,
    handleSeekForward,
    handleSeekBackward,
    handleFullScreen,
  ]);

  return (
    <View
      style={styles.container}
      ref={mainRef}
      //onMouseEnter={() => setShowControls(true)}
      //onMouseLeave={() => setShowControls(false)}
      onMouseMove={handleMouseMove}>
      {/* MiddleScreen Pause/Play button */}
      <View
        style={{
          ...styles.centerPlayPauseButton,
          opacity: showControls || !playing ? 1 : 0,
        }}>
        <TouchableOpacity onPress={handlePlayPause}>
          {playing ? (
            <Ionicons color="#fff" name={'pause'} size={40} />
          ) : (
            <Ionicons color="#fff" name={'play'} size={40} />
          )}
        </TouchableOpacity>
      </View>

      <View
        style={{
          ...styles.controls,
          opacity: showControls || !playing ? 1 : 0,
        }}>
        {/* Pause/Play button */}
        <CCButton onPress={handlePlayPause}>
          {playing ? (
            <Ionicons color={CCColors.Black} name={'pause'} size={20} />
          ) : (
            <Ionicons color={CCColors.Black} name={'play'} size={20} />
          )}
        </CCButton>

        {/* Rewind button - button diabled but works with arrow key*/}
        {/* <TouchableOpacity onPress={handleSeekBackward}>
          <Icon name="replay-10" size={25} style={styles.fastBackwordButton} />
        </TouchableOpacity> */}

        {/* Fastforward button - button diabled but works with arrow key */}
        {/* <TouchableOpacity onPress={handleSeekForward}>
          <Icon name="forward-10" size={25} style={styles.fastForwardButton} />
        </TouchableOpacity> */}

        {/* Video Progress (timeline) */}
        <input
          type="range"
          min={0}
          max={1}
          step="0.01"
          value={progress}
          onChange={handleProgressChange}
          style={styles.timeline}
        />

        {/* Volume Control */}
        <View style={styles.volumeContainer}>
          <CCText color={CCColors?.White}>Volume</CCText>
          <input
            type="range"
            min={0}
            max={1}
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            style={styles.volumeSlider}
          />
        </View>

        {/* Timestamp */}
        <View style={styles.timestamp}>
          <CCText color={CCColors?.White}>{formatTime(playedSeconds)}</CCText> /{' '}
          <CCText color={CCColors?.White}>{formatTime(duration)}</CCText>
        </View>

        {/* PlaybackRate */}
        <Picker
          selectedValue={playbackRate}
          style={styles.picker}
          onValueChange={itemValue => setPlaybackRate(itemValue)}>
          <Picker.Item label="0.25x" value={0.25} />
          <Picker.Item label="0.50x" value={0.5} />
          <Picker.Item label="0.75x" value={0.75} />
          <Picker.Item label="1.00x" value={1.0} />
          <Picker.Item label="1.25x" value={1.25} />
          <Picker.Item label="1.50x" value={1.5} />
          <Picker.Item label="1.75x" value={1.75} />
          <Picker.Item label="2.00x" value={2.0} />
        </Picker>

        {/* PictureInPicture - working but disabled for now*/}
        {/* <TouchableOpacity onPress={handlePictureInPicture}>
          <Icon
            name="picture-in-picture-alt"
            size={20}
            style={styles.pipButton}
          />
        </TouchableOpacity> */}

        {/* FullScreen */}
        <TouchableOpacity onPress={handleFullScreen}>
          {isFullScreen ? (
            <Icon
              name="fullscreen-exit"
              size={20}
              style={styles.fullScreenButton}
            />
          ) : (
            <Icon name="fullscreen" size={20} style={styles.fullScreenButton} />
          )}
        </TouchableOpacity>
      </View>

      <ReactPlayer
        ref={playerRef}
        url={selectedVideo.s3_path}
        playing={playing}
        volume={volume}
        width="100%"
        height="100%"
        controls={false}
        onProgress={state => {
          setProgress(state.played);
          setPlayedSeconds(state.playedSeconds);
        }}
        onDuration={duration => setDuration(duration)}
        onContextMenu={e => e.preventDefault()}
        playbackRate={playbackRate}
      />

      {/* Custom controls */}
    </View>
  );
};

// Custom styles
const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
  },
  controls: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    //pointerEvents: 'none', // Hide the controls when not hovered
    zIndex: 3, // Ensure controls are on top
  },
  playPauseButton: {
    backgroundColor: '#fff',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  timeline: {
    width: '60%',
    margin: '0 10px',
    cursor: 'pointer',
  },
  volumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
  },
  volumeLabel: {
    color: '#fff',
    marginBottom: '5px',
    fontSize: '12px',
  },
  volumeSlider: {
    width: '100px',
    cursor: 'pointer',
  },
  timestamp: {
    color: '#fff',
    fontSize: '12px',
    flexDirection: 'row',
    padding: '5px',
  },
  fastBackwordButton: {
    color: '#fff',
    border: 'none',
    padding: '5px',
    paddingRight: '0px',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  fastForwardButton: {
    color: '#fff',
    border: 'none',
    padding: '5px',
    paddingLeft: '0px',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  picker: {
    backgroundColor: '#000',
    color: '#fff',
    border: 'none',
    padding: '2px',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    //alignItems: 'center',
    // justifyContent: 'center',
  },
  pipButton: {
    color: '#fff',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  fullScreenButton: {
    //backgroundColor: '#fff', //change depending on button
    color: '#fff',
    border: 'none',
    padding: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  centerPlayPauseButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{translateX: -30}, {translateY: -30}], // Adjust for the button's size
    zIndex: 5,
  },
};

export default CustomVideoPlayer;
