import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import SlideModal from '@/Atoms/SlideModal';
import {useDeleteBookApi} from '@/Hooks/BooksApi/useBooksApi';
import {ScreenName} from '@/navigators/StackConstants';
import {PopUp} from '@/Screens/Visualisation/components/LevelModal';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  View,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const TaskList = ({
  totalChapters,
  chapterIndex,
  solvedChapters,
  attemptedChapters,
  chapters,
  selectedItem,
  onChapterChange,
  setShowChapters,
  isChapterHover,
  setChapterHover,
  isEvaluateRedirect,
  selectedBook,
  bookType,
  puzzleData,
  totalScoredCount,
  handleResetPuzzleData,
  dashboardData,
  bookData,
  bookId,
}) => {
  const [visibleChapters, setVisibleChapters] = useState(8);
  const [showPopup, setShowPopup] = useState(false);
  const [solvedCount, setSolvedCount] = useState<number>(solvedChapters);
  const [scoredCount, setScoredCount] = useState<number>(totalScoredCount);
  const [pendingCount, setPendingCount] = useState<number>(
    Math.max(0, chapterIndex - solvedChapters),
  );
  const navigation = useNavigation();
  const handleShowMore = () => {
    const remainingChapters = chapters.length - visibleChapters;
    setVisibleChapters(visibleChapters + remainingChapters);
  };

  const {mutate: deleteBookApi, isLoading} = useDeleteBookApi(
    res => {
      console.log('Deleted successfully:', res);
      handleResetPuzzleData?.();
      navigation.navigate(ScreenName.Books, {tab: bookType});
    },
    err => {
      console.error('Error deleting book:', err);
    },
  );

  useEffect(() => {
    if (puzzleData?.summary) {
      setSolvedCount(puzzleData.summary.correct ?? solvedChapters);
      setScoredCount(puzzleData.summary.scored ?? totalScoredCount);
      setPendingCount(
        Math.max(
          0,
          chapterIndex - (puzzleData.summary.correct ?? solvedChapters),
        ),
      );
    } else {
      setSolvedCount(solvedChapters);
      setScoredCount(totalScoredCount);
      setPendingCount(Math.max(0, chapterIndex - solvedChapters));
    }
  }, [puzzleData, solvedChapters, totalScoredCount, chapterIndex]);

  function handleOnCancel() {
    setShowPopup(false);
  }

  const renderItem = ({item: c, index}) => {
    const isChapterSolved =
      puzzleData?.chapter_list?.[index]?.solved || c?.solved;

    return (
      <TouchableOpacity
        onPress={() => {
          onChapterChange(c.chapter_index);
          setShowChapters(false);
        }}
        onMouseEnter={() => setChapterHover(c.chapter_index)}
        onMouseLeave={() => setChapterHover(0)}
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          backgroundColor:
            isChapterHover === c.chapter_index
              ? CCColors.Primary.lightBrown
              : CCColors.White,
        }}>
        <View style={styles.taskItem}>
          <Icon name="book-open-outline" style={styles.bookIcon} />
          <CCText
            style={[
              styles.taskTitle,
              // {
              //   color: c?.solved ? CCColors.Primary.Grey : '',
              //   textDecorationLine: c?.solved ? 'line-through' : 'none',
              // },
            ]}
            numberOfLines={1}
            ellipsizeMode="tail"
            showEllipses>
            {c?.chapter_index}. {c?.chapter}
          </CCText>
          <Icon
            name="check-circle"
            style={{marginLeft: 10}}
            size={20}
            color={isChapterSolved ? CCColors.Green : CCColors.Primary.Grey}
          />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      {!isEvaluateRedirect && selectedBook && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 8,
          }}>
          <Box
            type="row-flex-start"
            style={{
              backgroundColor: updateColorWithOpacity(
                CCColors?.Primary?.Brown,
                0.8,
              ),
              borderRadius: 8,
              padding: 8,
              margin: 8,
              width: 'fit-content',
            }}
            onPress={() => {
              handleResetPuzzleData?.();
              navigation.navigate(ScreenName.Books, {tab: bookType});
            }}>
            <Ionicons name={'arrow-back'} size={25} color={CCColors.White} />
            <CCText fontSize={FontSizes[15]} color={CCColors.White}>
              Back
            </CCText>
          </Box>
          {dashboardData?.user?.user_id === bookData?.book_owner && (
            <Box
              type="row-flex-start"
              style={{
                backgroundColor: updateColorWithOpacity(CCColors?.Error, 0.8),
                borderRadius: 8,
                padding: 8,
                width: 'fit-content',
              }}
              onPress={() => setShowPopup(true)}>
              <Ionicons name={'trash'} size={20} color={CCColors.White} />
              <CCText
                fontSize={FontSizes[15]}
                color={CCColors.White}
                style={{marginLeft: 4}}>
                Delete
              </CCText>
            </Box>
          )}
          {showPopup && (
            <SlideModal
              visible={showPopup}
              type="center"
              customStyle={styles.levelModalCustom}>
              <PopUp
                confirm={() => {
                  deleteBookApi({book_id: bookId});
                }}
                cancel={handleOnCancel}
                text={'Are you sure you want to delete?'}
                buttonConfirm="Delete"
              />
            </SlideModal>
          )}
        </View>
      )}
      <CCText style={styles.header}>{totalChapters} Chapters</CCText>
      <View style={styles.summaryContainer}>
        <View style={[styles.summaryItem]}>
          <CCText style={styles.summaryTitle}>Scored</CCText>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <CCText style={styles.summaryCount}>{scoredCount}</CCText>
            <Icon name="check-circle" size={20} color={CCColors?.Green} />
          </Box>
        </View>
        <View style={[styles.summaryItem]}>
          <CCText style={styles.summaryTitle}>Solved</CCText>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <CCText style={styles.summaryCount}>{solvedCount}</CCText>
            <Icon
              name="check-circle"
              size={20}
              color={CCColors?.Primary?.Default}
            />
          </Box>
        </View>
        <View style={[styles.summaryItem]}>
          <CCText style={styles.summaryTitle}>Pending</CCText>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <CCText style={styles.summaryCount}>{pendingCount}</CCText>
            <Icon
              name="check-circle"
              size={20}
              color={CCColors?.Primary?.Grey}
            />
          </Box>
        </View>
      </View>
      <ScrollView
        style={{flex: 1, width: 300}}
        showsVerticalScrollIndicator={false}>
        <FlatList
          data={chapters.slice(0, visibleChapters)}
          keyExtractor={item => item.chapter_index.toString()}
          renderItem={renderItem}
        />
      </ScrollView>
      {visibleChapters < chapters.length && (
        <TouchableOpacity
          style={styles.showMoreContainer}
          onPress={handleShowMore}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <CCText style={styles.showMore}>Show More </CCText>
            <Ionicons name="chevron-down" size={16} color={CCColors.Green} />
          </Box>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: CCColors?.White,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    marginLeft: 10,
    color: CCColors?.TextColor?.Grey,
  },
  summaryContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  summaryItem: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    marginHorizontal: 4,
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: 5,
    backgroundColor: CCColors?.Primary?.lightBrown,
  },
  summaryTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: CCColors?.TextColor?.Primary,
  },
  summaryCount: {
    fontSize: 14,
    fontWeight: 'bold',
    color: CCColors?.TextColor?.Primary,
    marginRight: 5,
  },
  taskItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
  },
  bookIcon: {
    fontSize: 20,
    marginRight: 12,
    color: CCColors?.TextColor?.Primary,
  },
  taskTitle: {
    flex: 1,
    fontSize: 14,
    color: CCColors?.TextColor?.Primary,
    flexShrink: 1,
  },
  statusIcon: {
    fontSize: 18,
    marginLeft: 8,
  },
  showMore: {
    fontSize: 14,
    color: CCColors?.Green,
    textAlign: 'center',
    marginVertical: 10,
  },
  showMoreContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: CCColors.White,
    alignItems: 'center',
    justifyContent: 'center',
  },
  levelModalCustom: {
    borderRadius: 12,
    backgroundColor: 'white',
    zIndex: 5,
    overflowY: 'hidden',
    minWidth: '20%',
    margin: 'auto',
  },
});

export default TaskList;
