import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, {useEffect, useMemo, useState} from 'react';
import SafeAreaWrapper from '../../Atoms/SafeViewWrapper';
import Spacer from '@/Atoms/Spacer';
import useUpdateLearningOnboardingStatusApi from '@/Hooks/UpdateOnboardingStatus/useUpdateLearningOnboardingStatusApi';
import {MaterialIcons} from '@expo/vector-icons';
import {hs, vs, FontSizes, isPhone} from '../../Utils/Dimensions';
import {styles} from './styles';
import CCTextInput from '@/Atoms/CCTextInput';
import useSyncGamesApi from '@/Hooks/OnboardingApis/useSyncGames';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChess} from '@fortawesome/free-solid-svg-icons';
import CCColors from '@/Utils/CCColors';
import logo from '../../Assets/Images/Svgs/cc_logo.png';
import illustration from '../../Assets/Images/Svgs/person_playing_chess.png';
import chesscom from '../../Assets/Images/Svgs/chesscom.png';
import lichess from '../../Assets/Images/Svgs/lichess.png';
import brief_logo from '../../Assets/Images/Svgs/brief_logo.png';
import ConditionalRender from '@/Atoms/ConditionalRender';
import useGAPageView from '@/navigators/useGAPageView';
import {Image, ScrollView, TouchableOpacity, View} from 'react-native';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCSelect from '@/Atoms/CCSelect';
import useFetchRegisteredPlayers from '@/Hooks/GetRegisteredPlayersApi/useFetchRegisteredPlayers';
import SlideModal from '@/Atoms/SlideModal';
import NewUserRegistration from '@/Components/NewUserRegistration';
import {ChipType} from '@/Atoms/ChipList/types';
import {handleLogout} from '@/Utils/CommonUtils';
import {useLoginState} from '@/Hooks/LoginApi/loginApi';
import CCCheckbox from '@/Atoms/CCCheckbox';
import {PopUp} from '../Visualisation/components/LevelModal';
import CCLoader from '@/Atoms/CCLoader';

const AddNewPlayer = ({postSubmitCallback}) => {
  const {isMobileOrTab} = useMediaQuery();
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [name, setName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [fideID, setFideID] = useState('');
  const [email, setEmail] = useState<string>('');
  const [gender, setGender] = useState<{value: number; key: string}>({
    key: '',
    value: 0,
  });

  const isValid = useMemo(() => {
    if (name?.length > 2 && dateOfBirth?.length === 10 && gender?.value > 0) {
      return true;
    } else if (fideID?.length > 3) {
      return true;
    }
    return false;
  }, [name, dateOfBirth, gender, fideID]);

  const handleGenderChange = (e: ChipType) => {
    setGender(e);
  };
  const handleNameChange = (text: string) => {
    setName(text);
  };
  const handleDOBChange = (text: string) => {
    setDateOfBirth(text);
  };

  const handleNewUserModal = () => {
    setOpenNewUserModal(prev => !prev);
  };

  return (
    <Box type="row-flex-end">
      <CCButton
        type="box"
        onPress={handleNewUserModal}
        titleStyle={{color: CCColors.White}}
        style={{width: !isMobileOrTab ? '100%' : '50%'}}>
        + Add new player
      </CCButton>
      <SlideModal
        type="side"
        modalHeaderTitle="New Registration"
        visible={openNewUserModal}
        onClose={handleNewUserModal}>
        <NewUserRegistration
          name={name}
          dateOfBirth={dateOfBirth}
          gender={gender}
          handleNameChange={handleNameChange}
          handleDOBChange={handleDOBChange}
          handleGenderChange={handleGenderChange}
          isValid={isValid}
          setFideID={setFideID}
          fideID={fideID}
          postSubmitCallBack={() => {
            handleNewUserModal();
            postSubmitCallback?.();
            // postSubmitCallBack();
          }}
          isMobileOrTab={isMobileOrTab}
        />
      </SlideModal>
    </Box>
  );
};

function ImportGames(props) {
  useGAPageView(ScreenName.Onboardingprogress);
  const {isMobileOrTab} = useMediaQuery();
  const navigation = useNavigation<any>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const {mutate: updateLearningOnboardingStatus, isLoadingStatus} =
    useUpdateLearningOnboardingStatusApi(
      () => {
        window.location.reload(); // Refresh the page
      },
      error => {
        console.error('Failed to update learning onboarding status:', error);
      },
    );
  const userData = props.userData;
  const [isDetailsSubmitted, setIsDetailsSubmitted] = useState<boolean>(
    userData.cdc_name || userData.lic_name,
  );
  const [showPopup, setShowPopup] = useState(false);
  const handleOnSuccess = (apiData: any) => {
    if (!apiData.data.status) {
      setIsError(true);
      setErrorMsg(apiData.data.error);
    } else {
      // navigation.navigate(ScreenName.Onboardingprogress);
      window.location.reload();
      setIsDetailsSubmitted(true);
    }
  };
  const [checkboxValue, setCheckboxValue] = useState(false);
  const handleOnError = (apiData: any) => {};

  const {
    mutate: getRegisteredPlayers,
    data: registeredPlayers,
    error: registeredPlayersError,
    isLoading: registeredPlayersLoading,
  } = useFetchRegisteredPlayers();

  const {
    mutate: syncGames,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = useSyncGamesApi(handleOnSuccess, handleOnError);
  const doGamesImport = async () => {
    // Resetting error condition
    setIsError(false);
    setErrorMsg('');
    const {lichessUser, chessdotcomUser, ccUser, userEmail} = onlineProfile;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(userEmail);

    if (!selectedPlayer) {
      setIsError(true);
      setErrorMsg('Select a valid player');
    } else if (!ccUser || ccUser === '') {
      setErrorMsg('Please select a CircleChess username');
      setIsError(true);
      return;
    } else if ((lichessUser || chessdotcomUser) && !checkboxValue) {
      setIsError(true);
      setErrorMsg(
        'You must read and agree to the Terms and Conditions before submitting.',
      );
      return;
    }
    if (userEmail === '' || isValidEmail === false) {
      setErrorMsg('Share a valid email id');
      setIsError(true);
    } else {
      syncGames({
        lichessUser,
        chessdotcomUser,
        ccUser,
        userEmail,
        player_id: selectedPlayer?.player_id,
        selectedPlayer: {
          name: selectedPlayer?.player_name,
          dob: selectedPlayer?.dob,
          fide_id: selectedPlayer?.fide_id,
        },
      });
    }
  };

  // const [reloadLoginState, setReloadLoginState] = useState<boolean>(false);

  // const {data, loading, isUserLoggedIn} = useLoginState(reloadLoginState);

  const [onlineProfile, setOnlineProfile] = useState<{
    ccUser: string;
    lichessUser: string;
    chessdotcomUser: string;
    userEmail: string;
  }>({
    ccUser: '',
    lichessUser: '',
    chessdotcomUser: '',
    userEmail: '',
  });

  const [registeredPlayersList, setRegisteredPlayersList] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const handleOnboardingDataChange = (text: string, field: string) => {
    setOnlineProfile(prev => ({...prev, [field]: text.trim()}));
  };

  useEffect(() => {
    if (registeredPlayers) {
      setRegisteredPlayersList(
        registeredPlayers?.registrations
          ?.map(i => ({
            value: i?.fields?.player_name,
            label: i?.fields?.player_name,
            extra: i?.fields,
          }))
          ?.filter(i => i?.label),
      );
    }
  }, [registeredPlayers]);

  useEffect(() => {
    getRegisteredPlayers();
  }, []);

  const handleSignout = async () => {
    await handleLogout();
    if (window?.location) {
      window.location.reload(true);
    }
  };

  return (
    <View style={{backgroundColor: '#FAF6EB', height: '100vh'}}>
      {/* <CCLoader loading={isLoadingStatus} /> */}
      <Box
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection={isMobileOrTab ? 'column' : 'row'}
        style={styles.container}>
        <Box
          style={[
            styles.labelText,
            {
              backgroundColor: '#FAF6EB',
              display: isMobileOrTab ? 'none' : '',
            },
          ]}
          flexDirection={isMobileOrTab ? 'column' : 'row'}>
          {!isMobileOrTab ? (
            <Box
              style={{flex: 1, backgroundColor: '#FAF6EB'}}
              flexDirection="column">
              <CCText
                fontSize={FontSizes[24]}
                textAlign="center"
                fontWeight="bold"
                style={{marginTop: '15%'}}
                color={CCColors.TextColor.Primary}>
                With CircleChess, <br />
                learn like never before.
              </CCText>
              <Image
                source={illustration}
                resizeMode="contain"
                style={{
                  // position: 'absolute',
                  // bottom: 0,
                  // right: 0,
                  // flex: 1,
                  // width: '100%',
                  height: '60%',
                  ...(isMobileOrTab ? {width: '100%'} : {}),
                }}
              />

              {/* <Image
              src={illustration}
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: vs(455),
              }}
            /> */}
            </Box>
          ) : (
            <Image
              resizeMode="contain"
              style={styles.logo}
              source={require('@/Assets/Images/Svgs/cc-logo-light.svg')}
            />
          )}
        </Box>
        {!isDetailsSubmitted && (
          <Box
            flex={isMobileOrTab ? 1 : 0.6}
            style={{
              height: '100%',
            }}>
            <ScrollView
              style={[
                styles.rightlabelText,
                {backgroundColor: CCColors.Background, flex: 1},
              ]}>
              {/* <Box flexDirection="row" style={{display: 'none'}}>
                <Box>
                  <img src={brief_logo} style={{width: 28, height: 28}} />
                </Box>
                <Box
                  style={{
                    justifyContent: 'flex-end',
                    marginHorizontal: vs(10),
                    borderTopWidth: 4,
                    borderTopColor: CCColors.TextBoxes.Borders,
                    width: 112,
                  }}>
                  <CCText
                    style={{color: CCColors.ColorCodes.Current}}
                    fontSize={FontSizes[12]}>
                    Link accounts
                  </CCText>
                </Box>
                <Box
                  style={{
                    justifyContent: 'flex-end',
                    marginHorizontal: vs(10),
                    borderTopWidth: 4,
                    borderTopColor: CCColors.Grey.Shade100,
                    width: 112,
                  }}>
                  <CCText
                    style={{color: CCColors.ColorCodes.Pending}}
                    fontSize={FontSizes[12]}>
                    Fetch Games
                  </CCText>
                </Box>
                <Box
                  style={{
                    justifyContent: 'flex-end',
                    marginHorizontal: vs(10),
                    borderTopWidth: 4,
                    borderTopColor: CCColors.Grey.Shade100,
                    width: 112,
                  }}>
                  <CCText
                    style={{color: CCColors.ColorCodes.Pending}}
                    fontSize={FontSizes[12]}>
                    Select Avatar
                  </CCText>
                </Box>
              </Box> */}
              <Spacer spacing={10} />
              <Box flexDirection="row" style={{alignItems: 'center'}}>
                <CCText
                  fontSize={FontSizes[24]}
                  fontWeight="bold"
                  color={CCColors.menuBackground.main}>
                  Welcome to
                </CCText>
                <img
                  src={logo}
                  alt="Circlechess"
                  style={{
                    height: 48,
                    marginLeft: 12,
                  }}
                />
              </Box>
              <Spacer spacing={24} />
              <CCText fontSize={FontSizes[14]}>Select Player</CCText>
              <CCSelect
                // multiSelect
                headerComponent={
                  <AddNewPlayer postSubmitCallback={getRegisteredPlayers} />
                }
                showModal
                modalLabel="Select a player"
                style={styles.textBoxes}
                placeholder="Select a player"
                onValueChange={playerNameTemp => {
                  const tempSelected = registeredPlayers?.registrations?.find(
                    i => i?.fields?.player_name === playerNameTemp?.[0],
                  );
                  setSelectedPlayer({
                    ...tempSelected?.fields,
                    player_id: tempSelected?.pk,
                  });
                }}
                selectedValues={
                  selectedPlayer?.player_name
                    ? [selectedPlayer?.player_name]
                    : []
                }
                items={registeredPlayersList}
                // label="Select player"
              />
              {console.log(selectedPlayer, 'selectedPlayer')}
              <Spacer spacing={10} />
              <CCText fontSize={FontSizes[14]}>
                Select your username for CircleChess
              </CCText>
              <Box
                flexDirection="row"
                alignItems="center"
                style={{paddingTop: vs(12)}}>
                <CCTextInput
                  label="Select Your CircleChess username"
                  style={styles.textBoxes}
                  outlineColor={CCColors.TextBoxes.Borders}
                  onChangeText={(text: string) => {
                    handleOnboardingDataChange(text, 'ccUser');
                  }}
                />
              </Box>
              <Box flexDirection="column">
                <Spacer spacing={8} />
                <CCText fontSize={FontSizes[14]}>
                  Please share your email for communication and updates
                </CCText>

                <Box
                  flexDirection="row"
                  alignItems="center"
                  gap={20}
                  style={{paddingTop: vs(10)}}>
                  <CCTextInput
                    label="Your Email"
                    style={styles.textBoxes}
                    outlineColor={CCColors.TextBoxes.Borders}
                    onChangeText={(text: string) => {
                      handleOnboardingDataChange(text, 'userEmail');
                    }}
                  />
                </Box>
              </Box>
              <Spacer spacing={38} />
              <CCText fontSize={FontSizes[14]}>
                Help us help you. Connect your Chess.com and Lichess accounts
                with Circlechess.
              </CCText>
              <Box
                flexDirection="row"
                alignItems="center"
                style={{paddingTop: vs(12)}}>
                <CCTextInput
                  label="Your Lichess Username"
                  style={styles.textBoxes}
                  outlineColor={CCColors.TextBoxes.Borders}
                  staticPrefix={
                    <>
                      <img src={lichess} />
                    </>
                  }
                  onChangeText={(text: string) => {
                    handleOnboardingDataChange(text, 'lichessUser');
                  }}
                />
              </Box>
              <Box
                flexDirection="row"
                alignItems="center"
                style={{paddingTop: vs(12)}}>
                <CCTextInput
                  label="Your Chess.com Username"
                  style={styles.textBoxes}
                  outlineColor={CCColors.TextBoxes.Borders}
                  staticPrefix={
                    <>
                      <img src={chesscom} />
                    </>
                  }
                  onChangeText={(text: string) => {
                    handleOnboardingDataChange(text, 'chessdotcomUser');
                  }}
                />
              </Box>
              <Spacer spacing={10} />
              {showPopup && (
                <SlideModal
                  visible={showPopup}
                  type="center"
                  customStyle={styles.levelModalCustom}>
                  <PopUp
                    confirm={() => setShowPopup(false)}
                    text={`By granting CircleChess access to your chess.com or lichess.org account, you give explicit permission to retrieve and use data from your last 100 games for analysis and services offered by CircleChess. You acknowledge full responsibility for granting this access and agree to indemnify CircleChess against any claims, damages, or consequences arising from this data usage. CircleChess will use your data solely for its intended features and will not share it with unauthorized third parties. You confirm that you have the legal right to share this data and that this permission complies with chess.com's or lichess.org's policies respectively. You may revoke this permission at any time, but data already accessed will not be retroactively deleted.
                      
`}
                    gap={5}
                    fontSize={15}
                    buttonConfirm="Okay"
                  />
                </SlideModal>
              )}
              <CCCheckbox
                label={
                  <>
                    <CCText>
                      I have read and agree to the{' '}
                      <span
                        style={{
                          color: 'blue',
                          textDecorationLine: 'underline',
                        }}>
                        terms and conditions.
                      </span>
                    </CCText>
                  </>
                }
                value={checkboxValue}
                onValueChange={(label, newValue) => {
                  // Handle checkbox state change
                  setCheckboxValue(newValue);
                }}
                customStyle={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginVertical: 4,
                }}
                tickSize={14}
                onLabelPress={() => setShowPopup(true)}
              />

              <Spacer spacing={30} />
              <ConditionalRender
                condition={isError}
                childrenA={
                  <CCText
                    style={{
                      flex: 0.1,
                      marginBottom: 6,
                      color: 'red',
                    }}>
                    {errorMsg}
                  </CCText>
                }
                childrenB={<></>}
              />
              <Box flexDirection={isMobileOrTab ? 'column' : 'row'}>
                <CCButton
                  style={styles?.buttonWrapper}
                  titleStyle={styles.buttonStyles}
                  onPress={doGamesImport}
                  disabled={isLoading}>
                  Submit
                </CCButton>
                <Spacer spacing={40} horizontal={true} />
                {/* <CCButton
                  style={styles?.buttonWrapper2}
                  titleStyle={styles.buttonStyles}
                  type="outline"
                  onPress={doGamesImport}>
                  Skip &gt;&gt;
                  </CCButton> */}
              </Box>
              <Spacer spacing={120} />
            </ScrollView>
          </Box>
        )}

        {isDetailsSubmitted && (
          <Box
            type="center"
            style={{flex: 0.5, height: '100vh', backgroundColor: '#FAF6EB'}}>
            <Box
              flexDirection="row"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                // flex: 0.5,
              }}>
              <CCText
                style={{
                  width: isMobileOrTab ? '100%' : '50%',
                  textAlign: 'center',
                  fontSize: 24,
                  fontWeight: '500',
                }}>
                Thanks for registering. We'll get back to you once your account
                is approved.
              </CCText>
            </Box>
            <Spacer spacing={20} />
            <CCButton
              // type="outline"
              disabled={isLoadingStatus}
              onPress={() => updateLearningOnboardingStatus()}>
              <CCText
                style={{
                  color: CCColors.TextBoxes.Borders,
                  fontSize: 16,
                  fontWeight: '500',
                }}>
                Skip waitlist
              </CCText>
            </CCButton>
          </Box>
        )}
      </Box>
      <Box style={styles.logoutButton} onPress={handleSignout}>
        <MaterialIcons
          name="logout"
          size={30}
          // color={colors.iconColor}
        />
      </Box>
    </View>
  );
}

export default ImportGames;
