import React from 'react';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import SlideModal from '@/Atoms/SlideModal';
import useIsMobile from '@/Hooks/useIsMobile';
import styles from './styles';
import CCText from '@/Atoms/CCText';

const PlayerTypeModal = ({visible, onClose, handleFormSubmit}) => {
  const isMobileOrTab = useIsMobile();

  return (
    <SlideModal
      type={'center'}
      visible={visible}
      customStyle={{width: '100%'}}
      showHeader={false}>
      <View style={styles.modalBackground}>
        <View
          style={
            isMobileOrTab
              ? styles.optionsContainerMobile
              : styles.optionsContainer
          }>
          <TouchableOpacity
            style={isMobileOrTab ? styles.optionMobile : styles.option}
            onPress={() => handleFormSubmit(1)}>
            <View
              style={
                isMobileOrTab
                  ? styles.textContainerMobile
                  : styles.textContainer
              }>
              <CCText
                style={
                  isMobileOrTab ? styles.optionTitleMobile : styles.optionTitle
                }>
                I'm a Beginner player
              </CCText>
              <CCText
                style={
                  isMobileOrTab
                    ? styles.optionSubtitleMobile
                    : styles.optionSubtitle
                }>
                Completely New to Chess
              </CCText>
            </View>
            <Image
              source={require('@/Assets/Dashboard/BeginnerOrPro/beginner_image.svg')}
              style={[styles.image, isMobileOrTab && styles.imageMobile]}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={isMobileOrTab ? styles.optionMobile : styles.option}
            onPress={() => handleFormSubmit(2)}>
            <View
              style={
                isMobileOrTab
                  ? styles.textContainerMobile
                  : styles.textContainer
              }>
              <CCText
                style={
                  isMobileOrTab ? styles.optionTitleMobile : styles.optionTitle
                }>
                I'm a Pro player
              </CCText>
              <CCText
                style={
                  isMobileOrTab
                    ? styles.optionSubtitleMobile
                    : styles.optionSubtitle
                }>
                Familiar with Chess
              </CCText>
            </View>
            <Image
              source={require('@/Assets/Dashboard/BeginnerOrPro/pro_image.svg')}
              style={[styles.image, isMobileOrTab && styles.imageMobile]}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </View>
      </View>
    </SlideModal>
  );
};

export default PlayerTypeModal;
