import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import { FontSizes, hs, vs } from '@/Utils/Dimensions';
import { View } from 'react-native';
import { Modal, Text, Button } from 'react-native';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    interpolateColor,
    interpolate,
    withTiming,
} from 'react-native-reanimated';
import { styles } from './styles';
import victory from "@/Assets/Images/png/win_result.png";
import defeat from "@/Assets/Images/png/defeat_result.png";
import draw from "@/Assets/Images/png/draw_result.png";
import close from "@/Assets/Images/icons/close.png";
import abort_result from "@/Assets/Images/png/empty_result.png";
import useAnimation from '@/Hooks/useAnimation';
import CCColors from '@/Utils/CCColors';
import rematch from "@/Assets/Images/icons/Rematch.png";
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { ScreenName } from '@/navigators/StackConstants';
import { useNavigation, useRoute } from '@react-navigation/native';
import RatingComponent from '../RatingComponent';
import newGame from '@/Assets/Images/png/newGame.png'

function ResultComponent(props) {
    const { challenger, visible = false, result = 'Won', endGameMessage, endType, game_uuid, handle_end_game_modal, joinGame, challengeGame, moveTimer, Increment, challengeMoveTimer, challengeIncrement, white, opponent='your opponent', myRating, myRatingChange, lastfen, gamePlayMode } = props;

    const [gemsAnimValue, setGemsAnimValue] = useAnimation({
        prop: 'backgroundColor',
        start: 'rgba(255, 255, 255, 0)',
        end: CCColors.Green,
        isColor: true,
    });
    const GAME_END_TYPE = {
        RESIGN: 'resign',
        DRAW: 'draw',
        CHECKMATE: 'checkmate',
        STALEMATE: 'stalemate',
        INSUFFICIENT: 'insufficient',
        REPETITION: 'repetition',
        OTHER: 'other',
        TIME_EXPIRE: 'timeup',
        ABORT: 'abort',
        CASTLINGRULE: 'breached castling_rule',
        ROOKSCONNECTEDRULE: 'breached rooks_connected_rule',
        AUTORESIGN: 'auto-resign'
    };

    function setEndTypeMessage(endType) {
        switch (endType) {
            case GAME_END_TYPE.RESIGN:
                return `The game ended by resignation.`;
            case GAME_END_TYPE.AUTORESIGN:
                return `The game ended by auto resignation.`;
            case GAME_END_TYPE.DRAW:
                return 'The game ended in a draw by agreement.';
            case GAME_END_TYPE.CHECKMATE:
                return 'The game ended with a checkmate.';
            case GAME_END_TYPE.STALEMATE:
                return 'The game ended in a stalemate.';
            case GAME_END_TYPE.INSUFFICIENT:
                return 'The game ended due to insufficient material.';
            case GAME_END_TYPE.REPETITION:
                return 'The game ended due to threefold repetition.';
            case GAME_END_TYPE.OTHER:
                return 'The game ended for some other reason.';
            case GAME_END_TYPE.TIME_EXPIRE:
                return 'The game ended because time expired.';
            case GAME_END_TYPE.ABORT:
                return 'The game was aborted.';
            case GAME_END_TYPE.CASTLINGRULE:
                return 'Violated the castling rule';
            case GAME_END_TYPE.ROOKSCONNECTEDRULE:
                return 'Violated the rooks connected rule';
        
            default:
                return 'Unknown end type.';
        }
    }

    function classifyTimeControl(duration, increment) {
        // Calculate the total time in minutes
        const totalTime = (duration + increment) / 60000;
      
        // Classify based on total time
        if (totalTime < 3) {
          return 'Bullet';
        } else if (totalTime < 10) {
          return 'Blitz';
        } else if (totalTime < 30) {
          return 'Rapid';
        } else {
          return 'Classical';
        }
      }
    

    const navigation = useNavigation();
    // console.log("white is : ",white);
    return (
        <Modal
            animationType='fade'
            transparent={true}
            visible={visible}
            onRequestClose={() => {
            }}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={{ position: 'absolute', top: 16, right: 16 }}>
                            <img src={close} 
                                onClick={handle_end_game_modal} 
                                style={{ backgroundColor: 'white', borderColor: '#22222240', borderWidth: 1, height: 40, width: 40 }}/>
                    </View>

                    { result === 'Win' && <img src={victory} style={{ width: hs(56), marginTop: vs(32) }} /> }
                    { result === 'Loss' && <img src={defeat} style={{ width: hs(56), marginTop: vs(32) }} /> }
                    { result === 'Draw' && <img src={draw} style={{ width: hs(56), marginTop: vs(32) }} /> }
                    { result === 'Abort' && <img src={abort_result} style={{ width: hs(56), marginTop: vs(32) }} /> }

                    <CCText fontSize={FontSizes[16]} style={{ color: 'black', marginTop: 16 }}>{endGameMessage}</CCText>
                    <CCText fontSize={FontSizes[12]} style={{ color: 'black', marginTop: 8 }}>
                        {setEndTypeMessage(endType)}
                    </CCText>
                    <RatingComponent rating={myRating} change={myRatingChange} />

                    {result !== 'Abort' &&<Box flexDirection="column" style={{ justifyContent: 'center', marginTop: 24, padding: 16, backgroundColor: CCColors.Primary.Light, width: '100%' }}>
                        <CCText style={{ textAlign: 'center' }}>
                        How did you play? Before starting the next game, take a moment to review this one.
                        </CCText>
                        <CCButton
                            onPress={() => {
                                console.log("game_uuid is", game_uuid);
                                navigation.navigate(ScreenName.ChessAnalysis, {
                                    game_id: game_uuid,
                                });
                            }}
                            disabled={!game_uuid || gamePlayMode == 'tournament'}
                            style={{
                                flexWrap: 'nowrap',
                                width: 240,
                                margin: 'auto',
                                marginTop: 16
                            }}
                            contentContainerStyle={{
                                paddingRight: 8,
                                paddingVertical: 8,
                                paddingLeft: 24,
                            }}>
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <CCText style={styles.reviewGameActionText}>Review</CCText>
                                <View style={[styles.reviewGameActionCoinsContainer, { marginLeft: 8 }]}>
                                    <FontAwesome5 name="coins" size={16} color="#D1AB41" />
                                    <CCText
                                        style={{ marginLeft: 8 }}>
                                        50+ pts
                                    </CCText>
                                </View>
                            </View>
                        </CCButton>
                    </Box>}

                    {gamePlayMode !== 'tournament' && <Box flexDirection='row' style={{ width: '100%', paddingTop: 24 }}>
                        <Box flex={1} style={{ backgroundColor: 'white', alignItems: 'center', borderColor: '#22222220', borderRightWidth: 1 }}>
                            <img src={rematch} style={{ height: 18, width: 18 }} />
                            {/* <CCText style={{textAlign: 'center', marginTop: 12 }}>
                                Up for a rematch against your opponent?
                            </CCText> */}
                            <CCButton onPress={() => challengeGame(challenger, challengeMoveTimer, challengeIncrement, white, lastfen)} flex={1} style={{ backgroundColor: CCColors.Primary.Light, marginTop: 24 }}>
                                <CCText style={{ color: 'black', marginLeft: 4 }} fontSize={12}>Rematch</CCText>
                            </CCButton>
                        </Box>
                        <Box flex={1} style={{ backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={newGame} style={{ height: 18, width: 18 }} />
                            {/* <CCText style={{ textAlign: 'center', marginTop: 12 }}>
                                Find new opponent to play against?
                            </CCText> */}
                            <CCButton onPress={() => joinGame(moveTimer, Increment)} flex={1} style={{ backgroundColor: CCColors.Primary.Light, marginTop: 24 }}>
                                <CCText style={{ color: 'black', marginLeft: 4, justifyContent: 'center' }} fontSize={12}>New  {moveTimer / 60000} + {Increment / 1000}</CCText>
                            </CCButton>
                        </Box>
                    </Box>}
                </View>
            </View>
        </Modal>

    );
}

export default ResultComponent;
