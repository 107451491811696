import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes} from '@/Utils/Dimensions';
import * as React from 'react';
import {useState} from 'react';
import {Pressable, StyleSheet} from 'react-native';
import Animated, {
  Easing,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import Entypo from 'react-native-vector-icons/Entypo';
import {Linking} from 'react-native';

const MenuItems = () => {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const animatedValue = useSharedValue(0);

  const toggleNavLinks = () => {
    setShowNavLinks(!showNavLinks);
    animatedValue.value = withTiming(showNavLinks ? 0 : 1, {
      duration: 300,
      easing: Easing.out(Easing.ease),
    });
  };

  const navLinksStyle = useAnimatedStyle(() => ({
    opacity: animatedValue.value,
    transform: [
      {
        translateY: animatedValue.value * -30, // Adjust the output range for a smoother slide effect
      },
      {
        scale: animatedValue.value * 0.05 + 0.95, // Add a slight scaling effect
      },
    ],
  }));

  return (
    <Box flexDirection="row" marginTop={10} overflow="visible">
      <Pressable onPress={toggleNavLinks}>
        <Box
          marginRight={10}
          style={{width: 32, height: 32, borderRadius: '50%'}}>
          <Entypo name="menu" size={20} color={CCColors.Black} />
        </Box>
      </Pressable>
      {showNavLinks && (
        <Animated.View style={[styles.navLinksContainer, navLinksStyle]}>
          <Pressable
            onPress={() => {
              Linking.openURL('https://circlechess.com/');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Home
            </CCText>
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL('/');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Play & Learn
            </CCText>
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL(
                'https://api.whatsapp.com/send/?phone=919620006600&text=Hi/',
              );
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Chess Assistant
            </CCText>
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL('https://event.circlechess.com/');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Manage Events
            </CCText>
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL('https://circlechess.com/events/tournaments');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Tournaments
            </CCText>
          </Pressable>
          {/* <Pressable
            onPress={() => {
              Linking.openURL('https://circlechess.com/prebooking');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Prebooking
            </CCText>
          </Pressable> */}
          <Pressable
            onPress={() => {
              Linking.openURL('https://circlechess.com/blogs');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              Blog
            </CCText>
          </Pressable>
          <Pressable
            onPress={() => {
              Linking.openURL('https://circlechess.com/about');
            }}>
            <CCText
              fontSize={16}
              fontWeight="medium"
              style={styles.navLinkText}>
              About
            </CCText>
          </Pressable>
        </Animated.View>
      )}
    </Box>
  );
};

export default MenuItems;

const styles = StyleSheet.create({
  navLinksContainer: {
    position: 'absolute',
    top: 50,
    right: 0,
    backgroundColor: CCColors.Primary.lightBrown,
    zIndex: 10,
    padding: 20,
    borderRadius: 12,
    overflow: 'visible',
    width: 150, // Adjust width as needed
  },
  navLinkText: {
    whiteSpace: 'nowrap',
  },
});
