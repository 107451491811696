import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';

import React from 'react';
import {Platform} from 'react-native';
import {RFPercentage} from 'react-native-responsive-fontsize';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const TournamentRow = ({
  item,
  rowIndex,
  activeTab,
  handleRowPress,
  timeControlToSvgAndColor,
  isMobileOrTab,
}) => {
  const [durationStr, incrementStr] = item.time_control.split('+');
  const duration = parseInt(durationStr) * 60000; // Convert to ms
  const increment = parseInt(incrementStr) * 1000; // Convert to ms
  function classifyTimeControl(duration: number, increment: number) {
    const totalTime = (duration + increment) / 60000;

    if (totalTime < 3) {
      return 'Bullet';
    } else if (totalTime < 10) {
      return 'Bolt';
    } else if (totalTime < 30) {
      return 'Rapid';
    } else {
      return 'Snail';
    }
  }
  // Get category
  const category = classifyTimeControl(duration, increment);

  // Get corresponding SVG and color
  const {svg: SvgComponent, color: desiredColor} =
    timeControlToSvgAndColor[category];

  const startTime = new Date(item.start_date);
  const tournamentTime = startTime.toLocaleString('en-US', {
    weekday: 'short', // e.g., Mon
    month: 'short', // e.g., Jan
    day: 'numeric', // e.g., 21
    hour: 'numeric', // e.g., 10 PM
    minute: 'numeric', // e.g., 38
  });

  const now = new Date();
  const timeDifference = startTime - now; // Difference in milliseconds
  const diffHours = Math.floor(timeDifference / (1000 * 60 * 60));
  const diffMinutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
  );

  const [day, date, time] = tournamentTime.split(', ');

  // Create the "startingIn" string
  const startingIn =
    timeDifference > 0
      ? diffHours > 0
        ? `${diffHours} hrs ${diffMinutes} mins`
        : `${diffMinutes} mins`
      : item.is_active
      ? 'In progress'
      : 'Finished';

  return (
    <Box
      key={rowIndex}
      flexDirection="row"
      style={{width: '100%'}}
      onPress={() => handleRowPress(rowIndex)}>
      <Box
        style={{
          padding: vs(10),
          width: !isMobileOrTab ? '18%' : '24%',
          backgroundColor: CCColors.CCTableBg,
          borderRightColor: CCColors.BorderLight,
          borderRightWidth: 1,
        }}>
        <CCText
          color={CCColors.TextColor.LightGrey}
          fontWeight={600}
          fontSize={FontSizes[16]}>
          {time}
        </CCText>
        {activeTab !== 'upcoming' && (
          <CCText color={CCColors.TextColor.LightGrey} fontSize={FontSizes[10]}>
            {date}
          </CCText>
        )}
      </Box>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          paddingVertical: vs(15),
          paddingLeft: vs(10),
          width: '76%',
          borderBottomWidth: 1,
          borderBottomColor: CCColors.BorderLight,
        }}>
        <Box
          flexDirection="row"
          style={{paddingLeft: Platform.OS === 'web' && 10}}>
          <Box
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: 20,
              height: 32,
              width: 32,
              backgroundColor: desiredColor,
              marginRight: 10,
            }}>
            {SvgComponent}
          </Box>
          <Box flexDirection="column">
            <CCText
              color={CCColors.TextColor.LightGrey}
              fontWeight={600}
              fontSize={!isMobileOrTab ? FontSizes[18] : RFPercentage(1.6)}>
              {item.name}
            </CCText>
            <CCText
              color={CCColors.TextColor.LightGrey}
              fontSize={!isMobileOrTab ? FontSizes[12] : RFPercentage(1.6)}>
              {startingIn} | {item.rounds} rounds
            </CCText>
          </Box>
        </Box>

        <Box
          alignItems="flex-end"
          justifyContent="center"
          style={{paddingRight: Platform.OS === 'web' && 10}}>
          {activeTab === 'upcoming' ? (
            <Box
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: !isMobileOrTab ? '50%' : 20,
                height: !isMobileOrTab ? 32 : 24,
                width: !isMobileOrTab ? 32 : 24,
                backgroundColor: CCColors.Primary.Default,
              }}>
              <MaterialCommunityIcons
                color={CCColors.Primary.Dark}
                name={'plus'}
                size={!isMobileOrTab ? 20 : 18}
                style={{fontWeight: 'bold'}}
              />
            </Box>
          ) : (
            <Box
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: !isMobileOrTab ? '50%' : 20,
                height: !isMobileOrTab ? 32 : 24,
                width: !isMobileOrTab ? 32 : 24,
                backgroundColor: CCColors.Primary.Default,
              }}>
              <MaterialCommunityIcons
                color={CCColors.Primary.Dark}
                name={'grid-large'}
                size={!isMobileOrTab ? 20 : 16}
                style={{fontWeight: 'bold'}}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TournamentRow;
