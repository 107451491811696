import {
  Animated,
  BackHandler,
  Modal,
  NativeEventSubscription,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import React, {useEffect, useRef} from 'react';

import CCColors from '../../Utils/CCColors';
import LottieView from 'react-native-web-lottie';
import {FontSizes, WINDOW_HEIGHT, WINDOW_WIDTH} from '../../Utils/Dimensions';
import CCText from '../CCText';

interface LoaderProps {
  loading: boolean;
  fullScreen?: boolean;
  loadingIcon?: () => JSX.Element;
  bgStyle?: ViewStyle;
  bgWhite?: boolean;
  isComponentLoader?: boolean;
  textToShow?: string;
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const {
    loading,
    fullScreen = false,
    bgStyle,
    bgWhite = false,
    isComponentLoader = false,
    textToShow,
  } = props;

  const spinAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const backAction = () => {
      return true; // This blocks the back button
    };

    let backHandler: NativeEventSubscription | null = null;
    if (loading) {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    } else {
      if (backHandler) {
        // @ts-ignore
        backHandler?.remove();
      }
    }
    return () => backHandler?.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  if (isComponentLoader) {
    if (!loading) {
      return;
    }
    return (
      // <View
      //   style={[
      //     styles.modalBackground,
      //     bgStyle,
      //     bgWhite && {backgroundColor: CCColors?.White},
      //   ]}>
      //   <View style={styles.activityIndicatorWrapper}>
      <View
        style={{
          transform: [{perspective: 1000}],
          margin: 'auto',
          opacity: 0.8,
        }}>
        <LottieView
          source={require('../../Assets/Animation/chessboardLoader.json')}
          style={{width: WINDOW_WIDTH / 4, height: WINDOW_HEIGHT / 4}}
          autoPlay
          loop
        />
      </View>
      // </View>
      // </View>;
    );
  }
  return (
    <>
      {loading && (
        // <Modal transparent={true} animationType={'none'} visible={loading}></Modal>
        <View
          style={[
            styles.modalBackground,
            fullScreen ? styles.fullScreenLoader : styles.modalOverlay,
            bgStyle,
            bgWhite && {backgroundColor: CCColors?.White},
          ]}>
          <View style={styles.activityIndicatorWrapper}>
            <View style={{transform: [{perspective: 1000}]}}>
              <LottieView
                source={require('../../Assets/Animation/chessboardLoader.json')}
                style={{width: WINDOW_WIDTH / 4, height: WINDOW_HEIGHT / 4}}
                autoPlay
                loop
              />
              {textToShow && (
                <CCText
                  color={CCColors.White}
                  fontSize={FontSizes[16]}
                  textAlign="center"
                  // style={{whiteSpace: 'noWrap'}}
                >
                  {textToShow}
                  {/* Resetting account please wait */}
                </CCText>
              )}
            </View>
          </View>
        </View>
        // </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    // flex: 1,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#00000063',
    alignItems: 'center',
    // flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 9999,
    elevation: 9999,
  },
  modalOverlay: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  fullScreenLoader: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#00000063',
  },
  activityIndicatorWrapper: {
    backgroundColor: 'transparent',
    // height: 100,
    // width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 9999999999999,
  },
});

export default Loader;
