import React from 'react';
import Box from '@/Atoms/Box';
import {StyleSheet, Text, Image, ImageStyle, View} from 'react-native';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import bookImg from '../../../Assets/Images/png/book_dummy.png';
import chapter from '../../../Assets/Images/png/eye.png';
import position from '../../../Assets/Images/png/shopping-bag.png';
import {FontSizes, vs} from '@/Utils/Dimensions';
import fontWeightToFamily from '@/Assets/Fonts/fontWeightToFamily';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import ProgressBar from '@/Atoms/ProgressBar';
import {useNavigation} from '@react-navigation/native';
import CCText from '@/Atoms/CCText';

function generateColor(index, total = 5) {
  // Calculate the hue based on the index and total number of items
  const hue = (360 / total) * index;
  // Convert HSL color to hexadecimal
  const hexColor = `hsl(${hue}, 70%, 50%)`;
  return hexColor;
}

const BookCard: React.FC<{
  id: number;
  book: string;
  index: number;
  progress: number;
  chapters: number;
  positions: number;
  type?: string;
  book_price?: number;
  book_mrp?: number;
  paid?: boolean;
  userData?: Object;
  userId?: number;
  orderBook?: ({}) => void;
}> = ({
  id,
  book,
  index,
  progress,
  chapters,
  positions,
  type,
  book_price,
  book_mrp,
  paid,
  userData,
  userId,
  orderBook,
}) => {
  const navigation = useNavigation();
  return (
    <Box key={id} style={styles.container}>
      <View style={{position: 'relative'}}>
        <img style={styles.imgStyle} src={bookImg} alt="Book Image" />
        <View style={[styles.bookTitle, {backgroundColor: generateColor(id)}]}>
          <CCText
            fontSize={14}
            color={CCColors.White}
            style={{textTransform: 'capitalize'}}>
            {book?.replace(/_/g, ' ')}
          </CCText>
        </View>
      </View>
      <Text style={styles.title} numberOfLines={2}>
        {book?.replace(/_/g, ' ')}
      </Text>
      <Spacer spacing={vs(18)} />
      {progress === 0 ? (
        <Box type="row-center-between">
          <Box style={styles.details} type="row-center-space-around">
            <Box type="center" style={styles.icon}>
              <Image style={styles.iconImg as ImageStyle} source={chapter} />
            </Box>
            <View>
              <Text style={styles.count}>{chapters}</Text>
              <Text style={styles.content}>chapters</Text>
            </View>
          </Box>
          <Box style={styles.details} type="row-center-space-around">
            <Box type="center" style={styles.icon}>
              <Image style={styles.iconImg as ImageStyle} source={position} />
            </Box>
            <View>
              <Text style={styles.count}>{positions}</Text>
              <Text style={styles.content}>positions</Text>
            </View>
          </Box>
        </Box>
      ) : (
        <View>
          <Spacer spacing={vs(8)} />
          <ProgressBar
            style={{height: 6, backgroundColor: 'rgba(38, 35, 34, 0.1)'}}
            progress={progress}
            fillStyle={{backgroundColor: '#3DAB9E'}}
            text={undefined}
            icon={undefined}
          />
          <Spacer spacing={vs(12)} />
          <Text style={styles.content}>{progress * 100}% complete</Text>
        </View>
      )}
      <Spacer spacing={vs(32)} />
      {type === 'circlechess' && !paid ? (
        <Box type="row-center-between">
          <View>
            <Text style={styles.price}>₹{book_price}</Text>
            <Text style={styles.mrp}>₹{book_mrp}</Text>
          </View>
          <CCButton
            titleStyle={{color: CCColors.TextColor.Dark}}
            onPress={() =>
              orderBook({
                customer_id: userId,
                mobile_number: userData?.mobile_number,
                email: userData?.email,
                amount: book_price,
                type: 4,
                return_url: window?.location?.href || undefined,
                book_id: id,
              })
            }>
            Buy Now
          </CCButton>
        </Box>
      ) : (
        <CCButton
          style={{
            backgroundColor:
              progress > 0
                ? CCColors.Primary.lightBrown
                : CCColors.Primary.Default,
          }}
          onPress={() => {
            navigation.setParams({
              name: book?.toLowerCase()?.replace(/\s/g, '-'),
              bookKey: id,
            });
          }}
          titleStyle={{color: CCColors.TextColor.Dark}}>
          {progress === 1
            ? 'Start Again'
            : progress > 0
            ? 'Resume'
            : 'Start Learning'}
        </CCButton>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 300,
    backgroundColor: CCColors.White,
    padding: 20,
    borderRadius: 8,
    border: `1px solid ${CCColors.Border}`,
  },
  bookTitle: {
    position: 'absolute',
    top: 20,
    height: 162,
    padding: 10,
    left: 68,
    width: 115,
  },
  imgStyle: {
    objectFit: 'contain',
    height: 220,
  },
  title: {
    fontSize: FontSizes[16],
    fontFamily: fontWeightToFamily('regular'),
    fontWeight: '500',
    color: CCColors.TextColor.Dark,
    textTransform: 'capitalize',
    lineHeight: 25,
    height: 50,
    justifyContent: 'center', // Center the text vertically
  },
  icon: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: CCColors.Primary.lightBrown,
  },
  iconImg: {
    width: 16,
    height: 16,
  },
  count: {
    fontSize: FontSizes[14],
    fontFamily: fontWeightToFamily('semibold'),
    color: CCColors.TextColor.Primary,
  },
  content: {
    fontSize: FontSizes[12],
    fontFamily: fontWeightToFamily('regular'),
    color: CCColors.TextColor.Primary,
  },
  details: {
    width: '50%',
  },
  price: {
    fontSize: FontSizes[14],
    fontFamily: fontWeightToFamily('semibold'),
    color: CCColors.TextColor.Primary,
  },
  mrp: {
    fontSize: FontSizes[12],
    fontFamily: fontWeightToFamily('regular'),
    color: CCColors.TextColor.Grey,
    textDecorationLine: 'line-through',
  },
});
export default BookCard;
