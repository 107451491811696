import Box from '@/Atoms/Box';
import PlayerComponent from '../PlayerComponent';
import {
  vs,
  isPhone,
  FontSizes,
  SCREEN_WIDTH,
} from '../../../../Utils/Dimensions';
import CCText from '@/Atoms/CCText';
import {ScrollView, TouchableOpacity} from 'react-native';
import {View} from 'react-native';
import React, {useRef, useEffect, useState} from 'react';
import MovesList from '@/Atoms/MovesList';
import customStyles from '@/Screens/ChessAnalysis/component/AnalysisRightComponent/styles.ts';
import {Chess} from 'chess.js';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import Icon from 'react-native-vector-icons/MaterialIcons'; // Import the icons

import {Image} from 'react-native';
import blunderImg from '@/Assets/Images/Svgs/learning/blunder-icon.svg';
import inaccuracy from '@/Assets/Images/Svgs/learning/inaccuracy-icon.svg';
import mistake from '@/Assets/Images/Svgs/learning/mistakes-icon.svg';
import {STARTING_FEN} from '@/Utils/CommonUtils';

const moveIconsAndColors = {
  Inaccuracy: {
    icon: <Image source={inaccuracy} style={{width: 20, height: 15}} />,
    color: '#FFA500',
  },
  Mistake: {
    icon: <Image source={mistake} style={{width: 20, height: 15}} />,
    color: '#FF0000',
  },
  Miss: {
    icon: 'X',
    color: '#FF0000',
  },
  Blunder: {
    icon: <Image source={blunderImg} style={{width: 20, height: 15}} />,
    color: '#FF0000',
  },
};

function formatTime(seconds) {
  if (seconds || seconds == 0) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

function NotationComponent(props) {
  const {
    moves = [],
    opponentTime,
    myTime,
    username,
    displayName,
    opponent,
    whitePieces,
    blackPieces,
    setPosition,
    white,
    firstMoveTimer,
    showFirstMoveTimer,
    setMoveClicked,
    setMoveSelected,
    startfen = STARTING_FEN,
    showPlayerComponent = true,
    setPreviousFen,
    myRating,
    opponentRating,
    myTurn,
    playing,
    setHint,
    isBlackTurnFirst,
    squareWidth,
    marginLeft,
    marginLeftUsername,
    usernameFont,
    position,
    setHint2,
    setShowIncorrectHintModal,
    illegalTouchPieceCount,
    showMoveMessage = false,
    initialMoveCount = 0,
  } = props;

  const [selectedKey, setSelectedKey] = useState(-1);
  const firstPressRef = useRef(true);

  console.log('moves', moves);

  const commonBoxStyle = {
    // justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    borderBlockColor: '#2623221A',
    borderLeftColor: '#2623221A',
    borderRightColor: '#2623221A',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    height: vs(42),
    flexDirection: 'row',
    alignItems: 'center',
    // backgroundColor: 'yellow',
  };

  const selectedBoxStyle = {
    backgroundColor: '#D9E4DD', // Highlight color for the selected box
  };

  const createNotationRow = (whiteMove, blackMove, key) => {
    const whiteMoveIcon =
      whiteMove.matched && whiteMove.isHumanMove ? (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: 20,
            height: 20,
            borderRadius: 5,
            backgroundColor: CCColors.Green,
          }}>
          <Icon name="check" size={16} color={CCColors.White} />
        </Box>
      ) : whiteMove.isHumanMove ? (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: 20,
            height: 20,
            borderRadius: 5,
            backgroundColor: CCColors.Red,
          }}>
          <Icon name="close" size={20} color={CCColors.White} />
        </Box>
      ) : null;
    const blackMoveIcon =
      blackMove.matched && blackMove.isHumanMove ? (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: 20,
            height: 20,
            borderRadius: 5,
            backgroundColor: CCColors.Green,
          }}>
          <Icon name="check" size={16} color={CCColors.White} />
        </Box>
      ) : blackMove.isHumanMove ? (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: 20,
            height: 20,
            borderRadius: 5,
            backgroundColor: CCColors.Red,
          }}>
          <Icon name="close" size={20} color={CCColors.White} />
        </Box>
      ) : null;

    return (
      <Box
        key={key}
        style={{width: '100%', height: vs(42)}}
        flexDirection="row">
        <Box
          style={[commonBoxStyle, selectedKey === key && selectedBoxStyle]}
          flex={1}
          gap={10}
          justifyContent="space-between"
          flexDirection="row"
          onPress={() => handlePress(key)}>
          <CCText
            style={{textAlign: 'left', marginLeft: 18}}
            fontSize={FontSizes[12]}>
            {whiteMove.move} {moveIconsAndColors?.[whiteMove.mistake]?.icon}
          </CCText>
          <Box marginRight={20}>{whiteMoveIcon}</Box>
          {whiteMove.hint && (
            <CCText
              fontSize={14}
              fontWeight="medium"
              style={{
                textAlign: 'Right',
                marginLeft: 18,
                alignSelf: 'flex-end',
                backgroundColor: '#FAF6EB', // Light blue highlight
                borderRadius: 4, // Rounded edges
                padding: 2, // Add some padding
              }}>
              See hint...
            </CCText>
          )}
        </Box>
        <Box
          justifyContent="space-between"
          flexDirection="row"
          style={[commonBoxStyle, selectedKey - 1 === key && selectedBoxStyle]}
          flex={1}
          gap={10}
          onPress={() => handlePress(key + 1)}>
          <CCText
            style={{textAlign: 'left', marginLeft: 18}}
            fontSize={FontSizes[12]}>
            {blackMove.move} {moveIconsAndColors?.[blackMove.mistake]?.icon}
          </CCText>
          <ConditionalRender
            condition={blackMove.move}
            childrenA={<Box marginRight={20}>{blackMoveIcon}</Box>}
          />
          {blackMove.hint && (
            <CCText
              fontSize={14}
              fontWeight="medium"
              style={{
                textAlign: 'Right',
                marginLeft: 18,
                alignSelf: 'flex-end',
                backgroundColor: '#FAF6EB', // Light blue highlight
                borderRadius: 4, // Rounded edges
                padding: 2, // Add some padding
              }}>
              See hint...
            </CCText>
          )}
        </Box>
      </Box>
    );
  };
  useEffect(() => {
    const handleKeyDown = event => {
      if (firstPressRef.current) {
        // First key press, set to moves.length - 1
        setSelectedKey(moves.length - 1);
        handlePress(moves.length - 1);
        firstPressRef.current = false; // Disable the first press logic
        return; // Exit to avoid running the arrow logic on the first press
      }

      if (event.key === 'ArrowRight') {
        setSelectedKey(prevKey => {
          const nextKey = prevKey + 1;
          if (nextKey < moves.length) {
            // Ensure it doesn't go out of bounds
            handlePress(nextKey);
            return nextKey;
          }
          return prevKey;
        });
      } else if (event.key === 'ArrowLeft') {
        setSelectedKey(key => {
          const prevKey = key - 1;
          if (prevKey > -1) {
            handlePress(prevKey);
            return prevKey;
          }
          return key;
        });
      }
    };

    // Attach event listener
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [moves]);

  // In the handlePress function, update the move processing to handle SAN (Standard Algebraic Notation) properly
  // In the handlePress function, update the move processing to handle SAN (Standard Algebraic Notation) properly
  const handlePress = moveIndex => {
    setMoveClicked(true);
    setSelectedKey(moveIndex);
    const movesUntilThisMove = isBlackTurnFirst
      ? moves.slice(1, moveIndex + 1)
      : moves.slice(0, moveIndex + 1); // Get moves up to this move

    const board = new Chess(startfen); // Create a new Chess instance
    let lastMoveFen;

    // Replay moves up to this move with improved error handling
    movesUntilThisMove.forEach(({move}) => {
      try {
        // Clean up the move notation
        let cleanMove = move;

        // Handle annotations and comments in parentheses
        if (move.includes('(')) {
          cleanMove = move.split('(')[0].trim();
        }

        // Handle other annotations
        cleanMove = cleanMove.replace(/[!?+#]/g, '');

        // Store the position before making the move
        lastMoveFen = board.fen();

        // Try to make the move using chess.js
        const moveResult = board.move(cleanMove, {sloppy: true});

        // If move is invalid, try alternative approaches
        if (!moveResult) {
          console.log(`Failed to make move: ${cleanMove}`);

          // For captures like "exd5", try alternative formats
          if (cleanMove.includes('x')) {
            const parts = cleanMove.match(/([a-h]?)x([a-h][1-8])/);
            if (parts) {
              const [_, fromFile, toSquare] = parts;

              // Find valid moves that could match this capture
              const validMoves = board.moves({verbose: true});
              const matchingMove = validMoves.find(
                m =>
                  m.to === toSquare &&
                  (fromFile ? m.from.startsWith(fromFile) : true) &&
                  m.captured,
              );

              if (matchingMove) {
                board.move(matchingMove);
              }
            }
          }
        }
        // Update the selected move info with the correct structure
        setMoveSelected({
          fen: board.fen(),
          lastFen: lastMoveFen || '',
          selectedMoveNumber: movesUntilThisMove.length,
        });
      } catch (error) {
        console.error(`Error playing move ${move}:`, error);
        // Continue with next move if possible
      }
    });

    // Get the FEN position after replaying moves
    const fenPosition = board?.fen();
    console.log('FEN position:', fenPosition);
    setPosition?.(fenPosition);
    setPreviousFen?.(lastMoveFen);
    setHint?.(moves?.[moveIndex]?.hint);
    setHint2?.(moves?.[moveIndex]?.hint2);
    setShowIncorrectHintModal?.(true);
  };

  function processPairs(arr, callback) {
    const results = [];
    for (let i = 0; i < arr.length; i += 2) {
      console.log('Processing move ..', i);
      if (i + 2 <= arr.length) {
        const pair = arr.slice(i, i + 2);
        console.log('processPairs', pair);
        results.push(callback(pair[0], pair[1], i));
      } else {
        console.log('Processing last isolated move ..');
        const pair = arr.slice(i, i + 1);
        results.push(callback(pair[0], {move: '', matched: false}, i));
      }
    }
    return results;
  }

  function generateMoveNumbers() {
    const results = [];
    for (let i = 0; i < moves.length / 2; i++) {
      results.push(
        <Box
          flexDirection="row"
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            padding: 2,
            height: vs(42),
          }}>
          <CCText style={{margin: 'auto'}}>{i + 1}</CCText>
        </Box>,
      );
    }

    return results;
  }

  const uiElements = processPairs(moves, createNotationRow);
  const moveNumbers = generateMoveNumbers();

  const scrollViewRef = useRef();

  // Function to scroll to the bottom of the ScrollView
  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({animated: true});
    }
    console.log('Scroll to bottom');
  };

  // Automatically scroll to bottom when component mounts or when new content is added
  useEffect(() => {
    scrollToBottom();
    setSelectedKey(-1);
    firstPressRef.current = true;
    setMoveClicked(false);
  }, [myTurn]);

  useEffect(() => {
    if (illegalTouchPieceCount) {
      setSelectedKey(moves.length - 1);
      handlePress(moves.length - 1);
    }
  }, [illegalTouchPieceCount]);

  function createGameMoves(moves) {
    const gameMoves = [];
    for (let i = 0; i < moves.length; i++) {
      gameMoves.push({move: moves[i].move});
    }

    return gameMoves;
  }

  const gameMoves = createGameMoves(moves);

  return (
    <Box
      // flex={8}
      flexDirection="column"
      style={{
        width: '100%',
        overflow: 'hidden',
        flex: 1,
        // , aspectRatio: 1
      }}>
      <ConditionalRender
        condition={showPlayerComponent}
        childrenA={
          <PlayerComponent
            flex={0.15}
            color={white == username ? 'black' : 'white'}
            username={opponent}
            timer={opponentTime}
            whitePieces={whitePieces}
            blackPieces={blackPieces}
            firstMoveTimer={firstMoveTimer}
            showFirstMoveTimer={
              !showFirstMoveTimer && moves.length < 2 && opponentTime && playing
            }
            rating={opponentRating}
            myTurn={!myTurn}
            playing={playing}
            marginLeftUsername={marginLeftUsername}
            usernameFont={usernameFont}
            squareWidth={squareWidth}
            marginLeft={marginLeft}
          />
        }
      />
      {showMoveMessage && moves.length < 21 && (
        <Box
          style={{
            padding: isPhone ? 10 : 20,
            borderTopWidth: isPhone ? 1 : 0,
            borderTopColor: CCColors?.BorderBottom,
          }}>
          <CCText
            color={CCColors?.TextColor?.Dark}
            fontSize={vs[14]}
            style={{lineHeight: 20, fontWeight: '400'}}>
            We've made {initialMoveCount} moves for you, start playing from the 11th move.
          </CCText>
        </Box>
      )}
      <Box flex={8} style={{marginTop: 0, marginBottom: 0}} flexDirection="row">
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{flex: 1, maxHeight: 550}}
          ref={scrollViewRef}
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'space-between',
          }}>
          <Box
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            style={{flexGrow: 1, width: '90%', position: position}}>
            <Box
              flex={1}
              style={{height: '100%', padding: 4, alignContent: 'center'}}>
              {moveNumbers}
            </Box>
            <Box
              flex={9}
              style={{
                borderBlockColor: '#2623221A',
                borderLeftColor: '#2623221A',
                borderRightColor: '#2623221A',
                borderWidth: 1,
                backgroundColor: 'white',
                height: '100%',
                width: '90%',
                borderRadius: 8,
              }}>
              {uiElements}
            </Box>
          </Box>
        </ScrollView>
      </Box>
      <ConditionalRender
        condition={showPlayerComponent}
        childrenA={
          <PlayerComponent
            flex={0.15}
            color={white == username ? 'white' : 'black'}
            username={displayName}
            timer={myTime}
            whitePieces={whitePieces}
            blackPieces={blackPieces}
            firstMoveTimer={firstMoveTimer}
            showFirstMoveTimer={showFirstMoveTimer}
            rating={myRating}
            myTurn={myTurn}
            playing={playing}
            marginLeftUsername={marginLeftUsername}
            usernameFont={usernameFont}
            squareWidth={squareWidth}
            marginLeft={marginLeft}
          />
        }
      />
    </Box>
  );
}

export default NotationComponent;
