import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, TextInput, TouchableOpacity, View} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {styles} from './styles';
import CCColors from '@/Utils/CCColors';
import CCButton from '@/Atoms/CCButton';
import CCLoader from '@/Atoms/CCLoader';
import Spacer from '@/Atoms/Spacer';
import CCCheckbox from '@/Atoms/CCCheckbox';
interface DropdownItem {
  label: string;
  value: string;
}
interface CCSelectProps {
  showModal?: boolean;
  selectedValues?: string[];
  onValueChange: (values: string[]) => void;
  items: DropdownItem[];
  loading?: boolean;
  multiSelect?: boolean;
  onPressClose?: () => void;
  label: string;
}
const DropdownModal: React.FC<CCSelectProps> = ({
  onPressClose,
  loading,
  selectedValues = [],
  showModal = false,
  onValueChange,
  items = [],
  multiSelect = false,
  label,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSelectedValues, setTempSelectedValues] =
    useState<string[]>(selectedValues);
  const clearOptions = () => {
    onValueChange([]);
    setTempSelectedValues([]);
    onPressClose?.();
  };
  useEffect(() => {
    setTempSelectedValues(selectedValues);
  }, [selectedValues]);
  const handleValueChange = (value: string) => {
    if (multiSelect) {
      const newValue = tempSelectedValues.includes(value)
        ? tempSelectedValues.filter(item => item !== value)
        : [...tempSelectedValues, value];
      setTempSelectedValues(newValue);
      onValueChange(newValue);
    } else {
      if (showModal) {
        setTempSelectedValues([value]);
      } else {
        onValueChange([value]);
        onPressClose?.();
      }
    }
  };
  const filteredItems = items.filter(item =>
    item?.label?.toLowerCase().includes(searchQuery?.toLowerCase()),
  );
  return (
    <View style={styles.dropdownContentContainer}>
      <Box flexDirection="row" justifyContent="space-between">
        <Box>
          <CCText fontWeight={500} fontSize={24}>
            {label}
          </CCText>
        </Box>
        <Box gap={10} flexDirection="row">
          <Box
            onPress={clearOptions}
            justifyContent="center"
            alignItems="center">
            <CCText fontSize={14} color={CCColors.BlueAqua}>
              Clear All
            </CCText>
          </Box>
          <Box
            onPress={onPressClose}
            style={{
              width: 36,
              height: 36,
              backgroundColor: CCColors.Primary.lightBrown,
              borderRadius: '50%',
              position: 'relative',
            }}>
            <MaterialCommunityIcons
              name={'close'}
              color={CCColors.Black}
              size={20}
              style={{
                position: 'absolute',
                top: '50%',
                right: '50%',
                transform: [{translateX: '50%'}, {translateY: '-50%'}],
              }}
            />
          </Box>
        </Box>
      </Box>
      <TextInput
        style={styles.searchInput}
        placeholder="Search..."
        value={searchQuery}
        onChangeText={setSearchQuery}
      />
      <CCLoader loading={loading} isComponentLoader />
      <View style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>
          {filteredItems.map((item, index) => {
            const match = item.label.match(/(.*)\((\d+)\)$/); // Matches "Vienna Game(12)"
            const name = match ? match[1].trim() : item.label; // "Vienna Game"
            const number = match ? match[2] : ''; // "12"
            return (
              <TouchableOpacity
                key={index}
                style={styles.dropdownItem}
                onPress={() => handleValueChange(item.value)}>
                {multiSelect && (
                  <CCCheckbox
                    value={tempSelectedValues.includes(item.value)}
                    onValueChange={() => handleValueChange(item.value)}
                  />
                )}
                <Box
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{width: '90%'}}>
                  <CCText>{name}</CCText>
                  {number && <CCText>{number}</CCText>}
                </Box>
                {/* <CCText>{item.label}</CCText> */}
              </TouchableOpacity>
            );
          })}
          <View
            style={{
              paddingTop: 20,
              paddingBottom: 40,
              marginBottom: 15,
            }}>
            <CCButton
              contentContainerStyle={{
                paddingVertical: 10,
              }}
              onPress={onPressClose}>
              <CCText fontSize={14} color={CCColors.Black}>
                Apply
              </CCText>
            </CCButton>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
export default DropdownModal;
