import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import SlideModal from '../../../Atoms/SlideModal';
import CCButton from '../../../Atoms/CCButton';
import {FontSizes, vs} from '../../../Utils/Dimensions';
import {AccordionSection} from '@/Atoms/CCAccordion';
import Spacer from '@/Atoms/Spacer';
import Box from '@/Atoms/Box';
import Feather from '@expo/vector-icons/Feather';
import CCColors from '@/Utils/CCColors';
import CCText from '@/Atoms/CCText';




// Static filter values (mocked based on the image)
const allFilters = {
  tournament_status: ['Upcoming', 'Ongoing', 'Completed', 'Daily', 'Watch'],
  tournament_category: ['Bullet', 'Blitz', 'Rapid', 'Classic'],
  starting_in: ['30 min', '1 hr', '2 hr', '3 hr', '1 day'],
  rating: ['Open', '>1200', '>1400', '>1600', '>1800', '>2000 to above'],
  tournament_type: ['Swiss', 'Arena'],
};

const TournamentFiltersModal = ({ openFilter, setOpenFilter }) => {
  const [filtersSelected, setFiltersSelected] = useState({});

  // Function to render filter options
  const renderFilterOptions = (filterName) => {
    return allFilters[filterName]?.map((filterValue, index) => (
      <View key={index} style={styles.filterOption}>
        <CCText
          style={[
            styles.filterText,
            filtersSelected[filterName]?.includes(filterValue) && styles.selectedText,
          ]}
          onPress={() => toggleFilterSelection(filterName, filterValue)}
        >
          {filterValue}
        </CCText>
      </View>
    ));
  };

  // Function to handle filter selection
  const toggleFilterSelection = (filterName, filterValue) => {
    setFiltersSelected((prev) => {
      const currentSelection = prev[filterName] || [];
      const isSelected = currentSelection.includes(filterValue);

      return {
        ...prev,
        [filterName]: isSelected
          ? currentSelection.filter((val) => val !== filterValue) // Remove if selected
          : [...currentSelection, filterValue], // Add if not selected
      };
    });
  };

  // Handle "Apply" button press
  const handleApplyFilter = () => {
    console.log('Applied Filters:', filtersSelected);
    setOpenFilter(false);
  };

  return (
    <SlideModal
      type="side"
      modalHeaderTitle="Filters"
      visible={openFilter}
      onClose={() => setOpenFilter(false)}
    >
      <View style={styles.modalContent}>
        {Object.keys(allFilters).map((filterName) => (
          <AccordionSection
            key={filterName}
            title={filterName.replace(/_/g, ' ')}
            selectedCount={filtersSelected[filterName]?.length || 0}
            renderContent={() => renderFilterOptions(filterName)}
          />
        ))}

        <Spacer spacing={40} />

        <CCButton
          onPress={handleApplyFilter}
          type="outline"
          style={styles.applyButton}
        >
          <CCText fontSize={FontSizes[12]}>Apply</CCText>
        </CCButton>
      </View>
    </SlideModal>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    paddingHorizontal: vs(20),
    maxHeight: 400,
  },
  filterOption: {
    paddingVertical: 8,
  },
  filterText: {
    fontSize: FontSizes[12],
    color: '#555',
  },
  selectedText: {
    fontWeight: 'bold',
    color: '#000',
  },
  applyButton: {
    width: '20%',
    margin: 'auto',
    marginBottom: 20,
  },
});

export default TournamentFiltersModal;
