import React from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import CCText from '@/Atoms/CCText';

interface TournamentDetailsProps {
  tournamentId: number;
  details: {
    timeControl: string;
    tournamentDuration: string;
    ratedGames: string;
    completedGames: number;
    rounds: number;
    players: string;
    players_joined: Array<{
      username: string;
      rating: number;
    }>;
  };
}

const TournamentDetails: React.FC<TournamentDetailsProps> = ({ tournamentId, details }) => {
  // Tournament rules
  const rules = [
    'All players must use the official tournament platform for their matches.',
    'Each game has a time control of 10 minutes per player, with a 5-second increment per move.',
    'Any form of external assistance, including chess engines, is strictly prohibited.',
    'Players must join the game lobby 5 minutes before their match starts.',
    'Disconnecting for more than 3 minutes results in an automatic forfeit.',
  ];

  return (
    <View style={styles.container}>
      {/* Details Section */}
      <View style={styles.detailsContainer}>
        <View style={styles.column}>
          <View style={styles.detailItem}>
            <CCText style={styles.label}>⏱ Time Control  </CCText>
            <CCText style={styles.value}>{details.timeControl}</CCText>
          </View>
          <View style={styles.detailItem}>
            <CCText style={styles.label}>✅ Rated Games  </CCText>
            <CCText style={styles.value}>{details.ratedGames}</CCText>
          </View>
        </View>
        
        <View style={styles.column}>
          <View style={styles.detailItem}>
            <CCText style={styles.label}>📊 Rounds  </CCText>
            <CCText style={styles.value}>{details.rounds}</CCText>
          </View>
          <View style={styles.detailItem}>
            <CCText style={styles.label}>👥 Players  </CCText>
            <CCText style={styles.value}>{details.players}</CCText>
          </View>
        </View>
      </View>


      {/* Rules Section */}
     

      {/* Players Joined Section */}
      <View style={styles.playersContainer}>
        <CCText style={styles.playersHeader}>Players Joined</CCText>
        {details.players_joined?.length > 0 ? (
          <FlatList
            data={details.players_joined}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => (
              <View style={styles.playerItem}>
                <CCText style={styles.playerName}>{item.username} </CCText>
                <CCText style={styles.playerRating}>Rating: {item.rating}</CCText>
              </View>
            )}
          />
        ) : (
          <CCText style={styles.noPlayers}>No players have joined yet.</CCText>
        )}
      </View>

      <View style={styles.rulesContainer}>
        <CCText style={styles.rulesHeader}>Tournament Rules</CCText>
        {rules.map((rule, index) => (
          <CCText key={index} style={styles.ruleItem}>
            • {rule}
          </CCText>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    // backgroundColor: '#f9f9f9',
    flex: 1,
  },
  // detailsContainer: {
  //   backgroundColor: '#fff',
  //   borderRadius: 8,
  //   padding: 16,
  //   marginBottom: 16,
  //   shadowColor: '#000',
  //   shadowOpacity: 0.1,
  //   shadowRadius: 4,
  //   shadowOffset: { width: 0, height: 2 },
  //   elevation: 2,
  // },
  // detailItem: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   marginBottom: 8,
  // },
  // label: {
  //   fontSize: 14,
  //   color: '#555',
  // },
  // value: {
  //   fontSize: 14,
  //   fontWeight: 'bold',
  //   color: '#333',
  // },
  rulesContainer: {
    backgroundColor: '#fff',
    // borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    // shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    elevation: 2,
  },
  rulesHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#333',
  },
  ruleItem: {
    fontSize: 14,
    color: '#555',
    marginBottom: 4,
  },
  playersContainer: {
    backgroundColor: '#fff',
    // borderRadius: 8,
    padding: 16,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    elevation: 2,
    overflow: 'scroll'
  },
  playersHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#333',
  },
  playerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  playerName: {
    fontSize: 14,
    color: '#555',
  },
  playerRating: {
    fontSize: 14,
    color: '#777',
  },
  noPlayers: {
    fontSize: 14,
    color: '#999',
    textAlign: 'center',
    marginTop: 16,
  },
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    // shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    gap: 16, // Adjust the spacing between columns
  },
  column: {
    flex: 1,
  },
  detailItem: {
    marginBottom: 10, 
    flexDirection: 'row'
  },
  label: {
    fontWeight: 'bold',
    alignSelf: 'center'
  },
  value: {
    fontSize: 16,
    alignSelf: 'center'
  },
});

export default TournamentDetails;
