import CCColors from '@/Utils/CCColors';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  // container: {flexDirection: 'row'},
  // left: {flex: 0.2, padding: 20},
  // center: {flex: 0.5},
  // right: {flex: 0.3, padding: 10},
  addNewBox: {
    backgroundColor: CCColors.Primary.Brown,
    padding: 10,
    borderRadius: 8,
  },
  stickyButtonWrapper: {
    padding: 10,
    backgroundColor: CCColors?.White,
    borderTopWidth: 1,
    borderTopColor: CCColors?.BorderBottom,
  },
  // participantsName: {
  //   padding: 10,
  //   elevation: 5,
  //   marginVertical: 5,
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  // },
  // selectedName: {
  //   backgroundColor: CCColors.Primary.lightBrown,
  //   borderRadius: 8,
  //   elevation: 5,
  // },
});
