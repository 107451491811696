import {FontSizes, hs, isPhone, vs} from '../../Utils/Dimensions';

import CCColors from '../../Utils/CCColors';
import {StyleSheet} from 'react-native';
import {Colors} from '@/styles';
import {DEFAULT_HEIGHT} from '@/Atoms/CCTextInput/CCTextInput';

export const styles = StyleSheet.create({
  // container: {
  //   flexDirection: 'row',
  // },
  levelModalCustom: {
    borderRadius: 12,
    backgroundColor: 'white',
    zIndex: 5,
    overflowY: 'hidden',
    minWidth: '20%',
    margin: 'auto',
  },
  mobileContainer: {
    flexDirection: 'column',
    overflow: 'hidden',
  },
  infoContainer: {
    flex: 0.2,
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  infoContainerMobile: {
    flex: 1,
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
  modalContainer: {
    padding: 20,
    paddingHorizontal: 'auto',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#FAF6EB',
  },
  modalNewContainer: {
    paddingHorizontal: 'auto',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: '#FAF6EB',
  },
  icon: {
    width: 26,
    height: 26,
    borderRadius: '50%',
    backgroundColor: CCColors.White,
  },
  iconct: {
    width: 36,
    height: 36,
    borderRadius: '50%',
    backgroundColor: CCColors.Primary.lightBrown,
  },
  infoWebContainer: {
    // width: '100%',
    flex: 0.25,
    // width: '25%',

    // paddingHorizontal: isPhone ? 5 : 32,
    // paddingVertical: isPhone ? 5 : 24,
    // height: '90vh',
    // overflow: 'scroll',
    // flex: 1,
  },
  chessboardContainer: {
    // flex: isPhone ? 1 : 0.55,
    backgroundColor: '#F5EDD3',
  },
  chessboardContainerWebBooks: {
    flex: 0.55,
    flexBasis: '1%',
    // flex: isPhone ? 1 : 0.55,
    backgroundColor: '#F5EDD3',
  },
  chessboardContainerWebBooksForEvaluate: {
    marginLeft: '5%',
    marginTop: '2%',
    flex: 0.38,
    flexBasis: '1%',
    // flex: isPhone ? 1 : 0.55,
    backgroundColor: '#F5EDD3',
  },
  chessboardWebContainer: {
    // flex: isPhone ? 1 : 0.55,
    // flex: 1,
    backgroundColor: '#F5EDD3',
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 0,
    // gap: 10,
    overflow: 'hidden',
  },
  puzzleSolutionText: {
    // textAlign: 'center',
    margin: 0,
    fontSize: isPhone ? 12 : 15,
    fontWeight: '500',
  },
  puzzleSolutionNext: {
    // textAlign: 'center',
    margin: 0,
    fontSize: 14,
  },
  puzzleSolutionTextMobile: {
    padding: 8,
    // textAlign: 'center',
    margin: 0,
    fontSize: 10,
    fontWeight: '500',
  },
  boxTabBarLabel: {
    color: CCColors?.White,
    paddingBottom: 20,
    marginBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    // borderWidth: StyleSheet.hairlineWidth,
  },
  boxIndicator: {
    height: '0%',
    marginBottom: 20,
  },
  row: {
    borderBottomWidth: 1,
    // borderBottomColor: CCColors?.Primary,
    // borderWidth: 0,

    minWidth: 200,
  },
  expandedRow: {
    backgroundColor: Colors.primaryLight,
  },
  hintIcon: {width: 24, height: 24, marginHorizontal: 10},
  details: {width: '100%', paddingLeft: 32},
  divider: {
    borderBottomColor: CCColors.BorderBottom,
    borderBottomWidth: 1,
  },
  view: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  checkIcon: {
    position: 'absolute',
    top: -10,
    right: 0.5,
    zIndex: 1,
    borderRadius: '50%',
    height: 16,
    width: 16,
    backgroundColor: CCColors.White,
  },
  inaccuracyText: {
    borderRadius: 10,
    width: 40,
    paddingVertical: 2,
    marginTop: 5,
  },
  tick: {
    borderRadius: '50%',
    backgroundColor: CCColors.White,
    width: '100%',
    height: '100%',
  },
  whiteBox: {
    backgroundColor: CCColors?.White,
    padding: 15,
    borderRadius: 8,
    marginBottom: isPhone ? 10 : 20,
    shadowColor: CCColors?.Black,
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 4,
  },
  progressIndicatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: isPhone ? 0 : 20,
  },
  progressDot: {
    width: 18,
    height: 6,
    borderRadius: 6,
    marginHorizontal: 5,
  },
  boxTabBarLabelMobile: {
    color: CCColors?.White,
  },
  boxLeftBorder: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  boxRightBorder: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  boxTabBar: {
    backgroundColor: CCColors?.White,
    width: '60%',
    // margin: 10,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: CCColors.Grey.Border,
    paddingHorizontal: 0,
    borderRadius: 8,
  },
  dropdown: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    backgroundColor: 'white',
    height: DEFAULT_HEIGHT,
    borderColor: CCColors.BorderLight,
  },
  dropdownDesktop: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    backgroundColor: 'white',
    borderColor: CCColors.BorderLight,
  },
});

export const infoStyles = StyleSheet.create({
  title: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
  },
  playColor: {
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 18,
    color: CCColors.TextColor.Grey,
  },
  status: {
    backgroundColor: CCColors.Primary.Light,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 100,
    textAlign: 'center',
    marginVertical: 24,
  },
  statsContainer: {
    borderTopColor: CCColors.Border,
    borderTopWidth: 1,
    paddingVertical: 16,
  },
  stat: {
    // display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  movesLog: {},
  movesLogBox: {
    backgroundColor: CCColors?.Primary?.lightYellow,
    borderRadius: 5,
    padding: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  statIcon: {
    marginRight: 8,
    // paddingTop: 1,
    color: CCColors.TextColor.Grey,
  },
  selectedMove: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 8,
    color: CCColors?.Primary?.Brown,
    borderBottomColor: CCColors?.Primary?.Brown,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    width: 'fit-content',
  },
  statText: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 18,
    width: 'fit-content',
    marginBottom: 8,
  },
  gameDetails: {
    fontWeight: '500',
    fontSize: 14,
    // lineHeight: 24,
    // marginBottom: 8,
  },
});

export const filterStyles = StyleSheet.create({
  puzzleContainer: {
    backgroundColor: CCColors.Primary.Light,
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 24,
    // overflow: 'scroll',
  },
  puzzleIncorrectContainer: {
    backgroundColor: CCColors.Primary.Light,
    width: '100%',
    paddingHorizontal: 32,
    paddingVertical: 24,
    overflow: 'scroll',
    height: '95vh',
  },
  puzzleNextContainer: {
    backgroundColor: CCColors.Primary.Light,
    width: '100%',
    paddingHorizontal: 32,
    paddingVertical: 24,
    paddingBottom: 24,
    paddingTop: 0,
    overflow: 'scroll',
    height: '100vh',
  },
  containerStock: {
    backgroundColor: CCColors.Primary.Light,
    flex: 0.35,
    paddingHorizontal: 32,
    overflow: 'auto',
    height: '90vh',
  },
  switchBox: {
    // padding: 1,
    // borderWidth: 1,
    borderRadius: 8,
    borderColor: 'transparent',
    borderCurve: 'circular',
    marginTop: 5,
  },
  container: {
    backgroundColor: CCColors.Primary.Light,
    flex: 0.35,
    paddingHorizontal: 32,
    paddingVertical: 24,
    overflow: 'auto',
    height: '90vh',
  },

  containerMobile: {
    backgroundColor: CCColors.Primary.Light,
    paddingHorizontal: 32,

    overflow: 'auto',
    height: '90vh',
  },
  containerHeading: {
    marginBottom: 10,
    marginTop: 10,
    fontSize: isPhone ? 16 : 24,
    fontWeight: '500',
  },
  groupHeading: {
    fontWeight: '500',
    marginBottom: 12,
    fontSize: FontSizes[16],
    lineHeight: 18,
  },

  item: {
    backgroundColor: '#fff',
    marginBottom: 10,
    marginVertical: 3,
  },
  itemSelected: {
    backgroundColor: CCColors.LightYellow,
    borderColor: 'rgba(77, 63, 55, 0.5)',
    borderWidth: 1,
  },

  itemContent: {
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  customPadding: {
    paddingHorizontal: 3,
    paddingVertical: 16,
  },
  customPaddingMobile: {
    paddingHorizontal: 7,
    paddingVertical: 13,
  },
  itemText: {
    fontWeight: '500',
    lineHeight: 18,
    // fontSize: 14,
    paddingHorizontal: 8,
  },
  itemPercent: {
    fontWeight: '500',
    lineHeight: 18,
    fontSize: 14,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  group: {
    flexBasis: '50%',
    paddingTop: 16,
    paddingBottom: 6,
  },

  groupContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  pieceImage: {
    width: 48,
    height: 48,
  },
  dot: {justifyContent: 'center', alignItems: 'center'},
  dotBox: {width: '100%', justifyContent: 'space-between', marginTop: 10},
  new: {
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderLight,
    marginVertical: 3,
    shadowColor: 'rgba(77, 63, 55, 0.07)', // Fixed RGBA color
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 1,
    shadowRadius: 8,
    // Android Shadow
    elevation: 4,
  },
});

export const leaderBoardStyles = StyleSheet.create({
  container: {
    // width: '16%',
    height: !isPhone ? '35%' : '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderRightWidth: 1,
    borderRightColor: '#ddd',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: '#f8f8f8',
    borderRadius: 8,
    marginBottom: 8,
  },
  highlightedItem: {
    backgroundColor: '#F6E5B6',
  },
  rankContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },
  rankIcon: {
    width: 24,
    height: 24,
  },
  rankText: {
    fontSize: 12,
    fontWeight: '500',
    color: '#333',
  },
  profilePic: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 10,
  },
  playerName: {
    flex: 1,
    fontSize: 14,
    fontWeight: '500',
    color: '#333',
  },
  points: {
    fontSize: 14,
    fontWeight: '500',
    color: '#333',
  },

  mostFirst: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  active: {
    borderBottomWidth: 2,
    borderBottomColor: CCColors.Tabs.InactiveBackground,
  },
  div: {},
  customButton: {
    lineHeight: 20,
    textAlign: 'left',
  },
  dailyLeaderBoard: {
    marginTop: 5,
  },
  buttonContainer: {
    fontSize: 10,
  },
  parentBadge: {
    width: 20,
    height: 20,
  },
  badge: {
    width: '100%',
    height: '100%',
  },
  fill: {
    backgroundColor: '#ADD8E6',
  },
  rankParent: {
    textAlign: 'center',
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  unlockParent: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unlockMasters: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
  },
  unlockMiniParent: {
    width: 56,
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E17846',
    borderRadius: '50%',
  },
});

export const movesLogStyles = StyleSheet.create({
  movesLog: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
  },
  gameDetails: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#333',
  },
  movesLogBox: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  statText: {
    fontSize: 16,
    color: CCColors.TextColor.Dark,
    marginHorizontal: 4,
  },
  selectedMove: {
    fontSize: 16,
    color: '#1e90ff',
    // fontWeight: 'bold',
    marginHorizontal: 4,
  },
  moveSeparator: {
    fontSize: 16,
    color: '#aaa',
  },
});

export const detailStyles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: '#F5F5F5',
    borderRadius: 8,
    marginVertical: 8,
  },
  gameDetails: {
    fontSize: 14,
    color: '#333',
    marginBottom: 4,
  },
  stat: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  statIcon: {
    marginRight: 8,
    color: '#666',
  },
  highlightedText: {
    color: '#4CAF50',
    fontWeight: 'bold',
  },
  tooltipText: {
    color: '#FFF',
    padding: 8,
    backgroundColor: '#333',
    borderRadius: 4,
  },
  platformText: {
    fontSize: 12,
    color: '#888',
  },
  attemptText: {
    fontSize: 12,
    color: '#FF5722',
  },
});
