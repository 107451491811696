import {useMutation} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useUpdateLearningOnboardingStatusApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<any, Error, void>(
    async () => {
      const token = await getLocalStorage('access_token');
      const response = await ChessRestClient.post(
        'v1/update-learning-onboarding-status/',
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      );

      return response;
    },
    {
      onSuccess: data => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: error => {
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return {
    ...mutation,
  };
}
