import Box from '@/Atoms/Box';
import {FontSizes, hs, isPhone, vs} from '@/Utils/Dimensions';

import React, {useState, useEffect, useCallback} from 'react';
import {
  TextInput,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  FlatList,
} from 'react-native';
import {debounce} from 'lodash';

import CCTextInput from '@/Atoms/CCTextInput';
import CCColors from '@/Utils/CCColors';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import Divider from '@/Atoms/Divider/Divider';
import AsyncSelect from 'react-select/async';
import {GestureResponderEvent} from 'react-native';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {
  getLocalStorage,
  removeLocalStorage,
  reverseObject,
} from '@/Utils/CommonUtils';

function ChallengeUserComponent(props) {
  const {handleOpponentName, searchPlayers, searchPlayersResult, user} = props;
  const [loadingPlayer, setLoadingPlayer] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [localOptions, setLocalOptions] = useState([]);
  const [leagueOppId, setLeagueOppId] = useState('');

  // Debounce the searchPlayers function to delay its execution
  const debouncedSearchPlayers = debounce(
    text => handleUsernameChange(text),
    300,
  );

  const handleUsernameChange = useCallback(
    username => {
      // Update the search term state
      setSearchTerm(username);
      // Call the debounced searchPlayers function
      searchPlayers(username);
    },
    [searchPlayers],
  );

  const handleFocus = () => {
    handleUsernameChange('');
    console.log('handleFocus run');
    setLoadingPlayer(true);
    setIsFocused(true);
  };

  useEffect(() => {
    const updateUsername = async () => {
      const league_opp_id = await getLocalStorage('league_opp_id');
      if (league_opp_id) {
        setLeagueOppId(league_opp_id);
      }
    };
    updateUsername();
  }, []);

  const handleBlur = () => {
    // Delay setting isFocused to false by 150 milliseconds
    setTimeout(() => {
      setIsFocused(false);
    }, 300); // 150 milliseconds
  };

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    console.log('player list = ', searchPlayersResult);
    if (searchPlayersResult) {
      setLoadingPlayer(false);
      if (leagueOppId) {
        const players = reverseObject(JSON.parse(searchPlayersResult));
        const username = players[leagueOppId];
        if (username) {
          setSearchTerm({
            value: `${leagueOppId}:${username}`,
            label: username,
          });
          handleOpponentName(`${leagueOppId}:${username}`);
        }
      } else {
        try {
          let parsedResult = JSON.parse(searchPlayersResult);
          parsedResult = Object.fromEntries(
            Object.entries(parsedResult).filter(
              ([key, value]) => value !== user,
            ),
          );
          let resultArray = Object.keys(parsedResult).map(username => ({
            username: username,
            userId: parsedResult[username],
          }));
          console.log('parsed list = ', parsedResult);
          console.log('resultArray = ', resultArray);
          setSearchResults(resultArray);
          const newOptions = resultArray.map(player => ({
            value: `${player.userId}:${player.username}`,
            label: player.username,
          }));
          setLocalOptions(newOptions);
        } catch (error) {
          console.error('Error parsing searchPlayersResult:', error);
        }
        if (!searchTerm) {
          handleOpponentName('');
        }
      }
    }
  }, [searchPlayersResult]);

  const handlePlayerSelect = async selectedPlayer => {
    // Call the handleOpponentName function with the selected player's name
    setSearchTerm(selectedPlayer);
    handleOpponentName(selectedPlayer.value);
    await removeLocalStorage('league_opp_id');
    // Clear the search results
  };
  // AsyncSelect component
  const loadOptions = (inputValue, callback) => {
    // If no input, show all local options
    if (!inputValue) {
      callback(localOptions);
      return;
    }

    const lowercaseInput = inputValue.toLowerCase();

    // Filter local options first
    const filteredOptions = localOptions.filter(option =>
      option.label.toLowerCase().includes(lowercaseInput),
    );

    // Sort results
    const sortedOptions = filteredOptions.sort((a, b) => {
      const aStartsWith = a.label.toLowerCase().startsWith(lowercaseInput);
      const bStartsWith = b.label.toLowerCase().startsWith(lowercaseInput);

      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    });

    // Return filtered results immediately
    callback(sortedOptions);

    // Then trigger API call for new results
    if (!loadingPlayer) {
      setLoadingPlayer(true);
      handleUsernameChange(inputValue);
    }
  };
  return (
    <View style={styles.container}>
      {/* <TouchableWithoutFeedback>
            <View>
                <TextInput
                    onChangeText={handleUsernameChange}
                    onFocus={handleFocus}
                    value={searchTerm}
                    style={styles.input}
                    placeholder="Username of the opponent"
                />
            
            
                <View>
                    {searchResults && isDropdownOpen && (
                        <View style={styles.dropdown}>
                            {Object.entries(searchResults).map(([username, userId]) => (
                                <TouchableOpacity key={userId} onPress={() => handlePlayerSelect({ username, userId })}>
                                    <Box type="row-center">
                                        <Text style={styles.dropdownItem}>{username}</Text>
                                    </Box>
                                </TouchableOpacity>
                            ))}
                        </View>
                    )}
                </View>
            </View>
            </TouchableWithoutFeedback> */}
      <Box overflow="visible" style={{zIndex: -10, width: '80%'}}>
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions={searchResults.map(player => ({
            value: player.userId + ':' + player.username,
            label: player.username,
          }))}
          value={searchTerm}
          onChange={handlePlayerSelect}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Username of the opponent"
          isClearable
          isLoading={loadingPlayer}
          styles={{
            menu: provided => ({
              ...provided,
              maxHeight: 150,
              overflowY: 'auto',
            }),
            menuList: provided => ({
              ...provided,
              maxHeight: 150,
              overflowY: 'auto',
            }),
          }}
        />
      </Box>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    alignItems: 'flex-start',
    padding: 8,
    zIndex: -10,
    // width: '90%'
  },
  input: {
    backgroundColor: 'white',
    borderColor: '#22222220',
    borderWidth: 1,
    width: isPhone ? '90vw' : '24vw',
    height: 48,
    borderRadius: 8,
    padding: 12,
    placeholderTextColor: '#26232240',
    zIndex: -10,
  },
  dropdown: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: 8,
    borderColor: '#22222220',
    borderWidth: 1,
    width: 480,
    zIndex: 0,
    bottom: 0,
  },
  autoCompleteFlatList: {
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    backgroundColor: CCColors.White,
    borderColor: CCColors.BorderBottom,
    borderRadius: 10,
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dropdownItem: {
    padding: 12,
  },
});

export default ChallengeUserComponent;
