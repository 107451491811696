import CCColors from '@/Utils/CCColors';
import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Image, StyleSheet} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import styles from './styles';
import useIsMobile from '@/Hooks/useIsMobile';
import CCText from '@/Atoms/CCText';

const PlayerTypeSelection = ({
  playerType,
  setPlayerType,
}: {
  playerType?: 'beginner' | 'pro';
  setPlayerType: (type: 'beginner' | 'pro') => void;
}) => {
  const isMobileOrTab = useIsMobile();

  return (
    <View style={[styles.container, isMobileOrTab && styles.containerMobile]}>
      <View
        style={[styles.textSection, isMobileOrTab && styles.textSectionMobile]}>
        <Text style={styles.sectionHeader}>Player Type</Text>
        <Text style={styles.subHeader}>
          You can change the player type anytime to match your gameplay
        </Text>
      </View>

      <View
        style={[
          styles.cardContainer,
          isMobileOrTab && styles.cardContainerMobile,
        ]}>
        <TouchableOpacity
          style={[
            isMobileOrTab ? styles.cardMobile : styles.card,
            playerType === 'beginner' ? styles.selectedCard : undefined,
          ]}
          onPress={() => setPlayerType('beginner')}>
          <View style={styles.cardContent}>
            <View
              style={[
                styles.textContainer,
                isMobileOrTab && styles.textContainerMobile,
              ]}>
              <CCText
                style={[
                  styles.cardTitle,
                  playerType !== 'beginner' && styles.grayedOut,
                ]}>
                I'm a Beginner player
              </CCText>
              <CCText
                style={[
                  styles.cardSubtitle,
                  playerType !== 'beginner' && styles.grayedOut,
                ]}>
                Completely New to Chess
              </CCText>
            </View>
            <Image
              source={require('@/Assets/Account/PlayerType/beginner_player.svg')}
              style={[
                styles.avatar,
                playerType !== 'beginner' && styles.disabledImage,
              ]}
            />
            {playerType === 'beginner' && (
              <AntDesign
                name="checkcircle"
                size={20}
                color={CCColors.Primary.Default}
                style={styles.checkmark}
              />
            )}
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            isMobileOrTab ? styles.cardMobile : styles.card,
            playerType === 'pro' ? styles.selectedCard : undefined,
          ]}
          onPress={() => setPlayerType('pro')}>
          <View style={styles.cardContent}>
            <View
              style={[
                styles.textContainer,
                isMobileOrTab && styles.textContainerMobile,
              ]}>
              <CCText
                style={[
                  styles.cardTitle,
                  playerType !== 'pro' && styles.grayedOut,
                ]}>
                I'm a Pro player
              </CCText>
              <CCText
                style={[
                  styles.cardSubtitle,
                  playerType !== 'pro' && styles.grayedOut,
                ]}>
                Familiar with Chess
              </CCText>
            </View>
            <Image
              source={require('@/Assets/Account/PlayerType/pro_player.svg')}
              style={[
                styles.avatar,
                playerType !== 'pro' && styles.disabledImage,
              ]}
            />
            {playerType === 'pro' && (
              <AntDesign
                name="checkcircle"
                size={20}
                color={CCColors.Primary.Default}
                style={styles.checkmark}
              />
            )}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default PlayerTypeSelection;
