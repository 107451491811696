import React, {useEffect, useState} from 'react';
import {View, StyleSheet} from 'react-native';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import CCCheckbox from '@/Atoms/CCCheckbox';
import Spacer from '@/Atoms/Spacer';
import SlideModal from '@/Atoms/SlideModal';
import {PopUp} from '@/Screens/Visualisation/components/LevelModal';
import useUpdateProfileApi from '@/Hooks/ProfileApi/profileUpdateApi';
import useIsMobile from '@/Hooks/useIsMobile';

const AssessmentTabModal = ({
  visible,
  cdcUsername,
  licUsername,
  onboardingStatus,
  firstAssessmentPending,
}) => {
  if (!visible) return null; // Render nothing if not visible

  const isMobileOrTab = useIsMobile();
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    licUsername: licUsername || '',
    cdcUsername: cdcUsername || '',
  });
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {mutate: updateProfile, isLoading} = useUpdateProfileApi(
    data => {
      console.log('Update successful:', data);
      setIsSubmitted(true);
    },
    error => {
      console.error('Update failed:', error);
    },
  );

  useEffect(() => {
    if (cdcUsername || licUsername) {
      setIsSubmitted(true);
    }
  }, [cdcUsername, licUsername]);

  const isSubmitDisabled =
    (!formData.licUsername && !formData.cdcUsername) || !checkboxValue;

  const handleFormSubmit = () => {
    updateProfile({
      email: '',
      fide_id: '',
      first_name: '',
      last_name: '',
      mobile_number: '',
      username: '',
      lic_name: formData.licUsername,
      cdc_name: formData.cdcUsername,
    });

    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const renderAnalyzingGamesText = () => (
    <View>
      <CCText style={[styles.modalText, {fontSize: isMobileOrTab ? 16 : 20}]}>
        We are analysing your games and getting your assessment report ready.
        This should be completed in some time. We will send you a notification
        via WhatsApp when it completes.
      </CCText>
      <Spacer spacing={16} />
      <CCText style={[styles.modalText, {fontSize: isMobileOrTab ? 16 : 20}]}>
        In the meantime, you can enjoy the following from the left navigation:
        {'\n'}- Play games
        {'\n'}- Add offline games
        {'\n'}- Analyse those games
        {'\n'}- Use our trainers to get better!
      </CCText>
    </View>
  );

  const renderInputForm = () => (
    <View>
      <CCText style={[styles.modalText, {fontSize: isMobileOrTab ? 16 : 20}]}>
        Please provide lichess and/or chess.com username for generating an
        assessment report on previous games or else play games on CircleChess to
        analyze:
      </CCText>
      <Spacer spacing={30} />
      <CCTextInput
        style={styles.input}
        placeholder="Lichess Username"
        value={formData.licUsername}
        onChangeText={text => setFormData({...formData, licUsername: text})}
      />
      <Spacer spacing={10} />
      <CCTextInput
        style={styles.input}
        placeholder="Chess.com Username"
        value={formData.cdcUsername}
        onChangeText={text => setFormData({...formData, cdcUsername: text})}
      />
      <Spacer spacing={10} />
      {showPopup && (
        <SlideModal
          visible={showPopup}
          type="center"
          customStyle={[
            styles.levelModalCustom,
            {width: isMobileOrTab ? '80%' : '50%'},
          ]}>
          <PopUp
            confirm={() => setShowPopup(false)}
            text={`By granting CircleChess access to your chess.com or lichess.org account, you give explicit permission to retrieve and use data from your last 100 games for analysis and services offered by CircleChess. You acknowledge full responsibility for granting this access and agree to indemnify CircleChess against any claims, damages, or consequences arising from this data usage. CircleChess will use your data solely for its intended features and will not share it with unauthorized third parties. You confirm that you have the legal right to share this data and that this permission complies with chess.com's or lichess.org's policies respectively. You may revoke this permission at any time, but data already accessed will not be retroactively deleted.`}
            gap={10}
            fontSize={15}
            buttonConfirm="Okay"
          />
        </SlideModal>
      )}
      <CCCheckbox
        label={
          <>
            <CCText>
              I have read and agree to the{' '}
              <span
                style={{
                  color: 'blue',
                  textDecorationLine: 'underline',
                }}>
                terms and conditions.
              </span>
            </CCText>
          </>
        }
        value={checkboxValue}
        onValueChange={(label, newValue) => {
          setCheckboxValue(newValue);
        }}
        customStyle={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 4,
        }}
        tickSize={14}
        onLabelPress={() => setShowPopup(true)}
      />
      <Spacer spacing={10} />
      <CCButton onPress={handleFormSubmit} disabled={isSubmitDisabled}>
        Submit
      </CCButton>
    </View>
  );

  return (
    <View
      style={[
        styles.overlayContainer,
        {justifyContent: isMobileOrTab ? 'flex-start' : 'center'},
      ]}>
      <View
        style={[styles.modalOverlay, {marginTop: isMobileOrTab ? '35%' : 0}]}>
        <View
          style={[
            styles.modalContainer,
            {width: isMobileOrTab ? '100%' : '80%'},
          ]}>
          {isSubmitted ? renderAnalyzingGamesText() : renderInputForm()}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  overlayContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
  },
  modalOverlay: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
  },
  modalText: {
    fontSize: 20,
    textAlign: 'left',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    padding: 10,
  },
  levelModalCustom: {
    padding: 20,
  },
});

export default AssessmentTabModal;
