import {useQuery} from '@tanstack/react-query';
import {getLocalStorage} from '@/Utils/CommonUtils';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useGetAssessmentDetailsApi(
  assessmentId?: string,
  accessUserKey?: string,
  {
    onSuccess,
    onError,
    staleTime = 5 * 60 * 1000, // 5 minutes
    cacheTime = 10 * 60 * 1000, // 10 minutes
  } = {},
) {
  return useQuery(
    ['assessmentDetails', assessmentId, accessUserKey],
    async () => {
      const token = await getLocalStorage('access_token');
      const params: Record<string, string> = {};
      if (accessUserKey) {
        params.access_user_key = accessUserKey;
      } else if (assessmentId) {
        params.assessment_id = assessmentId;
      }
      const response = await ChessRestClient.get(
        'assessment/v1/learning/get_assessment_details/',
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      );
      return response.data;
    },
    {
      enabled: Boolean(assessmentId || accessUserKey), // only run when assessmentId is provided
      onSuccess,
      onError,
      staleTime,
      cacheTime,
      refetchOnWindowFocus: false, // disable auto-refetch on window focus
      refetchOnMount: false, // disable refetch when component mounts if data is fresh
    },
  );
}
