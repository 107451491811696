import {StyleSheet} from 'react-native';
import {FontSizes, vs} from '@/Utils/Dimensions'; // Import your dimensions utils if needed
import CCColors from '@/Utils/CCColors';

export const styles = StyleSheet.create({
  rightPaneContainer: {
    overflow: 'hidden',
    flexDirection: 'column',
    margin: 10,
    flex: 0.5, // The parent Box takes up 50% of the available space
  },
  scrollView: {
    margin: 0,
  },
  buttonIcon: {
    position: 'absolute',
    left: '0.1%',
    width: '15%',
    bottom: 0,
    zIndex: 20,
    height: '140%',
  },
  buttonGirlIcon: {
    position: 'absolute',
    right: 5,
    width: '35%',
    bottom: '-3%',
    zIndex: 20,
    height: '140%',
  },
  chessPattern: {
    position: 'absolute',
    width: '30%',
    height: '100%',
  },
  chessPatternLeft: {
    left: '-0.5%',
    top: 0,
    // zIndex: 20,
    transform: [{scaleX: -1}],
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // borderWidth: StyleSheet.hairlineWidth,
  },
  chessPatternRight: {
    right: -6,
    top: 0,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  playModeContainer: {
    flexDirection: 'row',
    overflow: 'visible',
    marginBottom: 8,
    // marginTop: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  playModeContainerSelected: {
    backgroundColor: CCColors?.Primary?.Brown,
  },
  playButtonTxtStyle: {
    paddingVertical: 14,
  },
  playButtonStyle: {
    position: 'relative',
    flex: 0.5,

    // overflowY: 'hidden',
    // aspectRatio: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CCColors.White,
  },
  playButtonStyleLeft: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#2623221A',
    // border: 1px solid #2623221A
  },
  playButtonStyleRight: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#2623221A',
  },
  playButtonStyleCentre: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#2623221A',
  },
  playModeBox: {
    alignItems: 'center', // Center the image in the box
    justifyContent: 'center',
    shadowColor: '#4D3F37',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    flex: 0.5, // The parent Box takes up 50% of the available space
    // flexBasis: '50%',
    overflow: 'visible',
    position: 'relative',
  },
  playIcon: {
    // flexBasis: '50%',
    width: '100%', // Image takes 50% of the parent's width
    height: 70, // Height is undefined to maintain aspect ratio
    aspectRatio: 1, // Ensures the image maintains a square ratio
    objectFit: 'contain',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.05)', // Semi-transparent grey overlay
  },
  comingSoonText: {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: [{translateX: -50}, {translateY: -50}],
    color: 'green', // Adjust this according to your theme colors
    fontSize: 12,
  },
  gameOptionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // zIndex: -20,
  },
  learnModeText: {
    margin: 10,
    textAlign: 'left',
    fontWeight: '300',
    fontSize: 18, // Modify according to your FontSizes[18]
  },
  learnModeDescription: {
    fontWeight: '300',
    marginBottom: vs(32),
    margin: 10,
    fontSize: 12, // Modify according to your FontSizes[12]
  },
  timeControlTitle: {
    fontWeight: '300',
    marginTop: vs(32),
    zIndex: -20,
    fontSize: 16, // Adjust according to your FontSizes[16]
  },
  timeControlContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    zIndex: -20,
  },
  additionalSettingsContainer: {
    flexDirection: 'row',
    // marginTop: 32,
    zIndex: -20,
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  additionalSettingsContainerMobile: {
    flexDirection: 'column',
    // marginTop: 32,
    // zIndex: -20,
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  additionalSettingsText: {
    fontSize: FontSizes[16],
    marginLeft: 5,
    color: CCColors.Green,
  },
  playButtonContainer: {
    borderRadius: 32,
    marginTop: 32,
    justifyContent: 'center',
    zIndex: -10,
  },
  playButtonText: {
    height: '100%',
    paddingVertical: 5,
    zIndex: -10,
  },
});
