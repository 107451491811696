const CCColors = {
  White: '#FFFFFF',
  Black: '#000000',
  Background: '#FFFFFF',
  LightGreen: '#1ECF52',
  LightYellow: '#FFF0C8',
  BrownShade2: '#806234',
  BlueAqua: '#3DAB9E',
  DarkBlueAqua: '#4b968d',
  DarkGrey: '#333',
  OffWhite: '#E0E0E0',
  BrightYellow: '#F9E9B8',
  Orange: '#E17B21',
  CCTableBg: '#FAF6EB',
  ChessBoard: {
    Light: '#edd899',
    Dark: '#d1ab41',
    StartColor: 'rgb(120, 113, 64)',
    EndColor: 'rgb(196, 181, 57)',
    GreenLightColor: '#EBECD0',
    GreenDarkColor: '#739552',
    GreenStartColor: '#F6EC77',
    GreenEndColor: '#B9C93B',
    BlueLightColor: '#DFDFDF',
    BlueDarkColor: '#6F84B4',
    BlueStartColor: '#9F9FFD',
    BlueEndColor: '#9F9FFD',
  },
  Box: {
    MiddleLine: '#2623221A',
    Boxborder: 'rgba(182, 172, 166, 0.8)',
    boxText: 'rgba(135, 122, 114,0.8)',
  },
  WrongMoves: {
    Background: '#EB5757',
  },
  Tooltip: {
    Background: '#f7dcc6',
  },
  TextColor: {
    Primary: '#4D3F37',
    Grey: '#A8A7A6',
    LightGrey: '#4D3F37CC',
    Dark: '#262322',
    PlaceHolderTextColor: '#8E844E',
    GreyText: '#888280',
  },
  // Grey: '#A8A7A6',
  Border: 'rgba(38, 35, 34, 0.2)',
  RedBorder: '#EC601D',
  BorderBottom: '#26232233',
  BorderLight: 'rgba(38, 35, 34, 0.1)',
  Blue: '#67B3E1',
  LightPink: '#F5EAF3',
  LightBlue: '#EAF4FA',
  Red: '#E17846',
  RedLight: '#FF854B29',
  Green: '#3DAB9E',
  GreenLight: '#21CDBD29',
  Purple: '#937ADB',
  Pink: '#DB7ACC',
  Primary: {
    // Default: '#572D86',
    Default: '#FACF47',
    Action: '#FACF47',
    lightBrown: '#FAF6EB',
    Light: '#FAF6EB',
    Brown: '#4D3F37',
    Dark: '#4D3F37',
    Aqua: '#3DAB9E',
    lightYellow: '#FEF2B5',
    Grey: 'rgba(38, 35, 34, 0.20)',
  },

  success: '#3dab5bba',
  Error: '#CD2C39',
  Grey: {
    Grey: '#7D7D7D',
    Shade100: '#ACACAC',
    Shade200: '#CACACA',
    Divider100: '#F2F3F5',
    Divider200: '#E8E8E8',
    Divider300: '#2222221A',
    Divider400: '#26232219',
    Border: '#E3E3E3',
    BG100: '#FAFAFA',
    BG200: '#F6F6F6',
    BG300: '#F6F4F8',
    BG400: '#F1F1F1',
    BG500: '#F2F2F2',
  },
  menuBackground: {
    main: '#4D3F37',
  },
  ChipList: {
    Default: '#3DAB9E',
    success: '#3dab5bba',
  },
  Dashboard: {
    DashboardBackground: '#FAF6EB',
  },
  Tabs: {
    InactiveBackground: '#FACF47',
    ActiveBackground: '#3DAB9E',

    ActiveTabFont: '#FFFFFF',
    InactiveTabFont: '#262322',
  },
  TextBoxes: {
    Borders: '#4D3F37',
  },
  Buttons: {
    Background: '#FACF47',
    TimeControlBackground: '#FFF0C8',
  },
  Texts: {
    Regular: '#4D3F37',
  },
  ColorCodes: {
    Done: '#FACF47',
    Current: '#4D3F37',
    Pending: '#7D7D7D',
  },
  Progress: {
    Bar: '#D1AB41',
  },
  Modal: {
    Background: 'rgba(0, 0, 0, 0.5)',
    CloseButton: '#FF474C',
  },
  Visualisation: {
    CalculationBackground: '#F3E7A7',
  },
  Rewards: {
    Green: '#21CDBD29',
    Red: '#FF854B29',
    TextGreen: '#3DAB9E',
    TextRed: '#E17846',
  },
  CCSelect: {
    BorderColor: '#D3D3D3',
  },
  Animated: {
    Background: '#F5EDD3',
  },
  Switch: {
    TrackColorFalse: '#767577',
    TrackColorTrue: '#81b0ff',
    ThumbColorEnabled: '#f5dd4b',
    ThumbColorDisabled: '#f4f3f4',
    IosBackgroundColor: '#3e3e3e',
  },
  EvaluationScore: {
    Background: '#A9873B',
  },
  TimeControl: {
    BorderColor: '#22222220',
  },
  GamesList: {
    Background: '#FFF5DB',
  },
  Level: {
    Blue: {
      Light: '#6EC6FF',
      Dark: '#01579B',
      iconColor: '#FFD700',
      levelNumberColor: '#6EC6FF',
    },
    Yellow: {
      Light: '#FFD700',
      Dark: '#FFA000',
      iconColor: '#01579B',
      levelNumberColor: '#FFD700',
    },
    Green: {
      Light: '#00C853',
      Dark: '#004D40',
      iconColor: '#FFD700',
      levelNumberColor: '#00C853',
    },
  },
  Payment: {
    Success: '#28a745',
    Pending: '#ffc107',
    Rejected: '#dc3545',
    Default: '#17a2b8',
    ButtonBackground: '#2196F3',
  },
  SignUp: {
    BoxBackground: '#F3E9CE',
  },
};

export default CCColors;

/**
 * @param color Hexadecimal color code in format #RRGGBB we want to update with opacity
 * @param opacity opacity value in range of [0, 1]
 */
export function updateColorWithOpacity(color: string, opacity: number): string {
  const percent = Math.max(0, Math.min(100, opacity * 100)); // bound percent from 0 to 100
  const intValue = Math.round((percent / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation

  return `${color}${hexValue.padStart(2, '0')?.toUpperCase()}`;
}
