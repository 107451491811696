import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import CCTextInput from '@/Atoms/CCTextInput';
import Empty from '@/Atoms/Empty';
import SlideModal from '@/Atoms/SlideModal';
import Spacer from '@/Atoms/Spacer';
import CCColors from '@/Utils/CCColors';
import {vs, FontSizes} from '@/Utils/Dimensions';
import React, {useMemo, useState} from 'react';
import {ScrollView} from 'react-native';
import BookCard from '../BookCard';
import UploadBook from '../UploadBook';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {AssignmentProps} from './types';
import {useFocusEffect} from '@react-navigation/native';

function Assignment({
  search,
  searchTerm,
  setSearchTerm,
  booksList,
  emptyResult,
  emptyBook,
  upload,
  setBookType,
  openDrawer,
  setOpenDrawer,
  importSuccess,
  userData,
  userId,
  orderBook,
}: AssignmentProps) {
  const {isMobileOrTab} = useMediaQuery();
  const [bookList, setBookList] = useState<any>();

  const booksListFormatted = useMemo(() => {
    if (!searchTerm || searchTerm.trim() === '') {
      return booksList?.map(i => ({
        ...i,
        book: i?.name,
        paid: !Number(i?.price) ? true : i?.paid,
        book_price: i?.price,
        book_mrp: i?.mrp,
        type: 'circlechess',
      }));
    }

    const filteredBooks = booksList?.filter(i =>
      i?.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return filteredBooks?.map(i => ({
      ...i,
      book: i?.name,
      paid: !Number(i?.price) ? true : i?.paid,
      book_price: i?.price,
      book_mrp: i?.mrp,
      type: 'circlechess',
    }));
    // id
    // book
    // progress
    // chapters
    // positions
    // type?
    // book_price?
    // book_mrp?
    // paid?
    // userData?
    // userId?
    // orderBook?
  }, [booksList, searchTerm]);

  const onSearch = (searchText: string) => {
    setSearchTerm(searchText);
    if (!searchText) {
      setBookList(booksList);
    }
  };

  return (
    <ScrollView
      style={{padding: isMobileOrTab ? 0 : 20}}
      showsVerticalScrollIndicator={false}>
      <Spacer spacing={vs(36)} />
      <Box
        {...(!isMobileOrTab && {type: 'row-center-between'})}
        // type="row-center-between"
        style={isMobileOrTab ? {flexDirection: 'column'} : {}}>
        <Box
          // type="row-flex-start"
          {...(!isMobileOrTab && {type: 'row-flex-start'})}
          style={isMobileOrTab ? {flexDirection: 'column'} : {}}>
          <CCText fontSize={FontSizes[24]} color={CCColors.Primary.Dark}>
            My Assignments
          </CCText>
          <Spacer spacing={vs(36)} horizontal />
          <CCTextInput
            icon={search}
            fontSize={14}
            value={searchTerm}
            style={{
              width: 300,
              height: 40,
              backgroundColor: CCColors.White,
              overflow: 'hidden',
            }}
            autoFocus={searchTerm.length}
            placeholder="Search an assignment"
            onChangeText={text => {
              setSearchTerm(text);
              onSearch(text);
            }}
            placeholderTextColor={CCColors.TextColor.Grey}
          />
        </Box>
        {isMobileOrTab && <Spacer spacing={10} />}
        <Box type={isMobileOrTab ? 'row-center-between' : 'row-flex-start'}>
          {userData?.is_staff && (
            <CCButton
              icon={upload}
              titleStyle={{color: CCColors.TextColor.Dark}}
              style={{backgroundColor: CCColors.White}}
              onPress={() => {
                setOpenDrawer(true);
              }}>
              Upload
            </CCButton>
          )}
          {/* <Spacer spacing={vs(10)} horizontal />
          <CCButton
            icon={discover}
            onPress={() => setBookType('books')}
            titleStyle={{color: CCColors.TextColor.Dark}}>
            Discover Books
          </CCButton> */}
        </Box>
      </Box>
      <Spacer spacing={vs(36)} />
      {booksListFormatted?.length ? (
        <Box type="row-flex-start" flexWrap="wrap" gap={20}>
          {booksListFormatted.map((book, index) => (
            <BookCard
              {...book}
              // id={i}
              index={index}
              userData={userData}
              userId={userId}
              orderBook={orderBook}
              progress={0}
            />
          ))}
        </Box>
      ) : (
        <Empty
          imgSrc={searchTerm?.length ? emptyResult : emptyBook}
          title={searchTerm?.length ? 'No results found' : 'No books found'}
          description={
            searchTerm?.length
              ? `We couldn’t find any assignment matching ”${searchTerm}”
              Try again with other assignment name.`
              : 'You can discover and buy from our collection of chess books or upload your own with the .pgn format'
          }>
          <Box type="row-flex-start">
            {userData?.is_staff && (
              <CCButton
                icon={upload}
                titleStyle={{color: CCColors.TextColor.Dark}}
                style={{backgroundColor: CCColors.Primary.lightBrown}}
                onPress={() => {
                  setOpenDrawer(true);
                }}>
                Upload
              </CCButton>
            )}
            {/* <Spacer spacing={vs(10)} horizontal />
            <CCButton
              icon={discover}
              onPress={() => {
                setBookType('books');
              }}
              titleStyle={{color: CCColors.TextColor.Dark}}>
              Discover Books
            </CCButton> */}
          </Box>
        </Empty>
      )}
      <SlideModal
        type="side"
        width={0.3}
        modalHeaderTitle={'Upload assignment'}
        visible={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}>
        <UploadBook
          uploadType={'assignment'}
          buttonName={'Upload Assignment'}
          importSuccess={importSuccess}
          isMobileOrTab={isMobileOrTab}
        />
      </SlideModal>
    </ScrollView>
  );
}

export default Assignment;
