import { useMutation } from '@tanstack/react-query';
import TournamentRestClient from '@/Api/TournamentRestClient';

export default function useGetTournamentPairings(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void
) {
  const mutation = useMutation(
    async ({ tournamentId, round }: { tournamentId: string; round: number }) => {
      const response = await TournamentRestClient.get(
        `/tournament/get-pairings?tournamentId=${tournamentId}&round=${round}`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: (error) => {
        if (handleOnError) handleOnError(error);
      },
    }
  );

  return mutation;
}
