import ChessRestClient from '@/Api/ChessRestClient';
import ChessPaymentRestClient from '@/Api/ChessPaymentRestClient';
import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {
  BookPayload,
  BooksResponse,
  BookImportResponse,
  BuyBookPayload,
  BuyBookResponse,
  BookOrderStatusResponse,
  BookOrderStatusPayload,
  VideoCourseImportResponse,
} from './types';
import {getLocalStorage, removeEmptyKeys} from '@/Utils/CommonUtils';

interface DeleteBookPayload {
  book_id: number;
}

export function useBooksApi(): UseMutationResult<
  BooksResponse,
  Error,
  BookPayload
> {
  return useMutation<BooksResponse, Error, BookPayload>(
    async (payload: BookPayload) => {
      const token = await getLocalStorage('access_token');
      const options: any = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      };
      // if (payload.product_type) {
      //   // options.params = payload;
      //   debugger;
      //   return ChessRestClient.get(
      //     `v1/fetch_book_chapters?product_type=1`,
      //     options,
      //   );
      // } else
      if (payload.name || payload.product_type) {
        options.params = payload;
        return ChessRestClient.get('v1/fetch_book_chapters', options);
      }
      return ChessRestClient.get('v1/fetch_book_chapters', options);
    },
    {
      // You can add onSuccess, onError, etc. here if needed
    },
  );
}

export function useBookImportApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
): UseMutationResult<
  BookImportResponse,
  Error,
  {
    file: File;
    tournament_date: string;
    release_date: string;
    name: string;
    chapter?: string;
    mrp: string;
    price: string;
    bookType: number;
    user_phone: number;
    tournamentId: number;
    tournamentCategory: string;
  }
> {
  return useMutation<
    BookImportResponse,
    Error,
    {
      file: File;
      tournament_date: string;
      release_date: string;
      name: string;
      chapter?: string;
      author?: string;
      product_type?: string;
      mrp: string;
      price: string;
      bookType: number;
      user_phone: number;
      tournamentId: number;
      tournamentCategory: string;
    }
  >(
    async ({
      file,
      tournament_date,
      release_date,
      name,
      chapter,
      author = '',
      product_type = 0,
      mrp,
      price,
      bookType,
      user_phone,
      tournamentId,
      tournamentCategory,
    }) => {
      const token = await getLocalStorage('access_token');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      formData.append('tournament_date', tournament_date);
      if (release_date) {
        formData.append('release_date', release_date);
      }
      if (mrp) {
        formData.append('mrp', mrp);
      }
      if (bookType) {
        formData.append('buy_type', bookType);
      }
      if (price) {
        formData.append('price', price);
      }
      if (product_type) {
        formData.append('product_type', product_type);
      }
      if (chapter?.length) {
        formData.append('chapter', chapter);
      }
      if (author?.length) {
        formData.append('author', author);
      }
      if (product_type === 'assignment') {
        formData.append('author', name);
      }
      if (user_phone) {
        formData.append('user_phone', user_phone);
      }
      if (tournamentId) {
        formData.append('tournamentId', tournamentId);
      }
      if (tournamentCategory) {
        formData.append('tournamentCategory', tournamentCategory);
      }
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      return ChessRestClient.post('import_book', formData, options);
    },
    {
      // You can add onSuccess, onError, etc. here if needed
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );
}

export function useDeleteBookApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  return useMutation(
    async ({book_id}: DeleteBookPayload) => {
      const response = await ChessRestClient.post('delete_book', {
        book_id: book_id,
      });
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);
        console.log('Book deleted successfully:', data);
      },
      onError: error => {
        handleOnError?.(error);
        console.error('Error deleting book:', error);
      },
    },
  );
}

export function useVideoCourseImportApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
): UseMutationResult<
  VideoCourseImportResponse,
  Error,
  {
    tournament_date: string;
    title: string;
    category_id: number;
    s3_path: string;
    mrp: number;
    price: number;
    coach: string;
    tags: string;
    is_private: number;
    is_free: number;
    thumbnail?: string;
    difficulty?: string;
    tournament_id?: number;
    tournament_category?: string;
  }
> {
  return useMutation<
    VideoCourseImportResponse,
    Error,
    {
      tournament_date: string;
      title: string;
      category_id: number;
      s3_path: string;
      mrp: number;
      price: number;
      coach: string;
      tags: string;
      is_private: number;
      is_free: number;
      thumbnail?: string;
      difficulty?: string;
      tournament_id?: number;
      tournament_category?: string;
    }
  >(
    async ({
      tournament_date,
      title,
      category_id,
      s3_path,
      mrp,
      price,
      coach,
      tags,
      thumbnail,
      difficulty = 'beginner',
      is_private,
      is_free,
      tournament_id,
      tournament_category,
    }) => {
      const token = await getLocalStorage('access_token');
      const formData = new FormData();

      formData.append('tournament_date', tournament_date);
      formData.append('title', title);
      formData.append('category_id', category_id.toString());
      formData.append('s3_path', s3_path);
      formData.append('mrp', mrp.toString());
      formData.append('price', price.toString());
      formData.append('coach', coach);
      formData.append('tags', tags);

      if (thumbnail) {
        formData.append('thumbnail', thumbnail);
      }

      if (difficulty) {
        formData.append('difficulty', difficulty);
      }

      formData.append('is_private', is_private.toString());
      formData.append('is_free', is_free.toString());

      if (tournament_id) {
        formData.append('tournament_id', tournament_id.toString());
      }

      if (tournament_category) {
        formData.append('tournament_category', tournament_category);
      }

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };

      return ChessRestClient.post(
        'learning/import_video_course/',
        formData,
        options,
      );
    },
    {
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );
}

export function useBookOrderApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
): UseMutationResult<BuyBookResponse, Error, BuyBookPayload> {
  return useMutation<BuyBookResponse, Error, BuyBookPayload>(
    async (payload: BuyBookPayload) => {
      const token = await getLocalStorage('access_token');
      const options: any = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      };
      const res = await ChessPaymentRestClient.post(
        'v1/generate_generic_payment_link',
        payload,
        options,
      );
      return res.data;
    },
    {
      // You can add onSuccess, onError, etc. here if needed
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );
}

export function useBookOrderStatusApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
): UseMutationResult<BookOrderStatusResponse, Error, BookOrderStatusPayload> {
  return useMutation<BookOrderStatusResponse, Error, BookOrderStatusPayload>(
    async (payload: BookOrderStatusPayload) => {
      const token = await getLocalStorage('access_token');
      const options: any = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: payload,
      };
      const res = await ChessRestClient.get('book_order_status', options);
      return res.data;
    },
    {
      // You can add onSuccess, onError, etc. here if needed
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );
}

export function useVideoCoursesApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
): UseMutationResult<any, Error, any> {
  return useMutation<any, Error, any>(
    async (payload: any) => {
      const token = await getLocalStorage('access_token');
      const options: any = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: payload,
      };
      const res = await ChessRestClient.get(
        'learning/get_video_courses/',
        options,
      );
      return res.data;
    },
    {
      // You can add onSuccess, onError, etc. here if needed
      onSettled: (data, error) => {
        if (handleOnSuccess && data) handleOnSuccess(data);
        else if (handleOnError && error) handleOnError(error);
      },
    },
  );
}
