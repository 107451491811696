import React, {useState, useEffect, useRef} from 'react';
import {Image, View} from 'react-native';
import BlindFoldChess from '../BlindFoldChess/BlindFoldChess';
import CCText from '@/Atoms/CCText';
import blindFoldStyles from '../../styles';
import CCButton from '@/Atoms/CCButton';
import ChessboardComponent from 'chessboard-package';
import ConditionalRender from '@/Atoms/ConditionalRender';
import CCColors from '@/Utils/CCColors';
import Box from '@/Atoms/Box';
import wrongSound from '@/Assets/Sounds/wrong.mp3';
import successSound from '@/Assets/Sounds/correct-sound-square-trainer.mp3';
import useSound from 'use-sound';
import TimeControl from '../TimeControl';
import LottieView from 'react-native-web-lottie';
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import useGetSquareTrainerLevel from '@/Hooks/SquareTrainerApi/getSquareTrainerApi';
import useSaveSquareTrainerResult from '@/Hooks/SquareTrainerApi/saveSquareTrainerApi';
import styles from './styles';
import SubscriptionModal from '@/Screens/Subscription/components/Modals';
import {
  FontSizes,
  SCREEN_WIDTH,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  hs,
} from '@/Utils/Dimensions';
import {ScrollView} from 'react-native-gesture-handler';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import levelUnitFilled from '@/Assets/Images/Svgs/levelUnitFilled.svg';
import levelUnitEmpty from '@/Assets/Images/Svgs/levelUnitEmpty.svg';
import LevelModal, {getLevel, LevelInfoBox, UpgradeLevel} from '../LevelModal';
import Divider from '@/Atoms/Divider/Divider';
import SlideModal from '@/Atoms/SlideModal';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';
import Spacer from '@/Atoms/Spacer';

const SquareTrainer = ({selectedValue, setShowDropDown, dashboardData}) => {
  const {isMobileOrTab} = useMediaQuery();
  const [timer, setTimer] = useState<number>(10);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [score, setScore] = useState<number>(0);
  const [coordinates, setCoordinates] = useState<string>('');
  const [squareColor, setSquareColor] = useState<string>('');
  const [orientation, setOrientation] = useState<string>('white');
  const [wrongMoves, setWrongMoves] = useState<Number>(0);
  const [isTimerOver, setIsTimerOver] = useState<boolean>(false);
  const [removeFill, setRemoveFill] = useState<number>(0);
  const [hideChessBoard, setHideChessBoard] = useState<boolean>(false);
  const [level, setLevel] = useState(0);
  const [objectData, setObjectData] = useState([]);
  const [accuracy, setAccuracy] = useState('');
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();
  const [showLevelModal, setShowLevelModal] = useState<boolean>(true);
  const chessboardColorTheme = useChessboardColorTheme();
  const [threshold, setThreshold] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [isUpgradeVisible, setIsUpgradeVisible] = useState(false);
  const getAnimation = () => {
    return '30s.json';
  };

  const timeControls = [
    {control: '10s', icon: 'timer10', selected: true},
    {control: '20s', icon: 'timer20', selected: false},
    {control: '30s', icon: 'timer30', selected: false},
  ];

  const colorTimeControls = [
    {control: 'As White', icon: 'white', selected: true},
    {control: 'As Black', icon: 'black', selected: false},
  ];

  const [selectedControl, setSelectedControl] = useState(timeControls[0]);
  const [selectedColorControl, setSelectedColorControl] = useState(
    colorTimeControls[0],
  );
  const [config, setConfig] = useState<string>('10s');
  const [num, setNum] = useState(1);

  const [incorrectSound] = useSound(wrongSound);
  const [rightSound] = useSound(successSound);

  const selectControl = control => {
    for (var i = 0; i < timeControls.length; i++) {
      timeControls[i].selected = false;
      if (timeControls[i].control === control) {
        timeControls[i].selected = true;
        setSelectedControl(timeControls[i]);
      }
    }
  };

  const selectColorControl = control => {
    for (var i = 0; i < colorTimeControls.length; i++) {
      colorTimeControls[i].selected = false;
      if (colorTimeControls[i].control === control) {
        colorTimeControls[i].selected = true;
        setSelectedColorControl(colorTimeControls[i]);
      }
    }
  };

  const correctAnswersConfig = (nextLevel: number, accuracy) => {
    const correctAnswersRequirement = [
      {level: 2, correct: 3},
      {level: 3, correct: 6},
      {level: 4, correct: 8},
      {level: 5, correct: 10},
    ];

    const requirement = correctAnswersRequirement.find(
      req => req.level === nextLevel,
    );

    if (requirement)
      return `Guess atleast ${
        requirement.correct * num
      } correct squares in ${config} in last 10 attempts to move to next level`;
  };

  const handleOnSuccessLevel = data => {
    const previousLevel = level;
    const nextThreshold = (data?.next_threshold * 10)?.toFixed(1);
    const attempts = data?.positions_count;
    const levelApi = data?.level;
    const correct = data?.last_entries.map(plan => {
      const averageCorrect = (
        parseFloat(plan.average_correct_count) * 10
      ).toFixed(1);
      return averageCorrect;
    });
    const accuracyApi = (data?.average_correct_count * 10)?.toFixed?.(1);
    setObjectData(correct);
    setAttempts(attempts);
    setThreshold(nextThreshold);
    setLevel(levelApi);
    setAccuracy(accuracyApi);

    if (data?.level - previousLevel === 1) {
      setIsUpgradeVisible(true);
    }
  };

  const handleOnFailedLevel = error => {
    console.log('error while getting square trainer level', error);
  };
  useEffect(() => {
    setIsUpgradeVisible(true);
    const timeoutId = setTimeout(() => {
      setIsUpgradeVisible(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [level]);

  const handleOnSuccessSave = data => {
    setLimitType(data?.limit_type);
    setUsageLimit(data?.usage_limit);
    setPlanName(data?.plan_name);
    // if (data?.is_permitted === false) {
    //   setSubscriptionModalOpen(true);
    //   return;
    // }
    if (data?.level) {
      setLevel(data?.level);
    }
  };

  const handleOnFailedSave = error => {
    console.log('error while saving square trainer result', error);
  };

  const {
    mutate: getSquareTrainerLevel,
    levelData,
    levelIsLoading,
  } = useGetSquareTrainerLevel(handleOnSuccessLevel, handleOnFailedLevel);

  const {
    mutate: saveSquareTrainerResult,
    saveResultData,
    saveResultIsLoading,
  } = useSaveSquareTrainerResult(handleOnSuccessSave, handleOnFailedSave);

  const generateRandomCoordinates = () => {
    const files = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
    const ranks = ['1', '2', '3', '4', '5', '6', '7', '8'];
    const randomFile = files[Math.floor(Math.random() * files.length)];
    const randomRank = ranks[Math.floor(Math.random() * ranks.length)];
    return `${randomFile}${randomRank}`;
  };

  const startTimer = () => {
    setSquareColor('');
    setCoordinates(generateRandomCoordinates());
    setScore(0);
    const startTime = Date.now();
    const endTime = startTime + timer * 1000;
    const id = setInterval(() => {
      const now = Date.now();
      const remainingTime = Math.max(0, endTime - now);
      const elapsed = timer * 1000 - remainingTime;
      const decrement = (elapsed / (timer * 1000)) * 100;
      setRemoveFill(decrement);
      if (remainingTime === 0) {
        clearInterval(id);
        setIntervalId(null);
        setSquareColor('');
        setIsTimerOver(true);

        setRemoveFill(0);
      }
    }, 100);
    setIntervalId(id);
  };

  const handleSquareClick = (square: string) => {
    if (square === coordinates && intervalId) {
      setScore(prevScore => prevScore + 1);
      setCoordinates(generateRandomCoordinates());

      setSquareColor(CCColors.LightGreen);
      rightSound();
    } else {
      setSquareColor(CCColors.Red);
      setCoordinates(generateRandomCoordinates());
      incorrectSound();
    }

    if (square !== coordinates && intervalId) {
      setWrongMoves(prev => prev + 1);
    }
  };

  useEffect(() => {
    setCoordinates(generateRandomCoordinates());
    getSquareTrainerLevel();

    const id = setTimeout(() => {
      setShowLevelModal(false);
    }, 5000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  let fillPerc = 100 - removeFill;
  let tintColor = CCColors.Green;
  if (fillPerc >= 75 && fillPerc <= 100) {
    tintColor = CCColors.Green;
  } else if (fillPerc >= 50 && fillPerc < 75) {
    tintColor = CCColors.Progress.Bar;
  } else if (fillPerc < 50) {
    tintColor = CCColors.WrongMoves.Background;
  }

  useEffect(() => {
    setTimer(parseInt(selectedControl.control.slice(0, 2)));

    if (isTimerOver) {
      saveSquareTrainerResult({
        correct_count: score,
        timer: Number(selectedControl.control.slice(0, 2)),
      });
    }
  }, [isTimerOver]);
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'game' : 'games'
  } is exhausted. Upgrade now to continue with the trainer.`;
  function handlePressOnGetLevel() {
    setShowLevelModal(true);
    getSquareTrainerLevel();
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {!isMobileOrTab && <Spacer spacing={15} />}
      <Box>
        <Box
          {...(isMobileOrTab && {
            flexDirection: 'column',
            flexWrap: 'wrap',
          })}>
          <ConditionalRender
            condition={
              !isMobileOrTab || (isMobileOrTab && !intervalId && !isTimerOver)
            }
            childrenA={
              <Box
                flexDirection={isMobileOrTab ? 'column' : 'row'}
                justifyContent="flex-end"
                alignItems="center"
                width={'100%'}
                flexWrap="wrap"
                gap={10}
                marginTop={10}
                marginBottom={!isMobileOrTab ? 15 : undefined}></Box>
            }
          />
        </Box>
        {/* {!isMobileOrTab && <Divider />} */}

        <Box
          {...(isMobileOrTab
            ? {
                flexDirection: 'column',

                flexWrap: 'wrap',
              }
            : {
                flexDirection: 'column',
                flexWrap: 'wrap',
              })}>
          <Box
            flexDirection="row"
            style={{
              height: '100%',
              width: '100%',
              justifyContent: 'space-between',
            }}>
            {!isMobileOrTab && showLevelModal && (
              <SlideModal
                visible={showLevelModal}
                type="center"
                customStyle={styles.levelModalCustom}>
                <Box
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    margin: 0,
                    overflow: 'hidden',
                  }}>
                  <LevelModal
                    level={level}
                    accuracy={accuracy}
                    config={correctAnswersConfig}
                    onClose={() => setShowLevelModal(false)}
                    isSquareTrainer={showLevelModal}
                    objectData={objectData}
                    threshold={threshold}
                  />
                </Box>
              </SlideModal>
            )}
            {isMobileOrTab && showLevelModal && (
              <SlideModal
                visible={showLevelModal}
                type="center"
                customStyle={styles.levelMobileModalCustom}>
                <Box
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: 0,
                    margin: 0,
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}>
                  <LevelModal
                    level={level}
                    accuracy={accuracy}
                    config={correctAnswersConfig}
                    onClose={() => setShowLevelModal(false)}
                    isSquareTrainer={showLevelModal}
                    objectData={objectData}
                    threshold={threshold}
                  />
                </Box>
              </SlideModal>
            )}
            <Box
              flex={0.9}
              style={{
                overflow: 'hidden',
                height: '100%',

                alignSelf: 'flex-start',
              }}>
              <ConditionalRender
                condition={
                  (selectedValue === 'Square Trainer' && intervalId) ||
                  isTimerOver
                }
                childrenA={
                  <BlindFoldChess
                    disabled={isTimerOver}
                    handleSquareClick={handleSquareClick}
                    squareColor={squareColor}
                    style={{
                      position: 'relative',
                      margin: isMobileOrTab ? 0 : undefined,
                    }}
                    hideChessBoard={hideChessBoard}
                    setHideChessBoard={setHideChessBoard}
                    selectedValue={selectedValue}
                    piecesVisible={false}
                    boardOrientation={orientation}
                    position="rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"
                  />
                }
                childrenB={
                  <ConditionalRender
                    condition={!isMobileOrTab}
                    childrenA={
                      <Box
                        style={{
                          overflow: 'hidden',
                          maxWidth: 'calc(100vh - 180px)',
                        }}>
                        <ChessboardComponent
                          disabled
                          colorTheme={chessboardColorTheme}
                        />
                      </Box>
                    }
                  />
                }
              />
            </Box>
            <Box
              {...(!isMobileOrTab && {gap: 5})}
              // marginTop={5}
              style={{
                justifyContent: 'flex-end',
                alignSelf: 'flex-start',
                // marginLeft: isMobileOrTab ? '0' : '5%',

                width: isMobileOrTab ? '100%' : '50%',
              }}>
              <ConditionalRender
                condition={!intervalId && !isTimerOver}
                childrenA={
                  <>
                    {!isMobileOrTab && (
                      <LevelInfoBox
                        isMobileOrTab={isMobileOrTab}
                        isUpgradeVisible={isUpgradeVisible}
                        level={level}
                        handlePressOnGetLevel={handlePressOnGetLevel}
                        getLevel={getLevel}
                        marginVertical={0}
                      />
                    )}
                    <Box
                      flexDirection="column"
                      alignItems={!isMobileOrTab && 'center'}
                      justifyContent="flex-start"
                      style={{marginBottom: isMobileOrTab && 6}}
                      gap={5}>
                      <CCText
                        style={{
                          marginLeft: isMobileOrTab ? '5%' : undefined,
                          marginRight: isMobileOrTab ? '5%' : undefined,
                          marginTop: isMobileOrTab ? 28 : 10,
                        }}
                        fontSize={FontSizes[20]}
                        textAlign={!isMobileOrTab ? 'center' : 'justify'}
                        fontWeight={500}
                        color={CCColors.TextColor.Dark}>
                        {!isTimerOver ? `Square Trainer` : `Game Finished!`}
                      </CCText>
                      <CCText
                        style={{
                          marginLeft: isMobileOrTab ? '5%' : undefined,
                          marginRight: isMobileOrTab ? '5%' : undefined,
                        }}
                        fontSize={14}
                        fontWeight="regular"
                        color={CCColors.TextColor.LightGrey}
                        textAlign={!isMobileOrTab ? 'center' : 'justify'}>
                        Select the exact chessboard square shown on your screen
                      </CCText>
                    </Box>
                    {isMobileOrTab && (
                      <LevelInfoBox
                        isMobileOrTab={isMobileOrTab}
                        isUpgradeVisible={isUpgradeVisible}
                        level={level}
                        handlePressOnGetLevel={handlePressOnGetLevel}
                        getLevel={getLevel}
                        marginVertical={10}
                      />
                    )}
                    <CCText
                      fontSize={14}
                      color="#4D3F37"
                      fontWeight="medium"
                      textAlign={isMobileOrTab ? 'left' : 'center'}
                      style={{
                        marginLeft: isMobileOrTab ? '5%' : undefined,
                        marginTop: !isMobileOrTab ? 10 : 4,
                        paddingBottom: !isMobileOrTab && 4,
                        marginBottom: isMobileOrTab && 10,
                      }}>
                      Training Time
                    </CCText>

                    <Box
                      flexDirection="row"
                      justifyContent="center"
                      style={
                        isMobileOrTab
                          ? styles.mobiletimecontrol
                          : styles.timecontrol
                      }>
                      {timeControls.map(e => (
                        <TimeControl
                          width={hs(104)}
                          flexwrap={isMobileOrTab ? 'nowrap' : 'wrap'}
                          flex={0.3}
                          control={e.control}
                          icon={e.icon}
                          selected={selectedControl.control === e.control}
                          onClick={() => {
                            setTimer(parseInt(e.control.slice(0, 2)));
                            selectControl(e.control);
                            setConfig(e.control);
                            setNum(parseInt(e.control) / 10);
                          }}
                          height={isMobileOrTab ? 124 : 128}
                        />
                      ))}
                    </Box>
                    <Box
                      style={{
                        marginTop: !isMobileOrTab ? 10 : 13,
                        marginLeft: isMobileOrTab ? '5%' : undefined,
                      }}>
                      <CCText
                        textAlign={isMobileOrTab ? 'left' : 'center'}
                        fontSize={14}
                        fontWeight="medium"
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                        }}>
                        Board Orientation
                      </CCText>
                      <Box
                        flexDirection="row"
                        flexWrap="wrap"
                        justifyContent="center"
                        style={{
                          color: CCColors.TextBoxes.Borders,
                          marginLeft: isMobileOrTab ? '-7%' : undefined,
                          gap: isMobileOrTab ? '1.6%' : undefined,
                          marginTop: isMobileOrTab ? 10 : undefined,
                        }}>
                        {colorTimeControls.map(e => (
                          <TimeControl
                            prefixImageSource={
                              'ChessPieces/' +
                              e.control.split(' ')[1] +
                              '-Rook.svg'
                            }
                            width={hs(118.2)}
                            prefixImageWidth={15}
                            prefixImageHeight={21}
                            enableAnimation={false}
                            fontWeight="DM Sans"
                            fontSize={16}
                            height={48}
                            flex={0.3}
                            control={e.control}
                            icon={e.icon}
                            selected={
                              selectedColorControl.control === e.control
                            }
                            onClick={() => {
                              setOrientation(
                                e.control.split(' ')[1].toLowerCase(),
                              );
                              selectColorControl(e.control);
                            }}
                          />
                        ))}
                      </Box>
                    </Box>

                    <View
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <View
                        style={
                          !isMobileOrTab
                            ? (blindFoldStyles.buttonContainer,
                              {
                                justifyContent: 'center',
                                alignItems: 'center',
                              })
                            : (blindFoldStyles.buttonContainerMobile,
                              {
                                justifyContent: 'center',
                                alignItems: 'center',
                              })
                        }>
                        {!intervalId && (
                          <CCButton
                            type="outline"
                            onPress={() => {
                              startTimer();

                              if (isMobileOrTab) {
                                setShowDropDown(false);
                              }
                            }}
                            style={{
                              backgroundColor: CCColors.Primary.Default,
                              width: isMobileOrTab
                                ? 350
                                : WINDOW_WIDTH < 920
                                ? 340
                                : WINDOW_WIDTH < 1100
                                ? 390
                                : 472,
                              alignSelf: 'center',
                              marginTop: isMobileOrTab ? 32 : 15,
                              marginBottom: isMobileOrTab && 32,
                            }}>
                            <CCText
                              fontSize={14}
                              fontWeight="medium"
                              style={{
                                paddingVertical: isMobileOrTab ? 2 : 5,
                              }}>
                              Start Training
                            </CCText>
                          </CCButton>
                        )}
                      </View>
                    </View>
                  </>
                }
                childrenB={
                  <>
                    {isMobileOrTab && (
                      <BlindFoldChess
                        disabled={isTimerOver}
                        handleSquareClick={handleSquareClick}
                        squareColor={squareColor}
                        style={{
                          position: 'relative',
                          margin: isMobileOrTab ? 0 : undefined,
                        }}
                        hideChessBoard={hideChessBoard}
                        setHideChessBoard={setHideChessBoard}
                        selectedValue={selectedValue}
                        piecesVisible={false}
                        boardOrientation={orientation}
                        position="rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"
                      />
                    )}

                    <Box flex={1} justifyContent="center" alignItems="center">
                      {!isMobileOrTab && (
                        <Box
                          flexDirection="column"
                          alignItems={!isMobileOrTab && 'center'}
                          justifyContent="flex-start"
                          gap={5}
                          style={{
                            marginBottom: 20,
                          }}>
                          <CCText
                            style={{
                              marginLeft: isMobileOrTab ? '5%' : undefined,
                              marginRight: isMobileOrTab ? '5%' : undefined,
                            }}
                            fontSize={FontSizes[20]}
                            textAlign={!isMobileOrTab ? 'center' : 'justify'}
                            fontWeight={500}
                            color={CCColors.TextColor.Dark}>
                            {!isTimerOver ? `Square Trainer` : `Game Finished!`}
                          </CCText>
                          <CCText
                            style={{
                              marginLeft: isMobileOrTab ? '5%' : undefined,
                              marginRight: isMobileOrTab ? '5%' : undefined,
                            }}
                            fontSize={14}
                            fontWeight="regular"
                            color={CCColors.TextColor.LightGrey}
                            textAlign={!isMobileOrTab ? 'center' : 'justify'}>
                            Select the exact chessboard square shown on your
                            screen
                          </CCText>
                        </Box>
                      )}
                      <Box
                        style={{
                          marginVertical: isMobileOrTab ? 54 : undefined,
                        }}>
                        <AnimatedCircularProgress
                          key="Square Trainer"
                          rotation={0}
                          prefill={100}
                          size={isMobileOrTab ? 180 : 240}
                          width={isMobileOrTab ? 8 : 12}
                          fill={fillPerc}
                          tintColor={
                            isTimerOver
                              ? CCColors.Primary.lightBrown
                              : tintColor
                          }
                          backgroundColor={
                            isTimerOver
                              ? CCColors.White
                              : CCColors.Box.MiddleLine
                          }>
                          {fill => (
                            <Box
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                width: 180,
                                height: 180,
                                backgroundColor: CCColors.White,
                                borderRadius: '50%',
                              }}>
                              {!isTimerOver ? (
                                <CCText
                                  fontSize={FontSizes[48]}
                                  fontWeight="medium">
                                  {coordinates}
                                </CCText>
                              ) : (
                                <>
                                  <Box
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                      height: 48,
                                      width: 48,
                                      marginTop: 4,
                                    }}>
                                    <LottieView
                                      source={require(`@/Assets/Animation/` +
                                        getAnimation())}
                                      autoPlay
                                      loop={true}
                                      style={{height: 48, width: 48}}
                                    />
                                  </Box>
                                  <CCText
                                    fontSize={20}
                                    fontWeight="medium"
                                    textAlign="center">
                                    Time up!
                                  </CCText>
                                  <CCText
                                    fontSize={14}
                                    fontWeight="semibold"
                                    textAlign="center">
                                    Your Score
                                  </CCText>
                                </>
                              )}
                            </Box>
                          )}
                        </AnimatedCircularProgress>
                      </Box>

                      <Box
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                        marginTop={30}
                        style={{
                          width: isMobileOrTab ? '100%' : 304,
                          height: 64,
                          backgroundColor: CCColors.White,
                          borderRadius: 8,
                        }}>
                        <Box
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          gap={16}
                          flex={1}
                          style={{
                            borderRightColor: CCColors.Box.MiddleLine,
                            borderRightWidth: 1,
                            height: '100%',
                          }}>
                          <Box justifyContent="center" style={styles.right}>
                            <CCText
                              fontSize={FontSizes[16]}
                              color={CCColors.Green}
                              textAlign="center">
                              {score}
                            </CCText>
                          </Box>
                          <CCText
                            fontSize={FontSizes[16]}
                            fontWeight="medium"
                            textAlign="center">
                            Right
                          </CCText>
                        </Box>
                        <Box
                          flex={1}
                          flexDirection="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          gap={16}>
                          <CCText
                            fontSize={FontSizes[16]}
                            fontWeight="medium"
                            textAlign="center">
                            Wrong
                          </CCText>
                          <Box justifyContent="center" style={styles.wrong}>
                            <CCText
                              fontSize={FontSizes[16]}
                              color={CCColors.WrongMoves.Background}
                              textAlign="center">
                              {wrongMoves}
                            </CCText>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                }
              />

              <ConditionalRender
                condition={isTimerOver}
                childrenA={
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      marginTop: 20,
                      marginBottom: 32,
                      width: isMobileOrTab ? SCREEN_WIDTH : undefined,
                    }}>
                    <CCButton
                      onPress={() => {
                        setIsTimerOver(false);
                        setScore(0);
                        setWrongMoves(0);
                      }}
                      style={{
                        width: 308,
                      }}>
                      <CCText
                        fontSize={14}
                        fontWeight="medium"
                        style={{paddingVertical: isMobileOrTab ? 10 : 5}}>
                        Start Again
                      </CCText>
                    </CCButton>
                  </Box>
                }
              />
            </Box>

            {/* <SubscriptionModal
              visible={subscriptionModalOpen}
              onClose={() => setSubscriptionModalOpen(false)}
              user={dashboardData?.user}
              planMessage={planMessage}
              currentPlan={planName}
            /> */}
          </Box>
        </Box>
      </Box>
    </ScrollView>
  );
};

export default SquareTrainer;
