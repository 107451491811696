import { useMutation } from '@tanstack/react-query';
import TournamentRestClient from '@/Api/TournamentRestClient';

export default function useGetTournamentStandings(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void
) {
  const mutation = useMutation(
    async ({ round, tournamentId }: { round: number; tournamentId: string }) => {
      const response = await TournamentRestClient.get(
        `/tournament/players-ranking?round=${round}&tournamentId=${tournamentId}`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: (error) => {
        if (handleOnError) handleOnError(error);
      },
    }
  );

  return mutation;
}

