import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {styles} from './styles';
import SlideModal from '@/Atoms/SlideModal';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import CCTextInput from '@/Atoms/CCTextInput';
import useManageParticipants from '@/Hooks/ManageStudyParticipantsApi/useManageParticipants';

const Addedparticipants = ({
  handleParticipants,
  manageParticipantsData,
  manageParticipantsError,
  refetchStudy,
  isOwner,
  gameId,
  startLoader = () => {},
}) => {
  const [addNewPlayer, setAddNewPlayer] = useState(false);
  const [playerDetails, setPlayerDetails] = useState({phone: ''});
  const [errors, setErrors] = useState({flag: false, msg: ''});
  //   const {
  //     mutate: manageParticipants,
  //     isLoading: isUpdatingPlayers,
  //     data: manageParticipantsData,
  //     error: manageParticipantsError,
  //   } = useManageParticipants();

  //   useEffect(() => {
  //     console.log(manageParticipantsData, 'manageParticipantsData');
  //     if (manageParticipantsData) {
  //       debugger;
  //     }
  //     if (manageParticipantsData?.success) {
  //       debugger;
  //       refetchStudy?.();
  //       setAddNewPlayer(false);
  //     } else if (manageParticipantsData || manageParticipantsError) {
  //       debugger;
  //       setErrors({
  //         flag: true,
  //         msg: 'Player already added',
  //       });
  //     }
  //   }, [manageParticipantsData, manageParticipantsError]);

  const handleAddClick = () => {
    setAddNewPlayer(true);
  };

  return (
    <Box>
      {isOwner && (
        <View style={styles.stickyButtonWrapper}>
          <TouchableOpacity style={styles.addNewBox} onPress={handleAddClick}>
            <CCText color={CCColors.White}>+ add new</CCText>
          </TouchableOpacity>
        </View>
      )}
      <SlideModal
        type="center"
        // width={0.3}
        modalHeaderTitle={'Add player'}
        visible={addNewPlayer}
        onClose={() => {
          setAddNewPlayer(false);
        }}>
        <Box type="center" margin={20}>
          <CCTextInput
            // fontSize={14}
            value={playerDetails?.phone}
            style={{
              marginTop: 20,
            }}
            error={errors}
            autoFocus
            label="Enter player's mobile number"
            onChangeText={text => {
              setErrors({flag: false, msg: ''});
              setPlayerDetails({phone: text});
            }}
            placeholderTextColor={CCColors.TextColor.Grey}
          />
          <Spacer spacing={20} />
          <CCButton
            onPress={async () => {
              startLoader?.();
              await handleParticipants(playerDetails?.phone, 'add');
            }}
            disabled={errors?.flag}>
            Add
          </CCButton>
        </Box>
      </SlideModal>
    </Box>
  );
};

export default Addedparticipants;
