import React, {useState, useEffect} from 'react';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';
import CCPackageTable from '@/Atoms/CCPackageTable';
import useGetTournamentStandings from '@/Hooks/TournamentsApi/useGetTournamentStandings';
import {View} from 'react-native';
import {Image} from 'react-native';
import NoStanding from '@/Assets/Images/Tournaments/no_standing.svg';
import useIsMobile from '@/Hooks/useIsMobile';

const Standings: React.FC<{round: number; tournamentId: string}> = ({
  round,
  tournamentId,
}) => {
  const isMobileOrTab = useIsMobile();
  const {
    mutate: fetchTournamentStandings,
    data: tournamentStandings,
    isLoading,
    isError,
    error,
  } = useGetTournamentStandings();

  // Fetch the tournament details when the component mounts
  useEffect(() => {
    if (tournamentId) {
      fetchTournamentStandings({round, tournamentId});
    }
  }, [round]);

  // useEffect(() => {
  //   // Uncomment the following block once the API is ready
  //   if (tournamentStandings){

  //   }
  // }, [tournamentStandings]);

  const standingsData = tournamentStandings?.data?.length
    ? tournamentStandings.data
    : [];

  const headers = ['#', 'Player', 'Score', 'Tie Break'];

  // Transform standingsData into tableData format
  const tableData = standingsData.map((item, index) => {
    const position = index + 1;
    const name = item.player_name;
    const score = item.point;
    const tieBreak = item.TB1;

    let itemValues: any = [];

    itemValues.push({
      name: (
        <Box style={{padding: vs(10), width: '100%', height: '100%'}}>
          <CCText fontSize={FontSizes[12]}>{position}</CCText>
        </Box>
      ),
      type: 'text',
    });

    itemValues.push({
      name: (
        <Box style={{padding: vs(10), width: '100%', height: '100%'}}>
          <CCText fontSize={FontSizes[12]}>{name}</CCText>
        </Box>
      ),
      type: 'text',
    });

    itemValues.push({
      name: (
        <Box style={{padding: vs(10), width: '100%', height: '100%'}}>
          <CCText fontSize={FontSizes[12]}>{score}</CCText>
        </Box>
      ),
      type: 'text',
    });

    itemValues.push({
      name: (
        <Box style={{padding: vs(10), width: '100%', height: '100%'}}>
          <CCText fontSize={FontSizes[12]}>{tieBreak}</CCText>
        </Box>
      ),
      type: 'text',
    });

    return itemValues;
  });

  // Style adjustments for standings rows
  const customFlexArr = [1, 3, 2, 2];

  // Handle row press (e.g., navigate to player details)
  const handleRowPress = (rowIndex: number) => {
    console.log(`Row ${rowIndex} pressed`);
  };

  return (
    <Box>
      {/* <CCText
        textAlign="center"
        fontSize={FontSizes[14]}
        fontWeight="bold"
        style={{ marginBottom: vs(10) }}>
        Standings after round {tournamentStandings?.data[0].round || round}
      </CCText> */}
      {tableData?.length > 0 ? (
        <CCPackageTable
          headers={headers}
          tableData={tableData}
          flexArr={customFlexArr}
          handleRowPress={handleRowPress}
          borderBottomColor={CCColors.BorderBottom}
          headerTextColor={CCColors.TextColor.Grey}
          margin={0}
          jsxTable={false}
          isMargin={false} // Enables Pressable rows
          tournament={true}
          backgroundColorRow={true}
          tableBorder={false}
        />
      ) : (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
            gap: 20,
            marginTop: isMobileOrTab ? 0 : '5%',
          }}>
          <Image
            resizeMode="contain"
            style={{
              width: isMobileOrTab ? 180 : 300,
              height: isMobileOrTab ? 180 : 300,
            }}
            source={NoStanding}
          />
          <CCText fontSize={24} style={{lineHeight: 24, fontWeight: 500}}>
            No standing found
          </CCText>
          <CCText
            fontSize={14}
            color={CCColors?.TextColor?.Dark}
            style={{lineHeight: 20, fontWeight: 400}}>
            Standings will appear here after one round.
          </CCText>
        </View>
      )}
    </Box>
  );
};

export default Standings;
