import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function useDownloadGameReviewPGN(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation(
    async ({gameUuid}: {gameUuid: string}) => {
      const response = await ChessRestClient.get(
        `game/download_game_pgn?game_uuid=${gameUuid}`,
      );
      return response.data;
    },
    {
      onSuccess: data => {
        handleOnSuccess?.(data);

        if (data?.downloadable_link) {
          const link = document.createElement('a');
          link.href = data.downloadable_link;
          link.setAttribute('download', 'game.pgn');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('Invalid response data: ', data);
        }
      },
      onError: error => {
        handleOnError?.(error);
      },
    },
  );

  return {...mutation};
}
