import React, {useState, useEffect} from 'react';
import {
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native';
import CCText from '@/Atoms/CCText';
import CCButton from '@/Atoms/CCButton';
import Feather from '@expo/vector-icons/Feather';
import Box from '@/Atoms/Box';
import useGetTournamentDetails from '@/Hooks/TournamentsApi/useGetTournamentDetails';
import NotationComponent from '@/Screens/PlayChess/components/NotationComponent';
import useGetUserGamesApi from '@/Hooks/GetUserGamesApi/useGetUserGamesApi';
import CCColors from '@/Utils/CCColors';

// Import individual components for tabs
import Standings from './standings'; // Replace with your actual component
import Games from './games'; // Replace with your actual component
// import Chat from './chat'; // Replace with your actual component
import TournamentDetails from './TournamentDetails'; // Replace with your actual component
import BottomControlsComponent from '@/Screens/PlayChess/components/BottomControlsComponent';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {hasTracingEnabled} from '@sentry/core';
import {CustomInfoBox} from '@/Atoms/InfoIcon';
import {useNavigation} from '@react-navigation/native';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const TournamentPlay = props => {
  // Default tab
  const [round, setRound] = useState(0);
  const {
    moves,
    opponentTime,
    myTime,
    myTurn,
    username,
    white,
    displayName,
    opponent,
    whitePieces,
    blackPieces,
    setPosition,
    firstMoveTimer,
    showFirstMoveTimer,
    setMoveClicked,
    setMoveSelected,
    startfen,
    setPreviousFen,
    myRating,
    opponentRating,
    playing,
    setHint,
    setHint2,
    isBlackTurnFirst,
    position,
    setShowIncorrectHintModal,
    tournamentId,
    current_round,
    handleBack,
    joinTournament,
    playersJoined,
    userId,
    active_tab,
    roundStartTime,
    setWhitePlayer,
    game_uuid,
    roundEnd,
    showDrawRequest,
    drawRequestSent,
    drawRequest,
    handleDrawResponse,
    resign,
    abort,
    showAbort,
    showDrawReject,
    setShowDrawReject,
    setGameId,
    tournamentMoves,
    startfenTournament,
    withDrawPlayer,
    illegalTouchPieceCount,
  } = props;
  const [activeTab, setActiveTab] = useState(active_tab);
  const [isTournamentActive, setIsTournamentActive] = useState(true);
  const navigation = useNavigation();
  const {isMobileOrTab} = useMediaQuery();

  // const [gameId, setGameId] = useState('');
  // const [myGame, setMyGame] = useState(true);

  // const {mutate: fetchGames, data} = useGetUserGamesApi(
  // );
  // useEffect(() => {
  //   if (gameId) {
  //     fetchGames({page: 1, game_id: gameId});
  //   } else if (game_uuid) {
  //     fetchGames({page: 1, game_id: game_uuid});
  //   }
  // }, [gameId, game_uuid]);

  // useEffect(() => {
  //   if (data) {
  //     console.log("game fetched by game id, data:",data);
  //     setWhitePlayer((data?.data?.data.username == displayName || data?.data?.data.opponent_username == displayName) ? (data?.data?.data?.userid == userId && data?.data?.data?.color == 'White' ? userId : data?.data?.data?.opponent_username) : data?.data?.data?.color == 'White' ? userId : '' );
  //     setMyGame(data?.data?.data.username == displayName || data?.data?.data.opponent_username == displayName);
  //     setPosition(data?.data?.data?.start_fen);
  //   }
  // }, [data]);

  const [details, setDetails] = useState({
    timeControl: '25min',
    tournamentDuration: '4hrs',
    ratedGames: 'No',
    completedGames: 10,
    rounds: 24,
    players: '30',
    players_joined: [],
  });

  const {
    mutate: fetchTournamentDetails,
    data: tournamentData,
    isLoading,
    isError,
    error,
  } = useGetTournamentDetails();

  // Fetch the tournament details when the component mounts
  useEffect(() => {
    if (tournamentId) {
      fetchTournamentDetails(tournamentId);
    }
  }, [playersJoined]);

  useEffect(() => {
    console.log('inside active_tab change useEffect active_tab:', active_tab);
    setActiveTab(active_tab);
  }, [active_tab]);

  useEffect(() => {
    console.log(
      'inside current_round change useEffect current_round:',
      current_round,
    );
    setRound(current_round);
  }, [current_round]);

  const [statusMessage, setStatusMessage] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [start_date, setStartDate] = useState<string>('');
  const [tournamentStarted, setTouramentStarted] = useState(0);

  useEffect(() => {
    console.log('Initial start_date:', start_date);

    const startTime = new Date(start_date).getTime();
    if (isNaN(startTime)) {
      console.error('Invalid start_date format:', start_date);
      setStatusMessage('Invalid start date');
      return;
    }

    const endTime = startTime + 2 * 60 * 60 * 1000; // Tournament duration in milliseconds

    const updateStatus = () => {
      const now = new Date().getTime();
      let difference = startTime - now - 10000;
      if (difference < 0) {
        setTouramentStarted(1);
      }
      if (roundStartTime) {
        difference = roundStartTime - now;
      }

      console.log(
        'Current time:',
        now,
        'Start time:',
        startTime,
        'Difference:',
        difference,
      );

      if (difference > 0) {
        // Tournament hasn't started yet
        const hours = Math.floor(difference / 1000 / 60 / 60);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setStatusMessage(
          roundStartTime
            ? `Round ${current_round} starts in`
            : 'Tournament starts in',
        );
        const formattedTime =
          hours > 0
            ? `${hours}:${String(minutes).padStart(2, '0')}:${String(
                seconds,
              ).padStart(2, '0')}`
            : `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
                2,
                '0',
              )}`;
        setTimeLeft(formattedTime);
      } else if (roundEnd) {
        setStatusMessage(`Round ${round} has ended`);
      } else if (isTournamentActive) {
        // Tournament is in progress
        if (round) {
          setTimeout(() => {
            setStatusMessage(`Round ${round} in progress`);
          }, 2000);
        } else {
          setStatusMessage(`Tournament in progress`);
        }

        clearInterval(intervalId);
        setTimeLeft('');
      } else {
        // Tournament has ended
        setStatusMessage('Tournament has ended');
        setTimeLeft('');
        clearInterval(intervalId);
      }
    };

    // Run the updateStatus immediately and then every second
    updateStatus();
    const intervalId = setInterval(updateStatus, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [start_date, roundStartTime, roundEnd, round]);

  useEffect(() => {
    // Uncomment the following block once the API is ready
    if (tournamentData) {
      setDetails({
        timeControl: tournamentData.data.time_control || 'N/A',
        tournamentDuration: tournamentData.data.duration || 'N/A',
        ratedGames: tournamentData.data.rated ? 'Yes' : 'No',
        completedGames: tournamentData.data.completedGames || 0,
        rounds: tournamentData.data.rounds || 0,
        players: `${
          tournamentData.data.player_fide_ids?.split(',').length || 0
        }`,
        players_joined: tournamentData.data.players_joined,
      });
      console.log(
        'players:',
        tournamentData.data.player_fide_ids?.split(',').length,
      );
      setRound(tournamentData.data.current_round || 1);
      setStartDate(tournamentData?.data?.start_date);
      setIsTournamentActive(tournamentData?.data?.is_active);
      if (!tournamentData?.data?.is_active) {
        setStatusMessage('Tournament has ended');
      }
      navigation.setParams({
        tournamentId,
        tournamentName: tournamentData?.data?.name,
      });
    }
  }, [tournamentData]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'standings':
        return (
          <ScrollView showsVerticalScrollIndicator={false}>
            <Standings round={round} tournamentId={tournamentId} />
          </ScrollView>
        );
      case 'games':
        return (
          <ScrollView showsVerticalScrollIndicator={false}>
            <Games
              round={round}
              tournamentId={tournamentId}
              setGameId={setGameId}
              setActiveTab={setActiveTab}
              playing={playing}
              isMobileOrTab={isMobileOrTab}
            />
          </ScrollView>
        );
      case 'notation':
        return playing ? (
          <Box flexDirection="column" flex={10}>
            <Box flex={8}>
              <NotationComponent
                moves={moves}
                opponentTime={opponentTime}
                myTime={myTime}
                myTurn={myTurn}
                username={username}
                white={white}
                displayName={displayName}
                opponent={opponent}
                whitePieces={whitePieces}
                blackPieces={blackPieces}
                setPosition={setPosition}
                firstMoveTimer={firstMoveTimer}
                showFirstMoveTimer={showFirstMoveTimer}
                setMoveClicked={setMoveClicked}
                setMoveSelected={setMoveSelected}
                startfen={startfen}
                setPreviousFen={setPreviousFen}
                myRating={myRating}
                opponentRating={opponentRating}
                playing={playing}
                setHint={setHint}
                setHint2={setHint2}
                isBlackTurnFirst={isBlackTurnFirst}
                position={position}
                setShowIncorrectHintModal={setShowIncorrectHintModal}
                showPlayerComponent={false}
                illegalTouchPieceCount={illegalTouchPieceCount}
              />
            </Box>
            <Box flex={2} style={{marginTop: 10}}>
              <BottomControlsComponent
                showDrawRequest={showDrawRequest}
                drawRequestSent={drawRequestSent}
                drawRequest={drawRequest}
                handleDrawResponse={handleDrawResponse}
                resign={resign}
                abort={abort}
                showAbort={false}
                playing={playing}
                showDrawReject={showDrawReject}
                setShowDrawReject={setShowDrawReject}
              />
            </Box>
          </Box>
        ) : (
          <NotationComponent
            moves={tournamentMoves}
            opponentTime={opponentTime}
            myTime={myTime}
            myTurn={myTurn}
            username={username}
            white={white}
            displayName={displayName}
            opponent={opponent}
            startfen={startfenTournament}
            myRating={myRating}
            opponentRating={opponentRating}
            whitePieces={[]}
            blackPieces={[]}
            setPosition={setPosition}
            firstMoveTimer={firstMoveTimer}
            showFirstMoveTimer={false}
            setMoveClicked={setMoveClicked}
            setMoveSelected={setMoveSelected}
            setPreviousFen={setPreviousFen}
            playing={playing}
            setHint={setHint}
            setHint2={setHint2}
            setShowIncorrectHintModal={setShowIncorrectHintModal}
            showPlayerComponent={false}
          />
        );
      case 'details':
        return (
          <ScrollView showsVerticalScrollIndicator={false}>
            <TournamentDetails tournamentId={tournamentId} details={details} />
          </ScrollView>
        );
      default:
        return (
          <ScrollView showsVerticalScrollIndicator={false}>
            <TournamentDetails tournamentId={tournamentId} details={details} />
          </ScrollView>
        );
    }
  };

  const handleJoin = () => {
    joinTournament(tournamentId.toString());
  };

  const handleWithDraw = () => {
    withDrawPlayer(username, tournamentId, round);
  };

  return (
    <SafeAreaView style={styles.container}>
      {/* Tournament Header */}
      {
        <View style={styles.header}>
          {!(
            tournamentStarted &&
            tournamentData?.data?.player_fide_ids
              ?.split(',')
              .includes(userId) &&
            statusMessage !== 'Tournament has ended'
          ) && (
            <TouchableOpacity style={styles.backButton} onPress={handleBack}>
              <Feather name="arrow-left" size={24} color="#5B5B5B" />
            </TouchableOpacity>
          )}

          <View style={styles.titleContainer}>
            <CCText style={styles.title}>{tournamentData?.data?.name}</CCText>
            <CCText style={styles.subtitle}>
              Swiss • {tournamentData?.data?.rounds} •{' '}
              {tournamentData?.data?.time_control}
            </CCText>
          </View>
          <View style={styles.actions}>
            {/* <Box style={styles.iconButton}>
            <Feather name="edit-2" size={16} color="#5B5B5B" />
          </Box> */}
            {tournamentData?.data?.player_fide_ids
              ?.split(',')
              .includes(userId) &&
              statusMessage !== 'Tournament has ended' &&
              !playing &&
              !roundEnd &&
              !roundStartTime && (
                <CustomInfoBox
                  text="Withdraw from the tournament"
                  customView={
                    <Box style={styles.iconButton} onPress={handleWithDraw}>
                      <MaterialCommunityIcons
                        name="location-exit"
                        size={18}
                        color="#5B5B5B"
                      />
                    </Box>
                  }
                />
              )}

            {!tournamentData?.data?.player_fide_ids
              ?.split(',')
              .includes(userId) && (
              <CCButton
                style={styles.ctaButton}
                disabled={
                  tournamentData?.data?.player_fide_ids
                    ?.split(',')
                    .includes(userId) || statusMessage == 'Tournament has ended'
                }
                onPress={handleJoin}>
                <CCText style={styles.ctaText}>Join</CCText>
              </CCButton>
            )}
          </View>
        </View>
      }

      {!isMobileOrTab && <Box style={styles.playModeContainer}>
        <Box
          onPress={() => {
            setActiveTab('notation');
          }}
          style={[styles.playButtonStyle, styles.playButtonStyleLeft]}>
          {activeTab == 'notation' && (
            <Image
              source={require('@/Assets/Images/Caissa/TournamenTab.svg')}
              style={[
                {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                },
              ]}
            />
          )}
          <CCText
            style={[
              styles.playButtonTxtStyle,
              activeTab == 'notation' && {color: CCColors.White},
            ]}>
            Game
          </CCText>
        </Box>

        <Box
          onPress={() => {
            setActiveTab('details');
            setGameId('');
          }}
          style={[styles.playButtonStyle, styles.playButtonStyleRight]}>
          {activeTab !== 'notation' && (
            <Image
              source={require('@/Assets/Images/Caissa/TournamenTab.svg')}
              style={[
                {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                },
              ]}

              // resizeMethod="scale"
              // resizeMode="stretch"
            />
          )}

          <CCText
            style={[
              styles.playButtonTxtStyle,
              activeTab !== 'notation' && {color: CCColors.White},
            ]}>
            Tournament
          </CCText>
        </Box>
      </Box>}

      {/* Tabs */}
      {activeTab !== 'notation' && (
        <View style={styles.tabs}>
          <TouchableOpacity
            style={[styles.tab, activeTab === 'standings' && styles.activeTab]}
            onPress={() => setActiveTab('standings')}>
            <CCText style={styles.tabText}>Standings</CCText>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.tab, activeTab === 'games' && styles.activeTab]}
            onPress={() => setActiveTab('games')}>
            <CCText style={styles.tabText}>Pairings</CCText>
          </TouchableOpacity>

          {/* <TouchableOpacity
            style={[styles.tab, activeTab === 'chat' && styles.activeTab]}
            onPress={() => setActiveTab('chat')}
          >
            <CCText style={styles.tabText}>Chat</CCText>
        </TouchableOpacity> */}

          <TouchableOpacity
            style={[styles.tab, activeTab === 'details' && styles.activeTab]}
            onPress={() => setActiveTab('details')}>
            <CCText style={styles.tabText}>Details</CCText>
          </TouchableOpacity>
        </View>
      )}
      {activeTab !== 'notation' && (
        <View style={styles.TimerContainer}>
          <CCText style={styles.statusText}>{statusMessage} </CCText>
          {timeLeft && <CCText style={styles.timeText}>{timeLeft}</CCText>}
        </View>
      )}

      {/* Render Active Tab */}
      <View style={styles.tabContent}>{renderActiveTab()}</View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    borderRadius: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
  },
  tab: {
    flex: 1,
    paddingVertical: 10,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: '#FFC107',
  },
  tabText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333',
  },
  backButton: {
    padding: 8,
    marginRight: 16,
  },
  subtitle: {
    fontSize: 14,
    color: '#666',
    marginTop: 4,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {
    backgroundColor: '#F6F6F6',
    borderRadius: 25,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  ctaButton: {
    marginLeft: 10,
  },
  ctaText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#fff',
  },
  tabs: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  tabButton: {
    flex: 1,
    paddingVertical: 12,
    alignItems: 'center',
  },
  activeTabText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333',
  },
  tabContent: {
    flex: 1,
    marginTop: 16,
    height: 'auto',
  },
  TimerContainer: {
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage:
      'linear-gradient(90deg, rgba(250, 246, 235, 0) 0%, rgba(250, 246, 235, 1) 30%, #FAF6EB 70%, rgba(250, 246, 235, 0) 100%)',
    cursor: 'pointer',
  },
  statusText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 4,
    alignSelf: 'center',
  },
  timeText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#666',
  },
  playModeContainer: {
    flexDirection: 'row',
    overflow: 'visible',
    margin: 16,
    // marginTop: 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    // flex: 1,
  },
  playModeContainerSelected: {
    backgroundColor: CCColors?.Primary?.Brown,
  },
  playButtonTxtStyle: {
    paddingVertical: 14,
  },
  playButtonStyle: {
    position: 'relative',
    flex: 0.5,

    // overflowY: 'hidden',
    // aspectRatio: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: CCColors.White,
  },
  playButtonStyleLeft: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#2623221A',
    // border: 1px solid #2623221A
  },
  playButtonStyleRight: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#2623221A',
  },
});

export default TournamentPlay;
