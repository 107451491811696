import Environment from '../../environment';
import axios from 'axios';
import {onFulfilled} from './interceptors/request';
import {handleLogout} from '@/Utils/CommonUtils';
import {useNavigation} from '@react-navigation/native';
import {ScreenName} from '@/navigators/StackConstants';

// const cc_ws_client = require('/home/sk/sources/cc-client-sdk/lib/index.js');
// const schema = require('/home/sk/sources/cc-client-sdk/lib/schema.js');
const {schema, cc_ws_client} = require('../../CCClientSdk');
// const schema = require('/Users/nitu/Circlechess/cc-client-sdk/lib/schema.js');
// const cc_ws_client = require('/Users/nitu/Circlechess/cc-client-sdk/lib/index.js');
let gameClient = null;

const GamePlayClient = (
  username: string,
  accessToken: string,
  callbacks: {
    startGameCallback;
    joinGameResponseCallback;
    gameReadyResponseCallback;
    preStartGameResponseCallback;
    playerTurnResponseCallback;
    startGameResponseCallback;
    drawCheckResponseCallback;
    drawRejectedCallback;
    challengeRequestCallback;
    challengeRejectedCallback;
    challengeExpiredCallback;
    onlinePlayersSearchResult;
    gameEndResponseCallback;
    challengeCancelCallback;
    // These are outgoing message encoders
    challengeResponseMessageHandler;
    registerJoinGameSchema;
    registerLeaveTableSchema;
    registerPlayerMoveSchema;
    registerOnlinePlayersSearchSchema;
    registerChallengeRequest;
  },
) => {
  console.log('Connecting with GamePlay server .. ', callbacks);
  // if (!gameClient) {
  gameClient = cc_ws_client(Environment.GAME_SERVICE_URL, {
    transports: ['websocket'],
    forceNew: true,
    query:
      'username=' +
      username +
      '&deviceId=92bd7016-002a-4983-ad2f-fb41b9ea45b2&sessionId=6c807ca8-cfc8-4866-9ae8-f95bf66fcf2d&Authorization=' +
      accessToken,
    // Authorization: accessToken, // Placeholder for token
    //apikey: Environment.API_KEY
  });
  // }

  initializeEvents(callbacks);

  // return back the normal game client that is already instantiated
  return gameClient;

  function initializeEvents({
    startGameCallback,
    joinGameResponseCallback,
    gameReadyResponseCallback,
    preStartGameResponseCallback,
    playerTurnResponseCallback,
    startGameResponseCallback,
    drawCheckResponseCallback,
    drawRejectedCallback,
    challengeRequestCallback,
    challengeRejectedCallback,
    challengeExpiredCallback,
    onlinePlayersSearchResult,
    gameEndResponseCallback,
    challengeCancelCallback,
    challengeResponseMessageHandler,
    registerJoinGameSchema,
    registerLeaveTableSchema,
    registerPlayerMoveSchema,
    registerOnlinePlayersSearchSchema,
    registerChallengeRequest,
  }) {
    /**
     * Event definitions for the game client
     */
    if (startGameResponseCallback) {
      gameClient.registerMessageType(
        'start_game_response',
        schema.startGameResMsgSchema,
        function (socket, data) {
          console.log('*** startGameResponseCallback = ', data);
          startGameResponseCallback(socket, data);
        },
      );
    }

    // Marked for deletion
    // --------------------
    if (registerJoinGameSchema) {
      gameClient.registerMessageType(
        'join_game_request',
        schema.joinGameReqMsgSchema,
        function (socket, data) {
          registerJoinGameSchema(socket, data);
        },
      );
    }

    if (registerLeaveTableSchema) {
      gameClient.registerMessageType(
        'leave_table_request',
        schema.leaveTableReqMsgSchema,
        function (socket, data) {
          registerLeaveTableSchema(socket, data);
        },
      );
    }

    if (challengeCancelCallback) {
      gameClient.registerMessageType(
        'challenge_cancel',
        schema.challengeGameMsgSchema,
        function (socket, data) {
          challengeCancelCallback(socket, data);
        },
      );
    }

    if (registerChallengeRequest) {
      gameClient.registerMessageType(
        'challenge_game_request',
        schema.challengeGameMsgSchema,
        function (socket, data) {
          console.log('About to call challenge response message handler ... ');
          registerChallengeRequest(socket, data);
        },
      );
    }

    if (joinGameResponseCallback) {
      gameClient.registerMessageType(
        'join_game_response',
        schema.joinGameResMsgSchema,
        function (socket, data) {
          joinGameResponseCallback(socket, data);
        },
      );
    }

    if (gameReadyResponseCallback) {
      gameClient.registerMessageType(
        'game_ready_response',
        schema.gameReadyResMsgSchema,
        function (socket, data) {
          gameReadyResponseCallback(socket, data);
        },
      );
    }

    if (preStartGameResponseCallback) {
      gameClient.registerMessageType(
        'pre_start_game',
        schema.preStartGameMsgSchema,
        function (socket, data) {
          preStartGameResponseCallback(socket, data);
        },
      );
    }

    if (playerTurnResponseCallback) {
      gameClient.registerMessageType(
        'player_turn_response',
        schema.eventResMsgSchema,
        function (socket, data) {
          playerTurnResponseCallback(socket, data);
        },
      );
    }

    if (registerPlayerMoveSchema) {
      gameClient.registerMessageType(
        'player_move',
        schema.moveResMsgSchema,
        function (socket, data) {
          registerPlayerMoveSchema(socket, data);
        },
      );
    }

    if (startGameCallback) {
      gameClient.registerMessageType(
        'start_game',
        schema.startGameMsgSchema,
        function (socket, data) {
          startGameCallback(socket, data);
        },
      );
    }

    if (drawCheckResponseCallback) {
      gameClient.registerMessageType(
        'draw_check',
        schema.drawCheckMsgSchema,
        function (socket, data) {
          drawCheckResponseCallback(socket, data);
        },
      );
    }

    if (drawRejectedCallback) {
      gameClient.registerMessageType(
        'draw_reject',
        schema.drawRejectMsgSchema,
        function (socket, data) {
          drawRejectedCallback(socket, data);
        },
      );
    }

    if (challengeRequestCallback) {
      console.log('Inside challenge Request Check .. ');
      gameClient.registerMessageType(
        'challenge_request_check',
        schema.challengeGameMsgSchema,
        function (socket, data) {
          console.log('Inside challenge_request_check .... ');
          challengeRequestCallback(socket, data);
        },
      );
    }

    if (challengeRejectedCallback) {
      gameClient.registerMessageType(
        'challenge_reject',
        schema.challengeGameMsgSchema,
        function (socket, data) {
          challengeRejectedCallback(socket, data);
        },
      );
    }

    if (challengeExpiredCallback) {
      gameClient.registerMessageType(
        'challenge_request_expire',
        schema.challengeGameMsgSchema,
        function (socket, data) {
          challengeExpiredCallback(socket, data);
        },
      );
    }

    if (registerOnlinePlayersSearchSchema) {
      gameClient.registerMessageType(
        'online_players_search',
        schema.onlinePlayersSearchMsgSchema,
        function (socket, data) {
          registerOnlinePlayersSearchSchema(socket, data);
        },
      );
    }

    if (onlinePlayersSearchResult) {
      gameClient.registerMessageType(
        'online_players_result',
        schema.onlinePlayersResultMsgSchema,
        function (socket, data) {
          console.log('ONline players result = ', data);
          onlinePlayersSearchResult(socket, data);
        },
      );
    }

    if (gameEndResponseCallback) {
      gameClient.registerMessageType(
        'game_end_response',
        schema.gameEndResMsgSchema,
        function (socket, data) {
          gameEndResponseCallback(socket, data);
        },
      );
    }
  }
};

export default GamePlayClient;
