import React, {useState, useEffect, useRef} from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Modal,
  Image,
  Switch,
  Pressable,
} from 'react-native';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import ChessboardComponent from 'chessboard-package';
import {Chess} from 'chess.js';
import useAnimation from '@/Hooks/useAnimation';
import Animated from 'react-native-reanimated';
import CCColors from '@/Utils/CCColors';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import ConditionalRender from '@/Atoms/ConditionalRender';
import Divider from '@/Atoms/Divider/Divider';
import Slider from '@react-native-community/slider';
import {FontSizes, SCREEN_WIDTH, isPhone, vs} from '@/Utils/Dimensions';
import NotationComponent from '@/Screens/PlayChess/components/NotationComponent';
import useSaveIntuitionTrainerMove from '@/Hooks/IntuitionTrainerApi/saveIntuitionTrainerMove';
import useIntuitionTrainerState from '@/Store/IntuitionTrainerStore';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import masterIcon from '@/Assets/Images/png/master.png';
import engineIcon from '@/Assets/Images/png/engine.png';
import lateGuessIcon from '@/Assets/Images/png/late_guess.png';
import backArrow from '@/Assets/Images/png/back_arrow.png';
import settingsClose from '@/Assets/Images/png/settings_close.png';

import styles from './styles';
import StockfishComponent from '@/Components/StockfishEngine/StockfishComponent';
// import {START_INTUITION_FROM_MOVE} from '@/Utils/Constants';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import {STARTING_FEN} from '@/Utils/CommonUtils';

const clearLocalStorage = trainerStore => {
  trainerStore?.setPgn([]);
  trainerStore?.setCurrentMoveIndex(0);
  trainerStore?.setBoardPosition('start');
  trainerStore?.setCount(0);
  trainerStore?.setCounter(0);
  trainerStore?.setMovesList([]);
  trainerStore?.setNoOfMovesAttempted(0);
  trainerStore?.setLateGuesses(0);
  trainerStore?.setIsTimerOn('No');
  trainerStore?.setIsToggleEnabled(false);
  trainerStore?.setWhitePieces([]);
  trainerStore?.setBlackPieces([]);
  trainerStore?.setText1('');
  trainerStore?.setItem({});
  trainerStore?.setGames([]);
  trainerStore?.setTotalPages(1);
  trainerStore?.setCurrentColor('');
  trainerStore?.setEngineMatchCount(0);
  trainerStore?.setGameAccuracy(0);
};

const IntuitionTrainerAnalysis = ({
  color,
  pgn,
  game,
  setIsGameStarted,
  setLevel,
}) => {
  const trainerStore = useIntuitionTrainerState();
  console.log("zzzzzz...",game);
  const START_INTUITION_FROM_MOVE = 2*game?.intuition_trainer_moves_display_count ||  20;
  const [playerOneColor, setPlayerOneColor] = useState(
    trainerStore?.playerColor || color?.toLowerCase(),
  );

  let playerTwoColor = 'white';

  const [currentMoveIndex, setCurrentMoveIndex] = useState<number>(
    trainerStore?.currentMoveIndex || START_INTUITION_FROM_MOVE,
  );
  const [boardPosition, setBoardPosition] = useState<string>(
    trainerStore?.boardPosition || STARTING_FEN,
  );
  let CColor = playerOneColor !== playerTwoColor ? 'white' : 'black';
  const [currentColor, setCurrentColor] = useState<string>(
    trainerStore?.currentColor || CColor,
  );
  const [playerMove, setPlayerMove] = useState<string>('');
  const [count, setCount] = useState<number>(trainerStore?.count || 0);
  const [counter, setCounter] = useState<number>(trainerStore?.counter || 0);
  const [movesList, setMovesList] = useState<any[]>(
    trainerStore?.movesList || [],
  );
  const [noOfMovesAttempted, setNoOfMovesAttempted] = useState<number>(
    trainerStore?.noOfMovesAttempted || 0,
  );
  const [matched, setMatched] = useState<boolean>(false);
  const [isHumanMove, setIsHumanMove] = useState<boolean>(false);
  const [engineMatchCount, setEngineMatchCount] = useState<number>(
    trainerStore?.engineMatchCount || 0,
  );
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(30);
  const [visible, setVisible] = useState<boolean>(false);
  const [moveSelected, setMoveSelected] = useState<{
    fen: string;
    lastFen: string;
    selectedMoveNumber: any;
  }>({
    fen: '',
    lastFen: '',
    selectedMoveNumber: 0,
  });
  const [lateGuesses, setLateGuesses] = useState<number>(
    trainerStore?.lateGuesses || 0,
  );
  const [isTimerOn, setIsTimerOn] = useState<string>(
    trainerStore?.isTimerOn || 'No',
  );
  const [clicked, setClicked] = useState<boolean>(false);
  const [isToggleEnabled, setIsToggleEnabled] = useState<boolean>(
    trainerStore?.isToggleEnabled || false,
  );
  const [whitePieces, setWhitePieces] = useState<string[]>(
    trainerStore?.whitePieces || [],
  );
  const [blackPieces, setBlackPieces] = useState<string[]>(
    trainerStore?.blackPieces || [],
  );

  const [initialMovesList, setinitialMovesList] = useState([]);

  const [resultantArray, setResultantArray] = useState<any[]>([]);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [applySettingsButtonCount, setApplySettingsButtonCount] =
    useState<number>(0);
  const [userGameDetails, setUserGameDetails] = useState<{}>({});
  const [gameAccuracy, setGameAccuracy] = useState<number>(
    trainerStore?.gameAccuracy,
  );

  const [feedbackMsg, setFeedBackMsg] = useState<string>('');
  const [showGameDetails, setShowGameDetails] = useState<boolean>(false);
  const [engineMove, setEngineMove] = useState<string>('');
  const [engineScore, setEngineScore] = useState<number>(0);
  const [previousFen, setPreviousFen] = useState<string>('start');

  const chessboardColorTheme = useChessboardColorTheme();
  const {isMobileOrTab} = useMediaQuery();
  const [newLevel, setNewLevel] = useState(0);
  const [threshold, setThreshold] = useState('');
  const [positionCount, setPositionCount] = useState(0);
  const handleOnSuccessMoveSave = data => {
    setLevel(data?.level);
    setNewLevel(data?.level);
    setThreshold(data?.next_threshold);
    setPositionCount(data?.positions_count);
    setGameAccuracy(() => {
      trainerStore?.setGameAccuracy(data?.game_accuracy * 100);
      return data?.game_accuracy * 100;
    });
    setFeedBackMsg(
      `Congrats! Your level has increased from ${data?.initial_level} to ${data?.level}`,
    );
  };

  const handleOnFailedMoveSave = error => {
    console.log('error while saving the move', error);
  };

  const {
    mutate: saveMove,
    // data,
    // isLoading,
  } = useSaveIntuitionTrainerMove(
    handleOnSuccessMoveSave,
    handleOnFailedMoveSave,
  );

  useEffect(() => {
    setMoveSelected(prev => ({...prev, fen: trainerStore?.boardPosition}));
    console.log(trainerStore, 'trainerStore');
  }, [trainerStore?.boardPosition]);

  const showInitial10Moves = movesArray => {
    let formattedMoves = '';
    for (let i = 0; i < movesArray.length && i < 20; i += 2) {
      const moveNumber = Math.floor(i / 2) + 1;
      const whiteMove = movesArray[i];
      const blackMove = movesArray[i + 1] || '';
      formattedMoves += `${moveNumber}. ${whiteMove} ${blackMove} `;
    }
    return formattedMoves.trim();
  };

  const initializeMovesList = moves => {
    console.log('inside initialize moves:', moves);
    const initialMovesList = moves.slice(0, START_INTUITION_FROM_MOVE).map((move, index) => {
      const content = {
        move: move,
        matched: false, // Initial matched is false since there's no player move comparison yet
        isHumanMove: false, // Assuming initial moves are not human moves
      };

      return content;
    });

    console.log('initial movesList:', initialMovesList);

    // Assuming you're using a state or store like trainerStore to set the initial moves list
    setinitialMovesList(initialMovesList);
  };

  const toggleSwitch = () =>
    setIsToggleEnabled(previousState => {
      trainerStore?.setIsToggleEnabled(!previousState);
      return !previousState;
    });
  const toggleEngineSwitch = () =>
    setIsEngineEnabled(previousState => !previousState);

  const [resultOpacityAnimStyle, startResultOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 1,
    end: 1,
    repeat: true,
    numberOfReps: 2,
    inputRange: [0, 0.1],
    duration: 1000,
  });

  const timerIdRef = useRef(null);

  let moves =
    pgn.length > 0
      ? pgn
          ?.split(/\d+\./)
          .filter(move => move.trim() !== '')
          .flatMap(move => move.trim()?.split(' ').filter(Boolean))
      : trainerStore?.pgn
          ?.split(/\d+\./)
          .filter(move => move.trim() !== '')
          .flatMap(move => move.trim()?.split(' ').filter(Boolean));

  moves = moves?.slice(0, moves?.length - 1);

  let tournament = game ? game?.event_name : trainerStore?.item.event_name;
  let year = game?.event_date?.split('.')[0];

  function generateFEN(movesArr) {
    const chess = new Chess();

    for (let move of movesArr) {
      chess.move(move);
    }

    return chess.fen();
  }

  useEffect(() => {
    console.log(trainerStore?.boardPosition, 'trainerStore?.boardPosition');
    setBoardPosition(
      trainerStore?.boardPosition ||
        generateFEN(moves.slice(0, START_INTUITION_FROM_MOVE)),
    );
    initializeMovesList(moves);
    // setPreviousFen(
    //   trainerStore?.previousFen ||
    //     generateFEN(moves.slice(0, START_INTUITION_FROM_MOVE - 1)),
    // );
  }, []);

  useEffect(() => {
    const updatePosition = () => {
      if (currentMoveIndex < moves?.length) {
        let position =
          generateFEN(moves.slice(0, START_INTUITION_FROM_MOVE)) || 'start';
        if (playerOneColor === playerTwoColor && counter === 0) {
          position = position;
        } else if (playerOneColor !== playerTwoColor && counter === 0) {
          position =
            generateFEN(moves.slice(0, START_INTUITION_FROM_MOVE)) || 'start';
        } else {
          position =
            boardPosition === 'start'
              ? 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
              : boardPosition;
        }
        console.log('position is:', position);
        const board = new Chess(position);
        const move = moves[currentMoveIndex].trim();

        const normalizeMove = move => move.replace(/[+#]/g, '');

        if (
          currentColor === color &&
          normalizeMove(move) === normalizeMove(playerMove)
        ) {
          if (isTimerOn === 'Yes') {
            if (timer > 0) {
              setCount(prev => {
                trainerStore?.setCount(prev + 1);
                return prev + 1;
              });
            } else {
              setLateGuesses(prev => {
                trainerStore?.setLateGuesses(prev + 1);
                return prev + 1;
              });
            }
          } else {
            setCount(prev => {
              trainerStore?.setCount(prev + 1);
              return prev + 1;
            });
          }
          setMatched(true);
        } else {
          setMatched(false);
        }

        if (
          playerMove === engineMove &&
          playerMove.length > 0 &&
          engineMove.length > 0
        ) {
          setEngineMatchCount(prev => {
            trainerStore.setEngineMatchCount(prev + 1);
            return prev + 1;
          });
        }
        let moveResponse;
        try {
          moveResponse = board.move(move);
        } catch (err) {
          console.log(err);
          return;
        }
        if (moveResponse.captured) {
          if (moveResponse.color === 'w') {
            setBlackPieces(prevArray => {
              trainerStore?.setBlackPieces([
                ...prevArray,
                moveResponse.captured?.toUpperCase(),
              ]);
              return [...prevArray, moveResponse.captured?.toUpperCase()];
            });
          } else {
            setWhitePieces(prevArray => {
              trainerStore?.setWhitePieces([
                ...prevArray,
                moveResponse.captured?.toUpperCase(),
              ]);
              return [...prevArray, moveResponse.captured?.toUpperCase()];
            });
          }
        }

        let prevFen;
        let fen;
        setBoardPosition(prevPosition => {
          // if (prevPosition === 'start') {
          //   prevPosition = generateFEN(
          //     moves.slice(0, START_INTUITION_FROM_MOVE - 1),
          //   );
          // }
          setPreviousFen(prevPosition);
          if (playerOneColor === playerTwoColor && counter === 0) {
            return generateFEN(moves.slice(0, START_INTUITION_FROM_MOVE));
          }
          prevFen = prevPosition;
          setNoOfMovesAttempted(prev => {
            trainerStore?.setNoOfMovesAttempted(prev + 1);
            return prev + 1;
          });
          fen = board.fen();

          trainerStore?.setBoardPosition(fen);
          setUserGameDetails({
            move: move,
            attempted_move: playerMove,
            move_index: currentMoveIndex,
            color: currentColor,
            engine_move_match: false,
            master_move_match:
              normalizeMove(move) === normalizeMove(playerMove),
            game_complete: isGameOver,
            game_id: game?.id,
            fen: fen,
          });
          saveMove({
            move: move,
            attempted_move: playerMove,
            move_index: currentMoveIndex,
            color: currentColor,
            engine_move_match: false,
            master_move_match:
              normalizeMove(move) === normalizeMove(playerMove),
            game_complete: isGameOver,
            game_id: game?.id,
            fen: fen,
          });
          return fen;
        });
        setCurrentColor(prevColor =>
          prevColor === 'white' ? 'black' : 'white',
        );
        trainerStore.setCurrentColor(
          currentColor === 'white' ? 'black' : 'white',
        );

        setMovesList(prev => {
          let content = {
            move: '',
            lastFen: '',
            fen: '',
            matched: normalizeMove(move) === normalizeMove(playerMove),
            isHumanMove,
          };
          if (playerOneColor === currentColor) {
            if (normalizeMove(move) === normalizeMove(playerMove)) {
              content.move = `${move}`;
            } else {
              content.move = `${move}(${playerMove})`;
            }
          } else {
            content.move = `${move}`;
          }
          content.lastFen = prevFen;
          content.fen = fen;
          if (boardPosition !== 'start') {
            trainerStore?.setMovesList([...movesList, content]);
          }
          return [...movesList, content];
        });
      }
    };

    updatePosition();

    if (currentMoveIndex >= moves?.length - 1) {
      setIsGameOver(true);
      setVisible(true);
      setIsTimerOn(val => {
        if (val === 'Yes') {
          trainerStore?.setIsTimerOn('No');
          return 'No';
        } else {
          trainerStore?.setIsTimerOn('No');
          return 'No';
        }
      });
      trainerStore?.setPlayerColor('');
      saveMove({...userGameDetails, game_complete: true});
    }
  }, [currentMoveIndex, counter]);

  const handleNextMove = () => {
    setCurrentMoveIndex(prevIndex => {
      if (counter === 0) {
        setCounter(1);

        trainerStore?.setCounter(1);
        if (playerOneColor === playerTwoColor) {
          return START_INTUITION_FROM_MOVE;
        } else {
          return START_INTUITION_FROM_MOVE + 1;
        }
      }

      trainerStore?.setCurrentMoveIndex(
        Math.min(prevIndex + 1, moves.length - 1),
      );
      return Math.min(prevIndex + 1, moves.length - 1);
    });
  };

  let actualMovesCount = Math.floor(noOfMovesAttempted / 2);
  if (actualMovesCount === 0) {
    actualMovesCount = 1;
  }
  let masterPercValue = (count / actualMovesCount) * 100;
  masterPercValue = masterPercValue.toFixed(1);
  let enginePercValue = (engineMatchCount / actualMovesCount) * 100;
  enginePercValue = enginePercValue.toFixed(1);

  const hideModal = () => {
    setVisible(false);
  };

  const startTimer = () => {
    setTimer(30); // Reset timer to 10 seconds
    timerIdRef.current = setInterval(() => {
      setTimer(prev => {
        if (prev === 0) {
          clearInterval(timerIdRef.current); // Clear the interval when the timer reaches 0
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (isTimerOn === 'Yes' && !isGameOver) {
      startTimer();
    }
    return () => {
      clearInterval(timerIdRef.current); // Clear the interval when the component unmounts
    };
  }, [isTimerOn]);

  const handleNewFenHandler = event => {
    setResultantArray([]);
    setClicked(false);
    setIsHumanMove(true);
    setPlayerMove(event.san); // Set the updated move notation

    handleNextMove();

    setTimeout(() => {
      setCurrentMoveIndex(prevIndex => {
        trainerStore?.setCurrentMoveIndex(
          Math.min(prevIndex + 1, moves.length - 1),
        );
        return Math.min(prevIndex + 1, moves.length - 1);
      });
      setIsHumanMove(false);

      clearInterval(timerIdRef.current); // Clear the interval when the component unmounts

      if (isTimerOn === 'Yes') {
        startTimer();
      }
    }, 1000);
  };

  const piecedrop = () => {
    console.log('Piecedrop');
  };

  useEffect(() => {
    setApplySettingsButtonCount(0);
  }, [settingsOpen]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  let notationMoves = movesList.map(move => {
    return {
      move: move.move,
      matched: move.matched,
      isHumanMove: move.isHumanMove,
    };
  });

  let selectedMoveNumber;
  if (playerOneColor.toLowerCase() === 'white') {
    notationMoves = notationMoves.slice(1);
    selectedMoveNumber = movesList.length - 1;
  } else {
    selectedMoveNumber = movesList.length;
  }

  return (
    <>
      {console.log(boardPosition, 'boardPosition here')}
      <Box
        flexDirection={isMobileOrTab ? 'column' : 'row'}
        style={{
          backgroundColor: CCColors.Primary.lightBrown,
        }}
        height={isMobileOrTab && '100%'}>
        <ConditionalRender
          condition={!isMobileOrTab}
          childrenA={
            <Box flex={0.6} marginTop={40} style={styles.tournamentDetails}>
              <Box gap={5}>
                <Pressable
                  onPress={() => {
                    setIsGameStarted(false);
                    trainerStore?.setIsGameStarted(false);
                    trainerStore?.setPlayerColor('');
                    trainerStore?.setIsGamesFetched(false);
                    clearLocalStorage(trainerStore);
                  }}>
                  <Image
                    resizeMethod="resize"
                    source={{uri: backArrow}}
                    alt="back arrow"
                    style={{width: 20, height: 20}}
                  />
                </Pressable>

                <CCText fontWeight="medium" fontSize={16}>
                  {trainerStore?.text1} (Rated)
                </CCText>
                <CCText fontSize={11} fontWeight="medium">
                  {tournament}
                </CCText>
                <CCText fontSize={11} fontWeight="medium">
                  {year}
                </CCText>
              </Box>

              <Divider marginTop={20} marginBottom={20} />

              <Box flexDirection="row" justifyContent="space-around">
                <CCText>Play with timer</CCText>
                <Switch
                  disabled={movesList.length > 1 && timer < 30 && timer > 0}
                  trackColor={{
                    false: CCColors.Switch.TrackColorFalse,
                    true: CCColors.Switch.TrackColorTrue,
                  }}
                  thumbColor={
                    isToggleEnabled
                      ? CCColors.Switch.ThumbColorEnabled
                      : CCColors.Switch.ThumbColorDisabled
                  }
                  ios_backgroundColor={CCColors.Switch.IosBackgroundColor}
                  onValueChange={val => {
                    toggleSwitch();
                    setIsTimerOn(val => {
                      if (val === 'Yes') {
                        trainerStore?.setIsTimerOn('No');
                        return 'No';
                      } else {
                        trainerStore?.setIsTimerOn('Yes');
                        return 'Yes';
                      }
                    });
                  }}
                  value={isToggleEnabled}
                />
              </Box>

              <Divider marginTop={20} marginBottom={20} />
              {isTimerOn === 'Yes' && (
                <CCText fontSize={12} fontWeight="medium">
                  Make a move in 30s or it's counted late
                </CCText>
              )}
              <Spacer />
              <Box gap={20}>
                <Box>
                  <Box flexDirection="row" gap={10} alignItems="center">
                    <Image
                      resizeMethod="resize"
                      source={{uri: masterIcon}}
                      alt="master moves"
                      style={{width: 16, height: 16}}
                    />
                    <CCText fontSize={11} fontWeight="medium">
                      Master Matches
                    </CCText>
                  </Box>
                  <CCText
                    textAlign="left"
                    fontSize={14}
                    style={{marginLeft: 25}}>
                    {count}/{Math.floor(noOfMovesAttempted / 2)}(
                    {masterPercValue}%)
                  </CCText>
                </Box>
                {/* <Box>
                  <Box flexDirection="row" gap={10} alignItems="center">
                    <Image
                      resizeMethod="resize"
                      source={{uri: engineIcon}}
                      alt="engine moves"
                      style={{width: 16, height: 16}}
                    />
                    <CCText fontSize={11} fontWeight="medium">
                      Engine Matches
                    </CCText>
                  </Box>
                  <CCText
                    textAlign="left"
                    fontSize={14}
                    style={{marginLeft: 25}}>
                    {Math.floor(engineMatchCount)}/
                    {Math.floor(noOfMovesAttempted / 2)}({enginePercValue}%)
                  </CCText>
                </Box> */}
                <Box>
                  <Box flexDirection="row" gap={10} alignItems="center">
                    <Image
                      resizeMethod="resize"
                      source={{uri: lateGuessIcon}}
                      alt="late guesses"
                      style={{width: 16, height: 16}}
                    />
                    <CCText fontSize={11} fontWeight="medium">
                      Late Guesses
                    </CCText>
                  </Box>
                  <CCText
                    textAlign="left"
                    fontSize={14}
                    style={{marginLeft: 25}}>
                    {lateGuesses}
                  </CCText>
                </Box>
                {/* <CCText>{showInitial10Moves(moves)}</CCText> */}
              </Box>
            </Box>
          }
        />

        <Box
          flex={!isMobileOrTab ? 1 : undefined}
          marginTop={!isMobileOrTab ? 40 : undefined}>
          <ConditionalRender
            condition={!isMobileOrTab}
            childrenA={
              <Animated.View style={resultOpacityAnimStyle}>
                <View style={styles.container}>
                  <CCText
                    style={[
                      styles.puzzleSolutionText,
                      {
                        color: false ? CCColors.Green : CCColors.Red,
                      },
                    ]}>
                    {playerOneColor === playerTwoColor
                      ? 'White to Play'
                      : 'Black to Play'}
                  </CCText>
                </View>
              </Animated.View>
            }
          />

          <ConditionalRender
            condition={
              !clicked || selectedMoveNumber === moveSelected.selectedMoveNumber
            }
            childrenA={
              <Box flexDirection="row">
                <Box
                  flex={!isMobileOrTab ? 1 : undefined}
                  style={{
                    width: isMobileOrTab ? SCREEN_WIDTH : undefined,
                    height: isMobileOrTab ? SCREEN_WIDTH : undefined,
                  }}>
                  <ChessboardComponent
                    boardOrientation={
                      playerOneColor === playerTwoColor ? 'white' : 'black'
                    }
                    position={boardPosition}
                    lastMoveFen={previousFen !== 'start' && previousFen}
                    handleNewFen={event => {
                      setTimeout(() => {
                        handleNewFenHandler(event);
                      }, 500);
                    }}
                    colorTheme={chessboardColorTheme}
                  />
                </Box>
              </Box>
            }
            childrenB={
              <Box flexDirection="row">
                <Box flex={!isMobileOrTab ? 1 : undefined}>
                  <ChessboardComponent
                    boardOrientation={
                      playerOneColor === playerTwoColor ? 'white' : 'black'
                    }
                    position={moveSelected?.fen}
                    lastMoveFen={previousFen !== 'start' && previousFen}
                    handleNewFen={handleNewFenHandler}
                    disabled={
                      moveSelected?.selectedMoveNumber !==
                      [...initialMovesList, ...notationMoves].length
                    }
                    colorTheme={chessboardColorTheme}
                  />
                </Box>
              </Box>
            }
          />
        </Box>
        <ConditionalRender
          condition={isMobileOrTab}
          childrenA={
            <Box margin={5}>
              <ConditionalRender
                condition={isTimerOn === 'Yes'}
                childrenA={
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      width: 82,
                      height: 40,
                      borderRadius: 4,
                      backgroundColor:
                        timer > 7 ? CCColors.Green : CCColors.Red,
                    }}>
                    <CCText
                      fontWeight="thunder`"
                      fontSize={22}
                      textAlign="center"
                      color={CCColors.White}>
                      {timer >= 10 ? `00:${timer}` : `00:0${timer}`}
                    </CCText>
                  </Box>
                }
              />
              <CCText fontSize={16} fontWeight="medium">
                {trainerStore?.text1}
              </CCText>
              <Box flexDirection="row">
                <CCText
                  fontWeight="regular"
                  fontSize={12}
                  color={`${CCColors.TextColor.Primary}80`}>
                  Playing as{' '}
                  {playerOneColor === playerTwoColor ? 'white' : 'black'} |
                </CCText>
                <CCText
                  fontWeight="regular"
                  fontSize={12}
                  color={`${CCColors.TextColor.Primary}80`}>
                  {tournament}
                </CCText>
              </Box>
              <Box
                gap={20}
                flexDirection="row"
                justifyContent="flex-start"
                marginVertical={15}
                style={{padding: 10}}>
                <Switch
                  // disabled={movesList.length > 1}
                  trackColor={{
                    false: CCColors.Switch.TrackColorFalse,
                    true: CCColors.Switch.TrackColorTrue,
                  }}
                  thumbColor={
                    isToggleEnabled
                      ? CCColors.Switch.ThumbColorEnabled
                      : CCColors.Switch.ThumbColorDisabled
                  }
                  ios_backgroundColor={CCColors.Switch.IosBackgroundColor}
                  onValueChange={val => {
                    toggleSwitch();
                    setIsTimerOn(val => {
                      if (val === 'Yes') {
                        trainerStore?.setIsTimerOn('No');
                        return 'No';
                      } else {
                        trainerStore?.setIsTimerOn('Yes');
                        return 'Yes';
                      }
                    });
                  }}
                  value={isToggleEnabled}
                />
                <CCText fontSize={14} fontWeight="medium">
                  Play with timer
                </CCText>
              </Box>
              <Pressable onPress={() => setShowGameDetails(prev => !prev)}>
                <CCText
                  style={{marginHorizontal: 10, marginVertical: 5}}
                  fontSize={14}
                  fontWeight="medium"
                  color={CCColors.Green}>
                  {showGameDetails ? 'Hide' : 'Show'} Game Details
                </CCText>
              </Pressable>
              <ConditionalRender
                condition={showGameDetails}
                childrenA={
                  <Box flexDirection="row" style={{margin: 10}} gap={10}>
                    <Box gap={5}>
                      <Image
                        resizeMethod="resize"
                        source={{uri: masterIcon}}
                        alt="master moves"
                        style={{width: 16, height: 16}}
                      />
                      <CCText
                        fontSize={11}
                        fontWeight="medium"
                        color={`${CCColors.TextColor.Primary}80`}>
                        Master Matches
                      </CCText>
                      <CCText
                        fontSize={11}
                        fontWeight="medium"
                        color={`${CCColors.TextColor.Primary}80`}>
                        {count}/{Math.floor(noOfMovesAttempted / 2)}(
                        {masterPercValue}%)
                      </CCText>
                    </Box>
                    {/* <Box gap={5}>
                      <Image
                        resizeMethod="resize"
                        source={{uri: engineIcon}}
                        alt="engine moves"
                        style={{width: 16, height: 16}}
                      />
                      <CCText
                        fontSize={11}
                        fontWeight="medium"
                        color={`${CCColors.TextColor.Primary}80`}>
                        Engine Matches
                      </CCText>
                      <CCText
                        fontSize={11}
                        fontWeight="medium"
                        color={`${CCColors.TextColor.Primary}80`}>
                        {engineMatchCount}/{Math.floor(noOfMovesAttempted / 2)}(
                        {enginePercValue}%)
                      </CCText>
                    </Box> */}
                    <Box gap={5}>
                      <Image
                        resizeMethod="resize"
                        source={{uri: lateGuessIcon}}
                        alt="late guesses"
                        style={{width: 16, height: 16}}
                      />
                      <CCText
                        fontSize={11}
                        fontWeight="medium"
                        color={`${CCColors.TextColor.Primary}80`}>
                        Late Guesses
                      </CCText>
                      <CCText
                        fontSize={11}
                        fontWeight="medium"
                        color={`${CCColors.TextColor.Primary}80`}>
                        {lateGuesses}
                      </CCText>
                    </Box>
                  </Box>
                }
              />
            </Box>
          }
        />
        <Box
          marginTop={!isMobileOrTab ? 62 : undefined}
          flex={!isMobileOrTab ? 1 : undefined}
          marginBottom={isMobileOrTab ? 20 : undefined}
          alignContent="flex-end"
          overflow="hidden">
          <Spacer />
          {/* <Box
            style={{
              backgroundColor: matched && isHumanMove ? CCColors.Green : '',
            }}>
            <CCText
              color="white"
              textAlign="center"
              style={{padding: isMobileOrTab ? 5 : undefined}}>
              {matched && isHumanMove && 'Good Guess!'}
            </CCText>
          </Box> */}

          <Box
            style={{
              height: !isMobileOrTab ? '100%' : undefined,
              width: '100%',
              overflow: 'hidden',
            }}
            // flex={1}
            marginLeft={!isMobileOrTab ? 10 : undefined}
            marginRight={isMobileOrTab ? 10 : undefined}>
            {/* <StockfishComponent
              engineOn={true}
              getMoveAndScore={(move, score) => {
                setEngineMove(move);
                setEngineScore(score);
              }}
              fen={clicked ? moveSelected?.fen : boardPosition}
            /> */}
            <NotationComponent
              showPlayerComponent={!isMobileOrTab}
              setMoveSelected={setMoveSelected}
              setMoveClicked={setClicked}
              moves={[...initialMovesList, ...notationMoves]}
              myTime={isTimerOn === 'Yes' ? timer : null}
              white={game?.white_player_name}
              usernameFont={FontSizes[11]}
              startfen={
                'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
              }
              username={
                playerOneColor.toLowerCase() === 'white'
                  ? game?.white_player_name
                  : game?.black_player_name
              }
              opponent={
                playerOneColor.toLowerCase() === 'white'
                  ? game?.black_player_name?.split(',').map((name, index) => {
                      const trimmedName = name.trim();
                      const displayName =
                        trimmedName.length > 24
                          ? trimmedName.slice(0, 24) + '..'
                          : trimmedName;

                      return (
                        <React.Fragment key={index}>
                          {displayName}
                          {index === 0 && <br />} {index === 1 && <br />}
                        </React.Fragment>
                      );
                    })
                  : game?.white_player_name?.split(',').map((name, index) => {
                      const trimmedName = name.trim();
                      const displayName =
                        trimmedName.length > 24
                          ? trimmedName.slice(0, 24) + '..'
                          : trimmedName;

                      return (
                        <React.Fragment key={index}>
                          {displayName}
                          {index === 0 && <br />} {index === 1 && <br />}
                        </React.Fragment>
                      );
                    })
              }
              displayName={
                playerOneColor.toLowerCase() === 'white'
                  ? game?.white_player_name?.split(',').map((name, index) => {
                      const trimmedName = name.trim();
                      const displayName =
                        trimmedName.length > 13
                          ? trimmedName.slice(0, 13) + '..'
                          : trimmedName;

                      return (
                        <React.Fragment key={index}>
                          {displayName}
                          {index === 0 && <br />} {index === 1 && <br />}
                        </React.Fragment>
                      );
                    })
                  : game?.black_player_name?.split(',').map((name, index) => {
                      const trimmedName = name.trim();
                      const displayName =
                        trimmedName.length > 13
                          ? trimmedName.slice(0, 13) + '..'
                          : trimmedName;

                      return (
                        <React.Fragment key={index}>
                          {displayName}
                          {index === 0 && <br />} {index === 1 && <br />}
                        </React.Fragment>
                      );
                    })
              }
              marginLeftUsername={8}
              squareWidth={18}
              marginLeft={'2%'}
              whitePieces={whitePieces}
              blackPieces={blackPieces}
              setPosition={setBoardPosition}
              setPreviousFen={setPreviousFen}
              myTurn={currentMoveIndex}
              showMoveMessage={true}
              initialMoveCount={START_INTUITION_FROM_MOVE/2}
            />
          </Box>
        </Box>
        <Modal
          visible={visible}
          animationType="slide"
          transparent={true}
          onRequestClose={hideModal}>
          <TouchableWithoutFeedback onPress={hideModal}>
            <View
              style={{
                flex: 1,

                backgroundColor: CCColors.Modal.Background,
              }}>
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    padding: 20,
                    borderRadius: 10,
                    gap: 5,
                    backgroundColor: CCColors.White,
                    width: '35%',
                    alignSelf: 'center',
                  }}>
                  <CCText fontSize={18}>Game Finished!</CCText>
                  {<CCText>Accuracy: {masterPercValue}%</CCText>}
                  {
                    <CCText>
                      Average game accuracy over last 5 games is:{' '}
                      {gameAccuracy.toFixed(2)}%
                    </CCText>
                  }
                  {<CCText>Current level: {newLevel}</CCText>}
                  {newLevel < 5 && (
                    <CCText>
                      Achieve at least {Number(threshold).toFixed(0)}% accuracy
                      consistently for the last {positionCount} games to move to
                      level {newLevel + 1}.
                    </CCText>
                  )}
                  {/* {!gameAccuracy && 
                    <CCText>Play 5 games to level up! </CCText>
                  } */}
                  <CCButton
                    onPress={() => {
                      hideModal();
                    }}
                    style={{
                      backgroundColor: CCColors.Red,
                      marginTop: 10,
                    }}>
                    Close
                  </CCButton>
                  <CCButton
                    onPress={() => {
                      setIsGameStarted(false);
                      trainerStore?.setIsGameStarted(false);
                      clearLocalStorage(trainerStore);
                      trainerStore?.setIsGamesFetched(false);
                    }}
                    style={{
                      marginTop: 10,
                    }}>
                    Play new Game
                  </CCButton>
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </Box>
    </>
  );
};

export default IntuitionTrainerAnalysis;
