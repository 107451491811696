import React, {useState, useEffect} from 'react';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, hs, vs, wrapFail} from '@/Utils/Dimensions';
import {View, GestureResponderEvent, Dimensions} from 'react-native';
import LottieView from 'react-native-web-lottie';
import settings from '@/Assets/Images/png/settings.png';
import styles from './styles'; // Import styles
import Spacer from '@/Atoms/Spacer';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';

const {width: SCREEN_WIDTH} = Dimensions.get('screen');

interface TimeControlProps {
  control?: '1+0' | '3+2' | '5+3' | '10+5' | '15+10' | '25+10' | 'settings';
  icon?: any; // Update to specific type if known
  selected?: boolean;
  flex?: number; // Adjust type based on usage
  animate?: boolean;
  onClick?: (event: GestureResponderEvent) => void;
  onHover?: () => void; // Adjust if needed
  handleCustomConfigChange?: (selected: boolean) => void;
  isBeginnerPlayer?: boolean;
}

const TimeControl: React.FC<TimeControlProps> = ({
  control,
  icon,
  selected = false,
  flex,
  animate = false,
  onClick = () => {},
  onHover = () => {},
  handleCustomConfigChange = () => {},
  isBeginnerPlayer,
}) => {
  const {isMobileOrTab} = useMediaQuery();

  if (isBeginnerPlayer && (control === '1+0' || control === '3+2')) {
    return null;
  }

  const color = selected
    ? CCColors.Buttons.TimeControlBackground
    : CCColors.White;

  const borderWidth = selected ? 1 : 0.5;
  const circleColor = selected ? CCColors.White : CCColors.Primary.lightBrown;

  const getAnimation = () => {
    switch (control) {
      case '1+0':
        return 'Bullet.json';
      case '3+2':
      case '5+3':
        return 'Blitz.json';
      case '10+5':
      case '15+10':
        return 'Rapid.json';
      case '25+10':
        return 'Snail.json';
      case 'settings':
        return settings;
      default:
        return 'Rapid.json';
    }
  };

  useEffect(() => {
    if (control === 'settings') {
      handleCustomConfigChange(selected);
    }
  }, [selected]);

  return (
    <Box
      flexDirection="column"
      onPress={onClick}
      style={[
        styles.container,
        styles.normalShadow,
        {
          backgroundColor: color,
          borderColor: selected ? '#4D3F37' : '#fff',
          borderWidth: borderWidth,
          //   aspectRatio: 1,
          //   paddingHorizontal: 31,
          //   paddingVertical: 10,
          marginHorizontal: 5,
          flex: 1,
          minWidth: isMobileOrTab ? hs(90) : hs(70),
          //   flexBasis: hs(70),
          //   width: SCREEN_WIDTH > 1150 ? (wrapFail ? hs(70) : hs(65)) : hs(70),
        },
      ]}>
      <View style={styles.content}>
        {control === 'settings' && (
          <CCText
            textAlign="center"
            fontWeight="thunder"
            style={styles.settingsText}
            fontSize={FontSizes[18]}>
            Additional Settings
          </CCText>
        )}
        {(
          <View style={styles.animationContainer}>
            {selected && <LottieView
                source={require(`../../../../Assets/Animation/${getAnimation()}`)}
                autoPlay
                loop={true}
              />}
            {!selected && 
              <LottieView
                source={require(`../../../../Assets/Animation/${getAnimation()}`)}
                autoPlay
                loop={false}
              />
            }
          </View>
        )}
        {control !== 'settings' && (
          <>
            <Spacer spacing={20} horizontal />

            <CCText
              textAlign="right"
              fontWeight="thunder"
              fontSize={FontSizes[18]}>
              {control}
            </CCText>
          </>
        )}
        {control === 'settings' && (
          <View style={styles.settingsIconContainer}>
            <img src={settings} />
          </View>
        )}
      </View>
    </Box>
  );
};

export default TimeControl;
