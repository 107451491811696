import CCText from "@/Atoms/CCText";
import React, { useState } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
export default function RoundNavigator({ totalRounds, currentRound, setRound }) {
  const handlePrevious = () => {
    if (currentRound > 1) {
      setRound(currentRound - 1);
    }
  };

  const handleNext = () => {
    if (currentRound < totalRounds) {
      setRound(currentRound + 1);
    }
  };

  return (
    <View style={styles.container}>
      <CCText style={styles.roundText}>Round {currentRound}</CCText>
      <TouchableOpacity
        style={styles.button}
        onPress={handlePrevious}
        disabled={currentRound === 1}
      >
        <CCText style={[styles.arrow, currentRound === 1 && styles.disabled]}>{"<"}</CCText>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.button}
        onPress={handleNext}
        disabled={currentRound === totalRounds}
      >
        <CCText style={[styles.arrow, currentRound === totalRounds && styles.disabled]}>{">"}</CCText>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    backgroundColor: "white",
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 3,
    margin: 8
  },
  roundText: {
    fontSize: 16,
    // fontWeight: "bold",
    marginRight: 10,
  },
  button: {
    paddingHorizontal: 10,
  },
  arrow: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#6D4C41",
  },
  disabled: {
    color: "#ccc",
  },
});
