import {StyleSheet} from 'react-native';
import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: vs(12),
    marginVertical: vs(16),
  },
  containerMobile: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 60,
  },
  textSection: {
    flex: 1,
    marginRight: vs(12),
    gap: vs(8),
  },
  textSectionMobile: {
    alignItems: 'flex-start',
    marginRight: 0,
    textAlign: 'center',
  },
  sectionHeader: {
    fontSize: FontSizes[16],
    fontWeight: '500',
    lineHeight: 24,
    color: CCColors?.TextColor?.Primary,
  },
  subHeader: {
    fontSize: FontSizes[14],
    fontWeight: '400',
    lineHeight: 20,
    color: CCColors?.TextColor?.Primary,
    opacity: 0.8,
  },
  cardContainer: {
    flexDirection: 'row',
    gap: vs(12),
    flex: 1,
  },
  cardContainerMobile: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: vs(16),
    width: '100%',
  },
  card: {
    flex: 1,
    paddingLeft: vs(12),
    paddingTop: vs(12),
    borderWidth: 1,
    borderColor: '#22222233',
    borderRadius: vs(8),
    alignItems: 'center',
  },
  cardMobile: {
    width: '100%', // Ensure full width on mobile
    paddingLeft: vs(12),
    paddingTop: vs(12),
    borderWidth: 1,
    borderColor: '#22222233',
    borderRadius: vs(8),
    alignItems: 'center',
  },
  selectedCard: {
    borderColor: CCColors?.TextColor?.Primary,
    borderWidth: 2,
  },
  cardContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  textContainer: {
    flex: 1,
    alignItems: 'flex-start',
    gap: vs(6),
  },
  textContainerMobile: {
    alignItems: 'flex-start',
    textAlign: 'center',
  },
  avatar: {
    width: vs(76),
    height: vs(72),
    resizeMode: 'contain',
  },
  disabledImage: {
    tintColor: CCColors?.Grey?.Grey,
  },
  cardTitle: {
    fontSize: FontSizes[16],
    fontWeight: '600',
    lineHeight: 24,
    color: CCColors?.TextColor?.Primary,
    textAlign: 'left',
  },
  cardSubtitle: {
    fontSize: FontSizes[14],
    fontWeight: '400',
    lineHeight: 20,
    color: CCColors?.TextColor?.LightGrey,
    textAlign: 'left',
  },
  grayedOut: {
    color: '#B0B0B0',
  },
  checkmark: {
    textDecorationColor: CCColors?.TextColor?.Primary,
    position: 'absolute',
    top: vs(-8),
    right: vs(6),
  },
});

export default styles;
