// import React, { useState, useEffect } from 'react';
// import Box from '@/Atoms/Box';
// import CCText from '@/Atoms/CCText';
// import CCColors from '@/Utils/CCColors';
// import { FontSizes, vs } from '@/Utils/Dimensions';
// import CCPackageTable from '@/Atoms/CCPackageTable';
// import useGetTournamentPairings from '@/Hooks/TournamentsApi/useGetTournamentPairings';
// import RoundNavigator from './RoundNavigator';

// const Games: React.FC <{ round: number; tournamentId: string; setGameId: (gameId : string) => void; setActiveTab: (active_tab: string) => void; playing : boolean;  }> = ({ round, tournamentId, setGameId, setActiveTab, playing }) =>  {
//   // Mock data for games

//   const [currentRound, setCurrentRound] = useState(round);
//   const {
//     mutate: fetchTournamentPairings,
//     data: tournamentPairings,
//     isLoading,
//     isError,
//     error,
//   } = useGetTournamentPairings();

//   // Fetch the tournament details when the component mounts
//   useEffect(() => {
//     if (tournamentId) {
//       fetchTournamentPairings({round:currentRound,tournamentId});
//     }
//   }, [currentRound]);

//   console.log("pairings:", tournamentPairings?.data);

//   const gamesData = tournamentPairings?.data?.map(({ player, opponent }) => ({
//     white: player.player_name, // Player is whiteset
//     black: opponent.player_name, // Opponent is black
//     result: player.result || "ongoing",
//     gameId: player.cc_gameid
//   })) || [
//     { white: '', result: '', black: '', gameId: '' },
//   ];

//   const headers = ['White', 'Result', 'Black'];

//   // Transform gamesData into tableData format
//   const tableData = gamesData?.map((game) => {
//     const white = game.white;
//     const result = game.result;
//     const black = game.black;

//     let itemValues: any = [];

//     itemValues.push({
//       name: (
//         <Box style={{ padding: vs(10), width: '100%', height: '100%' }}>
//           <CCText fontSize={FontSizes[12]}>{white}</CCText>
//         </Box>
//       ),
//       type: 'text',
//     });

//     itemValues.push({
//       name: (
//         <Box style={{ padding: vs(10), width: '100%', height: '100%' }}>
//           <CCText fontSize={FontSizes[12]} color={CCColors.Blue}>
//             {result}
//           </CCText>
//         </Box>
//       ),
//       type: 'text',
//     });

//     itemValues.push({
//       name: (
//         <Box style={{ padding: vs(10), width: '100%', height: '100%' }}>
//           <CCText fontSize={FontSizes[12]}>{black}</CCText>
//         </Box>
//       ),
//       type: 'text',
//     });

//     return itemValues;
//   });

//   // Style adjustments for games rows
//   const customFlexArr = [3, 1, 3];

//   // Handle row press (e.g., navigate to game details)
//   const handleRowPress = (rowIndex: number) => {
//     console.log(`Row ${rowIndex} pressed`);
//     if(!playing){
//       const gameId = gamesData[rowIndex].gameId;
//       setGameId(gameId);
//       setActiveTab('notation');
//     }

//     // Example: navigation.navigate('GameDetails', { id: rowIndex });
//   };

//   return (
//     <Box>
//       {/* <CCText
//         textAlign="center"
//         fontSize={FontSizes[14]}
//         fontWeight="bold"
//         style={{ marginBottom: vs(10) }}>
//         Games for round {round}
//       </CCText> */}
//       <RoundNavigator
//         totalRounds={round}
//         currentRound={currentRound}
//         setRound={setCurrentRound}
//       />
//       <CCPackageTable
//         headers={headers}
//         tableData={tableData}
//         flexArr={customFlexArr}
//         handleRowPress={handleRowPress}
//         borderBottomColor={CCColors.BorderBottom}
//         jsxTable={true} // Enables Pressable rows
//       />
//     </Box>
//   );
// };

// export default Games;

import React, {useState, useEffect} from 'react';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import CCColors from '@/Utils/CCColors';
import {FontSizes, vs} from '@/Utils/Dimensions';
import CCPackageTable from '@/Atoms/CCPackageTable';
import useGetTournamentPairings from '@/Hooks/TournamentsApi/useGetTournamentPairings';
import RoundNavigator from './RoundNavigator';
import {Platform, Switch, View} from 'react-native';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import {Image} from 'react-native';
import NoPairings from '@/Assets/Images/Tournaments/no_pairing.svg';
import {RFPercentage} from 'react-native-responsive-fontsize';

const Games: React.FC<{
  round: number;
  tournamentId: string;
  setGameId: (gameId: string) => void;
  setActiveTab: (active_tab: string) => void;
  playing: boolean;
  isMobileOrTab: boolean;
}> = ({
  round,
  tournamentId,
  setGameId,
  setActiveTab,
  playing,
  isMobileOrTab,
}) => {
  const [currentRound, setCurrentRound] = useState(round);
  const {
    mutate: fetchTournamentPairings,
    data: tournamentPairings,
    isLoading,
    isError,
    error,
  } = useGetTournamentPairings();
  const [isOngoing, setIsOngoing] = useState(false);

  useEffect(() => {
    if (tournamentId) {
      fetchTournamentPairings({round: currentRound, tournamentId});
    }
  }, [currentRound, tournamentId]);

  const gamesData =
    tournamentPairings?.data?.map(({player, opponent}) => {
      const white = player?.player_name || 'BYE';
      const black = opponent?.player_name || 'BYE';
      const whiteRating = player?.player_rating || '';
      const blackRating = opponent?.player_rating || '';
      let result = player?.result || '-'; // Default result

      // Handle cases where one player is missing
      if (!player) {
        result = '0-1'; // Black wins if White is missing
      } else if (!opponent) {
        result = '1-0'; // White wins if Black is missing
      }

      return {
        white,
        white_rating: whiteRating,
        black,
        black_rating: blackRating,
        result,
        gameId: player?.cc_gameid || null, // If player is missing, gameId is null
      };
    }) || [];

  const headers = ['White Player', 'Result', 'Black Player'];
  const mobileHeaders = ['Players', 'Result'];
  const filteredGamesData = isOngoing
    ? gamesData.filter(game => game.result === '-')
    : gamesData;

  const tableData = filteredGamesData.map(game => [
    {
      name: (
        <Box
          style={{
            padding: vs(10),
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}>
          <Box style={{alignItems: 'flex-start', width: 'auto'}}>
            <CCText fontSize={FontSizes[12]} style={{alignSelf: 'flex-end'}}>
              {game.white}
              {`(${game.white_rating})`}
            </CCText>
          </Box>
        </Box>
      ),
      type: 'text',
    },
    {
      name: (
        <Box
          style={{
            padding: vs(10),
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}>
          <Box
            style={{
              padding: vs(10),
              width: '40%',
              height: '75%',
              backgroundColor: '#FAF6EB',
              alignItems: 'center',
              borderRadius: 12,
              alignSelf: 'center',
            }}>
            <CCText fontSize={FontSizes[12]}>{game.result}</CCText>
          </Box>
        </Box>
      ),
      type: 'text',
    },
    {
      name: (
        <Box
          style={{
            padding: vs(10),
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}>
          <CCText fontSize={FontSizes[12]}>
            {game.black}
            {`(${game.black_rating})`}
          </CCText>
        </Box>
      ),
      type: 'text',
    },
  ]);
  const mobileTableData = filteredGamesData.map(game => [
    {
      name: (
        <Box
          style={{
            padding: vs(10),
            width: '90%',
            height: '100%',
            alignItems: 'flex-start',
          }}>
          <Box style={{alignItems: 'flex-start', width: 'auto'}}>
            <CCText
              color={CCColors.Primary.Brown}
              fontWeight={500}
              fontSize={!isMobileOrTab ? FontSizes[16] : RFPercentage(2)}
              style={{}}>
              {game.white}
            </CCText>
            <CCText
              fontWeight={500}
              color={CCColors.TextColor.GreyText}
              fontSize={!isMobileOrTab ? FontSizes[14] : RFPercentage(1.8)}
              style={{}}>
              {game.white_rating}
            </CCText>
          </Box>
          <Box style={{alignItems: 'flex-start', width: 'auto'}}>
            <CCText
              color={CCColors.Primary.Brown}
              fontWeight={500}
              fontSize={!isMobileOrTab ? FontSizes[16] : RFPercentage(2)}
              style={{}}>
              {game.black}
            </CCText>
            <CCText
              fontWeight={500}
              color={CCColors.TextColor.GreyText}
              fontSize={!isMobileOrTab ? FontSizes[14] : RFPercentage(1.8)}
              style={{}}>
              {game.black_rating}
            </CCText>
          </Box>
        </Box>
      ),
      type: 'text',
    },
    {
      name: (
        <Box
          flexDirection="column"
          gap={20}
          style={{
            padding: vs(10),
            width: '100%',
            height: '100%',
            alignItems: game.result === '-' ? 'center' : 'flex-start',
          }}>
          {game.result === '-' ? (
            <Box
              style={{
                width: '100%',
                height: '50%',
                justifyContent: !isMobileOrTab ? 'flex-start' : 'center',
                alignItems: !isMobileOrTab ? 'flex-start' : 'center',
                marginRight: 10,
              }}>
              <Image
                style={{
                  width: 80,
                  height: 20,
                }}
                source={require('@/Assets/Images/Svgs/IsOngoing.svg')}
              />
            </Box>
          ) : (
            <>
              <Box style={{justifyContent: 'flex-start'}}>
                <CCText
                  color={
                    game.result.split('-')[0] == 0
                      ? CCColors.Red
                      : game.result.split('-')[0] == 0.5
                      ? CCColors.EvaluationScore.Background
                      : CCColors.Green
                  }
                  fontSize={!isMobileOrTab ? FontSizes[18] : RFPercentage(2)}
                  fontWeight={600}>
                  {game.result.split('-')[0]}
                </CCText>
              </Box>
              <Box>
                <CCText
                  color={
                    game.result.split('-')[1] == 0
                      ? CCColors.Red
                      : game.result.split('-')[1] == 0.5
                      ? CCColors.EvaluationScore.Background
                      : CCColors.Green
                  }
                  fontWeight={600}
                  fontSize={!isMobileOrTab ? FontSizes[18] : RFPercentage(2)}>
                  {game.result.split('-')[1]}
                </CCText>
              </Box>
            </>
          )}
        </Box>
      ),
      type: 'text',
    },
  ]);
  const mobileFlexArr = [4, 1];

  const handleRowPress = (rowIndex: number) => {
    if (!playing && !isOngoing && !isMobileOrTab) {
      const gameId = gamesData[rowIndex].gameId;
      if (gameId) {
        setGameId(gameId);
        setActiveTab('notation');
      }
    }
  };

  return (
    <Box>
      <Box
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          // marginBottom: vs(10),
          marginLeft: isMobileOrTab ? 0 : vs(8),
          width: isMobileOrTab ? '100%' : '50%',
        }}>
        <RoundNavigator
          totalRounds={round}
          currentRound={currentRound}
          setRound={setCurrentRound}
        />
        <Box
          style={{
            backgroundColor: '#FAF6EB',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {width: 0, height: 2},
            shadowOpacity: 0.2,
            shadowRadius: 3,
            elevation: 3,
            height: '75%',
            width: !isMobileOrTab && '50%',
            borderRadius: 8,
          }}>
          <CCText
            style={{
              fontSize: 16,
              marginRight: 10,
              alignSelf: 'center',
              margin: 8,
            }}>
            Ongoing
          </CCText>
          <Switch
            trackColor={{false: '#767577', true: CCColors.Green}}
            thumbColor={isOngoing ? '#f5dd4b' : '#f4f3f4'}
            ios_backgroundColor="#3e3e3e"
            onValueChange={() => setIsOngoing(!isOngoing)}
            value={isOngoing}
            style={{
              marginRight: 8,
            }}
          />
        </Box>
      </Box>
      {tableData?.length > 0 ? (
        <CCPackageTable
          headers={mobileHeaders}
          tableData={mobileTableData}
          flexArr={mobileFlexArr}
          handleRowPress={handleRowPress}
          borderBottomColor={CCColors.BorderBottom}
          jsxTable={true}
          // headerStyle={{alignSelf : 'center'}}
          // margin = {0}
          headerTextColor={CCColors.TextColor.Grey}
          isMargin={false} // Enables Pressable rows
          tournament={true}
          backgroundColorRow={true}
          headerHeight={50}
          tableBorder={false}
          border={true}
          // jsxTable
        />
      ) : (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
            gap: 10,
            marginTop: isMobileOrTab ? 0 : '5%',
          }}>
          <Image
            resizeMode="contain"
            style={{
              width: !isMobileOrTab ? 160 : 100,
              height: !isMobileOrTab ? 160 : 100,
            }}
            source={NoPairings}
          />
          <CCText
            fontSize={FontSizes[20]}
            style={{lineHeight: 20, fontWeight: 500}}>
            No pairing found
          </CCText>
          <CCText
            fontSize={FontSizes[16]}
            color={CCColors?.TextColor?.Dark}
            style={{fontWeight: 400}}>
            Pairing will appear here after sometime.
          </CCText>
        </View>
      )}
    </Box>
  );
};

export default Games;
