import Box from '@/Atoms/Box';
import React, {useEffect, useState, useMemo, useContext} from 'react';
import {styles} from './styles';
import uuid from 'react-native-uuid';
// import {uuid} from 'uuidv4';
import ChessboardComponent from 'chessboard-package';
import CCText from '@/Atoms/CCText';
import {useGetStudyGamesDetail} from '@/Hooks/GetStudyGamesDetailsApi/useGetStudyGamesDetail';
import {useNavigation} from '@react-navigation/native';
import {useRoute} from '@react-navigation/native';
import {ScrollView, Switch, TouchableOpacity, View} from 'react-native';
import {Hover} from 'react-native-gesture-handler';
import CCColors, {updateColorWithOpacity} from '@/Utils/CCColors';
import MovesList from '@/Atoms/MovesList';
import AntDesign from 'react-native-vector-icons/AntDesign';
import SlideModal from '@/Atoms/SlideModal';
import CCTextInput from '@/Atoms/CCTextInput';
import Spacer from '@/Atoms/Spacer';
import CCButton from '@/Atoms/CCButton';
import useManageParticipants from '@/Hooks/ManageStudyParticipantsApi/useManageParticipants';
import CCLoader from '@/Atoms/CCLoader';
import ConfirmationModal from '@/Components/ConfirmationModal';
import useUpdatePgn from '@/Hooks/ManageStudyParticipantsApi/useUpdatePgn';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {
  STARTING_FEN,
  cleanPgn,
  generateMoveData,
  generatePGN,
  generatePGNMovesOnly,
  getStartingFenFromPgn,
  getUserDetails,
} from '@/Utils/CommonUtils';
import useRandomUserlStore from '../Home/store';
import {ScreenName} from '@/navigators/StackConstants';
import CCTabs from '@/Atoms/CCTabs';
import Participants from './Component/Participants';
import Chapters from './Component/Chapters';
import StockfishEngine from '@/Components/StockfishEngine';
import {FontSizes, WINDOW_HEIGHT, WINDOW_WIDTH} from '@/Utils/Dimensions';
import useStudySaveAlternateMoves from '@/Hooks/StudyAlternateMoves/useStudySaveAlternateMoves';
import useStudyUpdateAnnotation from '@/Hooks/StudyUpdateAnnotation/useStudyUpdateAnnotation';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import UpAndDownArrow from '@/Animations/UpAndDownArrow';
import BottomSheet from '@/Atoms/BottomSheet';
import {TabView} from 'react-native-tab-view';
import {renderTabBarPill} from '@/Components/TabBar/TabBarPill';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {MOBILE_HEADER_HEIGHT} from '@/Utils/Constants';
import useChessboardColorTheme from '@/Hooks/ChessboardColorTheme/useChessboardColorTheme';
import InfoIcon from '@/Atoms/InfoIcon';
import CCChessMoves from '@/Atoms/CCChessMoves';
import ChessboardWithArrow from '@/Components/ChessboardWithArrow';
import {SocketProvider} from '@/Providers/SocketProvider';
import {useNotificationStore} from '@/Store/useNotificationStore';
import SettingsTab from './Component/SettingsTab';

function Study() {
  const {isMobileOrTab} = useMediaQuery();
  const route = useRoute();
  const gameClient = useContext(SocketProvider);

  const gameId = route?.params?.study_id;
  const {
    data: studyDetails,
    isLoading,
    error,
    refetch,
  } = useGetStudyGamesDetail(gameId);
  const [chessboardHeight, setChessboardHeight] = useState(0);

  const onChessboardLayout = event => {
    const {height} = event.nativeEvent.layout;
    setChessboardHeight(height);
  };

  const [selectedUser, setSelectedUser] = useState(null);
  const [enableStockfish, setEnableStockfish] = useState(false);
  const [loading, setLoading] = useState(false);
  const [movesList, setMovesList] = useState({});
  const [tabSelected, setTabSelected] = useState(0);
  const navigation = useNavigation();
  const [openRemoveConfimation, setOpenRemoveConfimation] = useState({
    flag: false,
    bodyTxt: '',
    callbackFunc: () => {},
  });
  const [moveSelected, setmoveSelected] = useState({
    move: '',
    id: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    lastFen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  });
  const [allIds, setAllIds] = useState([]);
  const [annotationToShow, setAnnotationToShow] = useState('');
  const [userDetailsData, setUserDetailsData] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [isSyncOn, setIsSyncOn] = useState(true);

  const [participantsList, setParticipantsList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [moveSequences, setMoveSequences] = useState({});
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [index, setIndex] = React.useState(0);
  const [chessboardSize, setChessboardSize] = useState(0);
  const [newChapterCreated, setNewChapterCreated] = useState(false);
  const [boardOrientation, setBoardOrientation] = useState(
    selectedChapter?.orientation === 'W' ? 'white' : 'black',
  );

  const handleFlipBoard = () => {
    setBoardOrientation(prev => (prev === 'white' ? 'black' : 'white'));
  };

  const onParentLayout = event => {
    const {width, height} = event.nativeEvent.layout;
    console.log(height, 'height');
    // Calculate the size based on the smaller of the parent's width and height
    const size = Math.min(width, height);
    setChessboardSize(size);
  };

  const [routes] = React.useState(
    [
      {key: 'PARTICIPANTS', title: 'Participants'},
      {key: 'CHAPTERS', title: 'Chapters'},
      // { key: "previousTournaments", title: "Previous Tournaments" },
    ]?.filter(i => i),
  );
  const {
    mutateAsync: saveAlternateMoves,
    isLoading: isSavingMoves,
    isError,
  } = useStudySaveAlternateMoves();

  const {
    mutateAsync: saveAnnotation,
    isLoading: isSavingAnnotation,
    isError: isSavingAnnotationError,
  } = useStudyUpdateAnnotation();

  const chessboardColorTheme = useChessboardColorTheme();
  // const participantsList = ['You', 'Player 1', 'Player 2'];
  // const {
  //   mutate: manageParticipants,
  //   isLoading: isUpdatingPlayers,
  //   data: manageParticipantsData,
  //   error: manageParticipantsError,
  // } = useManageParticipants();
  const [playerOrChaptersSelect, setPlayerOrChaptersSelect] = useState(0);
  const {
    mutate: updatePgn,
    isLoading: updatePgnLoading,
    // data: updatedPgnData,
    // error: updatedPgnError,
  } = useUpdatePgn(() => {}, handleupdatePgnError);
  // const {userDetails} = useRandomUserlStore();
  const handleupdatePgnError = data => {
    console.log(data, 'error new');
  };

  const handleSaveAnnotation = async () => {
    if (annotationToShow?.length < 1 || !isSyncOn) return;
    const chapterId = selectedChapter?.id;

    await saveAnnotation({
      move_id: moveSelected?.id,
      annotation: annotationToShow,
    });
    setmoveSelected(prev => ({...prev, annotation: annotationToShow}));
    // Update the movelist
    setMovesList(prevMovesList => {
      const updatedMoves = prevMovesList[chapterId]?.map(move => {
        if (move.id === moveSelected.id) {
          return {
            ...move,
            annotation: annotationToShow,
          };
        }
        return move;
      });

      return {
        ...prevMovesList,
        [chapterId]: updatedMoves,
      };
    });
  };

  const handleSaveAnnotationIcon = async annotationIconSelected => {
    if (!isSyncOn) return;
    const chapterId = selectedChapter?.id;

    await saveAnnotation({
      move_id: moveSelected?.id,
      annotation_icon: annotationIconSelected,
    });
    setmoveSelected(prev => ({
      ...prev,
      annotation_icon: annotationIconSelected,
    }));

    // Update the movelist
    setMovesList(prevMovesList => {
      const updatedMoves = prevMovesList[chapterId]?.map(move => {
        if (move.id === moveSelected.id) {
          return {
            ...move,
            annotation: annotationToShow,
            annotation_icon: annotationIconSelected,
          };
        }
        return move;
      });

      return {
        ...prevMovesList,
        [chapterId]: updatedMoves,
      };
    });
  };
  const addExtraKeyForMoves = tempMove => {
    return {
      fen: tempMove?.newFen,
      lastFen: tempMove?.lastFen,
      move: tempMove?.san,
      ...tempMove,
    };
  };
  const setRefetchStudy = useNotificationStore(state => state.setRefetchStudy);
  const refetchStudyFlag = useNotificationStore(state => state.refetchStudy);

  useEffect(() => {
    if (refetchStudyFlag) {
      refetchStudy();
      setRefetchStudy(false);
    }
  }, [refetchStudyFlag]);

  useEffect(() => {
    setAnnotationToShow(moveSelected?.annotation || '');
  }, [moveSelected]);

  // useEffect(() => {
  //   if (isSavingAnnotation) {
  //     refetchStudy();
  //   }
  // }, [isSavingAnnotation]);

  const saveMove = async ({
    parent_id,
    san,
    fen,
    chapter_id,
  }: {
    parent_id: string;
    san: string;
    fen: string;
    chapter_id;
  }) => {
    try {
      const moveData = {
        parent_id: parent_id,
        san: san,
        fen: fen,
        chapter_id: chapter_id,
      };
      const savedMoveId = await saveAlternateMoves(moveData);
      return savedMoveId;
      console.log('Move saved successfully!');
    } catch (error) {
      console.error('Error saving move:', error);
    }
  };

  // const isOwner = useMemo(async () => {
  //   let userDetails = await getUserDetails();
  //   userDetails = JSON.parse(userDetails);
  //   return userDetails?.mobile_number === data?.data?.owner?.mobile_number;
  // }, [data, getUserDetails]);

  // const userDetailsData = useMemo(async () => {
  //   let userDetails = await getUserDetails();
  //   userDetails = JSON.parse(userDetails);
  //   return userDetails;
  // }, [data, getUserDetails]);

  // const handleParticipants = (
  //   phone: string,
  //   action: 'add' | 'remove' = 'add',
  // ) => {
  //   manageParticipants({
  //     phone: phone,
  //     action: action,
  //     gameId: gameId,
  //   });
  // };

  const refetchStudy = () => {
    setLoading(true);
    refetch();
  };

  useEffect(() => {
    setmoveSelected(
      movesList?.[selectedChapter?.id]?.[0] || {
        move: '',
        fen:
          selectedChapter?.fen ||
          'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
        lastFen:
          selectedChapter?.fen ||
          'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
      },
    );
  }, [selectedChapter?.id]);

  const resetBoard = () => {
    setmoveSelected({
      move: '',
      fen:
        studyDetails?.data?.starting_position ||
        'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
      lastFen:
        studyDetails?.data?.starting_position ||
        'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
    });
  };

  const updateUserData = async gameData => {
    let userDetails = await getUserDetails();
    userDetails = JSON.parse(userDetails);
    // return userDetails?.mobile_number === data?.data?.owner?.mobile_number;
    setIsOwner(
      userDetails?.mobile_number ===
        gameData?.data?.owner_details?.mobile_number,
    );
    setUserDetailsData(userDetails);
  };

  // useEffect(() => {
  //   debugger;
  // }, []);

  useEffect(() => {
    setLoading(false);
    if (studyDetails?.data) {
      updateUserData(studyDetails);

      const allPlayers = [
        {
          ...studyDetails?.data?.owner_details,
          pgn: studyDetails?.data?.pgn,
          isDeletable: false,
        },
        ...studyDetails?.data?.participants?.map(i => ({
          ...i,
          isDeletable: true,
        })),
      ];

      setParticipantsList(allPlayers);
      let allChapters = studyDetails?.data?.chapters;
      setChapterList(allChapters);
      if (!selectedChapter) {
        setSelectedChapter(studyDetails?.data?.chapters?.[0]);
      }
      if (newChapterCreated) {
        setSelectedChapter(
          studyDetails?.data?.chapters?.[
            studyDetails?.data?.chapters?.length - 1
          ],
        );
      }
      if (movesList?.length === 0) {
        setmoveSelected({
          ...addExtraKeyForMoves(allChapters?.[0]?.moves?.[0]),
        });
      }
      setSelectedUser(allPlayers?.[0]);
      allChapters?.forEach(eachChapter => {
        let parsedMovesForPgn: any = null;
        if (eachChapter?.moves?.length > 0) {
          parsedMovesForPgn = eachChapter?.moves?.map((i, index) => ({
            ...i,
            move: i?.san,
            newFen: i?.fen,
            isMainBranch: true,
            lastFen:
              eachChapter?.moves?.[index - 1]?.fen ||
              eachChapter?.moves?.[index]?.fen,
          }));
        } else if (eachChapter?.pgn) {
          const startignFenFromPgn = getStartingFenFromPgn(
            cleanPgn(eachChapter?.pgn),
          );
          parsedMovesForPgn = generateMoveData(
            startignFenFromPgn || STARTING_FEN,
            cleanPgn(eachChapter?.pgn),
          );
        } else {
          parsedMovesForPgn = [];
        }

        console.log('parsedMovesForPgn', parsedMovesForPgn);
        setMovesList(prev => ({...prev, [eachChapter?.id]: parsedMovesForPgn}));
      });
    } else {
      // navigation?.navigate(ScreenName?.Dashboard);
    }
  }, [studyDetails]);

  // const handleStockfishResult = result => {
  //   debugger;
  // };
  const handlePositionChange = async tempPosition => {
    const findAndAddMove = async (moves, moveToAdd) => {
      let found = false;
      for (let move of moves) {
        if (move.fen === moveToAdd.lastFen) {
          if (!move.child_moves) {
            move.child_moves = [];
          }
          let savedMove = `${moveToAdd.newFen}`;
          // let savedMove = `${moveToAdd.newFen}`;
          if (isOwner && isSyncOn) {
            savedMove = await saveMove({
              chapter_id: chapterId,
              parent_id: moveSelected?.id,
              san: moveToAdd?.san,
              fen: moveToAdd?.fen,
            });
          }

          setmoveSelected({
            ...moveToAdd,
            id: savedMove?.move_id || savedMove,
            newFen: `${moveToAdd.fen}`,
            fen: `${moveToAdd.fen}`,
            isMainBranch: false,
            child_moves: [],
          });
          move.child_moves.push({
            ...moveToAdd,
            id:
              savedMove?.move_id || `${moveToAdd.fen}` || `${moveToAdd.newFen}`,
            newFen: `${moveToAdd.fen}`,

            isMainBranch: false,
            child_moves: [],
          });
          found = true;
          break;
        }
        if (
          move.child_moves &&
          (await findAndAddMove(move.child_moves, moveToAdd))
        ) {
          found = true;
          break;
        }
      }
      return found;
    };

    const newMove = {
      fen: tempPosition.newFen,
      lastFen: tempPosition.lastFen,
      move: tempPosition.san,
      san: tempPosition.san,
      id: `${tempPosition.newFen}`,
      isMainBranch: false, // Assume not main branch initially
      child_moves: [],
    };

    // Attempt to add the new move
    const chapterId = selectedChapter?.id;
    let moveAdded = false;
    // debugger;
    if (
      chapterId &&
      movesList[chapterId]?.length > 0 &&
      moveSelected?.fen !==
        movesList[chapterId]?.[movesList[chapterId]?.length - 1]?.fen
    ) {
      moveAdded = findAndAddMove(movesList[chapterId], newMove);
    }

    // If no parent move was found, add the new move to the main line
    if (!moveAdded) {
      newMove.isMainBranch = true; // This move is a continuation of the main branch
      let savedMove = `${newMove.fen}_${uuid.v4()}`;
      // let savedMove = `${moveToAdd.newFen}`;
      if (isOwner && isSyncOn) {
        savedMove = await saveMove({
          chapter_id: chapterId,
          parent_id: moveSelected?.id,
          san: newMove?.san,
          fen: newMove?.fen,
        });
      }
      console.log(savedMove, savedMove?.move_id, 'savedMove');
      newMove['id'] = savedMove?.move_id || savedMove;
      setmoveSelected({
        ...newMove,
        id: newMove?.id,
        newFen: `${newMove.fen}`,
        fen: `${newMove.fen}`,
        isMainBranch: false,
        child_moves: [],
      });
      if (!movesList[chapterId]) {
        movesList[chapterId] = []; // Ensure the chapter's moves list is initialized
      }
      movesList[chapterId].push(newMove);
    }

    // Update the movesList state
    setMovesList(prev => ({
      ...prev,
      [chapterId]: [...movesList[chapterId]],
    }));

    // Update the selected move
    setmoveSelected(newMove);

    // Optionally generate and log PGN
    const convertedPgn = generatePGNMovesOnly(movesList[chapterId]);
    console.log(convertedPgn, movesList[chapterId], 'convertedPgn');
  };

  // const handlePositionChange = async tempPosition => {
  //   const findAndAddMove = async (moves, moveToAdd) => {
  //     let found = false;
  //     for (let move of moves) {
  //       if (
  //         move?.id ? move.id === moveToAdd.id : move.fen === moveToAdd.lastFen
  //       ) {
  //         if (!move.child_moves) {
  //           move.child_moves = [];
  //         }
  //         let savedMove = `${moveToAdd.newFen}_${uuid.v4()}`;
  //         if (isOwner && isSyncOn) {
  //           savedMove = await saveMove({
  //             parent_id: moveSelected?.id,
  //             san: moveToAdd?.san,
  //             fen: moveToAdd?.fen,
  //             chapter_id: chapterId,
  //           });
  //         }
  //         setmoveSelected({
  //           ...moveToAdd,
  //           id:
  //             savedMove?.move_id ||
  //             `${moveToAdd.fen}_${uuid.v4()}` ||
  //             `${moveToAdd.newFen}_${uuid.v4()}`,
  //           newFen: moveToAdd.fen,
  //           fen: moveToAdd.fen,
  //           isMainBranch: false,
  //           child_moves: [],
  //         });
  //         move.child_moves.push({
  //           ...moveToAdd,
  //           id:
  //             savedMove?.move_id ||
  //             `${moveToAdd.fen}_${uuid.v4()}` ||
  //             `${moveToAdd.newFen}_${uuid.v4()}`,
  //           newFen: `${moveToAdd.fen}`,

  //           isMainBranch: false,
  //           child_moves: [],
  //         });
  //         found = true;
  //         break;
  //       }

  //       if (
  //         move.child_moves &&
  //         (await findAndAddMove(move.child_moves, moveToAdd))
  //       ) {
  //         found = true;
  //         break;
  //       }
  //     }
  //     return found;
  //   };

  //   const newMove = {
  //     fen: tempPosition.newFen,
  //     newFen: tempPosition.newFen,
  //     lastFen: tempPosition.lastFen,
  //     move: tempPosition.san,
  //     san: tempPosition.san,
  //     id: `${tempPosition.newFen}_${uuid.v4()}`,
  //     isMainBranch: false, // Assume not main branch initially
  //     child_moves: [],
  //   };
  //   // Attempt to add the new move
  //   const chapterId = selectedChapter?.id;
  //   let moveAdded = false;
  //   console.log(
  //     moveSelected?.id !==
  //       movesList[chapterId]?.[movesList[chapterId]?.length - 1]?.id,
  //     moveSelected?.id,
  //     movesList[chapterId]?.[movesList[chapterId]?.length - 1]?.id,
  //     'this moveSelected',
  //   );
  //   if (
  //     chapterId &&
  //     movesList[chapterId] &&
  //     moveSelected?.id !==
  //       movesList[chapterId]?.[movesList[chapterId]?.length - 1]?.id
  //   ) {
  //     moveAdded = await findAndAddMove(movesList[chapterId], newMove);
  //   }

  //   // If no parent move was found, add the new move to the main line
  //   if (!moveAdded) {
  //     newMove.isMainBranch = true; // This move is a continuation of the main branch
  //     if (!movesList[chapterId]) {
  //       movesList[chapterId] = []; // Ensure the chapter's moves list is initialized
  //     }
  //     let savedMove = `${newMove.newFen}`;
  //     if (isOwner && isSyncOn) {
  //       savedMove = await saveMove({
  //         parent_id: moveSelected?.id,
  //         san: newMove?.san,
  //         fen: newMove?.fen,
  //         chapter_id: chapterId,
  //       });
  //     }
  //     newMove.id = savedMove?.move_id;
  //     movesList[chapterId].push({...newMove, id: savedMove?.move_id});
  //   }

  //   // Update the movesList state
  //   setMovesList(prev => ({
  //     ...prev,
  //     [chapterId]: [...movesList[chapterId]],
  //   }));

  //   // Update the selected move

  //   // Optionally generate and log PGN
  //   // const convertedPgn = generatePGNMovesOnly(movesList[chapterId]);
  //   // console.log(convertedPgn, movesList[chapterId], 'convertedPgn');
  // };

  const renderScene = (rendererSceneProps: any) => {
    const {route: tempRoute} = rendererSceneProps;
    // let returnJSX = <></>;
    switch (tempRoute.key) {
      case 'PARTICIPANTS':
        return (
          // <ScrollView style={{flex: 1, paddingVertical: 10}}>
          <Participants
            participantsList={participantsList}
            userDetailsData={userDetailsData}
            gameId={gameId}
            setLoading={setLoading}
            refetchStudy={refetchStudy}
            isOwner={isOwner}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setOpenRemoveConfimation={setOpenRemoveConfimation}
          />
          // </ScrollView>
        );
      case 'CHAPTERS':
        return (
          // <ScrollView style={{flex: 1, paddingVertical: 10}}>
          <Chapters
            chaptersList={chapterList}
            gameId={gameId}
            setLoading={setLoading}
            refetchStudy={refetchStudy}
            isOwner={isOwner}
            selectedChapter={selectedChapter}
            setSelectedChapter={setSelectedChapter}
            setNewChapterCreated={setNewChapterCreated}
            setBoardOrientation={setBoardOrientation}
          />
          // </ScrollView>
        );

      default:
        return PuzzleDetailsJSX;
    }
    // switch (tempRoute.key) {
  };

  const playerOrChaptersTab = [
    {
      key: 0,
      label: 'Chapters',
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        // <ScrollView style={{flex: 1}}>
        <Chapters
          chaptersList={chapterList}
          gameId={gameId}
          setLoading={setLoading}
          refetchStudy={refetchStudy}
          isOwner={isOwner}
          selectedChapter={selectedChapter}
          setSelectedChapter={setSelectedChapter}
          setOpenRemoveConfimation={setOpenRemoveConfimation}
          setNewChapterCreated={setNewChapterCreated}
          setBoardOrientation={setBoardOrientation}
        />
        // </ScrollView>
      ),
    },
    {
      key: 1,
      label: 'Participants',
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        // <ScrollView style={{flex: 1}}>
        <Participants
          participantsList={participantsList}
          userDetailsData={userDetailsData}
          gameId={gameId}
          setLoading={setLoading}
          refetchStudy={refetchStudy}
          isOwner={isOwner}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setOpenRemoveConfimation={setOpenRemoveConfimation}
        />
        // </ScrollView>
      ),
    },
  ];

  const tabDetails = [
    {
      key: 0,
      label: 'Details',
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => leftComponent,
    },
    {
      key: 1,
      label: 'Moves',
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <>
          <Spacer spacing={10} />
          <MovesList
            gameMoves={movesList?.[selectedChapter?.id]}
            activeMove={moveSelected}
            setGameMoves={tempGameMoves => {
              setMovesList(tempGameMoves);
            }}
            // setClicked={setClicked}
            setmoveSelected={setmoveSelected}
            // useFeather={false}
          />
        </>
      ),
    },
  ];

  const leftComponent = (
    <>
      <Box type="row-space-between">
        {/* {isOwner && (
          <Box type="row-flex-end">
            <CCText
              fontWeight="semibold"
              color={isSyncOn ? CCColors.Green : CCColors.Error}>
              {isSyncOn ? 'Synced' : 'Not synced'}
            </CCText>
            <Spacer spacing={10} horizontal />
            <Switch
              trackColor={{
                false: updateColorWithOpacity(CCColors.Error, 0.2),
                true: updateColorWithOpacity(CCColors.Green, 0.2),
              }}
              thumbColor={isSyncOn ? CCColors.Green : CCColors.Error}
              // ios_backgroundColor="#3e3e3e"
              onValueChange={() => setIsSyncOn(!isSyncOn)}
              value={isSyncOn}
            />
          </Box>
        )} */}
        <CCText
          fontSize={FontSizes[18]}
          fontWeight="thunder"
          style={{padding: 10}}>
          {studyDetails?.data?.name}
        </CCText>
      </Box>
      <Box flex={0.8}>
        <ConditionalRender
          condition={isMobileOrTab}
          childrenA={
            <TabView
              lazy
              //   style={styles.contentContainerStyle}
              // @ts-ignore
              navigationState={{index: index, routes}}
              renderScene={renderScene}
              renderTabBar={renderTabBarPill}
              onIndexChange={setIndex}
              initialLayout={{width: WINDOW_WIDTH / 2}}
              // style={{height: '75vh'}}
            />
          }
          childrenB={
            <CCTabs
              tabIndex={playerOrChaptersSelect}
              tabItems={playerOrChaptersTab}
              handleTabChange={({route}) => {
                setPlayerOrChaptersSelect(parseInt(route.key, 10));
              }}
            />
          }
        />
      </Box>
      {/*       
      <Spacer spacing={10} />
      <Box flex={0.25}>
        <CCTextInput
          value={annotationToShow}
          onChangeText={text => setAnnotationToShow(text)}
          // label="Notes"
          placeholder="Notes"
          name="Notes"
          // style={{height: '80%'}}
          numberOfLines={4}
          multiline={true}
        />
        <Spacer spacing={2} />
        <TouchableOpacity
          style={[
            styles.addNewBox,
            (annotationToShow?.length < 1 || !isSyncOn) && styles.disabled,
          ]}
          onPress={handleSaveAnnotation}>
          <CCText textAlign="center" color={CCColors.White}>
            Save
          </CCText>
        </TouchableOpacity>
      </Box> */}
    </>
  );
  if (isMobileOrTab) {
    return (
      <Box style={styles.mobileContainer}>
        {/* <Box style={styles.infoContainer}> */}
        <Box flex={1} onLayout={onChessboardLayout}>
          <ChessboardWithArrow
            position={moveSelected?.fen || selectedChapter?.fen || 'start'}
            boardOrientation={boardOrientation}
            lastMoveFen={moveSelected?.lastFen}
            handleNewFen={handlePositionChange}
            disabled={isSavingMoves}
            colorTheme={chessboardColorTheme}
          />
        </Box>
        {console.log(chessboardHeight, 'chessboardHeight')}
        {/* <UpAndDownArrow /> */}
        {chessboardHeight && (
          <BottomSheet
            minHeight={WINDOW_HEIGHT - chessboardHeight - MOBILE_HEADER_HEIGHT}>
            {/* <View style={{flex: 1}}> */}
            <CCTabs
              tabIndex={tabSelected}
              tabItems={tabDetails}
              handleTabChange={({route}) => {
                setTabSelected(parseInt(route.key, 10));
                // if (route.key === 1) {
                //   setFilter('participant');
                // } else {
                //   setFilter('owner');
                // }
              }}
            />
            {/* </View> */}
          </BottomSheet>
        )}
        <CCLoader loading={loading || isLoading || isSavingAnnotation} />
      </Box>
    );
  }

  return (
    <Box>
      <Box style={styles.container} onLayout={onParentLayout}>
        <Box style={styles.left}>
          {/* <ScrollView flex={1} style={{height: '85vh'}}> */}
          {leftComponent}
          {/* </ScrollView> */}
        </Box>
        <Box
          // overflow="hidden"
          style={[
            styles.center,
            {
              width: chessboardSize,
              height: chessboardSize,
            },
            // {flex: isMobileOrTab ? 1 : playing || started ? 0.48 : 0.5},
          ]}>
          <Box
            style={[
              styles.chessboardContainer,

              // {flex: isMobileOrTab ? 1 : playing || started ? 0.48 : 0.5},
            ]}>
            <ChessboardWithArrow
              position={moveSelected?.fen || selectedChapter?.fen || 'start'}
              boardOrientation={boardOrientation}
              lastMoveFen={moveSelected?.lastFen}
              handleNewFen={handlePositionChange}
              disabled={isSavingMoves}
              colorTheme={chessboardColorTheme}
              annotation={moveSelected?.annotation_icon}
            />
            {isOwner && (
              <Box type={'row-center-between'} marginTop={10}>
                <Box type="row-flex-start">
                  <>
                    <CCText
                      fontWeight="semibold"
                      color={isSyncOn ? CCColors.Green : CCColors.Error}>
                      Sync
                    </CCText>
                    <Spacer spacing={5} horizontal />
                    <Switch
                      trackColor={{
                        false: updateColorWithOpacity(CCColors.Error, 0.2),
                        true: updateColorWithOpacity(CCColors.Green, 0.2),
                      }}
                      thumbColor={isSyncOn ? CCColors.Green : CCColors.Error}
                      // ios_backgroundColor="#3e3e3e"
                      onValueChange={() => setIsSyncOn(!isSyncOn)}
                      value={isSyncOn}
                    />
                  </>
                </Box>
                <SettingsTab
                  commentToShow={annotationToShow}
                  setCommentToShow={setAnnotationToShow}
                  isSyncOn={isSyncOn}
                  handleSaveComment={handleSaveAnnotation}
                  handleSaveAnnotationIcon={handleSaveAnnotationIcon}
                  annotation_icon={moveSelected?.annotation_icon}
                  handleFlipBoard={handleFlipBoard}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box style={styles.right}>
          <Box>
            <Spacer spacing={10} />
            <CCText>
              <InfoIcon size={15} />
              &nbsp; To promote or delete a branch, right-click on the very last
              move of that branch.
            </CCText>
            <MovesList
              gameMoves={movesList?.[selectedChapter?.id]}
              activeMove={moveSelected}
              // setClicked={setClicked}
              setGameMoves={tempGameMoves => {
                setMovesList(tempGameMoves);
              }}
              setLoading={tempLoading => {
                setLoading(tempLoading);
              }}
              refetchStudy={refetchStudy}
              setmoveSelected={setmoveSelected}
              // useFeather={false}
            />
          </Box>
        </Box>
        <ConfirmationModal
          open={openRemoveConfimation?.flag}
          bodyTxt={openRemoveConfimation?.bodyTxt}
          handleClose={() =>
            setOpenRemoveConfimation({flag: false, bodyTxt: ''})
          }
          handleConfirmation={openRemoveConfimation?.callbackFunc}
        />
      </Box>
      <CCLoader loading={loading || isLoading} />
    </Box>
  );
}

export default Study;
