import {StyleSheet} from 'react-native';
import {FontSizes, hs, isPhone, vs} from '@/Utils/Dimensions';

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // marginTop: 22,
  },
  modalView: {
    width: isPhone ? '90%' : 540,
    height: 'auto',
    maxHeight: '80%',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 35,
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  closeButton: {
    backgroundColor: 'white',
    borderColor: '#22222240',
    borderWidth: 1,
    height: 30,
    width: 30,
    cursor: 'pointer',
  },
  animationContainer: {
    width: '60%',
    aspectRatio: 1,
    marginBottom: 14,
    overflow: 'hidden',
  },
  textContainer: {
    width: '85%',
  },
  messageText: {
    color: 'black',
    textAlign: 'center',
  },
});
