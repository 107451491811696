import {StyleSheet} from 'react-native';
import {
  isPortrait,
  isPhone,
  SCREEN_WIDTH,
  WINDOW_WIDTH,
  WINDOW_HEIGHT,
} from '@/Utils/Dimensions';
import CCColors from '@/Utils/CCColors';

const isSmallerWindow = WINDOW_WIDTH < 1212 || WINDOW_HEIGHT < 800;
export const styles = StyleSheet.create({
  mainView: {
    // flex: isPhone || isPortrait ? 1 : 0.48,
    height: '74vh',
    borderRadius: 16,
    borderWidth: 2,
    borderColor: '#FACF47',
    margin: 5,
  },
  contentContainer: {
    height: '90%',
    margin: SCREEN_WIDTH < 1212 ? 20 : 30,
    flexDirection: 'column',
    overflow: 'visible',
    justifyContent: 'space-between',
  },
  mainHeading: {
    position: 'relative',
    height: '20%',
    overflow: 'visible',
    marginTop: 10,
    marginBottom: 20,
    marginLeft: SCREEN_WIDTH < 1212 ? 0 : 19,
  },
  backgroundImg: {
    width: 691,
    height: '100%',
    top: 0,
    right: 0,
    position: 'absolute',
    backgroundColor:
      'linear-gradient(90deg, #4D3F37 0%, rgba(77, 63, 55, 0) 107.23%)',
  },
  tilesContainer: {
    height: '70%',
    position: 'relative',
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    margin: isSmallerWindow ? 5 : 19,
    padding: isSmallerWindow ? 15 : 30,
    gap: WINDOW_WIDTH < 1212 ? 10 : 25,
    flexDirection: 'column',
  },
  knight_elephants_logo: {
    margin: 'auto',
    position: 'absolute',
    width: isSmallerWindow ? 110 : 220,
    height: isSmallerWindow ? 60 : 120,
    right: 10,
    top: isSmallerWindow ? -51 : -102,
    display: 'flex',
  },
  tileLogo: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  tileTitle: {color: CCColors.Primary.Dark},
  tileDescription: {color: '#A8A7A6'},
  circle: {
    width: 8,
    height: 8,
    borderRadius: 6,
    marginHorizontal: 3,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.4)',
  },
  circleActive: {
    backgroundColor: CCColors.White, // White when active
  },
  circleInactive: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)', // Transparent when inactive
  },
  mobilebg: {
    // width:'75vh',
    height: '90%',
    overflow: 'hidden',
  },
  desktopbg: {
    // width:'75vh',
    height: '75vh',
    marginTop: '4%',
    borderRadius: 16,
    overflow: 'hidden',
    margin: '3%',
  },
  mobileView: {
    height: '90%',
    margin: 5,
    borderWidth: 2,
    borderColor: '#FACF47',
  },
  mobileSubView: {
    height: '90%',
    margin: SCREEN_WIDTH < 1212 ? 10 : 30,
    flexDirection: 'column',
    overflow: 'visible',
    justifyContent: 'space-between',
  },
  deskSubView: {
    height: '90%',
    margin: SCREEN_WIDTH < 1212 ? 20 : 30,
    flexDirection: 'column',
    overflow: 'visible',
    justifyContent: 'space-between',
  },
  mobileImage: {
    height: '40%',
    position: 'relative',
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    // margin: isSmallerWindow ? 5 : 19,
    // padding: isSmallerWindow ? 15 : 30,
    gap: WINDOW_WIDTH < 1212 ? 10 : 25,
    flexDirection: 'column',
  },
  deskImage: {
    height: '70%',
    position: 'relative',
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    margin: isSmallerWindow ? 5 : 19,
    padding: isSmallerWindow ? 15 : 30,
    gap: WINDOW_WIDTH < 1212 ? 10 : 25,
    flexDirection: 'column',
  },
  carouselRules: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 2,
  },
});
