import CCColors from '@/Utils/CCColors';
import {vs} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';
export const styles = StyleSheet.create({
  container: {
    margin: vs(0),
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  cclabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  dropdown: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  hideBorder: {
    backgroundColor: CCColors.White,
    borderWidth: 0,
    borderColor: CCColors.White,
  },
  dropdownContentContainer: {
    width: '100%',
    backgroundColor: CCColors.White,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    height: '100%',
  },
  searchInput: {
    padding: 10,
    borderBottomWidth: 1,
    borderColor: CCColors.Grey.Grey,
  },
  dropdownContent: {
    flex: 1,
  },
  dropdownItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 15,
  },
  dropdownBox: {
    // width: WINDOW_WIDTH < 1000 ? 100 : 120,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 5,
    borderColor: CCColors.Box.Boxborder,
    justifyContent: 'center',
    backgroundColor: CCColors.White,
  },
});
