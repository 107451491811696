import {useEffect, useRef} from 'react';

const StockfishEngine = ({fen, depth, variations, engineOn, onResult}) => {
  const stockfish: any = useRef(null);

  useEffect(() => {
    stockfish.current = new Worker('/stockfish.js');

    stockfish.current.onmessage = function (event) {
      if (event.data.startsWith('info') && event.data.includes('pv')) {
        const match = event.data.match(/multipv (\d+) .+ pv (.+)/);
        const regex = /baseTurn (\w)/;
        const cpScoreRegex = /score cp ([\-+]?[0-9]+)/;
        const mateScoreRegex = /score mate ([\-+]?[0-9]+)/;

        const matchBaseTurn = event.data.match(regex);
        const matchCpScore = event.data.match(cpScoreRegex);
        const matchMateScore = event.data.match(mateScoreRegex);

        let baseTurn = 'w';
        let extractedScore = 0;
        let isMate = false;

        if (matchBaseTurn) {
          baseTurn = matchBaseTurn[1];
        }

        if (matchCpScore) {
          extractedScore = matchCpScore[1];
        } else if (matchMateScore) {
          extractedScore = matchMateScore[1];
          isMate = true;
        }

        if (match) {
          const variationNumber = parseInt(match[1], 10);
          const movesSAN = match[2].split(' ');
          onResult(
            variationNumber,
            movesSAN,
            baseTurn,
            extractedScore,
            isMate,
            event?.data,
          );
        }
      }
    };

    return () => {
      stockfish.current.terminate();
    };
  }, []);

  useEffect(() => {
    console.log('depth: ', depth);
    if (engineOn) {
      stockfish.current.postMessage('ucinewgame');
      stockfish.current.postMessage('isready');
      stockfish.current.postMessage(`position fen ${fen}`);
      stockfish.current.postMessage(
        `setoption name MultiPV value ${variations}`,
      );
      stockfish.current.postMessage(`go depth ${depth} movetime 10000`);
    } else {
      stockfish.current.postMessage('stop');
    }
  }, [fen, depth, variations, engineOn]);
};

export default StockfishEngine;
