import {getLocalStorage} from '@/Utils/CommonUtils';
import Loader from '@/Atoms/CCLoader/CCLoader';
import React, {useEffect} from 'react';

const Redirect = () => {
  useEffect(() => {
    const redirect = async () => {
      const token = await getLocalStorage('access_token');
      window.location.href = `https://lms.circlechess.com/?token=${token}`;
    };
    redirect();
  }, []);

  return <Loader loading={true} />;
};

export default Redirect;
