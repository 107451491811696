import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import CCText from '@/Atoms/CCText';

const RatingComponent = ({ rating, change, variant }) => {
  const isIncrease = change > 0;
  const isNeutral = change === 0;
  const changeColor = isNeutral ? '#B0B0B0' : isIncrease ? '#00B7A2' : '#E05F5F';
  const changeIcon = isNeutral ? 'remove' : isIncrease ? 'trending-up' : 'trending-down';

  return (
    <View style={styles.container}>
      <MaterialIcons name={changeIcon} size={24} color={changeColor} />
      <CCText style={styles.ratingText}>CircleChess Rating</CCText>
      <CCText style={styles.ratingValue}>{rating}</CCText>
      <View style={[styles.changeBox, { backgroundColor: changeColor }]}>
        <CCText style={styles.changeText}>{change > 0 ? `+${change}` : change}</CCText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
  },
  ratingText: {
    marginLeft: 4,
    marginRight: 8,
    fontSize: 16,
    fontWeight: '100',
    color: '#3B302A',
  },
  ratingValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3B302A',
    marginRight: 8,
  },
  changeBox: {
    borderRadius: 4,
    padding: 4,
  },
  changeText: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
  },
});

export default RatingComponent;
