import Box from '@/Atoms/Box';
import CCButton from '@/Atoms/CCButton';
import CCText from '@/Atoms/CCText';
import {Colors} from '@/styles';
import CCColors from '@/Utils/CCColors';
import {useRef, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {styles} from '../../styles';
import {setLocalStorage} from '@/Utils/CommonUtils';
import {ScreenName} from '@/navigators/StackConstants';
import NestedDropDown from '../NestedDropdown';
import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {FontSizes} from '@/Utils/Dimensions';

const UserAccessDropdown = ({
  dropdownData,
  dropdown,
  item,
  summaryData,
  setModifyMainMenu,
  setDropdown,
  setOpen,
  setHeaderName,
}) => {
  const [nestedDropdown, setNestedDropdown] = useState(false);
  const [guardian, setGuardian] = useState('');
  const [hoveredItem, setHoveredItem] = useState(null);
  const nestedUserAccessRef = useRef(null);
  const navigation = useNavigation();
  if (!dropdown || item?.title !== 'User access') return null;
  const iconMapping = {
    Coach: 'account-cowboy-hat-outline',
    Parent: 'account-plus-outline',
    Mentor: 'book-open-page-variant-outline',
    Students: 'account-group-outline',
    Admin: 'cog-outline',
  };
  const dropdownItems = Object.keys(dropdownData).map(key => ({
    key: key.toLowerCase(),
    title: key,
    icon: iconMapping[key] || 'question-circle',
    onPress: () => {
      console.log('dropdown item clicked:', key);
    },
  }));

  const nestedDropdownItems =
    guardian && Array.isArray(dropdownData[guardian])
      ? dropdownData[guardian].map(user => {
          const title = user?.full_name;
          const words = title?.split(' ');

          // Logic for icon text:
          let iconText = '';
          if (words.length === 2) {
            iconText = `${words[0][0]}${words[1][0]}`.toUpperCase();
          } else {
            iconText = title?.substring(0, 2).toUpperCase();
          }
          return {
            key: user?.user_key,
            title: user?.full_name,
            value: user?.user_id,
            icon: (
              <Box
                style={styles.usericon}
                justifyContent="center"
                alignItems="center">
                <CCText
                  color={CCColors.Primary.Brown}
                  fontSize={FontSizes[10]}
                  fontWeight={'600'}>
                  {iconText}
                </CCText>
              </Box>
            ),
            onPress: async () => {
              console.log('Navigating with user_key:', user?.user_key);
              navigation.navigate(ScreenName.AssessmentReport, {
                user_key: `${user?.user_key}`,
              });
              setHeaderName(user?.full_name);
              setModifyMainMenu(true);
              await setLocalStorage('user_key', `${user?.user_key}`);
              await setLocalStorage('profile_access', user?.full_name);
            },
          };
        })
      : [];

  return (
    <Box flexDirection="column">
      {dropdownItems.map((dropdownItem, index) => (
        <Box
          key={dropdownItem.key}
          style={{
            width: 150,
            borderRadius: 8,
            shadowColor: Colors.primaryDark,
            shadowRadius: 8,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            shadowOpacity: 0.2,
            position: 'relative',
          }}>
          {hoveredItem === dropdownItem.key && nestedDropdown && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{
                position: 'absolute',
                top: 0,
                right: '100%',
                maxHeight: 450,
                borderRadius: 8,
                shadowColor: Colors.primaryDark,
                shadowRadius: 8,
                shadowOffset: {
                  width: 0,
                  height: 0,
                },
                shadowOpacity: 0.2,
                borderBottomWidth:
                  index === dropdownData?.length - 1
                    ? 0
                    : StyleSheet.hairlineWidth,
                borderColor: CCColors.Grey.Border,
              }}>
              <NestedDropDown
                dropdownData={nestedDropdownItems}
                setNestedDropdown={setNestedDropdown}
                setDropdown={setDropdown}
                setOpen={setOpen}
              />
            </ScrollView>
          )}
          <View ref={nestedUserAccessRef}>
            <CCButton
              onPress={() => {
                dropdownItem.onPress();
                setHoveredItem(dropdownItem.key);
                setGuardian(dropdownItem.title);
                setNestedDropdown(!nestedDropdown);
              }}
              style={styles.userMenuItem}
              contentContainerStyle={styles.userMenuItemContent}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Box
                  flexDirection="column"
                  style={{
                    borderBottomWidth:
                      index === dropdownItems?.length - 1
                        ? 0
                        : StyleSheet.hairlineWidth,
                    borderColor: CCColors.Grey.Border,
                    paddingVertical: 8,
                  }}>
                  <Box
                    flexDirection="row"
                    style={{width: 130}}
                    justifyContent="space-between">
                    <Box
                      flexDirection="row"
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <MaterialCommunityIcons
                        name={dropdownItem.icon}
                        size={18}
                        style={styles.userMenuItemIcon}
                      />
                      <CCText style={styles.userMenuItemText}>
                        {dropdownItem.title}
                      </CCText>
                    </Box>
                    <Box>
                      <MaterialCommunityIcons
                        name={
                          hoveredItem === dropdownItem.key && nestedDropdown
                            ? 'chevron-up'
                            : 'chevron-down'
                        }
                        size={14}
                        style={{
                          fontWeight: 'bold',
                        }}
                        color={CCColors.Black}
                      />
                    </Box>
                  </Box>
                </Box>
              </View>
            </CCButton>
          </View>
        </Box>
      ))}
    </Box>
  );
};

export default UserAccessDropdown;
