import { useMutation } from '@tanstack/react-query';
import TournamentRestClient from '@/Api/TournamentRestClient';

export default function useGetTournamentDetails(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation(
    async (tournamentId: number) => {
      const response = await TournamentRestClient.get(`/tournament/${tournamentId}`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (handleOnSuccess) handleOnSuccess(data);
      },
      onError: (error) => {
        if (handleOnError) handleOnError(error);
      },
    }
  );

  return mutation;
}

